import { Menu, Spin } from 'antd';
import React, { memo } from 'react';
import { CustomMenuProps } from '../models';
import '../styles.css';

const CustomMenu = memo((props: CustomMenuProps) => {
  return (
    <Menu>
      <Spin spinning={props.loading}>
        {props.questionnaires.map((template: any) => {
          return (
            <React.Fragment key={template.id}>
              <Menu.Item key={template?.id}>
                <div onClick={() => props.assignQuestionnaire(template.id)}>
                  <span>Questionnaire {template?.id}: </span>
                  <span>{template?.name}</span>
                </div>
              </Menu.Item>
              <Menu.Divider />
            </React.Fragment>
          );
        })}
      </Spin>
    </Menu>
  );
});

export default CustomMenu;

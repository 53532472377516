import { useEffect, useState } from 'react';
import { ProjectsService, TypesServices } from 'api/services/index';
import { Button, Col, Layout, Row, Spin, Pagination } from 'antd';
import { Header } from 'components/index';
import { Link } from 'react-router-dom';
import { CardList } from 'components/index';
import './styles.css';
import { useRedux } from 'src/hooks';

const HomeView = (props: any) => {
  const { Content } = Layout;
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  let archivedProjectList: any = [];
  const { Save, Get } = useRedux();
  const { searchResultProject } = Get().app;
  const role = localStorage.getItem('userRole');
  const userId = localStorage.getItem('userId');

  const getTypes = async () => {
    let res = await TypesServices.getTypes();
    if (res) {
      Save({ roomTypes: res }, 'app');
    }
  };

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {
    Save({ expandedItem: undefined }, 'app');
  }, []);

  const getProjects = async (offset = 1) => {
    const result = await ProjectsService.projectList({
      limit: 10,
      offset: (offset - 1) * 10,
    });

    if (result) {
      setCount(result.count);
      for (let item of result.results) {
        if (item.is_archived === false && role === 'admin') {
          if (searchResultProject === '') {
            archivedProjectList.push(item);
          } else {
            if (item.name === searchResultProject) {
              archivedProjectList.push(item);
            }
          }
        } else if (role !== 'admin') {
          if (item.users) {
            for (let i of item.users) {
              if (i.id === Number(userId)) {
                archivedProjectList.push(item);
              }
            }
          }
        }
      }

      setProjects(archivedProjectList);
      Save({ projectsLength: archivedProjectList.length }, 'app');
    }
    setLoading(false);
  };

  useEffect(() => {
    getProjects();
  }, [searchResultProject]);
  return (
    <Layout className="home">
      <Header search={true} />

      <Content>
        {loading ? (
          <div className="spin-container">
            <Spin size="default" />
          </div>
        ) : (
          <>
            <Row className="recent-row">
              <Col
                xl={12}
                xs={12}
              >
                <p>Recent Projects ({projects && projects.length})</p>
              </Col>
              {localStorage.getItem('userRole') !== 'client' && (
                <Col
                  xl={12}
                  className="archived-btns"
                  xs={12}
                >
                  <span className="recent-span">
                    <Button>
                      <Link to="/archived-projects">Show Archived</Link>
                    </Button>
                  </span>
                </Col>
              )}
            </Row>
            <div className="card-row">
              <Row>
                {projects && (
                  <>
                    <CardList
                      project={projects}
                      projectListType="activate"
                    />
                  </>
                )}
              </Row>
            </div>
            {count !== 0 && (
              <div className="pagination-container">
                <Pagination
                  total={count}
                  pageSize={10}
                  onChange={(e) => getProjects(e)}
                />
              </div>
            )}
          </>
        )}
      </Content>
    </Layout>
  );
};

export default HomeView;

import { debug } from "src/tools";
export const apiErrorMessage = (location, err) => {
  const circular = {};
  circular.circular = circular;
  let cache = [];
  JSON.stringify(circular, (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (cache.includes(value)) return;
      cache.push(value);
    }
    return value;
  });
  cache = null;
  if (err.response) {
    const { status } = err.response;
    if (status === 400) {
      return;
    }
  } else {
    debug.error(err);
  }
};

import './styles.css';
import { ViewProps } from './models';
import { PlusSVG } from 'assets/index';
import { Button } from 'antd';

const CirclePlusButtonView = (props: ViewProps) => {
  const { style, btnText, disabled, onClick } = props;

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      style={style}
      className="container"
    >
      <div className="pink-circle">
        <img
          src={PlusSVG}
          alt="plus"
        />
      </div>
      <div className="title-container">{btnText}</div>
    </Button>
  );
};

export default CirclePlusButtonView;

import { Button } from "antd";
import { ClockCircleOutlined, CheckOutlined } from "@ant-design/icons";
import { PropsType } from "./models";

function InprogressButtons(props: PropsType) {
  const { status,isArchived } = props;
  return (
    <Button className={!isArchived  ? "completed-btn" : ""}>
      {status !== "completed" && <ClockCircleOutlined />}
      <span>{isArchived ? 'Inactive' : <>Active <CheckOutlined /></>}</span>
      {/* <span>
        Status: {status === "in_progress" && <span> In progress</span>}
        {status === "inactive" && <span>Inactive</span>}
        {status === "working" && <span>Working</span>}
        {status === "completed" && (
          <span>
            Completed
            <CheckOutlined />
          </span>
        )} 
      </span>*/}
    </Button>
  );
}

export default InprogressButtons;

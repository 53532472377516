import { useState } from 'react';
import { Props } from './models';
import QuestionsScreenView from './questionsScreen-view';
import { message } from 'antd';
import './styles.css';

export const QuestionsScreen = (props: Props) => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSucceedModalVisible, setIsSucceedModalVisible] = useState(false);

  const handleOk = () => {
    setIsConfirmModalVisible(false);
    setIsSucceedModalVisible(true);
    message.success({
      content: 'Template has been deleted successfuly',
    });
  };

  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };

  const showConfirmModal = () => {
    setIsConfirmModalVisible(true);
  };

  return (
    <QuestionsScreenView
      onClick={showConfirmModal}
      showConfirmModal={showConfirmModal}
      isConfirmModalVisible={isConfirmModalVisible}
      isSucceedModalVisible={isSucceedModalVisible}
      handleCancel={handleCancel}
      handleOk={handleOk}
      {...props}
    />
  );
};

export default QuestionsScreen;

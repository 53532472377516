import './styles.css';
import { ViewProps } from './models';
import UserProjectCardItem from './UserProjectCardItem';

const CardUserProjectView = (props: ViewProps) => {
  const { data, user, getUser } = props;

  return (
    <div className="card-container">
      {data &&
        data.map((project) => (
          <UserProjectCardItem
            key={project.id}
            project={project}
            user={user}
            getUser={getUser}
          />
        ))}
    </div>
  );
};

export default CardUserProjectView;

import { Layout, Row, Col } from 'antd';
import { ProfileSider } from 'components/index';
function ProfileView() {
  const { Header } = Layout;

  return (
    <div>
      <Header>
        <Row>
          <Col
            xs={24}
            xl={12}
            offset={8}
          >
            <ProfileSider />
          </Col>
        </Row>
      </Header>
    </div>
  );
}

export default ProfileView;

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Spin } from 'antd';
import { Questionnaire } from './components';
import { QuestionnaireViewProps } from './models';
import './styles.css';
import ReviewQuestionnaire from './components/ReviewQuestionnaire';
import { useRedux } from 'src/hooks';

const QuestionnaireView = (props: QuestionnaireViewProps) => {
  const { Get } = useRedux();
  const { questionnaireStatus } = Get().app;
  return (
    <>
      <div className="questionnaire_container">
        <div className="questionnaire-tamplates_container">
          {props.questionnaires.length > 0 &&
            localStorage.getItem('userRole') === 'admin' && (
              <Dropdown
                overlay={props.menu}
                trigger={['click']}
              >
                <a
                  href="/"
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Add Questionaire from Template <DownOutlined />
                </a>
              </Dropdown>
            )}
        </div>
        <div className="questionnaire-lists_container">
          <Spin spinning={props.loading}>
            {props.questionnaireLists.length > 0 &&
              props.questionnaireLists.map((questionnaire: any) => {
                return (
                  <Questionnaire
                    allQuestionsAndAnswers={props.allQuestionsAndAnswers}
                    key={questionnaire?.id}
                    id={props.idOfproject}
                    questionnaireId={questionnaire.id}
                    questionnaire_pk={questionnaire.questionnaire_pk}
                    setLoading={props.setLoading}
                    getProjectQuestionnaire={props.getProjectQuestionnaire}
                    name={questionnaire.name}
                    questionnaire_list={questionnaire.questionnaire_list}
                  />
                );
              })}
          </Spin>
          {questionnaireStatus === 'review' && (
            <ReviewQuestionnaire pageData={props.allQuestionsAndAnswers} />
          )}
        </div>
      </div>
    </>
  );
};

export default QuestionnaireView;

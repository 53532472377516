import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import {
  Login,
  Home,
  QuestionTemplates,
  ArchivedProjects,
  Profile,
  AddProject,
  Member,
  UserProject,
  ForgotPass,
} from './screens';
import Reducers from './redux/index';
import { createStore } from 'redux';
import { PrivateRoute } from './routes/PrivateRoute';
import { AdminRoute } from './routes/AdminRoute';
import ResetPassword from './screens/forgotPassword/ResetPassword';
function App() {
  const [store] = useState(createStore(Reducers));

  if (
    window.location.pathname === '/login' &&
    localStorage.getItem('rememberMe') === 'true' &&
    localStorage.getItem('userData')
  ) {
    window.location.href = '/home';
  } else if (
    window.location.pathname === '/login' &&
    localStorage.getItem('rememberMe') !== 'true'
  ) {
    localStorage.clear();
  }

  useEffect(() => {
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  }, []);
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route
            component={Login}
            path="/login"
          />

          <PrivateRoute
            component={Home}
            path="/home"
          />
          <PrivateRoute
            component={QuestionTemplates}
            path="/question-list"
          />
          <PrivateRoute
            component={ArchivedProjects}
            path="/archived-projects"
          />
          <PrivateRoute
            component={Profile}
            path="/profile"
          />
          <AdminRoute
            exact
            component={Member}
            path="/members"
          />
          <AdminRoute
            exact
            component={UserProject}
            path="/members/:id"
          />

          <AdminRoute
            exact
            component={AddProject}
            path="/add-project"
          />
          <PrivateRoute
            exact
            component={AddProject}
            path="/add-project/:id/"
          />
          <Route
            component={ForgotPass}
            path="/forgot-password"
          />
          <Route
            component={ResetPassword}
            path="/reset-password/"
          />

          <Route
            exact
            path="/"
          >
            <Redirect to="/home" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

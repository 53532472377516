import { api } from 'api/api';

export const NotificationsServices = {
  sendCheck: async (project_pk: string, section_name: string) => {
    return await api
      .post(`/send-check/${project_pk}/${section_name}/`, null, true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err, 'err');
        return null;
      });
  },
  checkNotif: async (notification_id: string) => {
    return await api
      .get(`/check-notification/${notification_id}/`, null, true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.log(err, 'err');
        return null;
      });
  },
};

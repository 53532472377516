import { deleteQuestionnaire } from 'src/api/services';
import { PenSVG, TrashSVG, downloadFile } from 'src/assets';
import { ConfirmModal } from 'src/components';
import { useRedux, useWindowSize } from 'src/hooks';
import { QuestionnaireListItem } from '.';
import { QuestionnaireProps } from '../models';

import { Button } from 'antd';
let role = localStorage.getItem('userRole');
const Questionnaire = (props: QuestionnaireProps) => {
  const { Save, Get } = useRedux();

  const {
    showModal,
    questionnaireStatus,
    questoinnaireDeleteId,
    expandedItem,
  } = Get().app;
  const size: any = useWindowSize();
  const DeleteQuestionnaire = async () => {
    props.setLoading(true);
    try {
      const result = await deleteQuestionnaire(
        props.id,
        questoinnaireDeleteId!
      );
      if (result) {
        props.getProjectQuestionnaire();
        Save({ showModal: false }, 'app');
        Save(
          { expandedItem: undefined, questionnaireStatus: 'notShow' },
          'app'
        );
      }
      props.setLoading(false);
    } catch (err) {
      console.log(err);
      props.setLoading(false);
    }
  };

  return (
    <div className="longItem-with-detail">
      <div className="longItem-container">
        <div className="left-part">
          <span
            className="title"
            onClick={() => {
              Save(
                {
                  expandedItem: props.questionnaireId,
                  questionnaireStatus: 'edit',
                },
                'app'
              );
            }}
          >
            {props.name}
          </span>
          {'  '}
          <span className="length">
            ({props.questionnaire_list.length}{' '}
            {props.questionnaire_list.length > 1 ? 'Categories' : 'Category'})
          </span>
        </div>
        <div className="right-container">
          {expandedItem === props.questionnaireId && (
            <button
              onClick={() => {
                Save(
                  { expandedItem: undefined, questionnaireStatus: 'notShow' },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
              className="categoriy_close-btn"
            >
              Close
            </button>
          )}
          {(questionnaireStatus === 'notShow' || !questionnaireStatus) &&
            role !== 'client' && (
              <div>
                {size.width && size.width > 800 ? (
                  <Button
                    className="download-questionnaire-btn"
                    onClick={() => {
                      Save({ questionnaireStatus: 'review' }, 'app');
                    }}
                  >
                    Review and Download questionnaire
                  </Button>
                ) : (
                  <div
                    onClick={() => {
                      Save({ questionnaireStatus: 'review' }, 'app');
                    }}
                    className="pen-container"
                  >
                    <img
                      src={downloadFile}
                      alt=""
                    />
                  </div>
                )}
              </div>
            )}
          <div
            onClick={() => {
              Save(
                {
                  expandedItem: props.questionnaireId,
                  questionnaireStatus: 'edit',
                  editable: true,
                },
                'app'
              );
            }}
            className="pen-container"
          >
            <img
              src={PenSVG}
              alt={'pen'}
            />
          </div>
          {role === 'admin' && (
            <div
              onClick={() => {
                Save({ showModal: true }, 'app');
                Save({ questoinnaireDeleteId: props.questionnaire_pk }, 'app');
              }}
              className="trash-container"
            >
              <img
                src={TrashSVG}
                alt={'trash'}
              />
            </div>
          )}
        </div>
      </div>

      {questionnaireStatus === 'edit' &&
        expandedItem === props.questionnaireId && (
          <QuestionnaireListItem
            questionnaireListData={props.questionnaire_list}
            getProjectQuestionnaire={props.getProjectQuestionnaire}
            project_id={props.id}
            template_id={props.questionnaireId}
          />
        )}
      {/* {questionnaireStatus === 'review' && (
        // <ReviewQuestionnaire pageData={props.allQuestionsAndAnswers} />
      )} */}
      <ConfirmModal
        questionTopText={
          'You are about to delete this Questionnaire. Are you sure? '
        }
        questionText={''}
        cancelText={'Yes, Delete Questionnaire'}
        okText={'Cancel'}
        isModalVisible={showModal}
        handleOk={() => DeleteQuestionnaire()}
        handleCancel={() => Save({ showModal: false }, 'app')}
      />
    </div>
  );
};

export default Questionnaire;

import { api } from "api/api";
import { apiErrorMessage } from "../../helpers/apiErrorHandling.js";
import { ResponseType } from "../index";

type ItemSizes = {
  id: number;
  sizes: Array<string>;
};
type CategoryType = {
  id: number;
  category: string;
  items: Array<ItemSizes>;
};

export const CategoryServices = {
  getCategory: async (
    badRequestCallback?: (err: any) => void
  ): Promise<ResponseType<CategoryType> | null> => {
    return await api
      .get("/categories", null, true)
      .then((response) => {
        return response.data as ResponseType<CategoryType>;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("CategoryServices -> getCategory: ", err);
        return null;
      });
  },
};

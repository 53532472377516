import { Layout } from 'antd';
import {
  CirclePlusButton,
  Header,
  QuestionList,
  ConfirmModal,
} from 'components/index';
import React from 'react';
import { strings } from 'src/common';
import { ViewProps } from './models';
import './styles.css';

const QuestionsScreenView = (props: ViewProps) => {
  const { Content } = Layout;

  const {
    showConfirmModal,
    isConfirmModalVisible,
    isSucceedModalVisible,
    handleCancel,
    handleOk,
  } = props;

  return (
    <Layout className="home">
      <Header />
      <Content>
        <QuestionList showConfirmModal={showConfirmModal} />
      </Content>
      <ConfirmModal
        questionTopText={'Delete: Preliminary Information questionnaire'}
        questionText={
          'This action can NOT be undone.  Are you sure you want to continue?'
        }
        okText={strings.uppercaseCancel}
        cancelText={'YES, DELETE TEMPLATE'}
        isModalVisible={isConfirmModalVisible}
        handleOk={handleOk}
        isSucceedModalVisible={isSucceedModalVisible}
        handleCancel={handleCancel}
      />
    </Layout>
  );
};

export default QuestionsScreenView;

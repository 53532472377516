import { Button, Card, Row } from 'antd';
import { useState } from 'react';
import {
  DeleteUploadedFilesServices,
  PresentationService,
} from 'src/api/services';
import { Alert } from 'ui-kits/index';
import { CarouselModal, FileItem, UploadFile } from 'src/components';
import { useRedux } from 'src/hooks';
import { ModalType } from '../ProjectData/models';
import { strings } from 'src/common';
const UploadPresentation = ({ getPresentationList }: any) => {
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [showCarousel, setShowCarousel] = useState(false);
  const { Save } = useRedux();
  const [carouselIndex, setCarouselIndex] = useState(0);

  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }
    let newMeetingFiles = [...files];
    let typeOfFile = newMeetingFiles.find(function (value: any) {
      return value.id === fileId;
    });
    let editedFile = newMeetingFiles.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'presentation_attachment',
        fileId
      );
      if (result) {
        setFiles(editedFile);
      }
    } else {
      setFiles(editedFile);
    }
  };
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  const createPresentation = async () => {
    setLoading(true);
    if (files.length === 0) {
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setLoading(false);
        setModal((prev) => ({ ...prev, show: false }));
      }, 2000);
      return;
    }
    let formData: any = new FormData();

    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded') {
          formData.append(`presentations[${i}]presentation_file`, files[i].file);
          formData.append(`presentations[${i}]presentation_file_title`, files[i].name);
          formData.append('project', idOfproject);
          // formData.append('get_file_size', files[i].size);
        }
      }
      await PresentationService.createPresentation(
        idOfproject,
        formData
      ).then((res) => {
        if (res) {
          setModal({ show: true, type: 'success' });
          setTimeout(() => {
            setLoading(false);
            getPresentationList();
            Save({ presentationStatus: 'list' }, 'app');
          }, 2000);
        } else {
          setModal({ show: true, type: 'error' });
        }
      });
    }
    setLoading(false);
    getPresentationList();
    Save({ presentationStatus: 'list' }, 'app');
    setTimeout(() => {
      setLoading(false);
      setModal((prev) => ({ ...prev, show: false }));
    }, 2000);
  };

  return (
    <Card className="presentation-card">
      <UploadFile
        text="Drag and drop file here"
        width={'100%'}
        height={110}
        setFiles={setFiles}
        reduxProperty={'meetingImages'}
        // onlyPdf
        // hasSingleFile
      />
      <div className="meeting-fileItem">
        {files &&
          files.map((item: any, idx: number) => {
            const { id, name, size, attachment_title, type } = item;

            return (
              <FileItem
                isEditable={false}
                key={id}
                format={item.format}
                disabled={localStorage.getItem('userRole') !== 'admin'}
                fileData={item.file.attachment}
                name={type === 'unUploaded' ? name : attachment_title}
                id={id}
                size={size}
                file={item}
                type={type}
                width={'100%'}
                showImagePress={() => showCarouselImage(idx)}
                removeFilePress={removeFilePress}
                onEdit={(rotateImageData: any) => {
                  return;
                }}
              />
            );
          })}
      </div>
      <Row justify="end">
        <Button
          className="cancel-btn"
          onClick={() => {
            Save({ presentationStatus: 'list' }, 'app');
          }}
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          className="save-btn"
          onClick={() => createPresentation()}
        >
          Save
        </Button>
      </Row>
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'Presentation has been added successfully '}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}
    </Card>
  );
};

export default UploadPresentation;

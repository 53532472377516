import { Button, Table } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { useRedux } from 'src/hooks';
import { ConfirmModal } from 'components/index';
import { UsersListType } from 'src/api/services';
import { TrashSVG } from 'src/assets';
import './styles.css';
type PropsTypes = {
  referrer: UsersListType[];
  deleteUser: (record: any) => Promise<void>;
};
const ProjectTableView = ({ referrer, deleteUser }: PropsTypes) => {
  const { Save, Get } = useRedux();
  const { showDeleteModal } = Get().app;

  let userId = localStorage.getItem('userId');
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '5%',
    },
    {
      title: 'Referrer',
      dataIndex: 'ref',
      key: 'reffer',
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '25%',
    },

    {
      title: '',
      key: 'edit',
      width: '5%',
      dataIndex: 'edit',
      responsive: ['md', 'xs'] as Breakpoint[],
      render: (text: any, record: any) => (
        <div className="table-btn">
          {Number(userId) !== record.id && (
            <Button
              className="trash-container"
              onClick={() =>
                Save(
                  {
                    showDeleteModal: {
                      isVisible: true,
                      deleteItem: record,
                    },
                  },
                  'app'
                )
              }
            >
              <img
                src={TrashSVG}
                alt="trash"
              />
            </Button>
          )}
        </div>
      ),
    },
  ];
  return (
    <div className="table-user">
      <Table
        dataSource={referrer}
        columns={columns}
        pagination={false}
      />
      <ConfirmModal
        handleOk={() => {
          showDeleteModal?.deleteItem && deleteUser(showDeleteModal.deleteItem);
        }}
        handleCancel={() =>
          Save({ showDeleteModal: { isVisible: false, deleteItem: 0 } }, 'app')
        }
        isModalVisible={
          showDeleteModal?.isVisible && showDeleteModal?.isVisible
        }
        okText={'Cancel'}
        cancelText={'Yes I am sure'}
        questionTopText={'Do you want to delete this user?'}
        questionText={''}
      />
    </div>
  );
};

export default ProjectTableView;

import { memo } from "react";
import "./styles.css";
import { ViewProps } from "./models";

const LongItem = (props: ViewProps) => {
  const {
    type,
    Goods,
    MessageBoard,
    Questionnaire,
    ProjectData,
    Rooms,
    Meeting,
  } = props;

  switch (type) {
    case "Goods":
      return <Goods />;
    case "MessageBoard":
      return <MessageBoard />;
    case "Questionnaire":
      return <Questionnaire />;
    case "ProjectData":
      return <ProjectData />;
    case "Rooms":
      return <Rooms />;
    case "Meeting":
      return <Meeting />;

    default:
      return <></>;
  }
};

export default memo(LongItem);

import { memo, useEffect, useState, useMemo } from 'react';
import { Button, Layout, Modal } from 'antd';
import { useRedux, useWindowSize } from 'src/hooks';
import { HelperIcon } from 'assets/index';
import { Header } from 'components/index';
import { Size } from './models';
import useComponent from './useComponent';
import { AuthServices, UsersListType } from 'src/api/services';
import Messages from 'src/ui-kits/Messages';
import './styles.css';
import { useHistory } from 'react-router-dom';
import { extraItem, tabItems } from './tabItems';
const { Content } = Layout;
const AddProject = () => {
  const { Get, Save } = useRedux();
  const {
    enableAddProjectTabs,
    terminalContentStatus,
    goodsContentStatus,
    roomContentStatus,
    paymentShowContent,
    messageBoardContentStatus,
    insprationContentStatus,
    questionnaireStatus,
    activeTab,
    projectDataContentStatus,
    conversationStatus,
    clientInformationStatus,
  } = Get().app;
  const userRole = localStorage.getItem('userRole');
  const { tabContent } = useComponent();
  const [showModalHelper, setShowModalHelper] = useState(false);
  const [warning, setWarning] = useState<
    | 'Status'
    | 'Goods'
    | 'Room'
    | 'Meeting'
    | 'Conversation'
    | 'Inspiration'
    | 'Changes'
    | 'Questionnaire'
    | 'Project Data'
    | 'Client Information'
    | ''
  >('');
  // size of current screen
  const size: Size = useWindowSize();
  let allUsers: Array<UsersListType> = [];

  //path
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  useMemo(
    async () =>
      await AuthServices.usersList({}).then((res) => {
        if (res) {
          for (let i of res.results) {
            allUsers.push(i);
          }
          Save({ users: allUsers }, 'app');
        }
      }),
    []
  );

  useEffect(() => {
    return () => {
      Save(
        {
          expandedItem: undefined,
          goodsContentStatus: 'NotShow',
          questionnaireStatus: 'notShow',
          terminalContentStatus: 'NotShow',
          roomContentStatus: 'NotAdd',
          paymentShowContent: 'NotShow',
          messageBoardContentStatus: 'NotShow',
          insprationContentStatus: 'notShowInspration',
        },
        'app'
      );
    };
  }, []);

  useEffect(() => {
    if (path === '/add-project' && idOfproject === undefined) {
      Save({ projectFormsType: 'AddProject', activeTab: 'PROJECTDATA' }, 'app');
    }
    if (idOfproject) {
      Save({ enableAddProjectTabs: false }, 'app');
    }
  }, [path, idOfproject]);

  const changeTab = (
    activeButton:
      | 'PROJECTDATA'
      | 'ROOMS'
      | 'GOODS'
      | 'USERS'
      | 'TIMELINE'
      | 'PAYMENT'
      | 'QUESTIONNAIRE'
      | 'MESSAGEBOARD'
      | 'PRESENTATION'
  ) => {
    Save({ editable: true }, 'app');
    const isFieldsTouched = JSON.parse(
      localStorage.getItem('isFieldsTouched') as string
    );
    if (isFieldsTouched) {
      if (terminalContentStatus === 'Add' || terminalContentStatus === 'edit') {
        if (userRole === 'admin') {
          setWarning('Status');
        } else {
          Save({ activeTab: activeButton }, 'app');

          setWarning('');
        }
      } else if (
        goodsContentStatus === 'AddNew' ||
        goodsContentStatus === 'editGoods'
      ) {
        if (
          userRole === 'admin' ||
          userRole === 'client' ||
          userRole === 'designer' ||
          userRole === 'concierge'
        ) {
          setWarning('Goods');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (
        roomContentStatus === 'Add' ||
        roomContentStatus === 'editRoom'
      ) {
        if (
          userRole === 'admin' ||
          userRole === 'client' ||
          userRole === 'designer' ||
          userRole === 'concierge'
        ) {
          setWarning('Room');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (
        paymentShowContent === 'Add' ||
        paymentShowContent === 'edit'
      ) {
        if (userRole === 'admin') {
          setWarning('Meeting');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (
        messageBoardContentStatus === 'Add' ||
        messageBoardContentStatus === 'editMessageBoard'
      ) {
        if (userRole === 'admin') {
          setWarning('Conversation');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (
        conversationStatus === 'chat' ||
        conversationStatus === 'edit'
      ) {
        if (userRole === 'admin') {
          setWarning('Conversation');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (clientInformationStatus === 'edit') {
        if (userRole === 'admin') {
          setWarning('Client Information');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (
        insprationContentStatus === 'addInspration' ||
        insprationContentStatus === 'editInspration'
      ) {
        if (userRole === 'admin' || userRole === 'client') {
          setWarning('Inspiration');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (
        projectDataContentStatus === 'AddInspirationItem' ||
        projectDataContentStatus === 'EditProjectDetail'
      ) {
        if (userRole === 'admin' || userRole === 'client') {
          setWarning('Project Data');
        } else {
          Save({ activeTab: activeButton }, 'app');
          setWarning('');
        }
      } else if (questionnaireStatus === 'edit') {
        setWarning('Questionnaire');
      } else {
        Save({ activeTab: activeButton }, 'app');
        setWarning('');
      }
    } else {
      Save({ activeTab: activeButton }, 'app');
      setWarning('');
    }
  };

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        //@ts-ignore
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          Save({ expandedItem: undefined }, 'app');
        } else {
          //@ts-ignore
          setLocationKeys((keys) => [location.key, ...keys]);

          Save({ expandedItem: undefined }, 'app');
        }
      }
    });
  }, [locationKeys]);
  const mapSource =
    userRole === 'admin' ? tabItems.concat(extraItem) : tabItems;
  const TopMenu = () => {
    return (
      <>
        <div
          style={
            size.width && size.width < 900
              ? { width: size.width }
              : { width: '100%' }
          }
          className="top-menu-container"
        >
          {mapSource.map((item: any) => (
            <Button
              key={item.id}
              disabled={
                item.id === 'PROJECTDATA' ? false : enableAddProjectTabs
              }
              onClick={() => changeTab(item.id)}
              style={item.style}
              className={
                size.width && size.width < 900
                  ? 'addProject-button-responsive'
                  : 'addProject-button'
              }
              icon={
                <img
                  style={item.styleImage}
                  src={item.src}
                  alt=""
                />
              }
            >
              {item.text}
            </Button>
          ))}
        </div>
      </>
    );
  };
  const handleOkHelperModal = () => {
    changeTab('MESSAGEBOARD');
    setShowModalHelper(false);
  };

  return (
    <Layout className="home">
      <Header />
      <Content>
        <div className="project-container-main-navigation">
          <TopMenu />
        </div>

        <div className="project-container-main">{tabContent(activeTab)}</div>
      </Content>
      {warning !== '' && warning === 'Questionnaire' ? (
        <Messages
          onClose={() => setWarning('')}
          type="warning"
          description={`Please answer the questions, or close it.`}
        />
      ) : (
        warning !== '' && (
          <Messages
            onClose={() => setWarning('')}
            type="warning"
            description={`Please Save the form or Cancel changes!`}
          />
        )
      )}

      <div
        className="helper_container"
        onClick={() => {
          setShowModalHelper(true);
        }}
      >
        <img
          src={HelperIcon}
          alt=""
        />{' '}
      </div>

      <Modal
        className="helper-modal"
        visible={showModalHelper}
        onCancel={() => setShowModalHelper(false)}
        onOk={handleOkHelperModal}
        footer={[
          <Button
            className="save-btn"
            onClick={handleOkHelperModal}
          >
            Ok
          </Button>,
          <Button
            className="cancel-btn"
            onClick={() => setShowModalHelper(false)}
          >
            No
          </Button>,
        ]}
      >
        <p style={{ textAlign: 'center' }}>
          Have a question?
          <br />
          Go to "Message Board" and <br />
          "Start a new conversation"!
        </p>
      </Modal>
    </Layout>
  );
};

export default memo(AddProject);

// import React from 'react'
import UsersView from './users-view';
import React, { useState, useEffect } from 'react';
import { Props, StyleSheet, ActiveButton } from './models';
import { Row, Divider } from 'antd';
import { strings } from 'common/index';
import {
  AssignedProject,
  All,
  User,
  Admin,
  Clients,
  Concierge,
  Reffer,
  Designers,
} from './components/Tabs';
import { UsersListType, ProjectsService } from 'src/api/services';
import { useRedux } from 'src/hooks';
import './styles.css';

export type referrerType = {
  id: number;
  name: string | null;
  reffer: Array<string>;
};
export const Users = (props: Props) => {
  let path = window.location.pathname;
  let idPath = path.split('/');
  let idOfproject: any = idPath[2];
  enum Buttons {
    ALL,
    ADMINISTORS,
    DESIGNERS,
    CONCIERGES,
    CLIENTS,
    REFFER,
    ASSIGNEDPROJECT,
    USER,
  }

  const [activeButton, setActiveButton] = useState<ActiveButton>(Buttons.ALL);
  const {
    ALLButton,
    ADMINButton,
    DESIGNERSButton,
    REFFERButton,
    ASSIGNEDPROJECTButton,
    CONCIERGESButton,
    CLIENTSButton,
  } = styles({ Buttons, activeButton });

  const [allusers, setUsers] = useState<Array<UsersListType>>([]);
  const [refferrer, setRefferrer] = useState<any>([]);
  const [clients, setClients] = useState<Array<UsersListType>>([]);
  const [admin, setAdmin] = useState<Array<UsersListType>>([]);
  const [designer, setDesigner] = useState<Array<UsersListType>>([]);
  const [concierge, setconcierge] = useState<Array<UsersListType>>([]);
  const [assignedProject, setAssaignedProject] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  // const [projectDetail, setProjectDetail] = useState([]);
  // const [newListUser, setnewListUser] = useState<any>([]);

  // redux
  const { Get, Save } = useRedux();
  const { users } = Get().app;
  const filterUsersByRole = () => {
    if (users) {
      let filterRef = users.filter((user: any) => user.role === 'referrer');
      let filterConcierge = users.filter(
        (user: any) => user.role === 'concierge'
      );
      let filterDesigner = users.filter(
        (user: any) => user.role === 'designer'
      );
      let filterAdmin = users.filter((user: any) => user.role === 'admin');
      let filterClients = users.filter((user: any) => user.role === 'client');

      setRefferrer(filterRef);
      setClients(filterClients);
      setDesigner(filterDesigner);
      setconcierge(filterConcierge);
      setAdmin(filterAdmin);
      setUsers(users);
    }
  };

  const projectUserList = async (offset = 1) => {
    setLoading(true);
    await ProjectsService.getProjectUsers(idOfproject, {
      limit: 10,
      offset: (offset - 1) * 10,
    }).then((res) => {
      if (res && res.users) {
        setCount(res.count);
        setLoading(false);
        setAssaignedProject(res.users);
        Save({ usersAssignedInProject: res.users }, 'app');
      }
    });
  };

  let filterNewListOfUsers: any = [];
  let updatedListId: any = [];
  let newFormData: any = new FormData();
  const removeUserFromProject = async (record: any) => {
    if (record) {
      filterNewListOfUsers = assignedProject.filter(
        (e: any) => e.id !== record.id
      );
    }
    if (filterNewListOfUsers && filterNewListOfUsers.length !== 0) {
      filterNewListOfUsers.map((e: any) => {
        newFormData.append('users', e.id);
      });
    }
    await ProjectsService.updateUserInProject(idOfproject, newFormData).then(
      (res) => {
        if (res) {
          Save({ showDeleteModal: { isVisible: false, deleteItem: 0 } }, 'app');
          projectUserList();
        }
      }
    );
  };
  const addUserToThisProject = async (record: any) => {
    if (record) {
      if (assignedProject && assignedProject.length !== 0) {
        assignedProject.map((e: any) => {
          filterNewListOfUsers.push(e.id);
        });
      }
      updatedListId = filterNewListOfUsers.push(record.id);

      if (filterNewListOfUsers && filterNewListOfUsers.length !== 0) {
        filterNewListOfUsers.map((e: any) => {
          newFormData.append('users', e);
        });
      }
    }
    await ProjectsService.updateUserInProject(idOfproject, newFormData).then(
      (res) => {
        if (res) {
          Save({ showAddModal: { isVisible: false, addItem: 0 } }, 'app');
          projectUserList();
        }
      }
    );
  };
  useEffect(() => {
    projectUserList();
    filterUsersByRole();
  }, []);
  const TabContent = () => {
    switch (activeButton) {
      case Buttons.ADMINISTORS:
        return (
          <Admin
            users={admin}
            addUserToThisProject={addUserToThisProject}
            removeUserFromProject={removeUserFromProject}
          />
        );
      case Buttons.ALL:
        return (
          <All
            addUserToThisProject={addUserToThisProject}
            removeUserFromProject={removeUserFromProject}
            projectUserList={projectUserList}
            users={allusers}
            loading={loading}
            count={count}
            idOfproject={idOfproject}
          />
        );
      case Buttons.CLIENTS:
        return (
          <Clients
            users={clients}
            removeUserFromProject={removeUserFromProject}
            addUserToThisProject={addUserToThisProject}
          />
        );
      case Buttons.CONCIERGES:
        return (
          <Concierge
            addUserToThisProject={addUserToThisProject}
            users={concierge}
            removeUserFromProject={removeUserFromProject}
          />
        );
      case Buttons.REFFER:
        return (
          <Reffer
            addUserToThisProject={addUserToThisProject}
            users={refferrer}
            removeUserFromProject={removeUserFromProject}
          />
        );
      case Buttons.DESIGNERS:
        return (
          <Designers
            addUserToThisProject={addUserToThisProject}
            users={designer}
            removeUserFromProject={removeUserFromProject}
          />
        );
      case Buttons.USER:
        return <User />;
      default:
        return (
          <AssignedProject
            users={assignedProject}
            removeUserFromProject={removeUserFromProject}
          />
        );
    }
  };

  const TopMenu = () => {
    return (
      <div className="top-menu-container-users ">
        <Row justify="start">
          <span
            onClick={() => setActiveButton(Buttons.ALL)}
            style={ALLButton}
          >
            {strings.All}({users && users.length})
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => setActiveButton(Buttons.ADMINISTORS)}
            style={ADMINButton}
          >
            {strings.Administrators}({admin && admin.length})
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => setActiveButton(Buttons.DESIGNERS)}
            style={DESIGNERSButton}
          >
            {strings.Designers}({designer && designer.length})
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => setActiveButton(Buttons.CONCIERGES)}
            style={CONCIERGESButton}
          >
            {strings.Concierges}({concierge && concierge.length})
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => setActiveButton(Buttons.CLIENTS)}
            style={CLIENTSButton}
          >
            {strings.Clients}({clients && clients.length})
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => setActiveButton(Buttons.REFFER)}
            style={REFFERButton}
          >
            {strings.Referrer}({refferrer && refferrer.length})
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => setActiveButton(Buttons.ASSIGNEDPROJECT)}
            style={ASSIGNEDPROJECTButton}
          >
            {strings.AssignedProject}(
            {assignedProject && assignedProject.length})
          </span>
        </Row>
      </div>
    );
  };
  const userTabContent = () => {
    return <AssignedProject users={assignedProject} />;
  };
  const userTopMenu = () => {
    return (
      <div className="top-menu-container-users ">
        <Row justify="start">
          <span
            onClick={() => setActiveButton(Buttons.ASSIGNEDPROJECT)}
            style={ASSIGNEDPROJECTButton}
          >
            {strings.AssignedProject}
          </span>
        </Row>
      </div>
    );
  };
  return (
    <>
      {
        <UsersView
          TabContent={
            localStorage.getItem('userRole') === 'client'
              ? userTabContent
              : TabContent
          }
          TopMenu={
            localStorage.getItem('userRole') === 'client'
              ? userTopMenu
              : TopMenu
          }
          {...props}
        />
      }
    </>
  );
};

export default React.memo(Users);

const styles: StyleSheet = ({ Buttons, activeButton }) => {
  return {
    ALLButton: {
      color:
        activeButton === Buttons.ALL
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    ADMINButton: {
      color:
        activeButton === Buttons.ADMINISTORS
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    DESIGNERSButton: {
      color:
        activeButton === Buttons.DESIGNERS
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    CONCIERGESButton: {
      color:
        activeButton === Buttons.CONCIERGES
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    CLIENTSButton: {
      color:
        activeButton === Buttons.CLIENTS
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    REFFERButton: {
      color:
        activeButton === Buttons.REFFER
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    ASSIGNEDPROJECTButton: {
      color:
        activeButton === Buttons.ASSIGNEDPROJECT
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },
    USERButton: {
      color:
        activeButton === Buttons.USER
          ? 'var(--blue-button-color)'
          : 'var(--near-white-color)',
    },

    ALLImage: {
      filter: activeButton === Buttons.ALL ? 'brightness(219%)' : undefined,
    },
    ADMINISTORSImage: {
      filter: activeButton === Buttons.ADMINISTORS ? 'invert(1)' : undefined,
    },
    DESIGNERSImage: {
      filter: activeButton === Buttons.DESIGNERS ? 'invert(1)' : undefined,
    },
    CONCIERGESImage: {
      filter: activeButton === Buttons.CONCIERGES ? 'invert(1)' : undefined,
    },
    USERsImage: {
      filter: activeButton === Buttons.USER ? 'invert(1)' : undefined,
    },
    REFFERImage: {
      filter: activeButton === Buttons.REFFER ? 'invert(1)' : undefined,
    },
    ASSIGNEDPROJECTImage: {
      filter:
        activeButton === Buttons.ASSIGNEDPROJECT ? 'invert(1)' : undefined,
    },
  };
};

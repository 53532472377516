import { Link } from 'react-router-dom';
import { Layout } from 'antd';
import { CirclePlusButton } from 'components/index';
import { strings } from 'src/common';
import { useRedux } from 'src/hooks';
import { ViewProps } from './models';
import './styles.css';

const UsersView = ({ TabContent, TopMenu }: ViewProps) => {
  const { Save } = useRedux();
  return (
    <Layout className="home">
      {localStorage.getItem('userRole') === 'admin' && (
        <Link
          to="/members"
          onClick={() => {
            Save({ userStatusConten: 'addUser' }, 'app');
          }}
        >
          <CirclePlusButton btnText={strings.addNewUser} />
        </Link>
      )}
      <div className="users-container">
        <TopMenu />
        <TabContent />
      </div>
    </Layout>
  );
};

export default UsersView;

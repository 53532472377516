import React, { useState, useEffect } from 'react';
import {
  Card,
  Row,
  Form,
  Col,
  Input,
  Button,
  Select,
  List,
  Spin,
  Pagination,
  Tooltip,
} from 'antd';
import {
  FileItem,
  UploadFile,
  CarouselModal,
  ConfirmModal,
} from 'components/index';
import { FileType, ModalType } from './models';
import { CirclePlusButton } from 'components/index';

import './styles.css';
import { Alert } from 'ui-kits/index';

import { strings } from 'common/languages/index';
import InfiniteScroll from 'react-infinite-scroller';
import { Checkbox } from 'antd';
import { RoomItem } from './components/index';
import {
  ProjectsService,
  RoomServices,
  TypesServices,
} from 'api/services/index';
import { useRedux } from 'hooks/index';
import { NotificationsServices } from 'src/api/services/notifications';
import { RotateImage } from 'src/components/RotateImage';
const RoomsView = () => {
  const { Get, Save } = useRedux();
  const [showCarousel, setShowCarousel] = useState(false);
  const {
    editingProjectName,
    roomImages,
    roomContentStatus,
    roomTypes,
    checkboxStatus,
  } = Get().app;

  const [removeModal, setRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState<string>();
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  useEffect(() => {}, [roomContentStatus]);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [isRotateImageActiveFloorPlan, setIsRotateImageActiveFloorPlan] =
    useState(false);
  const [name, setName] = useState('');
  const [type, setType] = useState<number>(0);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pdfs, setPdfs] = useState<FileType[]>([]);
  const [rooms, setRooms] = useState<any>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [disable, setDisable] = useState(false);
  const [count, setCount] = useState(0);
  const [showCarouselPdf, setShowCarouselPdf] = useState(false);
  const [CarouselFileIndex, setCarouseFilelIndex] = useState(0);
  const [checkStatus, setCheckStatus] = useState(checkboxStatus?.rooms);
  const [checkStatusModal, setCheckStatusModal] = useState(false);
  const [allRoomsHaveFile, setallRoomsHaveFile] = useState(false);
  const [degree, setDegree] = useState(0);
  const [rotateImageData, setRotateImageData] = useState(null);
  const [rotateFloorPlanData, setRotateFloorPlanData] = useState(null);
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };

  const showPdf = (index: number) => {
    setCarouseFilelIndex(index);
    setShowCarouselPdf(true);
  };
  let role = localStorage.getItem('userRole');
  const { TextArea } = Input;
  const { Option } = Select;

  let path = window.location.pathname;
  let idPath = path.split('/');
  let idOfproject: any = idPath[2];

  const getTypes = async () => {
    let res = await TypesServices.getTypes();
    if (res) {
      Save({ roomTypes: res }, 'app');
    }
  };

  const convertData = (value: any) => {
    let newData = [];
    for (let perRoom of value) {
      let newFiles = [];
      for (let file of perRoom.photo_or_video) {
        let newFile = {
          ...file,
          size: {
            type: file.get_photo_size < 100000 ? 'KB' : 'MB',
            size:
              file.get_photo_size < 100000
                ? Math.round(file.get_photo_size / 10) / 10
                : Math.round(file.get_photo_size / 100000) / 10,
          },
        };
        newFiles.push(newFile);
      }
      let newRoom = {
        ...perRoom,
        photo: newFiles,
      };
      newData.push(newRoom);
    }
    return newData;
  };

  const createRoom = async () => {
    if (name === '' || type === undefined || (role ==='clinet' && description ==='')) {
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 3000);
      return;
    }

    let formData: any = new FormData();
    if (files.length !== 0 || pdfs.length !== 0) {
      for (let pdfIndex in pdfs) {
        if (pdfs[pdfIndex].type === 'unUploaded') {
          formData.append(
            `floor_plan[${pdfIndex}]floor_plan_title`,
            pdfs[pdfIndex].name
          );
          formData.append(
            `floor_plan[${pdfIndex}]floor_plan`,
            pdfs[pdfIndex].file
          );
          if (pdfs[pdfIndex].rotate_angle) {
            formData.append(
              `floor_plan[${pdfIndex}]rotate_angle`,
              pdfs[pdfIndex].rotate_angle
            );
          }
        }
      }

      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded') {
          formData.append(`photo_or_video[${i}]photo_or_video`, files[i].file);
          formData.append(
            `photo_or_video[${i}]photo_or_video_title`,
            files[i].name
          );
          if (files[i].rotate_angle) {
            formData.append(
              `photo_or_video[${i}]rotate_angle`,
              files[i].rotate_angle
            );
          }
        }
      }
    }
    formData.append('name', name);
    formData.append('type', type);
    formData.append('description', description);
    formData.append('project_name', editingProjectName);

    setDisable(true);

    let result = await RoomServices.createRoom(formData, idOfproject);
    form.resetFields();
    if (result) {
      setLoading(false);
      setModal({ show: true, type: 'success' });
      form.resetFields();
      setTimeout(() => {
        setDisable(false);
        setModal((prev) => ({ ...prev, show: false }));
        Save(
          {
            roomContentStatus: 'RoomsList',
            expandedItem: undefined,
          },
          'app'
        );
        getRooms(idOfproject);
      }, 2000);
    } else {
      setModal({ show: true, type: 'error' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
        setDisable(false);
        Save(
          {
            roomContentStatus: 'RoomsList',
            expandedItem: undefined,
          },
          'app'
        );
        getRooms(idOfproject);
      }, 1500);
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  useEffect(() => {
    getTypes();
  }, []);

  const editStateOfCheckbox = async (isDataComplete: boolean) => {
    setLoading(true);

    let result = await ProjectsService.editProject(idOfproject, {
      rooms_completed: isDataComplete,
    });

    if (result) {
      Save(
        { checkboxStatus: { ...checkboxStatus, rooms: isDataComplete } },
        'app'
      );
      localStorage.setItem('rooms_compeleted', `${isDataComplete}`);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const getRooms = async (id: string, offset = 1) => {
    setLoading(true);
    await RoomServices.roomsList(id, {
      limit: 10,
      offset: (offset - 1) * 10,
    }).then((result) => {
      setCount(result.count);
      setRooms(convertData(result.results));
      if (result.results.length > 0) {
        let emptyFileExists = result.results.find((e: any) => !e.has_file);
        if (!emptyFileExists) {
          setallRoomsHaveFile(true);
        }
      }
      Save({ whereToKeepGoods: result.results }, 'app');
      setLoading(false);
    });
  };
  useEffect(() => {
    if (idOfproject) {
      getRooms(idOfproject);
    }
  }, []);

  const sendCheckStatusRooms = async () => {
    return await NotificationsServices.sendCheck(idOfproject, 'Rooms').then(
      (res) => {
        if (res) {
          setTimeout(() => {
            setCheckStatusModal(false);
            setCheckStatus(true);
            editStateOfCheckbox(true);
          }, 1000);
        }
      }
    );
  };
  const TopRow = () => {
    return (
      <div className="topRow-container">
        <CirclePlusButton
          disabled={
            roomContentStatus === 'Add' ||
            (checkboxStatus?.rooms && role === 'client')
          }
          onClick={() =>
            Save({ roomContentStatus: 'Add', expandedItem: 0 }, 'app')
          }
          btnText={strings.addNewRoom}
        />
        <Row style={!allRoomsHaveFile ? { opacity: 0.5 } : { opacity: 1 }}>
          <Tooltip title="Please add floor plan/pictures/details/etc... to all rooms.">
            <span className="checkbox-text">
              {"I've added all rooms details"}
            </span>{' '}
            <Checkbox
              disabled={
                (role !== 'admin' && roomContentStatus === 'Add') ||
                checkboxStatus?.rooms ||
                !allRoomsHaveFile
              }
              onChange={() => {
                if (!checkStatus) {
                  setCheckStatusModal(true);
                } else {
                  setCheckStatus(false);
                  editStateOfCheckbox(false);
                }
              }}
              checked={checkboxStatus?.rooms}
            />
          </Tooltip>
        </Row>
      </div>
    );
  };

  const RoomsList = () => {
    return (
      <div className="goods-list">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {}}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            dataSource={rooms}
            renderItem={(item) => (
              <RoomItem
                getRooms={getRooms}
                data={item}
                setRemoveItem={setRemoveItem}
                setRemoveModal={setRemoveModal}
              />
            )}
          >
            {loading && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
        {count !== 0 && (
          <div className="pagination-container">
            <Pagination
              current={count}
              total={count}
              pageSize={10}
              onChange={(e) => getRooms(idOfproject, e)}
            />
          </div>
        )}
      </div>
    );
  };

  const removeFilePress = (fileId: number) => {
    if (!files || !pdfs) {
      return;
    }
    if (files) {
      let newFile = [...files];

      let editedFile = newFile.filter(function (value: any) {
        return value.id !== fileId;
      });
      setFiles(editedFile);
    }
    if (pdfs) {
      let newFile = [...files];

      let editedFile = newFile.filter(function (value: any) {
        return value.id !== fileId;
      });
      setPdfs(editedFile);
    }
  };

  const removeRoom = async () => {
    if (!removeItem) {
      return;
    }
    let res = await RoomServices.deleteRoomsById(
      idOfproject.toString(),
      removeItem.toString()
    );
    if (res) {
      setRemoveModal(false);

      Save(
        {
          roomContentStatus: 'RoomsList',
          expandedItem: undefined,
        },
        'app'
      );
      setTimeout(() => {
        getRooms(idOfproject);
      }, 1000);
    }
  };
  const [form] = Form.useForm();
  useEffect(() => {}, [pdfs]);

  const isFieldsTouched = form.isFieldsTouched(['name', 'type', 'description']);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);
  const isPNG = (file: any) =>
    file.toUpperCase() === 'PNG' ||
    file.toUpperCase() === 'TIFF' ||
    file.toUpperCase() === 'JPG' ||
    file.toUpperCase() === 'JPEG';
  return (
    <>
      {role === 'admin' && <TopRow />}

      <div className="rooms-container">
        {roomContentStatus === 'Add' && (
          <Card className="froms-container">
            <Form
              layout="vertical"
              form={form}
            >
              <Row className="first-row">
                <Col
                  xs={24}
                  xl={8}
                  key={'idx1_'}
                >
                  <Form.Item
                    label={strings.name}
                    name={'name'}
                    rules={[{ required: true, message: 'field is required' }]}
                  >
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  xl={8}
                  key={'idx1__'}
                >
                  <Form.Item
                    label={strings.type}
                    name={'type'}
                    rules={[{ required: true, message: 'field is required' }]}
                  >
                    <Select
                      value={type}
                      onChange={(value) => setType(value)}
                      style={{ width: '100%' }}
                    >
                      {roomTypes &&
                        roomTypes.length !== 0 &&
                        roomTypes.map((item, idx) => {
                          return (
                            <Option
                              value={item.id}
                              key={idx}
                            >
                              {item.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="third-row">
                <Col
                  xs={24}
                  xl={24}
                  key={'idx2_'}
                >
                    <Form.Item
                      name={'description'}
                      label={strings.description}
                      style={{ width: '100%' }}
                    >
                      <TextArea
                        rows={7}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </Form.Item>
                 
                </Col>
              </Row>
              <Row>
                <Col
                  xl={12}
                  xs={24}
                >
                  <UploadFile
                    title={'Upload Photos/Videos of your space'}
                    text={'Click to Add Photo or Video'}
                    setFiles={setFiles}
                    imageArray={roomImages}
                    reduxProperty={'roomImages'}
                    width={'97%'}
                    height={90}
                  />
                </Col>

                <Col
                  xl={12}
                  xs={24}
                >
                  <UploadFile
                    onlyImageOnlyPdfAlowed={true}
                    title={'Upload Floor Plan of your space'}
                    text="Click to Add Floor Plan (PDF, Image)"
                    imageArray={roomImages}
                    reduxProperty={'roomImages'}
                    width={'97%'}
                    height={90}
                    setFiles={setPdfs}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  xs={24}
                  xl={12}
                >
                  <div className="file-items-container">
                    {files &&
                      files.map((item: any, idx) => {
                        const {
                          id,
                          name,
                          size,
                          type,
                          attachment_title,
                          format,
                        } = item;
                        return (
                          <FileItem
                            isEditable={isPNG(item.format)}
                            setIsRotateImageActive={setIsRotateImageActive}
                            key={id}
                            disabled={
                              role === 'client' ? checkboxStatus?.rooms : false
                            }
                            fileData={item.file}
                            name={
                              type === 'unUploaded' ? name : attachment_title
                            }
                            setFiles={setFiles}
                            files={files}
                            id={id}
                            format={format}
                            type={type}
                            file={item}
                            size={size}
                            width={'100%'}
                            removeFilePress={removeFilePress}
                            showImagePress={() => showCarouselImage(idx)}
                            onEdit={(rotateImageData: any) => {
                              setIsRotateImageActive(true);
                              setRotateImageData(rotateImageData);
                            }}
                          />
                        );
                      })}
                  </div>
                </Col>
                <Col
                  xs={24}
                  xl={12}
                >
                  <div className="file-items-container">
                    {pdfs &&
                      pdfs.map((item: any, idx) => {
                        const {
                          id,
                          name,
                          size,
                          type,
                          attachment_title,
                          format,
                        } = item;
                        return (
                          <FileItem
                            isEditable={isPNG(format)}
                            setIsRotateImageActive={
                              setIsRotateImageActiveFloorPlan
                            }
                            disabled={
                              role === 'client' ? checkboxStatus?.rooms : false
                            }
                            key={id}
                            fileData={item.file}
                            name={
                              type === 'unUploaded' ? name : attachment_title
                            }
                            id={id}
                            format={format}
                            file={item}
                            setFiles={setPdfs}
                            files={pdfs}
                            type={type}
                            size={size}
                            width={'100%'}
                            removeFilePress={removeFilePress}
                            showImagePress={() => showPdf(idx)}
                            onEdit={(rotateFloorPlanData: any) => {
                              setIsRotateImageActiveFloorPlan(true);
                              setRotateFloorPlanData(rotateFloorPlanData);
                            }}
                          />
                        );
                      })}
                  </div>
                </Col>
              </Row>

              <Row
                justify="end"
                className="btns-row"
              >
                <Button
                  className="cancel-btn"
                  onClick={() => {
                    Save(
                      {
                        roomContentStatus: 'NotAdd',
                        expandedItem: undefined,
                      },
                      'app'
                    );
                    localStorage.setItem(
                      'isFieldsTouched',
                      JSON.stringify(false)
                    );
                  }}
                >
                  {strings.cancel}
                </Button>
                <Form.Item shouldUpdate>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={() => createRoom()}
                    className="save-btn"
                    disabled={
                      // form
                      //   .getFieldsError()
                      //   .filter(({ errors }) => errors.length).length > 0 &&
                      disable
                    }
                  >
                    {strings.save}
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Card>
        )}
        {loading ? (
          <Spin size="small" />
        ) : (
          (roomContentStatus === 'RoomsList' ||
            roomContentStatus === 'editRoom' ||
            roomContentStatus === 'NotAdd') && <RoomsList />
        )}

        <ConfirmModal
          deleteModal={true}
          handleOk={removeRoom}
          handleCancel={() => setRemoveModal(false)}
          isModalVisible={removeModal}
          okText={strings.uppercaseCancel}
          cancelText={'Yes,Delete Room'}
          questionTopText={strings.thisActionCanNotBeUndoneAreYouSure}
          questionText={''}
        />

        <ConfirmModal
          deleteModal={false}
          handleOk={async () => {
            await sendCheckStatusRooms();
          }}
          handleCancel={() => setCheckStatusModal(false)}
          isModalVisible={checkStatusModal}
          okText={strings.uppercaseCancel}
          cancelText={'YES,I have added all my rooms'}
          questionTopText={'Are you sure that you have added all your rooms? '}
          questionText={''}
        />
        {showCarousel && (
          <CarouselModal
            onCancelPress={() => setShowCarousel(false)}
            isModalVisible={showCarousel}
            imageArray={files}
            defaultIndex={carouselIndex}
          />
        )}
        {showCarouselPdf && (
          <CarouselModal
            onCancelPress={() => setShowCarouselPdf(false)}
            isModalVisible={showCarouselPdf}
            imageArray={pdfs}
            defaultIndex={CarouselFileIndex}
          />
        )}

        {isRotateImageActive && (
          <RotateImage
            files={files}
            setFiles={setFiles}
            onFinish={() => {
              setIsRotateImageActive(false);
              setDegree(0);
            }}
            isRotateImageActive={isRotateImageActive}
            rotateImageData={rotateImageData}
            onEdit={() => setDegree((prev: number) => prev + 90)}
            degree={degree}
          />
        )}
        {isRotateImageActiveFloorPlan && (
          <RotateImage
            files={pdfs}
            setFiles={setPdfs}
            onFinish={() => {
              setIsRotateImageActiveFloorPlan(false);
              setDegree(0);
            }}
            isRotateImageActive={isRotateImageActiveFloorPlan}
            rotateImageData={rotateFloorPlanData}
            onEdit={() => setDegree((prev: number) => prev + 90)}
            degree={degree}
          />
        )}

        {modal.type === 'success' && modal.show && (
          <Alert
            description={'Room has been added successfully '}
            type="success"
          />
        )}
        {modal.type === 'error' && modal.show && <Alert type="error" />}
        {modal.type === 'warning' && modal.show && (
          <Alert
            description={strings.pleaseFillAllInputs}
            type="warning"
          />
        )}
      </div>
    </>
  );
};

export default React.memo(RoomsView);

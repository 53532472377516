import { useState } from 'react';
import QuestionListView from './questionList-view';
import { Props } from './models';

export const QuestionList = (props: Props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  return (
    <QuestionListView
      data={data}
      setData={setData}
      loading={loading}
      setLoading={setLoading}
      hasMore={hasMore}
      setHasMore={setHasMore}
      {...props}
    />
  );
};

export default QuestionList;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Location } from "history";
import { Layout, Row, Col } from "antd";
import { CardUserProject } from "components/index";
import { Header } from "components/index";
import { AuthServices, UsersListType } from "src/api/services";
import "./styles.css";
const UserProjectView = (props: any) => {
  const { Content } = Layout;

  let path = window.location.pathname;
  let idPath = path.split("/");
  const idOfUser = idPath[2];

  const [userProjects, setuserProjects] = useState([]);
  const [user, setUser] = useState<UsersListType[] | any>([]);
  const getUser = async () => {
    await AuthServices.userProfile(idOfUser).then((res) => {
      if (res) {
        setuserProjects(res.projects);
        setUser(res);
      }
    });
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <Layout className="home">
        <Header />
        <Content>
          <Row className="recent-row">
            <Col xl={12}>
              <p style={{ fontSize: "16px" }}>
                {user.first_name + " " + user.last_name} is a member of the
                following Projects:
              </p>
            </Col>
          </Row>
          <div className="card-row">
            <Row>
              {userProjects && (
                <CardUserProject
                  data={userProjects}
                  user={user}
                  getUser={getUser}
                />
              )}
            </Row>
          </div>
        </Content>
      </Layout>
    </>
  );
};

export default UserProjectView;

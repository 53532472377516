import { PDFDocument, StandardFonts } from 'pdf-lib';
import html2canvas from 'html2canvas';
import { Button, Row, Spin, message } from 'antd';
import { useState } from 'react';

function ReviewQuestionnaire({ pageData }) {
  const [loading, setLoading] = useState(false);
  const generatePDF = async () => {
    setLoading(true);
    const pageWidth = 1200;
    const pageHeight = 800;
    const questionsPerPage = 7;
    const numberOfPages = Math.ceil(pageData.length / questionsPerPage);

    const pdfDoc = await PDFDocument.create();
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    for (let i = 0; i < numberOfPages; i++) {
      const startIndex = i * questionsPerPage;
      const endIndex = Math.min((i + 1) * questionsPerPage, pageData.length);

      const canvas = await html2canvas(document.querySelector(`#page-${i}`), {
        scale: 1,
        useCORS: true,
      });

      const imgData = canvas.toDataURL('image/png');
      const pngImage = await pdfDoc.embedPng(imgData);

      const { width, height } = pngImage.scale(1);
      const page = pdfDoc.addPage([pageWidth, pageHeight]);
      page.drawImage(pngImage, {
        x: 0,
        y: page.getHeight() - height,
        width,
        height,
      });

      for (let j = startIndex + 1; j < endIndex; j++) {
        const { question, answer } = pageData[j];
        const y = page.getHeight() - (j - startIndex + 1) * 90;
        page.drawText(question.toString(), {
          x: 70,
          y,
          size: 16,
          fontWeight: 500,
        });

        page.drawText(answer !== null ? answer.toString() : 'No response', {
          x: 90,
          y: y - 30,
          size: 16,
          font: helveticaFont,
          fontWeight: 600,
        });
      }
    }

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    // window.open(url);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'questionnaire.pdf';
    link.click();
    message.success({content:'file has been downloaded'})
    setLoading(false);
  };

  return (
    <div>
      {pageData ? (
        <>
          <Row
            justify="end"
            style={{ marginBottom: '10px' }}
          >
            <Button
              loading={loading}
              className="download-questionnaire-btn"
              onClick={generatePDF}
            >
              Download PDF
            </Button>
          </Row>
          <section>
            <div className="questionnaire--container">
              <div>
                <div className="questionnaire--card">
                  {pageData?.map(({ question, answer, id }, index) => (
                    <div
                      id={`page-${Math.floor(index / 7)}`}
                      key={id}
                    >
                      <ul className="questionnaire--card__body">
                        <li>
                          <div>
                            <p>{question} </p>
                            <p>{answer !== null ? answer : 'No response'}</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
}
export default ReviewQuestionnaire;

//@ts-nocheck
import { useDispatch, useSelector } from 'react-redux'
import { appChangeData, appResetData } from '../redux/actions/app/action'
import { userChangeData, userResetData } from '../redux/actions/user/action'
import { AppStates } from '../redux/reducers/app/models'
import { UserStates } from '../redux/reducers/user/models'

interface AllStates extends AppStates , UserStates{
}

const useRedux = () => {
  const dispatch = useDispatch()
  const Get = () => {
    const { user, app } = useSelector(
      (state) => state,
    )
    let reduxData : { user : UserStates , app : AppStates } = { user , app }
    return reduxData 
  }
  const Save = ( data : AllStates , statesType ) => {
    switch (statesType) {
      case 'app':
        data = data
        dispatch(appChangeData(data))
        break
      case 'user':
        data = data
        dispatch(userChangeData(data))
        break
    }
  }
  const Reset = (statesType) => {
    switch (statesType) {
      case 'app':
        dispatch(appResetData())
        break
      case 'user':
        dispatch(userResetData())
        break
    }
  }
  return { Save, Reset, Get }
}

export default useRedux

import { Button, Checkbox, Form, Input, Row, Col, message } from 'antd';
import { AuthServices } from 'api/services';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import LoginView from './login-view';
import { Props } from './models';
import './styles.css';

export const Login = (props: Props) => {
  const [loading, setLoading] = useState(false);

  const loginUser = async (value: { username: string; password: string }) => {
    let password = value.password;
    let username = value.username.toLocaleLowerCase();

    setLoading(true);
    try {
      let login = await AuthServices.login({ username, password });
      if (login) {
        localStorage.setItem('token', login.token);
        localStorage.setItem('userId', login.user_id);
        localStorage.setItem('userRole', login.user_role);
        getUserProfile(login.user_id);
      } else {
        setLoading(false);
        message.error({
          content: 'email or password may not be correct',
        });
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const getUserProfile = async (id: string) => {
    try {
      const userResult = await AuthServices.userProfile(id.toString());
      if (userResult) {
        let userData = {
          id: userResult.id,
          fullname: `${userResult.first_name} ${userResult.last_name}`,
          role: userResult.role,
          email: userResult.email,
          bio: userResult.bio,
          firstName: userResult.first_name,
          lastName: userResult.last_name,
          preferredName: userResult.preferred_name,
          calendar_link: userResult.calendar_link,
          avatar: userResult.avatar,
          status: userResult.status,
          pdf: userResult.pdf,
        };

        localStorage.setItem('userData', JSON.stringify(userData));

        setLoading(false);
        window.location.href = '/home';
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const LoginForm = () => {
    return (
      <div className="form-container">
        <Form
          className="login-form"
          onFinish={loginUser}
        >
          <div className="username-container">
            <Form.Item
              name="username"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </div>
          <div className="password-container">
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Password"
              />
            </Form.Item>
          </div>
          <br />

          <Row className="login-footer-row">
            <Col
              xl={12}
              xs={24}
            >
              <Form.Item
                name="remember"
                valuePropName="checked"
                noStyle
              >
                <div className="remember-container">
                  Remember me
                  <Checkbox
                    defaultChecked={
                      localStorage.getItem('rememberMe') === 'true'
                    }
                    onChange={(e) =>
                      localStorage.setItem(
                        'rememberMe',
                        JSON.stringify(e.target.checked)
                      )
                    }
                  />
                </div>
              </Form.Item>
            </Col>
            <Col
              xl={12}
              xs={24}
            >
              <Link
                className="login-form-forgot"
                to="/forgot-password"
              >
                Forgot password
              </Link>
            </Col>
          </Row>
          <br />
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <LoginView
      LoginForm={LoginForm}
      {...props}
    />
  );
};

export default Login;

import { useState, useEffect, memo } from 'react';
import FileItemView from './fileItem-view';
import { PdfSvg, VideoPlay } from 'assets/index.js';
import { message } from 'antd';
import { useRedux } from 'hooks/index';

export const FileItem = (props: any) => {
  const {
    file,
    showImagePress,
    type = 'uploaded',
    name,
    size,
    fileData,
    format,
    onEdit,
  } = props;
  const [fileImage, setFileImage] = useState<any>();
  const [rotate, setRotate] = useState(0);
  const { Save } = useRedux();

  const finallShowImagePress = () => {
    showImagePress
      ? showImagePress()
      : Save({ showCarouselModal: true }, 'app');
  };

  const getBase64 = async (blob: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (value: any) {
      setFileImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  };

  function DataURIToBlob(dataURI: any) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  }

  useEffect(() => {
    if (format === 'video') {
      setFileImage(VideoPlay);
    } else if (format === 'pdf') {
      setFileImage(PdfSvg);
    } else if (type === 'unUploaded') {
      message.success({
        content: 'The file has been successfully selected',
      });
      getBase64(file.file);
    } else if (type === 'uploaded' && fileData) {
      if (
        fileData.split('/')[0] === 'http:' ||
        fileData.split('/')[0].includes('http')
      ) {
        setFileImage(fileData);
      } else {
        setFileImage(`${process.env.REACT_APP_BASE_URL}${fileData}`);
      }
    }
  }, []);

  const { width } = props;
  return (
    <FileItemView
      {...props}
      format={format}
      size={size}
      width={width}
      name={name}
      file={file}
      fileImage={fileImage}
      DataURIToBlob={DataURIToBlob}
      finallShowImagePress={finallShowImagePress}
      onEdit={onEdit}
      rotate={rotate}
      setRotate={setRotate}
      type={type}
    />
  );
};

export default memo(FileItem);

import React from 'react'
import UserProjectView from './userProject-view'
import { Props } from './models'

export const UserProject = ( props : Props ) => {

  return (
    <UserProjectView
      {...props}
    />
  )
}

export default UserProject

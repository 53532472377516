
import SaveModalsView from "./saveModals-view";
import {Props} from './models'
import './styles.css'
const SaveModals = (props : Props) => {
 
  const {isSaveModalVisible,handleCancel,handleOk,isSucceedModalVisible} = props
  
  return <SaveModalsView
  isSaveModalVisible={isSaveModalVisible}
  handleCancel={handleCancel}
  handleOk={handleOk}
  isSucceedModalVisible={isSucceedModalVisible}
  />;
};
export default SaveModals;

import React from 'react';
import { Route, Redirect } from 'react-router';

export const AdminRoute = ({ component, isAuthenticated, ...rest }: any) => {
  const role = localStorage.getItem('userRole');
  const routeComponent = (props: any) =>
    localStorage.getItem('token') && role === 'admin' ? (
      React.createElement(component, props)
    ) : role !== 'admin' ? (
      <Redirect to={{ pathname: '/home' }} />
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    );

  return <Route {...rest} render={routeComponent} />;
};

import { api } from 'api/api';
import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';
import { CreateProjectType, EditProjectType } from './models';

const END_POINTS = {
  projects: '/projects/',
  projectById: '/projects/{project_id}/',
  projectUsers: '/projects/{project_pk}/users/',
  insprationWebsite: '/projects/{project_pk}/website-inspiration/',
  insprationWebsiteById: '/projects/{project_pk}/website-inspiration/{id}/',
  insprationPinterest: '/projects/{project_pk}/pinterest-inspiration/',
  insprationPinterestById: '/projects/{project_pk}/pinterest-inspiration/{id}/',
  insprationUploadFile: '/projects/{project_pk}/photo-or-video-inspiration/',
  insprationUploadFileById:
    '/projects/{project_pk}/photo-or-video-inspiration/{id}/',
  inspirationHouzz: '/projects/{project_pk}/houzz-idea-book/',
  inspirationHouzzById: '/projects/{project_pk}/houzz-idea-book/{id}/',
};

export const ProjectsService = {
  createProject: async (
    data: CreateProjectType,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(END_POINTS.projects, data, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> createProject: ', err);
        return null;
      });
  },
  projectList: async (params: any, badRequestCallback?: (err: any) => void) => {
    return await api
      .get(END_POINTS.projects, params, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> projectList: ', err);
        return null;
      });
  },
  projectDetail: async (
    projectId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        END_POINTS.projectById.replace('{project_id}', projectId),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> projectDetail: ', err);
        return null;
      });
  },
  editProject: async (
    projectId: string,
    data: EditProjectType,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        END_POINTS.projectById.replace('{project_id}', projectId),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.log(err, 'err');

        return null;
      });
  },
  deleteProjectById: async (
    projectId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        END_POINTS.projectById.replace('{project_id}', projectId),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> projectDetail: ', err);
        return null;
      });
  },
  getProjectUsers: async (
    projectId: string,
    params: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        END_POINTS.projectUsers.replace('{project_pk}', projectId),

        params,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('userprojects -> usersList: ', err);
        return null;
      });
  },
  updateUserInProject: async (
    projectId: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .put(
        END_POINTS.projectUsers.replace('{project_pk}', projectId),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> projectDetail: ', err);
        return null;
      });
  },
  addInsprationWebsite: async (
    projectId: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(
        END_POINTS.insprationWebsite.replace('{project_pk}', projectId),
        data,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> create inspration: ', err);
        return null;
      });
  },
  editeInsprationWebsite: async (
    projectId: string,
    data: any,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        END_POINTS.insprationWebsiteById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
  deleteInsprationWebsite: async (
    projectId: string,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        END_POINTS.insprationWebsiteById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },

  addPinterestBoardLink: async (
    projectId: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(
        END_POINTS.insprationPinterest.replace('{project_pk}', projectId),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> create inspration: ', err);
        return null;
      });
  },
  editInsprationPinterest: async (
    projectId: string,
    data: any,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        END_POINTS.insprationPinterestById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
  deleteInsprationPinterest: async (
    projectId: string,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        END_POINTS.insprationPinterestById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
  addInsprationUploadFile: async (
    projectId: string,
    data: any,
    customHeader: object,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(
        END_POINTS.insprationUploadFile.replace('{project_pk}', projectId),
        data,
        null,
        true,
        customHeader
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> create inspration: ', err);
        return null;
      });
  },
  editInsprationUploadFile: async (
    projectId: string,
    data: any,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        END_POINTS.insprationUploadFileById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
  deleteInsprationUploadFile: async (
    projectId: string,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        END_POINTS.insprationUploadFileById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
  addInsprationHouzz: async (
    projectId: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(
        END_POINTS.inspirationHouzz.replace('{project_pk}', projectId),
        data,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> create inspration: ', err);
        return null;
      });
  },
  editInsprationHouzz: async (
    projectId: string,
    data: any,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        END_POINTS.inspirationHouzzById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
  deleteInpirationHouzz: async (
    projectId: string,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        END_POINTS.inspirationHouzzById
          .replace('{project_pk}', projectId)
          .replace('{id}', id),
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ProjectsService -> editProject: ', err);
        return null;
      });
  },
};

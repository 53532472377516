import { useEffect } from 'react';
import { Button, Modal } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  RotateRightOutlined,
} from '@ant-design/icons';
import './styles.css';

export const RotateImage = ({
  files,
  setFiles,
  onFinish,
  isRotateImageActive,
  rotateImageData,
  degree = 0,
  onEdit,
}: any) => {
  useEffect(() => {}, [degree, rotateImageData]);

  return (
    <Modal
      visible={isRotateImageActive}
      className="modal-rotate"
      style={{ top: '0' }}
      closable={false}
      footer={null}
    >
      <div className="rotate-body-container">
        <div className={'btn-container'}>
          <Button
            className={'cancel-btn'}
            onClick={() => {
              onFinish();
            }}
            icon={<CloseOutlined />}
          />

          {degree !== 0 && (
            <Button
              className="save-btn"
              icon={<CheckOutlined />}
              onClick={() => {
                let newFiles = files.map((value: any) => {
                  if (value.id === rotateImageData?.id) {
                    return {
                      ...value,
                      rotate_angle: value?.rotate_angle + degree,
                    };
                  }
                  return value;
                });

                setFiles(newFiles);
                setTimeout(() => {
                  onFinish();
                }, 1500);
              }}
            />
          )}

          <Button
            onClick={onEdit}
            className="edit-btn"
            icon={<RotateRightOutlined />}
          />
        </div>
      </div>

      <div className="modal-image-container">
        <div className="modal-inner-image">
          <img
            style={{
              transform: `rotate(${
                rotateImageData?.rotate_angle + degree
              }deg) `,
            }}
            src={rotateImageData?.url}
            alt=""
          />
        </div>
      </div>
    </Modal>
  );
};

import { useEffect, useState, useRef } from 'react';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { ExportSVG } from 'assets/index';
import { strings } from 'common/index';
import { ProjectsService } from 'src/api/services';
import { ConfirmModal } from 'components/index';
import { Alert } from 'src/ui-kits';
import { useRedux } from 'src/hooks';
import { useForm } from 'antd/lib/form/Form';
const WebsiteInspration = () => {
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  const { Save } = useRedux();
  const { TextArea } = Input;

  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [saveModal, setSaveModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [webLink, setWebLink] = useState('');
  const [webDescription, setWebDescription] = useState('');
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {}, [saveModal]);

  const addWebsite = async () => {
    setLoading(true);
    setDisabled(true);
    let insprationData = {
      link: webLink,
      description: webDescription,
    };

    if (
      webLink === undefined ||
      webLink === '' ||
      webDescription === '' ||
      webDescription === undefined
    ) {
      setLoading(false);
      setDisabled(false);
      setSaveModal(false);
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setModal({ show: false, type: 'warning' });
      }, 3000);
      return null;
    }
    if (webLink.toLocaleLowerCase().includes('https://')) {
      await ProjectsService.addInsprationWebsite(
        idOfproject,
        insprationData
      ).then((res) => {
        if (res) {
          setModal({ show: true, type: 'success' });
          setLoading(false);
          setSaveModal(false);
          setTimeout(() => {
            setDisabled(false);
            setModal((prev) => ({ ...prev, show: false }));
            Save(
              {
                insprationContentStatus: 'insprationList',
                checkStatusInspration: 'notSelected',
                expandedItem: undefined,
              },
              'app'
            );
          }, 2000);
        } else {
          setLoading(false);
          setSaveModal(false);
          setModal({ show: true, type: 'error' });
          setTimeout(() => {
            setDisabled(false);
            setModal((prev) => ({ ...prev, show: false }));
          }, 2000);
        }
      });
    } else {
      setDisabled(false);
      setLoading(false);
      setSaveModal(false);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const [form] = useForm();

  const isFieldsTouched = form.isFieldsTouched(['link', 'description']);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <Card className="form-container-inspiration">
      <div className="pinterest-container">
        <Form
          layout="vertical"
          form={form}
        >
          <Row className="second-row">
            <Col
              xs={24}
              xl={24}
              key={'id1'}
            >
              <Form.Item
                label={'Link'}
                name="link"
              >
                <div className="input-icon-container">
                  <Input
                    value={webLink}
                    onChange={(e) => setWebLink(e.target.value)}
                  />

                  <a
                    href={webLink}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={ExportSVG}
                      alt=""
                    />
                    {''}
                  </a>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row className="third-row">
            <Col
              xs={24}
              xl={24}
              key={'id1'}
            >
              <Form.Item
                label={strings.description}
                name="description"
              >
                <TextArea
                  rows={5}
                  value={webDescription}
                  onChange={(e) => setWebDescription(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              onClick={() => addWebsite()}
              className="save-btn"
              htmlType="submit"
              disabled={disabled}
              loading={loading}
            >
              {strings.save}
            </Button>
            <Button
              className="cancel-btn"
              onClick={() => {
                Save(
                  {
                    checkStatusInspration: 'notSelected',
                    insprationContentStatus: 'notShowInspration',
                    expandedItem: undefined,
                  },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
            >
              {strings.cancel}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The inspiration has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={'All inputs must be filled out'}
          type="warning"
        />
      )}

      {/* <ConfirmModal
        okText={"Cancel"}
        cancelText={"Yes the website is public "}
        questionText={
          "Please make sure the website is public otherwise we can not access it"
        }
        isModalVisible={saveModal}
        handleOk={() => addWebsite()}
        handleCancel={() => setSaveModal(false)}
      /> */}
    </Card>
  );
};

export default WebsiteInspration;

import { api } from 'api/api';
import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';
import { ResponseType } from '../index';

const END_POINTS = {
  login: '/auth/login/',
  authUsers: '/auth/users/',
  forgotPassword: '/auth/forget-password/',
  resetPassword: '​auth​/reset-password​/token​/',
  userById: '/auth/users/id/',
  notifs: '/auth/users/<user_pk>/notifications/',
};
export type AllUsersInfoType = {
  id: number;
  email: string;
  password: string | null;
  password2: string | null;
  preferred_name: string;
  role: 'admin' | 'designer' | 'client' | 'referrer' | 'concierge';
  firs_name: string | null;
  last_name: string | null;
  bio: string | null;
  referred: any;
  status: boolean | null;
};
export type UsersListType = {
  id: number;
  email: string;
  preferred_name: string;
  role: 'admin' | 'designer' | 'client' | 'referrer' | 'concierge';
  first_name: string | null;
  last_name: string | null;
  bio: string | null;
  referrer: any;
  status: boolean | null;
  projects: Array<any>;
  avatar: string | null;
  is_referrer: boolean;
};
export const AuthServices = {
  login: async (data: any, badRequestCallback?: (err: any) => void) => {
    return await api
      .post(END_POINTS.login, data, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> login: ', err);
        return null;
      });
  },

  usersList: async (
    params: any,
    badRequestCallback?: (err: any) => void
  ): Promise<ResponseType<UsersListType> | null> => {
    return await api
      .get(END_POINTS.authUsers, params, true)
      .then((response) => {
        return response.data as ResponseType<UsersListType>;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> usersList: ', err);
        return null;
      });
  },
  addNewUser: async (data: any, badRequestCallback?: (err: any) => void) => {
    return await api
      .post(END_POINTS.authUsers, data, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> new user: ', err);
        return null;
      });
  },

  userProfile: async (
    userId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(END_POINTS.userById.replace('id', userId), null, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> userProfile: ', err);
        return null;
      });
  },
  editProfile: async (
    userId: string,
    data: object,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(END_POINTS.userById.replace('id', userId), data, null, true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> editProfile: ', err);
        return null;
      });
  },
  editUserByID: async (
    userId: string,
    data: object,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(END_POINTS.userById.replace('id', userId), data, null, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> editProfile: ', err);
        return null;
      });
  },
  forgotPassword: async (data: any) => {
    return await api
      .post(END_POINTS.forgotPassword, data, null, true)
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        console.log(err, 'err');

        return null;
      });
  },
  resetPassword: async (data: any, token: string) => {
    return await api
      .put(`​auth​/reset-password​/${token}`, data, null, false)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return null;
      });
  },
  deleteUserById: async (
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(END_POINTS.userById.replace('id', id), true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('AuthServices -> delete: ', err);
        return null;
      });
  },
  getNotifs: async (user_pk: string) => {
    return await api
      .get(`/auth/users/${user_pk}/notifications/`, null, true)
      .then((response) => {
        return response.data.results;
      })
      .catch((err) => {
        console.log(err, 'err');

        return null;
      });
  },
};

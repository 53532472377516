import {
  Goods,
  MessageBoard,
  PaymentMetting,
  ProjectData,
  Questionnaire,
  Rooms,
  TimeLine,
  Users,
} from './tabs/index';
import Presentation from './tabs/Presentation';

const useComponent = () => {
  // enum Buttons {
  //   PROJECTDATA,
  //   ROOMS,
  //   GOODS,
  //   USERS,
  //   TIMELINE,
  //   PAYMENT,
  //   QUESTIONNAIRE,
  //   MESSAGEBOARD,
  //   PRESENTATION,
  // }

  const tabContent = (activeButton: any) => {
    switch (activeButton) {
      case 'TIMELINE':
        return <TimeLine />;
      case 'USERS':
        return <Users />;
      case 'ROOMS':
        return <Rooms />;
      case 'PROJECTDATA':
        return <ProjectData />;
      case 'GOODS':
        return <Goods />;
      case 'MESSAGEBOARD':
        return <MessageBoard />;
      case 'PAYMENT':
        return <PaymentMetting />;
      case 'QUESTIONNAIRE':
        return <Questionnaire />;
      case 'PRESENTATION':
        return <Presentation />;
      default:
        return <ProjectData />;
    }
  };

  return {
    tabContent,
  };
};

export default useComponent;

import {
  DocumentSVG,
  GoodSVG,
  MeetingSVG,
  MessagesSVG,
  QuestionsSVG,
  RoomSVG,
  TimeLineSVG,
  UserSVG,
  presentaionSvg,
} from 'assets/index';
import { strings } from 'common/index';

const styles: any = (activeTab: any) => {
  return {
    projectDataButton: {
      color: activeTab === 'PROJECTDATA' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'PROJECTDATA' ? 'var(--blue-button-color)' : 'white',
    },
    roomButton: {
      color: activeTab === 'ROOMS' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'ROOMS' ? 'var(--blue-button-color)' : 'white',
    },
    goodsButton: {
      color: activeTab === 'GOODS' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'GOODS' ? 'var(--blue-button-color)' : 'white',
    },
    usersButton: {
      color: activeTab === 'USERS' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'USERS' ? 'var(--blue-button-color)' : 'white',
    },
    timeLineButton: {
      color: activeTab === 'TIMELINE' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'TIMELINE' ? 'var(--blue-button-color)' : 'white',
    },
    paymentButton: {
      color: activeTab === 'PAYMENT' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'PAYMENT' ? 'var(--blue-button-color)' : 'white',
    },
    questionnaireButton: {
      color: activeTab === 'QUESTIONNAIRE' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'QUESTIONNAIRE' ? 'var(--blue-button-color)' : 'white',
    },
    messageButton: {
      color: activeTab === 'MESSAGEBOARD' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'MESSAGEBOARD' ? 'var(--blue-button-color)' : 'white',
    },
    presentationButton: {
      color: activeTab === 'PRESENTATION' ? 'white' : 'black',
      backgroundColor:
        activeTab === 'PRESENTATION' ? 'var(--blue-button-color)' : 'white',
    },
    documentImage: {
      filter: activeTab === 'PROJECTDATA' ? 'brightness(219%)' : undefined,
    },
    roomImage: {
      filter: activeTab === 'ROOMS' ? 'invert(1)' : undefined,
    },
    goodImage: {
      filter: activeTab === 'GOODS' ? 'invert(1)' : undefined,
    },
    userImage: {
      filter: activeTab === 'USERS' ? 'invert(1)' : undefined,
    },
    timeLineImage: {
      filter: activeTab === 'TIMELINE' ? 'invert(1)' : undefined,
    },
    meetingImage: {
      filter: activeTab === 'PAYMENT' ? 'invert(1)' : undefined,
    },
    questionImage: {
      filter: activeTab === 'QUESTIONNAIRE' ? 'invert(1)' : undefined,
    },
    messageImage: {
      filter: activeTab === 'MESSAGEBOARD' ? 'invert(1)' : undefined,
    },
    presentationImage: {
      filter: activeTab === 'PRESENTATION' ? 'invert(1)' : undefined,
    },
  };
};
const {
  projectDataButton,
  roomButton,
  goodsButton,
  usersButton,
  timeLineButton,
  paymentButton,
  questionnaireButton,
  messageButton,
  documentImage,
  roomImage,
  goodImage,
  userImage,
  timeLineImage,
  meetingImage,
  questionImage,
  messageImage,
  presentationImage,
  presentationButton,
} = styles;
export const tabItems: any = [
  {
    id: 'PROJECTDATA',
    style: projectDataButton,
    src: DocumentSVG,
    text: strings.projectData,
    styleImage: documentImage,
  },
  {
    id: 'ROOMS',
    style: roomButton,
    src: RoomSVG,
    text: strings.rooms,
    styleImage: roomImage,
  },
  {
    id: 'GOODS',
    style: goodsButton,
    src: GoodSVG,
    text: strings.goods,
    styleImage: goodImage,
  },
  {
    id: 'QUESTIONNAIRE',
    style: questionnaireButton,
    src: QuestionsSVG,
    text: strings.questionnaire,
    styleImage: questionImage,
  },
  {
    id: 'MESSAGEBOARD',
    style: messageButton,
    src: MessagesSVG,
    text: strings.messaginBoard,
    styleImage: messageImage,
  },
  {
    id: 'PAYMENT',
    style: paymentButton,
    src: MeetingSVG,
    text: 'Meeting',
    styleImage: meetingImage,
  },

  {
    id: 'TIMELINE',
    style: timeLineButton,
    src: TimeLineSVG,
    text: strings.timeLine,
    styleImage: timeLineImage,
  },
  {
    id: 'PRESENTATION',
    style: presentationButton,
    src: presentaionSvg,
    text: 'Presentations',
    styleImage: presentationImage,
  },
];
export const extraItem = [
  {
    id: 'USERS',
    style: usersButton,
    src: UserSVG,
    text: strings.users,
    styleImage: userImage,
  },
];

import { useState, useEffect } from 'react';
import { Button, Card, Col, Form, Input, message, Row, Select } from 'antd';
import {
  DeleteUploadedFilesServices,
  RoomServices,
  StaticServices,
} from 'api/services/index';
import { useRedux } from 'hooks/index';
import { houseIcon, maintenanceIcon, measureIcon } from 'assets/index.js';
import { FileItem, UploadFile, CarouselModal } from 'components/index';
import { strings } from 'src/common';
import { FileType } from './models';
import './styles.css';
import { FilePdfOutlined } from '@ant-design/icons';
import CollapseItems from 'src/components/collapseItems';
import { RotateImage } from 'src/components/RotateImage';

const EditDetailsView = (props: any) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const { data, getRooms, attachments, floorPlanAttachments } = props;
  const { Get, Save } = useRedux();
  const [pdfs, setPdfs] = useState<FileType[]>(floorPlanAttachments);
  const [showCarouselPdf, setShowCarouselPdf] = useState(false);
  const [CarouselFileIndex, setCarouseFilelIndex] = useState(0);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [newType, setNewType] = useState<number>(data.type);
  const [files, setFiles] = useState<FileType[]>(attachments);
  const [newName, setNewName] = useState(data.name);
  const [nameOfTypes, setNameOfTypes] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [staticFiles, setstaticFiles] = useState<any>([]);
  const [newDescription, setNewDescription] = useState(data.description);
  const [disabled, setDisabled] = useState(false);
  const [degree, setDegree] = useState(0);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [rotateImageData, setRotateImageData] = useState(null);
  const [rotateFloorPlanData, setRotateFloorPlanData] = useState(null);
  const [isRotateImageActiveFloorPlan, setIsRotateImageActiveFloorPlan] =
    useState(false);
  //

  useEffect(() => {}, [files, degree]);

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const { roomImages, roomTypes, checkboxStatus, editable } = Get().app;

  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  const showPdf = (index: number) => {
    setCarouseFilelIndex(index);
    setShowCarouselPdf(true);
  };
  const filterTypes = () => {
    if (roomTypes && data) {
      const nameOfType = roomTypes.filter(
        (roomType) => roomType.id === data.type
      );
      let nameType = nameOfType.forEach((name) => setNameOfTypes(name.type));
      return nameType;
    }
  };

  useEffect(() => {
    filterTypes();
  }, []);

  const editRoom = async () => {
    if (clientRole ) {
      // message.warning('Description is required');
      return;
    }
    setLoading(true);
    let newformData: any = new FormData();
    if (pdfs && pdfs.length !== 0) {
      for (let pdfIndex in pdfs) {
        if (pdfs[pdfIndex].type === 'unUploaded') {
          newformData.append(
            `floor_plan[${pdfIndex}]floor_plan_title`,
            pdfs[pdfIndex].name
          );
          newformData.append(
            `floor_plan[${pdfIndex}]floor_plan`,
            pdfs[pdfIndex].file
          );
          if (pdfs[pdfIndex].rotate_angle) {
            newformData.append(
              `floor_plan[${pdfIndex}]rotate_angle`,
              pdfs[pdfIndex].rotate_angle
            );
          }
        }
        if (pdfs[pdfIndex].type === 'uploaded') {
          newformData.append(
            `floor_plan[${pdfIndex}]id_number`,
            pdfs[pdfIndex].id
          );
          newformData.append(
            `floor_plan[${pdfIndex}]rotate_angle`,
            pdfs[pdfIndex].rotate_angle
          );
        }
      }
    }
    if (files && files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded') {
          newformData.append(
            `photo_or_video[${i}]photo_or_video`,
            files[i].file
          );
          newformData.append(
            `photo_or_video[${i}]photo_or_video_title`,
            files[i].name
          );
          if (files[i].rotate_angle) {
            newformData.append(
              `photo_or_video[${i}]rotate_angle`,
              -files[i].rotate_angle
            );
          }
        }
        if (files[i].type === 'uploaded') {
          newformData.append(`photo_or_video[${i}]id_number`, files[i].id);
          newformData.append(
            `photo_or_video[${i}]rotate_angle`,
            files[i].rotate_angle
          );
        }
      }
    }
    newformData.append('name', newName);
    newformData.append('type', newType);
    newformData.append('description', newDescription);
    setDisabled(true);
    let result = await RoomServices.editRoomsById(
      Number(idOfproject),
      data.id,
      newformData
    );

    if (result) {
      setLoading(false);
      setTimeout(() => {
        setDisabled(false);
        Save(
          {
            roomContentStatus: 'RoomsList',
            expandedItem: undefined,
            whereToKeepGoods: result,
          },
          'app'
        );
        getRooms(idOfproject);
      }, 1000);
    } else {
      Save({ errorGoodsModal: true }, 'app');
      setTimeout(() => {
        setDisabled(false);
        Save({ errorGoodsModal: false }, 'app');
      }, 1500);
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }

    let newRoomImages = [...files];
    let typeOfFile = newRoomImages.find(function (value: any) {
      return value.id === fileId;
    });
    let editedRoomImages = newRoomImages.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'room_images_or_video',
        fileId
      );
      if (result) {
        setFiles(editedRoomImages);
      }
    } else {
      setFiles(editedRoomImages);
    }
  };

  const removePdfPress = async (fileId: number) => {
    if (!pdfs) {
      return;
    }
    let newRoomPdfs = [...pdfs];

    let typeOfFile = newRoomPdfs.find(function (value: any) {
      return value.id === fileId;
    });
    let editedRoomPdfs = newRoomPdfs.filter(function (value: any) {
      return value.id !== fileId;
    });

    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'room_floor_plan',
        fileId
      );
      if (result) {
        setPdfs(editedRoomPdfs);
      }
    } else {
      setPdfs(editedRoomPdfs);
    }
  };
  const getRoomsGuides = async () => {
    await StaticServices.getStatics().then((res) => {
      if (res) {
        setstaticFiles(res);
      }
    });
  };

  useEffect(() => {
    getRoomsGuides();
  }, []);
  let fileSrc = (attachment: string) =>
    `${URL}${attachment}`;
  let clientRole = localStorage.getItem('userRole') === 'client';
  let adminRole = localStorage.getItem('userRole') === 'admin';

  const isFieldsTouched = form.isFieldsTouched(['name', 'type', 'description']);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      <Card className="froms-container">
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            name: newName,
            type: newType,
            description: newDescription,
          }}
        >
          {!editable && (
            <>
              <CollapseItems
                onClick={() =>
                  Save(
                    {
                      expandedItem: undefined,
                      roomContentStatus: 'NotAdd',
                      editable: true,
                    },
                    'app'
                  )
                }
              />
              <br />
            </>
          )}
          <Row className="first-row">
            <Col
              xs={24}
              xl={6}
              key={'idx1_'}
            >
              <Form.Item
                label={strings.name}
                name={'name'}
                rules={[{ required: true, message: 'field is required' }]}
              >
                <Input
                  readOnly={!editable}
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
              </Form.Item>
            </Col>
            {clientRole ? (
              <Col
                xs={24}
                xl={6}
                key={'idx1'}
                className="client-view-room"
              >
                <div>
                  <span className="client-view-span">Types</span>
                  <p className="client-view-p">{nameOfTypes}</p>
                </div>
              </Col>
            ) : (
              <Col
                className="project-type-col"
                xs={24}
                xl={8}
                key={'idx1__'}
              >
                <Form.Item
                  label={strings.type}
                  name={'type'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <Select
                    // defaultValue={newType}
                    value={newType}
                    disabled={!editable}
                    placeholder={nameOfTypes}
                    onChange={(value) => setNewType(value)}
                    style={{ width: '100%' }}
                  >
                    {roomTypes &&
                      roomTypes.length !== 0 &&
                      roomTypes.map((item, idx) => {
                        return (
                          <Option
                            value={item.id}
                            key={idx}
                          >
                            {item.type}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {editable && (
              <Col
                xs={24}
                xl={10}
                key={'idx1___'}
              >
                <div className="pdf-container">
                  <div className="measurment_guide_container">
                    {staticFiles &&
                    staticFiles.length !== 0 &&
                    staticFiles[0] &&
                    staticFiles[0].file ? (
                      <a
                        href={fileSrc(staticFiles[0].file)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={houseIcon}
                          alt=""
                        />
                        <p>
                          {' '}
                          <FilePdfOutlined />
                          Photo Guide
                        </p>
                      </a>
                    ) : (
                      <div className="guide-btns disabled">
                        <img
                          src={houseIcon}
                          alt=""
                        />
                        <p>
                          {' '}
                          <FilePdfOutlined />
                          Photo Guide
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="guide-btns">
                    <div className="measurment_guide_container">
                      {staticFiles &&
                      staticFiles.length !== 0 &&
                      staticFiles[1].file ? (
                        <a
                          href={fileSrc(staticFiles[1].file)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={maintenanceIcon}
                            alt=""
                          />
                          <p>
                            {' '}
                            <FilePdfOutlined />
                            Measurement Guide
                          </p>
                        </a>
                      ) : (
                        <div className="guide-btns disabled">
                          <img
                            src={maintenanceIcon}
                            alt=""
                          />
                          <p>
                            {' '}
                            <FilePdfOutlined />
                            Measurement Guide{' '}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="guide-btns">
                    <div className="measurment_guide_container">
                      {staticFiles &&
                      staticFiles.length !== 0 &&
                      staticFiles[2] &&
                      staticFiles[2].file ? (
                        <a
                          href={fileSrc(staticFiles[2].file)}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={measureIcon}
                            alt=""
                          />
                          <p>
                            <FilePdfOutlined />
                            Reno Measurement Guide
                          </p>
                        </a>
                      ) : (
                        <div className="guide-btns disabled">
                          <img
                            src={measureIcon}
                            alt=""
                          />
                          <p>
                            {' '}
                            <FilePdfOutlined />
                            Reno Measurement Guide
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            )}
          </Row>
          <Row className="third-row">
            <Col
              xs={24}
              xl={24}
              key={'idx2_'}
            >
              {clientRole ? (
                <Form.Item
                  label={strings.description}
                  name="description"
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <TextArea
                    readOnly={!editable}
                    style={{ width: '100%' }}
                    rows={4}
                    onChange={(e) => setNewDescription(e.target.value)}
                    value={newDescription}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  label={strings.description}
                  name="description"
                >
                  <TextArea
                    readOnly={!editable}
                    style={{ width: '100%' }}
                    rows={4}
                    onChange={(e) => setNewDescription(e.target.value)}
                    value={newDescription}
                  />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
        <div>
          {((clientRole && !checkboxStatus?.rooms) || adminRole) && (
            <Row>
              <Col
                xl={12}
                xs={24}
              >
                <UploadFile
                  title={'Upload Photos/Videos of your space'}
                  text={'Click to Browse Files or Drop Files here'}
                  setFiles={setFiles}
                  imageArray={roomImages}
                  reduxProperty={'roomImages'}
                  width={'97%'}
                  height={90}
                  disabled={!editable}
                />
              </Col>

              <Col
                xl={12}
                xs={24}
              >
                <UploadFile
                  onlyImage
                  onlyPdf
                  onlyImageOnlyPdfAlowed={true}
                  title={'Upload Floor Plan of your space'}
                  text="Click to Add Floor Plan (PDF, Image)"
                  imageArray={roomImages}
                  reduxProperty={'roomImages'}
                  width={'97%'}
                  height={90}
                  disabled={!editable}
                  setFiles={setPdfs}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col
              xs={24}
              xl={12}
            >
              <div className="file-items-container">
                {files &&
                  files.map((item: any, idx: number) => {
                    const {
                      id,
                      name,
                      size,
                      type,
                      photo_or_video_title,
                      format,
                    } = item;
                    const isPNG =
                      name?.toUpperCase().includes('PNG') ||
                      name?.toUpperCase().includes('JPG') ||
                      name?.toUpperCase().includes('JPEG') ||
                      photo_or_video_title?.toUpperCase().includes('PNG') ||
                      photo_or_video_title?.toUpperCase().includes('JPG') ||
                      photo_or_video_title?.toUpperCase().includes('JPEG');

                    return (
                      <div
                        key={idx}
                        style={{ width: '100%' }}
                      >
                        <FileItem
                          isEditable={isPNG}
                          key={id}
                          disabled={clientRole ? checkboxStatus?.rooms : false}
                          fileData={
                            type === 'unUploaded'
                              ? item.file
                              : item.photo_or_video
                          }
                          name={
                            type === 'unUploaded' ? name : photo_or_video_title
                          }
                          id={id}
                          format={format}
                          type={type}
                          file={item}
                          size={size}
                          width={'100%'}
                          removeFilePress={removeFilePress}
                          showImagePress={() => showCarouselImage(idx)}
                          onEdit={(rotateImageData: any) => {
                            setIsRotateImageActive(true);
                            setRotateImageData(rotateImageData);
                          }}
                          degree={degree}
                        />
                      </div>
                    );
                  })}
              </div>
            </Col>
            <Col
              xs={24}
              xl={12}
            >
              <div className="file-items-container">
                {pdfs &&
                  pdfs.map((item: any, idx: number) => {
                    const {
                      id,
                      name,
                      get_floor_plan_size,
                      type,
                      floor_plan_title,
                      format,
                    } = item;
                    const isPNG =
                      name?.toUpperCase().includes('PNG') ||
                      name?.toUpperCase().includes('JPG') ||
                      name?.toUpperCase().includes('JPEG') ||
                      floor_plan_title?.toUpperCase().includes('PNG') ||
                      floor_plan_title?.toUpperCase().includes('JPG') ||
                      floor_plan_title?.toUpperCase().includes('JPEG');

                    return (
                      <div
                        key={idx}
                        style={{ width: '100%' }}
                      >
                        <FileItem
                          isEditable={isPNG}
                          key={id}
                          disabled={clientRole ? checkboxStatus?.rooms : false}
                          fileData={
                            type === 'unUploaded' ? item.file : item.floor_plan
                          }
                          name={type === 'unUploaded' ? name : floor_plan_title}
                          id={id}
                          format={format}
                          type={type}
                          file={item}
                          size={get_floor_plan_size}
                          width={'100%'}
                          removeFilePress={removePdfPress}
                          showImagePress={() => showPdf(idx)}
                          onEdit={(rotateFloorPlanData: any) => {
                            setIsRotateImageActiveFloorPlan(true);
                            setRotateFloorPlanData(rotateFloorPlanData);
                          }}
                          degree={degree}
                        />
                      </div>
                    );
                  })}
              </div>
            </Col>
          </Row>
        </div>
        {editable && (
          <Row
            justify="end"
            className="rooms-btns"
          >
            <Button
              className="cancel-btn"
              onClick={() => {
                Save(
                  { expandedItem: undefined, roomContentStatus: 'NotAdd' },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
            >
              {strings.cancel}
            </Button>
            {((clientRole && !checkboxStatus?.rooms) || !clientRole) && (
              <Form.Item shouldUpdate>
                <Button
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  onClick={() => editRoom()}
                  className="save-btn"
                  disabled={disabled}
                >
                  {strings.save}
                </Button>
              </Form.Item>
            )}
          </Row>
        )}
      </Card>
      <RotateImage
        files={files}
        setFiles={setFiles}
        onFinish={() => {
          setIsRotateImageActive(false);
          setDegree(0);
        }}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      />

      <RotateImage
        files={pdfs}
        setFiles={setPdfs}
        onFinish={() => {
          setIsRotateImageActiveFloorPlan(false);
          setDegree(0);
        }}
        isRotateImageActive={isRotateImageActiveFloorPlan}
        rotateImageData={rotateFloorPlanData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      />

      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}
      {showCarouselPdf && (
        <CarouselModal
          onCancelPress={() => setShowCarouselPdf(false)}
          isModalVisible={showCarouselPdf}
          imageArray={pdfs}
          defaultIndex={CarouselFileIndex}
        />
      )}
    </>
  );
};

export default EditDetailsView;

import { api } from "api/api";

export const StaticServices = {
  getStatics: async () => {
    return await api
      .get("/statics", null, true)
      .then((response) => {
        return response.data.results;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  },
};

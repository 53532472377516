import { LongItem } from 'components/index';
import { useRedux } from 'src/hooks';
import { EditDetails } from '../index';
import { memo } from 'react';
import './styles.css';

const GoodItemView = (props: any) => {
  const {
    data,
    setRemoveModal,
    setRemoveItem,
    title,
    getGoods,
    category,
    goodItem,
  } = props;

  const { Get } = useRedux();
  const { expandedItem } = Get().app;

  let newFiles: any = [];
  if (data.Attachments && data.Attachments.length !== 0) {
    for (let perFile of data.Attachments) {
      let file = {
        ...perFile,
        type: 'uploaded',
        rotate_angle: 0,
      };
      newFiles.push(file);
    }
  }

  return (
    <>
      {expandedItem === data.id ? (
        <EditDetails
          data={data}
          attachments={newFiles}
          getGoods={getGoods}
          category={category}
          goodItem={goodItem}
        />
      ) : (
        <LongItem
          setRemoveItem={setRemoveItem}
          setRemoveModal={setRemoveModal}
          type={'Goods'}
          data={data}
          title={title}
        />
      )}
    </>
  );
};

export default memo(GoodItemView);

import { useState, useEffect } from "react";
import { Layout, Row, Col, Input, Button, Drawer, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  HomeOutlined,
  PlusCircleOutlined,
  TeamOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { useWindowSize, useRedux } from "hooks/index";
import { LogoutSVG } from "assets/index";
import Profile from "../ProfileSider";
import HeaderButtons from "../../ui-kits/Buttons/HeaderButtons";
import { Size, PropsType } from "./models";
import { strings } from "src/common";
import { Link, useHistory } from "react-router-dom";
import "./styles.css";
function HomeHeaderView({ search }: PropsType) {
  const [showMenu, setShowMenu] = useState(false);
  const { Header } = Layout;
  const { Search } = Input;
  const size: Size = useWindowSize();
  const { Save } = useRedux();
  const history = useHistory();
  const [current, setCurrent] = useState("1");
  const logoutUser = () => {
    history.push("/login");
    localStorage.clear();
  };
  let pathname = window.location.pathname;

  let userDataJson: any = localStorage.getItem("userData");

  const onSearch = (value: string) => {
    Save({ searchResultProject: value }, "app");
  };
  useEffect(() => {
    switchActiveLink();
  }, []);
  const switchActiveLink = () => {
    switch (pathname) {
      case "/home":
        return setCurrent("1");
      case "/add-project":
        return setCurrent("2");
      case "/members":
        return setCurrent("3");
      case "/profile":
        return setCurrent("4");
      default:
        break;
    }
  };
  return (
    <>
      <Header>
        <Row className="row-header">
          {size.width && size.width > 800 ? (
            <>
              <Col
                xl={12}
                xs={12}
                className="header-btns"
                style={{ display: "flex" }}
              >
                <HeaderButtons />
              </Col>
              {search ? (
                <Col
                  xl={12}
                  xs={12}
                  style={{ display: "flex" }}
                  className="header-with-search"
                >
                  <Col xl={12} xs={12}>
                    {search && (
                      <div className="search-box">
                        <Search
                          placeholder={strings.searchForProjects}
                          className="search-header"
                          onSearch={onSearch}
                        />
                      </div>
                    )}
                  </Col>
                  <Col xs={12} xl={11} className="profile-head">
                    <Profile />
                  </Col>
                </Col>
              ) : (
                <Col xs={12} xl={12} className="profile-head">
                  <Profile />
                </Col>
              )}
            </>
          ) : (
            <>
              <Button onClick={() => setShowMenu(true)}>
                <MenuUnfoldOutlined />
              </Button>
              <Profile />

              {search && (
                <div className="search-box">
                  <Search
                    placeholder={strings.searchForProjects}
                    style={{ width: 300 }}
                    className="search-header"
                  />
                </div>
              )}
              <Drawer
                title={
                  userDataJson &&
                  `welcome ${JSON.parse(userDataJson).preferredName}`
                }
                placement="left"
                closable={true}
                onClose={() => setShowMenu(false)}
                visible={showMenu}
                key={"left"}
              >
                <div>
                  <Menu mode="inline" selectedKeys={[current]}>
                    <Menu.Item key="1" icon={<HomeOutlined />}>
                      <Link to="/home">Home</Link>
                    </Menu.Item>
                    {localStorage.getItem("userRole") !== "client" && (
                      <>
                        <Menu.Item
                          key="2"
                          icon={<PlusCircleOutlined />}
                          onClick={() => {
                            Save(
                              {
                                projectFormsType: "AddProject",
                                projectDataContentStatus: "AddProjectData",
                              },
                              "app"
                            );
                          }}
                        >
                          <Link to="/add-project">Add new Project</Link>
                        </Menu.Item>
                        <Menu.Item key="3" icon={<TeamOutlined />}>
                          <Link to="/members">Members</Link>
                        </Menu.Item>
                      </>
                    )}

                    <Menu.Item key="4" icon={<ProfileOutlined />}>
                      <Link to="/profile">Profile</Link>
                    </Menu.Item>
                    <Menu.Item key="5" icon={<img src={LogoutSVG} alt="" />}>
                      <div onClick={() => logoutUser()}>Exit</div>
                    </Menu.Item>
                  </Menu>
                </div>
              </Drawer>
            </>
          )}
        </Row>
      </Header>
    </>
  );
}

export default HomeHeaderView;

import { useState, useEffect } from 'react';
import { TrashSVG, CameraSVG, VideoSVG } from 'assets/index';
import { FileOutlined } from '@ant-design/icons';
import { useRedux } from 'hooks/index';
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  Upload,
  message,
} from 'antd';
import { strings } from 'common/index';
import FileItem from '../FileItem';
import { CarouselModal } from '..';
import ConfirmModal from '../Modals/ConfirmModals';
import {
  ConversationServices,
  DeleteUploadedFilesServices,
  ProjectsService,
} from 'src/api/services';
import './styles.css';
import { useForm } from 'antd/lib/form/Form';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { RotateImage } from '../RotateImage';
export const EditMessage = (props: any) => {
  const { data, getMessages, firstMessage } = props;
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const { Save, Get } = useRedux();
  const { Option } = Select;
  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );

  const html = data.description;
  const contentBlock: any = htmlToDraft(html);
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data.description);
  const [selectedUser, setselectedUser] = useState<any>([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [assignedProject, setAssaignedProject] = useState<any>([]);
  const { expandedItem, editable } = Get().app;
  const [removeItem, setRemoveItem] = useState<any>(expandedItem);
  const [files, setFiles] = useState<any[]>([]);
  const [degree, setDegree] = useState(0);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [rotateImageData, setRotateImageData] = useState(null);
  const [photos, setphotos] = useState(props.photos);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const isEditable =
    firstMessage?.id !== data.id ||
    localStorage.getItem('userRole') !== 'client';
  //

  const role = localStorage.getItem('userRole');

  const isPNG = (file: any) =>
    file &&
    (file.toUpperCase() === 'PNG' ||
      file.toUpperCase() === 'TIFF' ||
      file.toUpperCase() === 'JPG' ||
      file.toUpperCase() === 'JPEG' ||
      file.toUpperCase() === 'GIF' ||
      file.toUpperCase() === 'EPS' ||
      file.toUpperCase() === 'HEIF' ||
      file.toUpperCase() === 'DNG' ||
      file.toUpperCase() === 'RAW');
  const isVideo = (file: any) =>
    file.toUpperCase() === 'AVI' ||
    file.toUpperCase() === 'FLV' ||
    file.toUpperCase() === 'WMV' ||
    file.toUpperCase() === 'MP4' ||
    file.toUpperCase() === 'MOV';
  //
  const projectUserList = async (offset = 1) => {
    await ProjectsService.getProjectUsers(idOfproject, {
      limit: 20,
      offset: (offset - 1) * 20,
    }).then((res: any) => {
      if (res && res.users) {
        setAssaignedProject(res.users);
      }
    });
  };

  useEffect(() => {}, [selectedUser]);

  useEffect(() => {
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
    projectUserList();
  }, []);
  let userString: any = [];
  let usersId: any = [];
  const multipleUsers = () => {
    for (let user of data.users) {
      userString.push(user.role + ': ' + user.preferred_name);
      usersId.push(user.id);
    }

    setselectedUser(usersId);
    return usersId;
  };
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };
  const editMessage = async () => {
    if (!title) {
      message.warning('please add a title');
      return;
    }
    if (selectedUser.length === 0) {
      message.warning('please select a member');
      return;
    }
    let formData: any = new FormData();
    setLoading(true);

    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'uploaded' && isPNG(files[i].format)) {
          formData.append(`photos[${i}]rotate_angle`, files[i].rotate_angle);
          formData.append(`photos[${i}]id_number`, files[i].id);
        } else if (files[i].type === 'unUploaded' && isVideo(files[i].format)) {
          formData.append(`videos[${i}]video`, files[i].file);
          formData.append(`videos[${i}]video_title`, files[i].name);
        } else {
          formData.append(`files[${i}]file`, files[i].file);
          formData.append(`files[${i}]file_title`, files[i].name);
        }
      }
    }
    if (photos.length > 0) {
      for (let i = 0; i < photos.length; i++) {
        if (photos[i].type === 'unUploaded') {
          formData.append(`photos[${i}]photo`, photos[i].file);
          formData.append(`photos[${i}]photo_title`, photos[i].name);
          if (photos[i].rotate_angle !== 0) {
            formData.append(`photos[${i}]rotate_angle`, photos[i].rotate_angle);
          }
        }
        if (photos[i].type === 'uploaded') {
          formData.append(`photos[${i}]id_number`, photos[i].id);
          formData.append(`photos[${i}]rotate_angle`, photos[i].rotate_angle);
        }
      }
    }
    formData.append('title', title);
    formData.append('description', description);
    if (selectedUser) {
      for (let i = 0; i < selectedUser.length; i++) {
        formData.append('users', selectedUser[i]);
      }
    }
    setDisabled(true);
    await ConversationServices.editConversationsById(
      idOfproject,
      data.id,
      formData
    )
      .then((res) => {
        if (res) {
          setLoading(false);
          setTimeout(() => {
            setDisabled(false);
            Save(
              {
                expandedItem: undefined,
                messageBoardContentStatus: 'List',
                conversationStatus: 'notShow',
              },
              'app'
            );
            getMessages();
          }, 1000);
        } else {
          setDisabled(false);

          message.error({
            content: 'something went wrong , please try again later',
          });
        }
      })
      .catch((e) => console.log(e, 'error'));
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const removeConverstaions = async () => {
    if (!removeItem) {
      return;
    }
    await ConversationServices.deleteConversationsById(
      idOfproject,
      removeItem.toString()
    ).then((res) => {
      if (res) {
        message.success({
          content: 'The conversations has been deleted successfully',
        });

        setRemoveModal(false);
        setTimeout(() => {
          getMessages();
          Save({ expandedItem: undefined }, 'app');
        }, 1000);
      }
    });
  };
  const removeFilePress = async (fileId: number, value: any, type: any) => {
    if (!files) {
      return;
    }
    let newFile: any = [];
    if (type === 'uploaded') {
      newFile = [...data.photos, ...data.videos, ...data.files];
      let typeOfFile = newFile.find(function (item: any) {
        return item.id === fileId;
      });
      let editedFile: any = newFile.filter(function (item: any) {
        return item.id !== fileId;
      });
      if ('video' in value) {
        await DeleteUploadedFilesServices.deleteUploadedFiles(
          'conversation_video',
          fileId
        ).then((res: any) => {
          if (res) {
            setFiles(editedFile);
          }
        });
      } else if ('photos' in value) {
        await DeleteUploadedFilesServices.deleteUploadedFiles(
          'conversation_photo',
          fileId
        ).then((res: any) => {
          if (res) {
            setFiles(editedFile);
          }
        });
      } else if ('file' in value) {
        await DeleteUploadedFilesServices.deleteUploadedFiles(
          'conversation_file',
          fileId
        ).then((res: any) => {
          if (res) {
            setFiles(editedFile);
          }
        });
      }
    }

    // if (typeOfFile?.type !== 'unUploaded') {

    // } else {
    //   setFiles(editedFile);
    // }
  };

  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };

  const [form] = useForm();

  const isFieldsTouched = form.isFieldsTouched([
    'title',
    'users',
    'description',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      <Card className="add-card">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            title: data.title,
            description: data.descripion,
          }}
        >
          <Row className="first-row">
            <Col
              xl={8}
              xs={24}
            >
              <Form.Item
                label={strings.title}
                name="title"
              >
                <Input
                  readOnly={!isEditable}
                  className="conversation-title"
                  defaultValue={title}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              xl={8}
              xs={24}
            >
              <Form.Item
                label={strings.members}
                name="users"
              >
                <Select
                  disabled={!isEditable || !editable}
                  showArrow
                  mode="multiple"
                  className="select-user"
                  style={{ width: '100%' }}
                  placeholder={strings.selectAUser}
                  optionFilterProp="children"
                  defaultValue={() => multipleUsers()}
                  value={selectedUser}
                  showSearch={false}
                  onChange={(value: any) => setselectedUser(value)}
                >
                  {assignedProject &&
                    assignedProject.length !== 0 &&
                    assignedProject.map((user: any) => {
                      let userRole =
                        user?.role?.charAt(0).toUpperCase() +
                        user?.role?.slice(1);
                      return (
                        <Option
                          value={user.id}
                          key={user.id}
                        >
                          {userRole} :{user.preferred_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row className="second-row">
            <Col
              xl={24}
              xs={24}
            >
              <Form.Item
                label={strings.description}
                name="description"
              >
                <Editor
                  readOnly={!editable}
                  spellCheck={true}
                  toolbarClassName="demo-toolbar-custom"
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor-custom"
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  onChange={(e: any) => {
                    localStorage.setItem(
                      'isFieldsTouched',
                      JSON.stringify(true)
                    );
                    setDescription(
                      draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {isEditable && (
            <div className="attach-row-message-board">
              <br />
              <Upload
                customRequest={({ file, onSuccess }: any) => {
                  return false;
                }}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isPNG =
                    file?.type.toUpperCase() === 'IMAGE/PNG' ||
                    file?.type.toUpperCase() === 'IMAGE/TIFF' ||
                    file?.type.toUpperCase() === 'IMAGE/JPG' ||
                    file?.type.toUpperCase() === 'IMAGE/JPEG' ||
                    file?.type.toUpperCase() === 'IMAGE/GIF' ||
                    file?.type.toUpperCase() === 'IMAGE/EPS' ||
                    file?.type.toUpperCase() === 'IMAGE/HEIF' ||
                    file?.type.toUpperCase() === 'IMAGE/DNG' ||
                    file?.type.toUpperCase() === 'IMAGE/RAW ';
                  if (!isPNG) {
                    message.error(
                      `${file.name} should be one of png, tiff, jpg, jpeg, gif, eps, HEIF, DNG, Raw types.`
                    );
                  }
                  return isPNG || Upload.LIST_IGNORE;
                }}
                onChange={(info: any) => {
                  setphotos((prev: any) => [
                    ...prev,
                    {
                      id: Math.round(Math.random() * 1000),
                      uid: info.file.uid,
                      lastModified: info.file.lastModified,
                      lastModifiedDate: info.file.lastModifiedDate,
                      name: info.file.name,
                      file: info.file.originFileObj,
                      size: {
                        type: info.file.size < 100000 ? 'KB' : 'MB',
                        size:
                          info.file.size < 100000
                            ? Math.round(info.file.size / 10) / 10
                            : Math.round(info.file.size / 100000) / 10,
                      },
                      type: 'unUploaded',
                      rotate_angle: 0,
                      format:
                        info.file.name.split('.')[
                          info.file.name.split('.').length - 1
                        ],
                      webkitRelativePath: info.file.webkitRelativePath,
                    },
                  ]);
                }}
              >
                <Button
                  icon={
                    <img
                      src={CameraSVG}
                      alt=""
                    />
                  }
                  disabled={!isEditable}
                >
                  {'Photo'}
                </Button>
              </Upload>
              <Upload
                customRequest={({ file, onSuccess }: any) => {
                  return false;
                }}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isVideo =
                    file.type.toUpperCase() === 'VIDEO/AVI' ||
                    file.type.toUpperCase() === 'VIDEO/FLV' ||
                    file.type.toUpperCase() === 'VIDEO/WMV' ||
                    file.type.toUpperCase() === 'VIDEO/MP4' ||
                    file.type.toUpperCase() === 'VIDEO/MOV';

                  if (!isVideo) {
                    message.error(
                      `${file.name} should be one of AVI, FLV, WMV, mp4, MOV types.`
                    );
                  }
                  return isVideo || Upload.LIST_IGNORE;
                }}
                onChange={(info: any) => {
                  //@ts-ignore
                  setFiles((prev) => [
                    ...prev,
                    {
                      id: Math.round(Math.random() * 1000),
                      uid: info.file.uid,
                      lastModified: info.file.lastModified,
                      lastModifiedDate: info.file.lastModifiedDate,
                      name: info.file.name,
                      file: info.file.originFileObj,
                      size: {
                        type: info.file.size < 100000 ? 'KB' : 'MB',
                        size:
                          info.file.size < 100000
                            ? Math.round(info.file.size / 10) / 10
                            : Math.round(info.file.size / 100000) / 10,
                      },
                      type: 'unUploaded',
                      format:
                        info.file.name.split('.')[
                          info.file.name.split('.').length - 1
                        ],
                      webkitRelativePath: info.file.webkitRelativePath,
                    },
                  ]);
                }}
              >
                <Button
                  icon={
                    <img
                      src={VideoSVG}
                      alt=""
                    />
                  }
                  disabled={!isEditable}
                >
                  {'Video'}
                </Button>
              </Upload>
              <Upload
                customRequest={({ file, onSuccess }: any) => {
                  return false;
                }}
                beforeUpload={(file) => {
                  const isVideoOrImage =
                    file.type.toUpperCase() === 'VIDEO/AVI' ||
                    file.type.toUpperCase() === 'VIDEO/FLV' ||
                    file.type.toUpperCase() === 'VIDEO/WMV' ||
                    file.type.toUpperCase() === 'VIDEO/MP4' ||
                    file.type.toUpperCase() === 'VIDEO/MOV' ||
                    file.type.toUpperCase() === 'IMAGE/PNG' ||
                    file.type.toUpperCase() === 'IMAGE/TIFF' ||
                    file.type.toUpperCase() === 'IMAGE/JPG' ||
                    file.type.toUpperCase() === 'IMAGE/JPEG' ||
                    file.type.toUpperCase() === 'IMAGE/GIF' ||
                    file.type.toUpperCase() === 'IMAGE/EPS' ||
                    file.type.toUpperCase() === 'IMAGE/HEIF' ||
                    file.type.toUpperCase() === 'IMAGE/DNG' ||
                    file.type.toUpperCase() === 'IMAGE/RAW ';

                  if (isVideoOrImage) {
                    message.error(
                      `${file.name} should not be an image or video type.`
                    );
                  }
                  return !isVideoOrImage || Upload.LIST_IGNORE;
                }}
                showUploadList={false}
                onChange={(info: any) => {
                  setFiles((prev) => [
                    ...prev,
                    {
                      id: Math.round(Math.random() * 1000),
                      uid: info.file.uid,
                      lastModified: info.file.lastModified,
                      lastModifiedDate: info.file.lastModifiedDate,
                      name: info.file.name,
                      file: info.file.originFileObj,
                      size: {
                        type: info.file.size < 100000 ? 'KB' : 'MB',
                        size:
                          info.file.size < 100000
                            ? Math.round(info.file.size / 10) / 10
                            : Math.round(info.file.size / 100000) / 10,
                      },
                      type: 'unUploaded',
                      rotate_angle: 0,
                      format:
                        info.file.name.split('.')[
                          info.file.name.split('.').length - 1
                        ],
                      webkitRelativePath: info.file.webkitRelativePath,
                    },
                  ]);
                }}
              >
                <Button
                  icon={<FileOutlined />}
                  disabled={!isEditable}
                >
                  {'File'}
                </Button>
              </Upload>
            </div>
          )}
          {photos.length > 0 ||
          data.videos.length > 0 ||
          data.files.length > 0 ? (
            <Row
              justify="start"
              className="attachment-row-message-board"
            >
              <span> Attachments</span>
              {photos?.map((item: any, idx: number) => {
                const { id, name, get_photo_size, type, photo_title, format } =
                  item;
                return (
                  <div
                    key={idx}
                    style={{ width: '100%' }}
                  >
                    <FileItem
                      isEditable={true}
                      key={id}
                      fileData={type === 'unUploaded' ? item.file : item.photo}
                      name={type === 'unUploaded' ? name : photo_title}
                      id={id}
                      format={format}
                      type={type}
                      file={item}
                      size={get_photo_size}
                      width={'100%'}
                      removeFilePress={removeFilePress}
                      showImagePress={() => showCarouselImage(idx)}
                      onEdit={(rotateImageData: any) => {
                        setIsRotateImageActive(true);
                        setRotateImageData(rotateImageData);
                      }}
                      degree={degree}
                    />
                  </div>
                );
              })}
              {data?.videos?.map((item: any, idx: number) => {
                const { id, name, get_video_size, type, video_title, format } =
                  item;
                return (
                  <div
                    key={idx}
                    style={{ width: '100%' }}
                  >
                    <FileItem
                      isEditable={false}
                      key={id}
                      fileData={type === 'unUploaded' ? item.file : item.video}
                      name={type === 'unUploaded' ? name : video_title}
                      id={id}
                      format={format}
                      type={type}
                      file={item}
                      size={get_video_size}
                      width={'100%'}
                      removeFilePress={removeFilePress}
                      showImagePress={() => showCarouselImage(idx)}
                    />
                  </div>
                );
              })}
              {data?.files?.map((item: any, idx: number) => {
                const { id, name, get_file_size, type, file_title, format } =
                  item;
                return (
                  <div
                    key={idx}
                    style={{ width: '100%' }}
                  >
                    <FileItem
                      isEditable={false}
                      key={id}
                      fileData={type === 'unUploaded' ? item.file : item.video}
                      name={type === 'unUploaded' ? name : file_title}
                      id={id}
                      format={format}
                      type={type}
                      file={item}
                      size={get_file_size}
                      width={'100%'}
                      removeFilePress={removeFilePress}
                      showImagePress={() => showCarouselImage(idx)}
                    />
                  </div>
                );
              })}
              {files &&
                files.map((item: any, idx: number) => {
                  return (
                    <div
                      key={idx}
                      style={{ width: '100%' }}
                    >
                      <FileItem
                        isEditable={false}
                        setFiles={setFiles}
                        files={files}
                        key={item.id}
                        disabled={false}
                        fileData={item.file}
                        name={item.name}
                        id={item.id}
                        format={item.format}
                        type={item.type}
                        file={item}
                        size={item.size}
                        width={'100%'}
                        removeFilePress={removeFilePress}
                        showImagePress={() => showCarouselImage(idx)}
                      />
                    </div>
                  );
                })}
            </Row>
          ) : null}

          <br />
          {showCarousel && (
            <CarouselModal
              onCancelPress={() => setShowCarousel(false)}
              isModalVisible={showCarousel}
              imageArray={files}
              defaultIndex={carouselIndex}
            />
          )}
          {isEditable && role !== 'client' && (
            <>
              <Row
                justify="end"
                className="message-bord-btn-container"
              >
                <div
                  className={isEditable ? 'trash' : 'trash disabled_container'}
                  onClick={() => {
                    setRemoveModal(true);
                  }}
                >
                  <img
                    src={TrashSVG}
                    alt=""
                  />
                </div>
                <Button
                  className="cancel-btn"
                  onClick={() => {
                    Save(
                      {
                        expandedItem: undefined,
                        conversationStatus: 'notShow',
                        messageBoardContentStatus: 'NotShow',
                      },
                      'app'
                    );
                    localStorage.setItem(
                      'isFieldsTouched',
                      JSON.stringify(false)
                    );
                  }}
                >
                  {strings.cancel}
                </Button>
                {isEditable && (
                  <Button
                    onClick={() => editMessage()}
                    className="save-btn"
                    loading={loading}
                    disabled={disabled || !isEditable}
                  >
                    {strings.save}
                  </Button>
                )}{' '}
              </Row>
            </>
          )}
        </Form>
      </Card>
      <RotateImage
        files={photos}
        setFiles={setphotos}
        onFinish={() => {
          setIsRotateImageActive(false);
          setDegree(0);
        }}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      />
      <ConfirmModal
        deleteModal={true}
        handleOk={removeConverstaions}
        handleCancel={() => setRemoveModal(false)}
        isModalVisible={removeModal}
        okText={'Cancel'}
        cancelText={'Yes,delete this conversations'}
        questionTopText={
          'This action can not be undone. Are you sure you want to continue?'
        }
        questionText={''}
      />
    </>
  );
};

import React, { useEffect, useState } from 'react';
import {
  assignQuestionnaire as assignQuestionnaireAPI,
  getAllQuestionnaires,
  getProjectQuestionnaire as getProjectQuestionnaireAPI,
} from 'src/api/services';
import { useRedux } from 'src/hooks';
import { CustomMenu } from './components';
import QuestionnaireView from './questionnaire-view';
import './styles.css';

const Questionnaire = () => {
  const [questionnaires, setQuestionnaires] = useState<any[]>([]);
  const [questionnaireLists, setQuestionnaireLists] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState();
  const { Get } = useRedux();
  const { expandedItem } = Get().app;

  let path = window.location.pathname;
  let idPath = path.split('/');
  let idOfproject: any = idPath[2];

  const getQuestionnaires = async () => {
    setLoading(!expandedItem);
    try {
      const result = await getAllQuestionnaires();
      if (result) {
        setQuestionnaires(result.data.results);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const getProjectQuestionnaire = async () => {
    setLoading(!expandedItem);
    try {
      const result = await getProjectQuestionnaireAPI(idOfproject);
      if (result) {
        setQuestionnaireLists(result.data.results);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  let allQuestionsAndAnswers: any = [];
  const filterQuestions = (questionnaire_list: any) => {
    questionnaire_list?.map((q: any) => {
      q.questionnaire_list.map((e: any) => {
        for (let index = 0; index < e.questions.length; index++) {
          const element = e.questions[index];
          allQuestionsAndAnswers.push({
            question: element.question,
            answer: element.answer,
            id: element.id,
            name: e.name,
          });
        }
      });
    });
    setQuestions(allQuestionsAndAnswers);
  };
  useEffect(() => {
    filterQuestions(questionnaireLists);
  }, [questionnaireLists]);
  const assignQuestionnaire = async (questionnaireId: string | number) => {
    setLoading(true);
    try {
      const result = await assignQuestionnaireAPI(idOfproject, questionnaireId);
      if (result) {
        getProjectQuestionnaire();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuestionnaires();
    getProjectQuestionnaire();
    //eslint-disable-next-line
  }, []);

  return (
    <QuestionnaireView
      allQuestionsAndAnswers={questions}
      questionnaires={questionnaires}
      questionnaireLists={questionnaireLists}
      loading={loading}
      setLoading={setLoading}
      idOfproject={idOfproject}
      getProjectQuestionnaire={getProjectQuestionnaire}
      menu={
        <CustomMenu
          loading={loading}
          questionnaires={questionnaires}
          assignQuestionnaire={assignQuestionnaire}
        />
      }
    />
  );
};

export default React.memo(Questionnaire);

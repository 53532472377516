import { ChatSVG, TrashSVG, PenSVG } from 'src/assets/index';
import './styles.css';
import { strings } from 'common/index';
import { ViewProps } from './models';
import { List, Spin, Row, Col } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import { Button } from 'antd';

const QuestionListView = (props: ViewProps) => {
  const { loading, hasMore, showConfirmModal } = props;

  return (
    <>
      <Row className="demo-infinite-container">
        <Col
          xs={24}
          xl={24}
        >
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => {}}
            hasMore={!loading && hasMore}
            useWindow={false}
          >
            <List
              dataSource={[
                {
                  title: 'Budget Information',
                  question: 10,
                  date: 'January 29 , 2021',
                  id: 1,
                },
                {
                  title: 'Budget Information',
                  question: 10,
                  date: 'January 29 , 2021',
                  id: 2,
                },
                {
                  title: 'Budget Information',
                  question: 10,
                  date: 'January 29 , 2021',
                  id: 3,
                },
              ]}
              renderItem={(item) => (
                <List.Item key={item.id}>
                  <Row>
                    <Col
                      xs={6}
                      xl={6}
                    >
                      <span className="question-title">{item.title}</span>
                    </Col>
                    <Col
                      xs={6}
                      xl={4}
                    >
                      <span className="question-number">
                        ({item.question} {strings.questions})
                      </span>
                    </Col>
                    <Col
                      xs={4}
                      xl={4}
                    >
                      <span className="date-text">{item.date}</span>
                    </Col>

                    <Col
                      xs={8}
                      xl={10}
                      className="icon-container"
                    >
                      <Button className="chat-container">
                        <img
                          src={ChatSVG}
                          alt="chat"
                        />
                      </Button>
                      <Button className="pen-container">
                        <img
                          src={PenSVG}
                          alt="pen"
                        />
                      </Button>
                      <Button
                        onClick={() => showConfirmModal()}
                        className="trash-container"
                      >
                        <img
                          src={TrashSVG}
                          alt="trash"
                        />
                      </Button>
                    </Col>
                  </Row>
                </List.Item>
              )}
            >
              {loading && (
                <div className="spin-container">
                  <Spin size="default" />
                </div>
              )}
            </List>
          </InfiniteScroll>
        </Col>
      </Row>
    </>
  );
};

export default QuestionListView;

import { memo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Anchor,
} from 'antd';
import { useRedux } from 'src/hooks';
import { ProjectsService } from 'api/services/index';
import ProjectItem from './components/ProjectItem/ProjectItem';
import { Alert } from 'ui-kits/index';
import { strings } from 'common/index';
import './styles.css';

const ProjectDataView = (props: any) => {
  const { setButtonStatus } = props;
  const { Link } = Anchor;
  const { Get, Save } = useRedux();
  const history = useHistory();

  const { projectDataContentStatus, enableAddProjectTabs } = Get().app;
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  const [removeModal, setRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState<string>();
  const [project, setProject] = useState<any>();
  const [name, setName] = useState('');
  const [type, setType] = useState<'test_1' | 'test_2' | undefined>();
  const [price, setPrice] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [scope, setScope] = useState('');
  const { TextArea } = Input;
  const { Option } = Select;
  const data = {
    name: name,
    type: type,
    price: price,
    scope_of_project: scope,
  };
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  const [form] = Form.useForm();

  const createProject = async () => {
    setLoading(true);
    let res = await ProjectsService.createProject(data);
    if (res) {
      setLoading(false);
      setModal({ show: true, type: 'success' });
      Save(
        {
          projectId: res.id,
          projectFormsType: 'AddProject',
        },
        'app'
      );
      setButtonStatus('Show');
      setTimeout(() => {
        if (res.id) {
          setModal((prev) => ({ ...prev, show: false }));
          history.push(`/add-project/${res.id}`);
          Save(
            {
              enableAddProjectTabs: false,
              expandedItem: undefined,
            },
            'app'
          );
        }
      }, 1500);
    } else {
      setLoading(false);
      setModal({ show: true, type: 'error' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 1500);
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };
  const getProject = async (id: string) => {
    await ProjectsService.projectDetail(id).then((res) => {
      if (res) {
        Save(
          {
            checkboxStatus: {
              rooms: res.rooms_completed,
              goods: res.goods_completed,
              inspiration: res.inspirations_completed,
              clientInfo: res.information_completed,
              projectId: res.id,
            },
          },
          'app'
        );
        setProject(res);
      }
    });
  };
  const ProjectList = () => {
    return (
      <div className="goods-list">
        {project && (
          <>
            <ProjectItem
              getProject={getProject}
              title={project.name}
              setRemoveItem={setRemoveItem}
              setRemoveModal={setRemoveModal}
              data={project}
            />
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (path.includes(`/add-project/${idOfproject}`)) {
      Save(
        {
          enableAddProjectTabs: false,
          projectDataContentStatus: 'EditProjectDetail',
        },
        'app'
      );
      getProject(idOfproject);
    } else {
      Save(
        {
          enableAddProjectTabs: true,
          projectDataContentStatus: 'AddProjectData',
        },
        'app'
      );
    }
  }, [path, enableAddProjectTabs]);

  const isFieldsTouched = form.isFieldsTouched([
    'projectName',
    'projectType',
    'price',
    'scope',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <div className="projectData-container">
      {projectDataContentStatus === 'AddProjectData' && (
        <>
          <Card
            className="froms-container"
            title="Project Details:"
          >
            <Form
              layout="vertical"
              form={form}
            >
              <Row className="first-row">
                <Col
                  xs={24}
                  xl={8}
                  key={'idx1'}
                  style={{ marginRight: '10px' }}
                >
                  <Form.Item
                    label={strings.projectName}
                    name={'projectName'}
                    rules={[
                      {
                        required: true,
                        message: 'field is required',
                      },
                    ]}
                  >
                    <Input
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col
                  className=""
                  xs={24}
                  xl={8}
                  key={'idx'}
                >
                  <Form.Item
                    label={strings.projectType}
                    name={'projectType'}
                    rules={[
                      {
                        required: true,
                        message: 'field is required',
                      },
                    ]}
                  >
                    <Select
                      value={type}
                      onChange={(value) => setType(value)}
                      style={{ width: '100%' }}
                    >
                      <Option value="space-planning-/-furnishing">
                        Space planning / furnishing
                      </Option>
                      <Option value="renovations">Renovations </Option>
                      <Option value="combo">Combo </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="second-row">
                <Col
                  xs={24}
                  xl={24}
                  key={'idx2'}
                >
                  <Form.Item
                    label={strings.totalPrice}
                    name={'price'}
                    rules={[
                      {
                        required: true,
                        message: 'field is required',
                      },
                    ]}
                  >
                    <InputNumber
                      style={{ width: '100%' }}
                      // type={"number"}
                      // value={price}
                      formatter={(value) =>
                        `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
                      min={0}
                      onChange={(e) => setPrice(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                style={{ paddingBottom: 0 }}
                className="third-row"
              >
                <Col
                  xs={24}
                  xl={24}
                  key={'idx4'}
                >
                  <Form.Item
                    label={'Scope of work'}
                    name={'scope'}
                    rules={[
                      {
                        required: true,
                        message: 'field is required',
                      },
                    ]}
                  >
                    <TextArea
                      value={scope}
                      onChange={(e) => setScope(e.target.value)}
                      rows={7}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item shouldUpdate>
                <Button
                  htmlType="submit"
                  disabled={
                    !form.isFieldsTouched(true) ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                      .length > 0
                  }
                  className="save-btn"
                  onClick={() => createProject()}
                  loading={loading}
                >
                  {strings.save}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </>
      )}

      {projectDataContentStatus === 'EditProjectDetail' && <ProjectList />}

      {modal.type === 'success' && modal.show && (
        <Alert
          description={'Project Data has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </div>
  );
};

export default memo(ProjectDataView);

import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { List, Spin, Pagination, Input, Row, Col, message } from 'antd';
import { ConversationServices } from 'src/api/services';
import { strings } from 'common/index';
import { CirclePlusButton } from 'components/index';
import { MessageBoardItem } from 'components/index';
import { ConfirmModal } from 'components/index';
import { useRedux } from 'hooks/index';
import { Props } from './models';
import MessageBoardView from './messageBoard-view';
import { ModalType } from '../Rooms/models';
import { Alert } from 'src/ui-kits';
import { api } from 'api/api';
export const MessageBoard = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [count, setCount] = useState(0);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState<string>();
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const getMessages = async (offset = 1) => {
    setLoading(true);
    await ConversationServices.getConversations(idOfproject, {
      limit: 10,
      offset: (offset - 1) * 10,
    }).then((res) => {
      if (res) {
        setLoading(false);
        setCount(res.count);
        setConversations(res.results);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    if (idOfproject) {
      getMessages();
    }
  }, []);

  const removeConverstaions = async () => {
    if (!removeItem) {
      return;
    }
    await ConversationServices.deleteConversationsById(
      idOfproject,
      removeItem.toString()
    )
      .then((res) => {
        if (res) {
          setModal({ show: true, type: 'success' });
          setRemoveModal(false);
          setTimeout(() => {
            setModal((prev) => ({ ...prev, show: false }));
            getMessages();
          }, 1000);
        }
      })
      .catch(() => {
        setModal({ show: true, type: 'error' });
        setTimeout(() => {
          setModal((prev) => ({ ...prev, show: false }));
        });
      });
  };

  const { Search } = Input;
  const { Get, Save } = useRedux();
  const { messageBoardContentStatus, conversationStatus } = Get().app;

  const handleSearch = async (text: string) => {
    api
      .get(
        `${URL}/v1/projects/${idOfproject}/conversations?search=${text}`
      )
      .then((res: any) => {
        if (res?.data?.results?.length > 0) {
          setConversations(res.data.results);
        } else {
          getMessages();
          message.error('No match found');
        }
      })
      .catch((e) => {
        if (e) {
          getMessages();
          message.error('No match found');
        }
      });
  };
  const findFirstMessage = conversations.find(
    (e: any) => e?.is_first_conversation === 'true'
  );

  const MessageBoardList = () => {
    return (
      <>
        <Row>
          <Col
            xs={24}
            xl={16}
          >
            <div className="topRow-container">
              <CirclePlusButton
                disabled={
                  messageBoardContentStatus === 'Add' ||
                  messageBoardContentStatus === 'editMessageBoard'
                }
                onClick={() =>
                  Save(
                    { messageBoardContentStatus: 'Add', expandedItem: 0 },
                    'app'
                  )
                }
                btnText={strings.startANewConversation}
              />
            </div>
          </Col>
          <Col
            xs={24}
            xl={8}
          >
            <div className="search-box-message-board">
              <Search
                onSearch={(e) => handleSearch(e)}
                disabled={
                  conversationStatus === 'edit' || conversationStatus === 'chat'
                }
                placeholder={strings.searchConversations}
                className="search"
                allowClear
              />
            </div>
          </Col>
        </Row>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {}}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            dataSource={conversations}
            renderItem={(item) => (
              <MessageBoardItem
                firstMessage={findFirstMessage}
                getMessages={getMessages}
                data={item}
                setRemoveItem={setRemoveItem}
                setRemoveModal={setRemoveModal}
              />
            )}
          >
            {loading && (
              <div className="spin-container">
                <Spin size="default" />
              </div>
            )}
          </List>
        </InfiniteScroll>
        {count !== 0 && (
          <div className="pagination-container">
            <Pagination
              responsive={true}
              current={count}
              total={count}
              pageSize={10}
              onChange={(e) => getMessages(e)}
            />
          </div>
        )}
        <ConfirmModal
          deleteModal={true}
          handleOk={removeConverstaions}
          handleCancel={() => setRemoveModal(false)}
          isModalVisible={removeModal}
          okText={'Cancel'}
          cancelText={'Yes,delete this conversation'}
          questionTopText={
            'This action can not be undone. Are you sure you want to continue?'
          }
          questionText={''}
        />

        {modal.type === 'success' && modal.show && (
          <Alert
            description={'The conversations has been deleted successfully'}
            type="success"
          />
        )}
        {modal.type === 'error' && modal.show && <Alert type="error" />}
      </>
    );
  };

  return (
    <MessageBoardView
      MessageBoardList={MessageBoardList}
      {...props}
      getMessages={getMessages}
    />
  );
};

export default React.memo(MessageBoard);

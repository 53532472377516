import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';

import { useRedux } from 'hooks/index';
import './styles.css';

function HeaderButtons() {
  const { Save, Get } = useRedux();
  const [active, setActive] = useState('');
  const history = useHistory();

  const { expandedItem } = Get().app;

  useEffect(() => {
    let activeScreen = window.location.pathname;
    if (activeScreen === '/home') {
      setActive('home');
    } else if (activeScreen === '/add-project') {
      setActive('addProject');
    } else if (activeScreen === '/members') {
      setActive('members');
    } else if (activeScreen === '/question-list') {
      setActive('question');
    }
  }, []);
  const role = localStorage.getItem('userRole');

  const handleAddProjectRoute = () => {
    Save(
      {
        projectFormsType: 'AddProject',
        projectDataContentStatus: 'AddProjectData',
      },
      'app'
    );
    history.push('/add-project');
  };
  return (
    <div className='buttons'>
      <Button
        className={active === 'home' ? 'activeStyle' : ''}
        disabled={expandedItem !== undefined}
        title={
          expandedItem !== undefined
            ? 'Please fill out the fields and save the changes, or cancel it.'
            : ''
        }>
        <Link to='/home'>
          <HomeOutlined />
        </Link>
      </Button>
      {role === 'admin' && (
        <>
          <Button
            title={
              expandedItem !== undefined
                ? 'Please fill out the fields and save the changes, or cancel it.'
                : ''
            }
            disabled={expandedItem !== undefined}
            className={active === 'addProject' ? 'activeStyle' : ''}
            onClick={() => {
              handleAddProjectRoute();
            }}>
            + Add New Project
          </Button>
          <Button
            title={
              expandedItem !== undefined
                ? 'Please fill out the fields and save the changes, or cancel it.'
                : ''
            }
            disabled={expandedItem !== undefined}
            className={active === 'members' ? 'activeStyle' : ''}>
            <Link to='/members'>Members</Link>
          </Button>
        </>
      )}
      {/* <Button className={active === 'question' ? 'activeStyle' : ''}>
        <Link to='/question-list'>Questionnaire Templates</Link>
      </Button> */}
    </div>
  );
}

export default HeaderButtons;

import { ViewProps } from './models';
import { Modal, Button } from 'antd';
import { strings } from 'src/common';
function SaveModalsView(props: ViewProps) {
  const { handleCancel, handleOk, isSaveModalVisible } = props;

  return (
    <>
      <Modal
        visible={isSaveModalVisible}
        className="save-modal"
        title=""
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        width={700}
        bodyStyle={{ height: '200px' }}
        closable={false}
        footer={[
          <Button
            key="cancel"
            onClick={handleCancel}
          >
            {strings.cancel}
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleOk}
          >
            {'SAVE CHANGES AND LEAVE THIS PAGE'}
          </Button>,
          <Button
            key="leave"
            type="primary"
            // loading={loading}
            onClick={handleOk}
          >
            {'LEAVE THIS PAGE'}
          </Button>,
        ]}
      >
        <div className="confirm-modal-text">
          <p>You have unsaved changes!</p>

          <p>
            All changes will be lost. Are you sure you want to leave without
            saving?
          </p>
        </div>
      </Modal>
    </>
  );
}

export default SaveModalsView;

import React from "react";
import ProjectTable from "../../Table";
import "./styles.css";

const ClientsView = ({
  users,
  addUserToThisProject,
  removeUserFromProject,
}: any) => {
  return (
    <div>
      <ProjectTable
        users={users}
        addUserToThisProject={addUserToThisProject}
        removeUserFromProject={removeUserFromProject}
      />
    </div>
  );
};

export default ClientsView;

//@ts-nocheck
import { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Spin,
  TimePicker,
  InputNumber,
  List,
  Pagination,
} from 'antd';
import moment from 'moment';
import {
  CirclePlusButton,
  FileItem,
  UploadFile,
  CarouselModal,
  ConfirmModal,
} from 'components/index';
import { useRedux } from 'hooks/index';
import { ExportSVG } from 'src/assets';
import { strings } from 'common/index';
import { Alert } from 'ui-kits/index';
import { SnippetsOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroller';
import { PaymentItem } from './components/index';
import { ModalType, FileType } from './models';
import {
  MeetingsServises,
  DeleteUploadedFilesServices,
} from 'api/services/index';
import './styles.css';
//
const PaymentMettingView = (props: any) => {
  const { Save, Get } = useRedux();
  const [form] = Form.useForm();
  const [title, setTitle] = useState<any>(0);
  const [meetingTitles, setMeetingTitles] = useState([]);
  const [link, setLink] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [instructions, setInstructions] = useState('');
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [meetings, setMeetings] = useState<any>([]);
  const [dateAndTime, setDateAndTime] = useState<any>('');
  const [startTime, setStartTime] = useState<any>('');
  const [showCarousel, setShowCarousel] = useState(false);
  const [files, setFiles] = useState<FileType[]>([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState<string>();
  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [duration, setDuration] = useState(0);

  const [messageModal, setMessageModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [clipboardText, setClipBoardText] = useState('');
  const { paymentShowContent } = Get().app;
  const { Option } = Select;
  const { TextArea } = Input;

  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  let path = window.location.pathname;
  let idPath = path.split('/');
  let idOfproject: string = idPath[2];

  // const userId = localStorage.getItem('userId');
  const getTitles = async () => {
    await MeetingsServises.getTitleMeeting().then((res) => {
      if (res) {
        setMeetingTitles(res);
      }
    });
  };

  const convertData = (value: any) => {
    let newMeetings = [];
    for (let meeting of value) {
      let newMeeting = {
        ...meeting,
        size: {
          type: meeting.get_attachment_size < 100000 ? 'KB' : 'MB',
          size:
            meeting.get_attachment_size < 100000
              ? Math.round(meeting.get_attachment_size / 10) / 10
              : Math.round(meeting.get_attachment_size / 100000) / 10,
        },
      };
      newMeetings.push(newMeeting);
    }
    return newMeetings;
  };
  const createPayment = async () => {
    setLoading(true);
    if (
      title === '' ||
      dateAndTime === '' ||
      link === '' ||
      instructions === '' ||
      startTime === ''
    ) {
      setLoading(false);
      setMessageModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setMessageModal((prev) => ({ ...prev, show: false }));
      }, 2000);
      return;
    }
    setDisabled(true);
    const formData: any = new FormData();
    if (files && files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded') {
          formData.append(`attachments[${i}]attachment`, files[i].file);
          formData.append(`attachments[${i}]attachment_title`, files[i].name);

          if (files[i].rotate_angle) {
            formData.append(
              `attachment[${i}]rotate_angle`,
              files[i].rotate_angle
            );
          }
        }
      }
    }
    formData.append('title', title);
    formData.append('date', `${dateAndTime}T${startTime}`);
    formData.append('online_meeting_link', link);
    formData.append('instructions', instructions);
    formData.append('complete', complete);
    formData.append('duration', duration);
    formData.append('payment_link', paymentLink);
    if (paymentLink !== '') {
      if (paymentLink.toLowerCase().includes('https://')) {
        formData.append('payment_link', paymentLink);
      } else {
        message.error({
          content:
            'The link should start with https:// , something like https://goodspaceplan.com/',
        });
        setLoading(false);
        setDisabled(false);
        return;
      }
    } else if (paymentLink !== null) {
      formData.append('payment_link', paymentLink);
    }
    //
    if (link.toLowerCase().includes('https://')) {
      let result = await MeetingsServises.createMeetings(
        formData,
        idOfproject,
        {
          'Content-Type': 'multipart/form-data',
        }
      );

      setLoading(false);
      if (result) {
        form.resetFields();
        setMessageModal({ show: true, type: 'success' });
        setTimeout(() => {
          form.resetFields();
          setMessageModal((prev) => ({ ...prev, show: false }));
          getPayments(idOfproject);
          setDisabled(false);
          Save(
            {
              paymentShowContent: 'List',
              expandedItem: undefined,
            },
            'app'
          );
        }, 1500);
      } else {
        form.resetFields();
        setMessageModal({ show: true, type: 'error' });
        setTimeout(() => {
          setDisabled(false);
          setMessageModal((prev) => ({ ...prev, show: false }));
        }, 1500);
      }
    } else {
      setDisabled(false);
      setLoading(false);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
  };
  const getPayments = async (id: string, offset = 1) => {
    setLoading(true);
    let res = await MeetingsServises.getMeetings(id, {
      limit: 10,
      offset: (offset - 1) * 10,
    });
    if (res) {
      setCount(res.count);
      setMeetings(convertData(res.results));
      setLoading(false);
    }
  };
  const PaymentList = () => {
    return (
      <div className="goods-list">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {}}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            dataSource={meetings}
            renderItem={(item: any) => (
              <PaymentItem
                meetingTitles={meetingTitles}
                getPayments={(id: string) => getPayments(id)}
                title={item.title}
                setRemoveItem={setRemoveItem}
                setRemoveModal={setRemoveModal}
                data={item}
              />
            )}
          >
            {loading && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>

          {count !== 0 && (
            <div className="pagination-container">
              <Pagination
                current={count}
                defaultCurrent={1}
                total={count}
                pageSize={10}
                onChange={(e) => getPayments(idOfproject, e)}
              />
            </div>
          )}
        </InfiniteScroll>
      </div>
    );
  };

  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }
    let newMeetingFiles = [...files];
    let typeOfFile = newMeetingFiles.find(function (value: any) {
      return value.id === fileId;
    });
    let editedFile = newMeetingFiles.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'meeting_attachment',
        fileId
      );
      if (result) {
        setFiles(editedFile);
      }
    } else {
      setFiles(editedFile);
    }
  };

  useEffect(() => {
    getTitles();
    if (idOfproject) {
      getPayments(idOfproject.toString());
    }
  }, []);

  const removeMeetings = async () => {
    if (!removeItem) {
      return;
    }
    await MeetingsServises.deleteMeetingsById(
      idOfproject,
      removeItem.toString()
    );
    getPayments(idOfproject);
    setRemoveModal(false);
  };

  const handleDate = (dateString: any) => {
    let year = dateString._d.getFullYear();
    let month = (dateString._d.getMonth() + 1).toString().padStart(2, '0');
    let day = dateString._d.getDate().toString().padStart(2, '0');
    let newDate = year + '-' + month + '-' + day;

    setDateAndTime(newDate);
  };
  const handleTime = (timeString: any) => {
    let timeData: string[] = timeString._d.toString().split(' ');
    if (timeData.length !== 0) {
      setStartTime(timeData[4]);
    }
  };
  var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  var is_android = navigator.platform.toLowerCase().indexOf('android') > -1;
  const getClipboard = () => {
    if (!isFirefox) {
      navigator.clipboard
        .readText()
        .then((text) => {
          setClipBoardText(text);
        })
        .catch((err) => {
          if (err) return null;
          // message.error({ content: 'Failed to read clipboard contents' });
        });
    } else {
      return null;
    }
    if (isFirefox && is_android) {
      return null;
    }
  };
  useMemo(() => {
    getClipboard();
  }, []);
  const onFill = () => {
    form.setFieldsValue({
      paymentLink: clipboardText,
    });
  };

  const isFieldsTouched = form.isFieldsTouched([
    'time',
    'date',
    'title',
    'duration',
    'link',
    'paymentLink',
    'instruction',
    'attachment',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      {localStorage.getItem('userRole') === 'admin' && (
        <div className="circleBtn-container">
          <CirclePlusButton
            disabled={paymentShowContent === 'Add'}
            onClick={() =>
              Save({ paymentShowContent: 'Add', expandedItem: 0 }, 'app')
            }
            btnText={strings.addNewButton}
          />
        </div>
      )}
      {paymentShowContent === 'Add' && (
        <Card className="payment-card">
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              paymentLink: paymentLink,
            }}
          >
            <Row className="row1">
              <Col
                className="col1"
                xs={24}
                xl={3}
                key={'id1'}
              >
                <Form.Item
                  label={strings.title}
                  name={'title'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => setTitle(value)}
                  >
                    {meetingTitles &&
                      meetingTitles.results &&
                      meetingTitles.results.length !== 0 &&
                      meetingTitles.results.map((title: any) => {
                        return (
                          <Option
                            key={title.id}
                            value={title.id}
                          >
                            {title.title}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                className="col2"
                xs={24}
                xl={3}
                key={'id2'}
              >
                <Form.Item
                  label={'Date'}
                  name={'date'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <DatePicker
                    value={moment(dateAndTime, 'YYYY-MM-DD')}
                    style={{ width: '100%' }}
                    onChange={(dateString: any) => handleDate(dateString)}
                  />
                </Form.Item>
              </Col>
              <Col
                className="col2"
                xs={24}
                xl={3}
                key={'id2-1'}
              >
                <Form.Item
                  label={'Start Time(PT)'}
                  name={'time'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <TimePicker
                    value={moment(startTime, 'HH:mm:ss')}
                    style={{ width: '100%' }}
                    onChange={(timeString: any) => handleTime(timeString)}
                  />
                </Form.Item>
              </Col>
              <Col
                className="col5"
                xs={24}
                xl={2}
                key={'id2-5'}
              >
                <Form.Item
                  label={'Duration(mins)'}
                  name={'duration'}
                >
                  <InputNumber
                    onChange={(e) => setDuration(e)}
                    value={duration}
                  />
                </Form.Item>
              </Col>
              <Col
                className="col3"
                xs={24}
                xl={4}
                key={'id3'}
              >
                <Form.Item
                  label={strings.onlineMeetingLink}
                  name={'link'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <Input
                    onChange={(e) => setLink(e.target.value)}
                    suffix={
                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          style={{ width: '15px', marginLeft: '11px' }}
                          src={ExportSVG}
                          alt=""
                        />
                      </a>
                    }
                    onClick={() => {
                      return (
                        <Link
                          to={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      );
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                className="col3"
                xs={24}
                xl={4}
                key={'id3444'}
              >
                <Form.Item
                  label={'Payment Link'}
                  name={'paymentLink'}
                >
                  <Input
                    onChange={(e) => setPaymentLink(e.target.value)}
                    suffix={
                      !isFirefox && (
                        <Button
                          className="clipboard-button"
                          type="text"
                          border={false}
                          htmlType="button"
                          onClick={onFill}
                        >
                          <SnippetsOutlined />
                        </Button>
                      )
                    }
                  />
                </Form.Item>
              </Col>
              <Col
                className="col4 checkbox-container"
                xs={24}
                xl={3}
                key={'id4'}
              >
                <span>{strings.completed}</span>
                <Checkbox onChange={(e) => setComplete(e.target.checked)} />
              </Col>
            </Row>
            <Row className="row2">
              <Col
                xs={24}
                xl={24}
                key={'idx3'}
              >
                <Form.Item
                  label={'Notes'}
                  name={'instruction'}
                  rules={[{ required: true, message: 'field is required' }]}
                >
                  <TextArea
                    rows={5}
                    onChange={(e) => setInstructions(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="row3">
              <Col
                className="project-type-col"
                xs={24}
                xl={24}
                key={'idx3'}
              >
                <Form.Item
                  label={strings.attachment}
                  name="attachment"
                >
                  <UploadFile
                    text="Drag and drop files here"
                    width={'100%'}
                    height={110}
                    setFiles={setFiles}
                    reduxProperty={'meetingImages'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="meeting-fileItem">
              {files &&
                files.map((item: any, idx: number) => {
                  const { id, name, size, attachment_title, type } = item;

                  return (
                    <FileItem
                      isEditable={false}
                      key={id}
                      format={item.format}
                      disabled={localStorage.getItem('userRole') !== 'admin'}
                      fileData={item.file.attachment}
                      name={type === 'unUploaded' ? name : attachment_title}
                      id={id}
                      size={size}
                      file={item}
                      type={type}
                      width={'100%'}
                      removeFilePress={removeFilePress}
                      showImagePress={() => showCarouselImage(idx)}
                      onEdit={(rotateImageData: any) => {
                        return;
                      }}
                    />
                  );
                })}
            </div>
            <Form.Item shouldUpdate>
              <Button
                onClick={() => {
                  createPayment();
                  localStorage.setItem(
                    'isFieldsTouched',
                    JSON.stringify(false)
                  );
                }}
                className="save-btn"
                loading={loading}
                htmlType="submit"
                disabled={
                  form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0 || disabled
                }
              >
                {strings.save}
              </Button>
              <Button
                className="cancel-btn"
                onClick={() => {
                  Save(
                    { paymentShowContent: 'List', expandedItem: undefined },
                    'app'
                  );
                  localStorage.setItem(
                    'isFieldsTouched',
                    JSON.stringify(false)
                  );
                }}
              >
                {strings.cancel}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      )}
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spin size="small" />
        </div>
      ) : (
        (paymentShowContent === 'List' ||
          paymentShowContent === 'NotShow' ||
          paymentShowContent === 'edit') && <PaymentList />
      )}

      <ConfirmModal
        deleteModal={true}
        handleOk={removeMeetings}
        handleCancel={() => setRemoveModal(false)}
        isModalVisible={removeModal}
        okText={strings.uppercaseCancel}
        cancelText={'YES,Delete this meeting'}
        questionTopText={strings.thisActionCanNotBeUndoneAreYouSure}
        questionText={''}
      />
      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}
      {/* <RotateImage
        files={files}
        setFiles={setFiles}
        onFinish={() => setIsRotateImageActive(false)}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      /> */}
      {messageModal.type === 'success' && messageModal.show && (
        <Alert
          description={'The meeting has been added successfully'}
          type="success"
        />
      )}
      {messageModal.type === 'error' && messageModal.show && (
        <Alert type="error" />
      )}
      {messageModal.type === 'warning' && messageModal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </>
  );
};

export default PaymentMettingView;

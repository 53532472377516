import { useState, useEffect } from 'react';
import { Layout, Pagination } from 'antd';
import { AuthServices, UsersListType } from 'src/api/services';
import { useRedux } from 'src/hooks';
import { Header } from 'components/index';
import UserList from './UsersList/index';
import AddUser from './AddUser';
import './styles.css';
export type referrerType = {
  avatar: null | string;
  email: string | null;
  preferred_name: string | null;
  role: string | null;
  status: boolean | null;
};
const Members = () => {
  // antd
  const { Content } = Layout;
  // states
  const [users, setUsers] = useState<any>([]);
  const [refferrer, setRefferrer] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [refRole, setrefRole] = useState<any>([]);

  let allUsers: Array<UsersListType> = [];
  let allReffere: Array<any> = [];
  // redux
  const { Save, Get } = useRedux();
  const { userStatusConten } = Get().app;

  const getUsers = async (offset = 1) => {
    await AuthServices.usersList({
      limit: 10,
      offset: (offset - 1) * 10,
    }).then((res) => {
      if (res && res.results) {
        setCount(res.count);
        setLoading(false);
        let referrerRoleArr = res?.results.filter((e: any) => e.is_referrer);
        for (let i of res.results) {
          allUsers.push(i);
          if (i.referrer.id && i.is_referrer) {
            allReffere.push({
              name: i.preferred_name
                ? i.preferred_name
                : i.first_name + ' ' + i.last_name,
              ref: i.referrer.preferred_name,
              id: i.referrer.id,
            });
          }
        }
        setUsers(allUsers);
        setRefferrer(allReffere);
        setrefRole(referrerRoleArr);
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);
  const deleteUser = async (record: any) => {
    await AuthServices.deleteUserById(record.id.toString()).then((res) => {
      if (res) {
        getUsers();
        Save({ showDeleteModal: { isVisible: false, deleteItem: 0 } }, 'app');
      }
    });
  };
  return (
    <Layout className="home">
      <Header search={false} />
      <Content>
        <div className="user-container">
          <AddUser
            addMood={userStatusConten === 'addUser'}
            editMood={userStatusConten !== 'addUser'}
            getUsers={getUsers}
            referrer={refRole}
          />
          <UserList
            users={users}
            loading={loading}
            referrer={refferrer}
            deleteUser={deleteUser}
          />
          {count !== 0 && !loading && (
            <div className="pagination-container">
              <Pagination
                defaultCurrent={1}
                total={count}
                pageSize={10}
                onChange={(e) => getUsers(e)}
              />
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default Members;

import { Modal, Button } from "antd";
import { ViewProps } from "./models";
import { useRedux } from "src/hooks";

function ConfirmModalsView(props: ViewProps) {
  const { Get } = useRedux();
  const { showModal } = Get().app;
  let {
    isModalVisible = showModal,
    questionTopText,
    questionText,
    okText,
    cancelText,
    finallHandleCancel,
    finallHandleOk,
    deleteModal,
    disable,
  } = props;

  return (
    <>
      <Modal
        className={deleteModal ? "confirm-modal-delete" : "confirm-modal"}
        visible={isModalVisible}
        centered
        width={700}
        bodyStyle={{ minHeight: "250px" }}
        closable={false}
        footer={[
          <Button key="back" onClick={finallHandleCancel} disabled={disable}>
            {cancelText}
          </Button>,
          <Button key="submit" onClick={finallHandleOk} disabled={disable}>
            {okText}
          </Button>,
        ]}
      >
        <div className="confirm-modal-text">
          {questionTopText && <p className="top">{questionTopText}</p>}

          <p>{questionText}</p>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmModalsView;

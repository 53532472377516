export const KEYS = {
  STORAGE: {
    TOKENS: "TOKEN",
    USER_DATA: "USER_DATA",
    USER_ID: "USER_ID",
    USER_ROLE: "USER_ROLE",
  },
};
let path = window.location.pathname;
let idPath = path.split("/");
export const idOfproject = idPath[2];

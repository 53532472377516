import { memo, useEffect } from 'react';
import { useRedux } from 'src/hooks';
import LongItemView from './longItem-view';
import { Props } from './models';
import {
  TickSVG,
  PenSVG,
  TrashSVG,
  ConversationSVG,
  DownArrowSVG,
  EyeSVG,
} from 'assets/index';

import { LinkOutlined } from '@ant-design/icons';
export const LongItem = (props: Props) => {
  const { Save, Get } = useRedux();
  const {
    type,
    data,
    setContentStatus,
    children,
    editOnclick,
    setRemoveModal,
    setRemoveItem,
    title,
    firstMessage,
  } = props;
  const { goodsContentStatus, checkboxStatus } = Get().app;
  const role = localStorage.getItem('userRole');
  useEffect(() => {}, [goodsContentStatus]);
  const finallEditOnclick = () => {
    editOnclick && editOnclick();
    Save(
      {
        expandedItem: data.id.toString(),
        conversationStatus: 'edit',
        messageBoardContentStatus: 'editMessageBoard',
        editable: true,
      },
      'app'
    );
    setContentStatus && setContentStatus('Edit');
  };

  const finallConversatinOnclick = () => {
    setContentStatus && setContentStatus('Chat');
    Save(
      {
        expandedItem: data.id,
        conversationStatus: 'chat',
      },
      'app'
    );
  };
  const onDeleteItem = () => {
    setRemoveModal && setRemoveModal(true);
    setRemoveItem && setRemoveItem(data.id);
  };

  const Goods = () => {
    return (
      <div className="longItem-with-detail">
        <div className="longItem-container">
          <span
            className="title"
            onClick={() => {
              Save(
                {
                  expandedItem: data.id,
                  goodsContentStatus: 'editGoods',
                  editable: true,
                },
                'app'
              );
            }}
          >
            {title ? title : data.name}
          </span>
          <div className="right-container">
            {role !== 'client' && (
              <div
                onClick={() => {
                  Save(
                    {
                      expandedItem: data.id,
                      goodsContentStatus: 'editGoods',
                      editable: false,
                    },
                    'app'
                  );
                }}
                className="eye-container"
              >
                <img
                  src={EyeSVG}
                  alt=""
                />
              </div>
            )}

            <div
              onClick={() => {
                Save(
                  { expandedItem: data.id, goodsContentStatus: 'editGoods' },
                  'app'
                );
              }}
              className="pen-container"
            >
              {!checkboxStatus?.goods || role === 'admin' ? (
                <img
                  src={PenSVG}
                  alt={'pen'}
                />
              ) : (
                <img
                  src={DownArrowSVG}
                  alt=""
                />
              )}
            </div>
            {(role === 'admin' ||
              role === 'concierge' ||
              (role === 'client' && !checkboxStatus?.goods)) && (
              <div
                onClick={() => onDeleteItem()}
                className="trash-container"
              >
                <img
                  src={TrashSVG}
                  alt={'trash'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const Rooms = () => {
    return (
      <div className="longItem-with-detail">
        <div className="longItem-container">
          <span
            className="title"
            onClick={() => {
              Save(
                {
                  expandedItem: data.id,
                  roomContentStatus: 'editRoom',
                  editable: true,
                },
                'app'
              );
            }}
          >
            {title ? title : data.name}
          </span>
          <div className="right-container">
            {role !== 'client' && (
              <div
                onClick={() => {
                  Save(
                    {
                      expandedItem: data.id,
                      roomContentStatus: 'editRoom',
                      editable: false,
                    },
                    'app'
                  );
                }}
                className="eye-container"
              >
                <img
                  src={EyeSVG}
                  alt=""
                />
              </div>
            )}
            <div
              onClick={() => {
                Save(
                  {
                    expandedItem: data.id,
                    roomContentStatus: 'editRoom',
                    editable: true,
                  },
                  'app'
                );
              }}
              className="pen-container"
            >
              {!checkboxStatus?.rooms || role === 'admin' ? (
                <img
                  src={PenSVG}
                  alt={'pen'}
                />
              ) : (
                <img
                  src={DownArrowSVG}
                  alt=""
                />
              )}
            </div>
            {role === 'admin' && (
              <div
                onClick={() => onDeleteItem()}
                className="trash-container"
              >
                <img
                  src={TrashSVG}
                  alt={'trash'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const MessageBoard = () => {
    return (
      <div className="longItem-with-detail">
        <div className="longItem-container">
          {data !== undefined && data.comments ? (
            <>
              <span
                className="title"
                onClick={() => finallEditOnclick()}
              >
                {data.title}
              </span>

              <div className="right-container messageboard">
                <span>
                  Last update on{' '}
                  <b>
                    {data?.last_comment_date !== null
                      ? data?.last_comment_date
                      : data?.created_on}
                  </b>{' '}
                  - Created By{' '}
                  <b>
                    {data?.last_comment_created_by
                      ? data?.last_comment_created_by?.preferred_name
                      : data?.created_by?.preferred_name}
                  </b>
                </span>

                {data.files.length > 0 && (
                  <div className="trash-container">
                    <LinkOutlined />
                  </div>
                )}
                {role !== 'client' && (
                  <div
                    onClick={() => {
                      Save(
                        {
                          expandedItem: data.id,
                          conversationStatus: 'chat',
                          editable: false,
                        },
                        'app'
                      );
                    }}
                    className="eye-container"
                  >
                    <img
                      src={EyeSVG}
                      alt=""
                    />
                  </div>
                )}

                <div
                  onClick={() => finallConversatinOnclick()}
                  className="chat-container"
                >
                  <img
                    src={ConversationSVG}
                    alt=""
                  />
                </div>
                {type === '' && (
                  <div className="tick-container">
                    <img
                      src={TickSVG}
                      alt={'done'}
                    />
                  </div>
                )}
                {role !== 'client' && (
                  <>
                    <div
                      onClick={() => finallEditOnclick()}
                      className="pen-container"
                    >
                      {firstMessage?.id !== data.id ? (
                        <img
                          src={PenSVG}
                          alt={'pen'}
                        />
                      ) : (
                        <img
                          src={DownArrowSVG}
                          alt=""
                        />
                      )}
                    </div>
                    <div
                      onClick={() => {
                        Save({ showModal: true }, 'app');
                        onDeleteItem();
                      }}
                      className="trash-container"
                    >
                      <img
                        src={TrashSVG}
                        alt={'trash'}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            ' '
          )}
        </div>
      </div>
    );
  };

  const Questionnaire = () => {
    return (
      <div className="longItem-with-detail">
        <div
          style={{ marginBottom: children ? 20 : 0 }}
          className="longItem-container"
        >
          <div className="left-part">
            <span className="title">{data.title}</span>
          </div>
          <div className="right-container">
            <div
              onClick={() => finallEditOnclick()}
              className="pen-container"
            >
              <img
                src={PenSVG}
                alt={'pen'}
              />
            </div>
            <div
              onClick={() => Save({ showModal: true }, 'app')}
              className="trash-container"
            >
              <img
                src={TrashSVG}
                alt={'trash'}
              />
            </div>
          </div>
        </div>
        {children}
      </div>
    );
  };
  const ProjectData = () => {
    return (
      <div className="longItem-with-detail">
        <div className="longItem-container">
          <span
            className="title"
            onClick={() => {
              editOnclick && editOnclick();
              Save(
                {
                  expandedItem: data.id,
                  projectDataContentStatus: 'EditProjectDetail',
                },
                'app'
              );
              setContentStatus && setContentStatus('Edit');
            }}
          >
            {title ? title : data.name}
          </span>
          <div className="right-container">
            {role !== 'client' && (
              <div
                onClick={() => {
                  editOnclick && editOnclick();
                  Save(
                    {
                      expandedItem: data.id,
                      projectDataContentStatus: 'EditProjectDetail',
                      editable: false,
                    },
                    'app'
                  );
                  setContentStatus && setContentStatus('Edit');
                }}
                className="eye-container"
              >
                <img
                  src={EyeSVG}
                  alt=""
                />
              </div>
            )}
            <div
              onClick={() => {
                editOnclick && editOnclick();
                Save(
                  {
                    expandedItem: data.id,
                    projectDataContentStatus: 'EditProjectDetail',
                    editable: true,
                  },
                  'app'
                );
                setContentStatus && setContentStatus('Edit');
              }}
              className="pen-container"
            >
              {localStorage.getItem('userRole') === 'admin' ? (
                <img
                  src={PenSVG}
                  alt={'pen'}
                />
              ) : (
                <img
                  src={DownArrowSVG}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  const Meeting = () => {
    return (
      <div className="longItem-with-detail">
        <div className="longItem-container">
          <span
            className="title"
            onClick={() => {
              Save(
                {
                  expandedItem: data.id,
                  paymentShowContent: 'edit',
                },
                'app'
              );
            }}
          >
            {title ? title : data.name}
          </span>
          <div className="right-container">
            {role !== 'client' && (
              <div
                onClick={() => {
                  Save(
                    {
                      expandedItem: data.id,
                      paymentShowContent: 'edit',
                      editable: false,
                    },
                    'app'
                  );
                }}
                className="eye-container"
              >
                <img
                  src={EyeSVG}
                  alt=""
                />
              </div>
            )}
            <div
              onClick={() => {
                Save(
                  {
                    expandedItem: data.id,
                    paymentShowContent: 'edit',
                    editable: true,
                  },
                  'app'
                );
              }}
              className="pen-container"
            >
              {localStorage.getItem('userRole') === 'admin' ? (
                <img
                  src={PenSVG}
                  alt={'pen'}
                />
              ) : (
                <img
                  src={DownArrowSVG}
                  alt=""
                />
              )}
            </div>
            {localStorage.getItem('userRole') === 'admin' && (
              <div
                onClick={() => onDeleteItem()}
                className="trash-container"
              >
                <img
                  src={TrashSVG}
                  alt={'trash'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <LongItemView
      Meeting={Meeting}
      Rooms={Rooms}
      Goods={Goods}
      ProjectData={ProjectData}
      Questionnaire={Questionnaire}
      MessageBoard={MessageBoard}
      conversatiOnclickFun={finallConversatinOnclick}
      {...props}
    />
  );
};

export default memo(LongItem);

import { api } from 'api/api';

import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';
import { StringMappingType } from 'typescript';

const ENDPOINTS = {
  conversations: '/projects/{project_pk}/conversations/',
  comments: '/projects/{project_pk}/conversations/{conversation_pk}/comments/',
  converstaionsById: '/projects/{project_pk}/conversations/{id}/',
  commentsById:
    '/projects/{project_pk}/conversations/{conversation_pk}/comments/{id}/',
};
export const ConversationServices = {
  createConversation: async (
    project_pk: string,
    data: any,
    customHeader?: object,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(
        ENDPOINTS.conversations.replace('{project_pk}', project_pk),
        data,
        null,
        true,
        customHeader
      )
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        console.log(err, 'err');
        if (err.toLowerCase().includes('timeout')) {
          return true;
        }
        return null;
      });
  },
  getConversations: async (
    project_pk: string,
    params: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        ENDPOINTS.conversations.replace('{project_pk}', project_pk),
        params,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ConversationServices -> getConversations: ', err);
        return null;
      });
  },
  getConversatonsById: async (
    project_pk: string,
    converstaionId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        ENDPOINTS.converstaionsById
          .replace('{project_pk}', project_pk)
          .replace('{id}', converstaionId),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ConversationServices -> getConversations: ', err);
        return null;
      });
  },
  postComments: async (
    data: any,
    project_pk: string,
    conversations_pk: string
  ) => {
    return await api
      .post(
        `/projects/${project_pk}/conversations/${conversations_pk}/comments/`,
        data,
        null,
        true,
        {
          'Content-Type': 'multipart/form-data',
        }
      )
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        if (err.toLowerCase().includes('timeout')) {
          return true;
        }
        return null;
      });
  },
  editCommentById: async (
    data: any,
    project_pk: string,
    conversations_pk: string,
    comment_pk: string
  ) => {
    return await api
      .patch(
        `/projects/${project_pk}/conversations/${conversations_pk}/comments/${comment_pk}/`,
        data,
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err: any) => {
        console.log('err', err);
        // if (err.toLowerCase().includes('timeout')) {
        //   return true;
        // }
        return null;
      });
  },
  getComments: async (
    project_pk: string,
    conversations_pk: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        ENDPOINTS.comments
          .replace('{project_pk}', project_pk)
          .replace('{conversation_pk}', conversations_pk),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage(
              'ConversationServices -> createConversation: ',
              err
            );
        return null;
      });
  },
  getCommentsById: async (
    data: any,
    project_pk?: number,
    conversations_pk?: number,
    comments_id?: number,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        `/projects​/${project_pk}​/conversations​/${conversations_pk}/comments/${comments_id}`,
        data,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage(
              'ConversationServices -> createConversation: ',
              err
            );
        return null;
      });
  },
  deleteConversationsById: async (
    project_pk: string,

    converstaionId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        ENDPOINTS.converstaionsById
          .replace('{project_pk}', project_pk)
          .replace('{id}', converstaionId),
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('ConversationServices -> getConversations: ', err);
        return null;
      });
  },
  editConversationsById: async (
    project_pk: string,
    converstaionId: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        ENDPOINTS.converstaionsById
          .replace('{project_pk}', project_pk)
          .replace('{id}', converstaionId),
        data,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err: any) => {
        if (err.toLowerCase().includes('timeout')) {
          return true;
        }
        return null;
      });
  },
};

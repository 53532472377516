import ProjectTable from "../../Table";

import "./styles.css";
const AllView = ({
  users,
  loading,
  removeUserFromProject,
  addUserToThisProject,
}: any) => {
  return (
    <div>
      <ProjectTable
        users={users}
        loading={loading}
        addUserToThisProject={addUserToThisProject}
        removeUserFromProject={removeUserFromProject}
      />
    </div>
  );
};

export default AllView;

import { api } from 'api/api';

export const getAllQuestionnaires = () => {
  return api.get(`/questionnaires/`);
};

export const getProjectQuestionnaire = (project_pk: string | number) => {
  return api.get(`/projects/${project_pk}/questionnaires/`);
};

export const assignQuestionnaire = (
  project_pk: number | string,
  questionnaire_pk: number | string
) => {
  return api.put(
    `/projects/${project_pk}/manage-questionnaire/${questionnaire_pk}/`
  );
};

export const deleteQuestionnaire = (
  project_pk: number | string,
  questionnaire_pk: number | string
) => {
  return api.delete(
    `/projects/${project_pk}/manage-questionnaire/${questionnaire_pk}/`
  );
};

export const saveQuestionnaireAnswers = (
  project_id: number | string,
  template_id: number | undefined,
  data: any
) => {
  return api.patch(
    `/projects/${project_id}/questionnaires/${template_id}/`,
    data
  );
};
export const getProjectQuestionnairePdf = (project_pk: string | number) => {
  return api.get(`/projects/${project_pk}/download-questionnaire/`);
};

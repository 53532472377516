import { UpArrowSVG } from 'assets/index.js';
const CollapseItems = ({ onClick }: any) => {
  return (
    <div className="right-container-client">
      <div
        className="arrow-container"
        onClick={onClick}
      >
        <img
          src={UpArrowSVG}
          alt=""
        />
      </div>
    </div>
  );
};
export default CollapseItems;

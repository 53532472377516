import { api } from "api/api";
import { apiErrorMessage } from "../../helpers/apiErrorHandling.js";

export const TypesServices = {
  getTypes: async (badRequestCallback?: (err: any) => void) => {
    return await api
      .get("/types/", null, true)
      .then((response) => {
        return response.data.results;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("TypesServices -> getTypes: ", err);
        return null;
      });
  },
};

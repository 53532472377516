import { useEffect, useState } from 'react';
import { useRedux } from 'hooks/index';
import { EditDetails } from '../index';
import { LongItem } from 'components/index';

import './styles.css';

const PaymentItemView = (props: any) => {
  const {
    data,
    setRemoveModal,
    setRemoveItem,
    title,
    getPayments,
    meetingTitles,
  } = props;

  const { Get } = useRedux();
  const { expandedItem } = Get().app;

  let findNameOfTitle: any = '';
  const [nameTitle, setnameTitle] = useState('');
  const [pdf, setPdf] = useState('');
  const findTitle = () => {
    if (title && meetingTitles.results) {
      findNameOfTitle = meetingTitles.results.find(
        (e: any) => e.id === data.title
      );

      setnameTitle(findNameOfTitle.title);
      if (findNameOfTitle.pdf) {
        setPdf(findNameOfTitle.pdf);
      }
      return findNameOfTitle.title;
    }
  };

  useEffect(() => {
    findTitle();
  }, [findNameOfTitle]);

  let newFiles: any = [];
  if (data.attachments && data.attachments !== 0) {
    for (let perAttachment of data.attachments) {
      let file = {
        ...perAttachment,
        file: perAttachment.attachment,
        type: 'uploaded',
        title: perAttachment.attachment_title,
      };
      newFiles.push(file);
    }
  }

  return (
    <>
      {expandedItem === data.id ? (
        <EditDetails
          attachments={newFiles}
          data={data}
          nameTitles={nameTitle}
          getPayments={getPayments}
          pdf={pdf}
        />
      ) : (
        <div className="payment-longItem-container">
          <LongItem
            title={nameTitle}
            setRemoveItem={setRemoveItem}
            setRemoveModal={setRemoveModal}
            type={'Meeting'}
            data={data}
          />
        </div>
      )}
    </>
  );
};

export default PaymentItemView;

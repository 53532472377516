import { Link } from "react-router-dom";
import { UsersListType } from "src/api/services";
import { Breakpoint } from "antd/lib/_util/responsiveObserve";
import { useRedux } from "src/hooks";
import { ConfirmModal } from "components/index";
import { Button, Table, Switch } from "antd";
import { TrashSVG, PenSVG } from "src/assets";
import "./styles.css";

//types
type PropsType = {
  users: UsersListType[];
  deleteUser: (record: any) => Promise<void>;
};

const ProjectTableView = ({ users, deleteUser }: PropsType) => {
  const { Save, Get } = useRedux();
  const { showDeleteModal } = Get().app;

  let userId = localStorage.getItem("userId");
  const columns = [
    {
      title: "Name",
      dataIndex: "preferred_name",
      key: "preferred_name",

      responsive: ["md", "xs"] as Breakpoint[],
      width: "5%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",

      responsive: ["md", "xs"] as Breakpoint[],
      width: "5%",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",

      responsive: ["md", "xs"] as Breakpoint[],
      width: "5%",
    },

    {
      title: "Projects",
      key: "projects",
      dataIndex: "projects",
      responsive: ["md", "xs"] as Breakpoint[],
      width: "5%",
      render: (projects: any, record: any) => {
        return (
          <>
            {" "}
            <Button style={{ color: "balck" }} className="link-project">
              <Link to={`/members/${record.id}`}>
                On {projects.length} Projects
              </Link>
            </Button>
          </>
        );
      },
    },
    {
      title: "Status",
      key: "Status",
      dataIndex: "status",
      responsive: ["md", "xs"] as Breakpoint[],
      render: (e: boolean) => {
        return <Switch checked={e} disabled={true} defaultChecked={e} />;
      },
      width: "5%",
    },
    {
      title: "",
      key: "edit",
      width: "5%",
      dataIndex: "edit",
      responsive: ["md", "xs"] as Breakpoint[],
      render: (text: any, record: any) => {
        return (
          <div className="table-btn">
            <Button
              className="pen-container"
              onClick={() => {
                Save(
                  { userStatusConten: "editeUser", userEditeMoodData: record },
                  "app"
                );
              }}
            >
              <img src={PenSVG} alt="pen" />
            </Button>
            {Number(userId) !== record.id && (
              <Button
                className="trash-container"
                onClick={() =>
                  Save(
                    {
                      showDeleteModal: {
                        isVisible: true,
                        deleteItem: record,
                      },
                    },
                    "app"
                  )
                }
              >
                <img src={TrashSVG} alt="trash" />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="table-user">
      <Table dataSource={users} columns={columns} pagination={false} />

      <ConfirmModal
        handleOk={() => {
          showDeleteModal?.deleteItem && deleteUser(showDeleteModal.deleteItem);
        }}
        handleCancel={() =>
          Save({ showDeleteModal: { isVisible: false, deleteItem: 0 } }, "app")
        }
        isModalVisible={
          showDeleteModal?.isVisible && showDeleteModal?.isVisible
        }
        okText={"Cancel"}
        cancelText={"Yes I am sure"}
        questionTopText={"Do you want to delete this user?"}
        questionText={""}
      />
    </div>
  );
};

export default ProjectTableView;

import { api } from 'api/api';
import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';

const ENDPOINTS = {
  roomsById: 'projects/{project_pk}/rooms/{id}/',
  rooms: '/projects/{project_pk}/rooms/',
};
export const RoomServices = {
  createRoom: async (data: any, project_pk: string, customHeader?: object) => {
    return await api
      .post(
        ENDPOINTS.rooms.replace('{project_pk}', project_pk),
        data,
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err: any) => {
        console.log('err', err);
        // if (err.toLowerCase().includes('timeout')) {
        //   return true;
        // }
        // return null;
      });
  },
  editRoomsById: async (
    project_pk: number,
    roomsId: number,
    data: any,
    badRequestCallback?: (value: any) => void
  ) => {
    return await api
      .patch(
        ENDPOINTS.roomsById
          .replace('{project_pk}', project_pk.toString())
          .replace('{id}', roomsId.toString()),
        data,
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err: any) => {
        if (err.toLowerCase().includes('timeout')) {
          return true;
        }
        return null;
      });
  },
  roomsList: async (
    project_pk: string,
    params: any,
    badRequestCallback?: (value: any) => void
  ) => {
    return await api
      .get(ENDPOINTS.rooms.replace('{project_pk}', project_pk), params, true)
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('RoomServices  -> roomsList: ', err);
        return null;
      });
  },
  deleteRoomsById: async (
    project_pk: string,
    rooms_id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        ENDPOINTS.roomsById
          .replace('{project_pk}', project_pk)
          .replace('{id}', rooms_id),
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('roomsServices -> rooms: ', err);
        return null;
      });
  },
};

import LoginBgSVG from "./images/loginBg.svg";
import AvatarSvg from "./images/avatar.svg";
import StarSVG from "./icons/star.svg";
import TrashSVG from "./icons/trash.svg";
import PenSVG from "./icons/pen.svg";
import ChatSVG from "./icons/chat.svg";
import PlusSVG from "./icons/plus.svg";
import DocumentSVG from "./icons/document.svg";
import RoomSVG from "./icons/room.svg";
import LogoutSVG from "./images/logout.svg";
import GoodSVG from "./icons/good.svg";
import UserSVG from "./icons/user.svg";
import TimeLineSVG from "./icons/timeLine.svg";
import MeetingSVG from "./icons/meeting.svg";
import QuestionsSVG from "./icons/questions.svg";
import MessagesSVG from "./icons/messages.svg";
import GoodsSVG from "./images/goods.svg";
import HeartSvg from "./icons/heart.svg";
import EyeSVG from "./icons/eye.svg";
import ExampleSVG from "./icons/example.svg";
import PlaySVG from "./icons/play.svg";
import CloudUploadSVG from "./icons/cloud-upload.svg";
import TickSVG from "./icons/tick.svg";
import ExportSVG from "./icons/export.svg";
import OrangeEyeSVG from "./icons/orangeEye.svg";
import ImageSVG from "./icons/image.svg";
import Example1SVG from "./icons/example1.svg";
import FilterSVG from "./icons/filter.svg";
import ConversationSVG from "./icons/conversation.svg";
import BlueTopButtonSVG from "./icons/blueTopButton.svg";
import CameraSVG from "./icons/camera.svg";
import VideoSVG from "./icons/video.svg";
import FileSVG from "./icons/file.svg";
import TwoMessageSVG from "./icons/twoMessage.svg";
import DownArrowSVG from "./icons/downArrow.svg";
import NewWindow from "./icons/newWindow.svg";
import UpArrowSVG from "./icons/upArrow.svg";
import AlertSuccess from "./icons/AlertSuccess.svg";
import AlertError from "./icons/AlertError.svg";
import AlertInfo from "./icons/AlertInfo.svg";
import AlertWarning from "./icons/AlertWarning.svg";
import PdfSvg from "./icons/pdf.svg";
import loveseat from "./goodsItem/loveseat.jpg";
import couchsections from "./goodsItem/couchsections.jpg";
import couchsectionsright from "./goodsItem/couchsectionsright.jpg";
import GoodSpaceLogo from "./icons/goodSpace.svg";
import GspoLogo from "./icons/gspoLogo.png";
import LinkInspo from "./icons/linkInspiration.svg";
import ImageInspo from "./icons/imageInspiration.svg";
import TickDone from "./icons/TickDone.svg";
import profileDefault from "./images/profileDefault.png";
import presentaionSvg from "./icons/presentation.svg";
import downloadFile from "./icons/downloadFile.svg";
import miniPdfIcon from "./icons/miniPdfIcon.svg";
import houseIcon from "./icons/house.png";
import maintenanceIcon from "./icons/maintenance.png";
import measureIcon from "./icons/measure.png";
import HelperIcon from "./icons/HelperIcon.svg";
import VideoPlay from "./icons/videoPlay.svg";
export {
  VideoPlay,
  HelperIcon,
  houseIcon,
  maintenanceIcon,
  measureIcon,
  downloadFile,
  presentaionSvg,
  miniPdfIcon,
  profileDefault,
  TickDone,
  ImageInspo,
  LinkInspo,
  GspoLogo,
  loveseat,
  couchsections,
  couchsectionsright,
  AlertWarning,
  AlertInfo,
  AlertError,
  AlertSuccess,
  UpArrowSVG,
  DownArrowSVG,
  TwoMessageSVG,
  CameraSVG,
  FileSVG,
  VideoSVG,
  BlueTopButtonSVG,
  ConversationSVG,
  Example1SVG,
  ImageSVG,
  OrangeEyeSVG,
  ExportSVG,
  TickSVG,
  CloudUploadSVG,
  EyeSVG,
  LoginBgSVG,
  AvatarSvg,
  StarSVG,
  TrashSVG,
  PenSVG,
  ChatSVG,
  PlusSVG,
  LogoutSVG,
  DocumentSVG,
  RoomSVG,
  GoodSVG,
  UserSVG,
  TimeLineSVG,
  MeetingSVG,
  QuestionsSVG,
  MessagesSVG,
  GoodsSVG,
  HeartSvg,
  ExampleSVG,
  PlaySVG,
  FilterSVG,
  NewWindow,
  PdfSvg,
  GoodSpaceLogo,
};

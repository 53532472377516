import React from 'react'
import UserView from './user-view'
import { Props } from './models'

export const User = ( props : Props ) => {

  return (
    <UserView
      {...props}
    />
  )
}

export default User

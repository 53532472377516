import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ProjectsService } from 'api/services/index';
import './styles.css';
import { Layout, Row, Col, Button, Spin } from 'antd';
import { CardList, Header } from 'components/index';
import { useRedux } from 'src/hooks';
const ArchivedProjectsView = (props: any) => {
  const { Content } = Layout;
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { Save, Get } = useRedux();
  let archivedProjectList: any = [];
  const role = localStorage.getItem('userRole');
  const userId = localStorage.getItem('userId');
  const getProjects = async () => {
    const result = await ProjectsService.projectList(null);
    if (result) {
      for (let item of result.results) {
        if (item.is_archived === true && role === 'admin') {
          archivedProjectList.push(item);
        } else if (role !== 'admin') {
          if (item.users) {
            for (let i of item.users) {
              if (i.id === Number(userId)) {
                archivedProjectList.push(item);
              }
            }
          }
        }
      }
      setProjects(archivedProjectList);
      Save({ projectsLength: archivedProjectList.length }, 'app');
    }
    setLoading(false);
  };

  const { projectsLength } = Get().app;

  useEffect(() => {
    getProjects();
  }, []);
  return (
    <>
      <Layout className='home'>
        <Header search={projects.length !== 0 && true} />
        <Content>
          {loading ? (
            <div className='spin-container'>
              <Spin size='default' />
            </div>
          ) : (
            <>
              <Row className='recent-row'>
                <Col xl={12} xs={12}>
                  <p>Recent Projects ({projectsLength})</p>
                </Col>
                <Col xl={12} className='archived-btns' xs={12}>
                  <span className='recent-span'>
                    <Button>
                      <Link to='/home'>Show Activate</Link>
                    </Button>
                  </span>
                </Col>
              </Row>
              <div className='card-row'>
                <Row>
                  {projects && (
                    <>
                      <CardList project={projects} projectListType='archive' />
                    </>
                  )}
                </Row>
              </div>
            </>
          )}
        </Content>
      </Layout>
    </>
  );
};

export default ArchivedProjectsView;

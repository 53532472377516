import { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Typography, message } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import ConfirmModal from '../Modals/ConfirmModals';
import { ProjectsService } from 'src/api/services';
import { strings } from 'common/index';
const UserProjectCardItem = ({ project, user, getUser }: any) => {
  const { Paragraph } = Typography;
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSucceedModalVisible, setIsSucceedModalVisible] = useState(false);
  const [assignedProject, setAssaignedProject] = useState<any>([]);
  let filterNewListOfUsers: any = [];

  const projectUserList = async (offset = 1) => {
    await ProjectsService.getProjectUsers(project.id, {}).then((res) => {
      if (res && res.users) {
        setAssaignedProject(res.users);
      }
    });
  };
  const showConfirmModal = () => {
    setIsConfirmModalVisible(true);
  };
  let newFormData: any = new FormData();
  const removeUserFromProject = async () => {
    filterNewListOfUsers = assignedProject.filter((e: any) => e.id !== user.id);
    if (filterNewListOfUsers && filterNewListOfUsers.length !== 0) {
      filterNewListOfUsers.map((e: any) => {
        newFormData.append('users', e.id);
      });
    }
    if (project.id) {
      await ProjectsService.updateUserInProject(project.id, newFormData).then(
        (res) => {
          if (res) {
            setTimeout(() => {
              getUser();
            }, 1000);
          }
        }
      );
    }
  };
  useEffect(() => {
    projectUserList();
  }, []);
  const handleOk = () => {
    removeUserFromProject();
    setIsConfirmModalVisible(false);
    setIsSucceedModalVisible(true);
    message.success({
      content: 'The user has been removed from project',
    });
  };

  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };
  return (
    <>
      <Col
        xl={8}
        xs={24}
      >
        <Card bordered={false}>
          <Row className="status-remove projectname-container-userproject">
            <Col
              xl={12}
              xs={12}
              className="user-project-name"
            >
              <p>{project.name}</p>
            </Col>
            <Col
              xl={12}
              xs={12}
              className="remove-btn-container"
            >
              <Button
                size="middle"
                className="remove"
                onClick={showConfirmModal}
              >
                {strings.Remove}
              </Button>
            </Col>
          </Row>
          <Row className="status-date">
            <span>
              <CalendarOutlined />
              {project.created_on}
            </span>
          </Row>
          <div className="description">
            <Paragraph
              ellipsis={
                project.scope_of_project.length >= 200 && {
                  rows: 2,
                  expandable: true,
                  symbol: 'more',
                }
              }
            >
              {project.scope_of_project}
            </Paragraph>
          </div>
        </Card>
      </Col>
      <ConfirmModal
        deleteModal={true}
        questionText={''}
        questionTopText={'Do you want to remove user in  this project?'}
        okText={'Cancel'}
        cancelText={'Yes ,remove user '}
        isModalVisible={isConfirmModalVisible}
        handleOk={handleOk}
        isSucceedModalVisible={isSucceedModalVisible}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default UserProjectCardItem;

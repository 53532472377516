import { Button, Form, Input, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Row, Card, Col } from 'antd';
import axios from 'axios';
import './styles.css';
const ResetPassword = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [token, setToken] = useState('');
  const path = window.location.pathname;
  // let path = `https://portal.goodspaceplan.com/reset-password/cLbdIhlzOHqfh3OvyATL6A/`;
  const pathUrlArr = path.split('/');
  const token: any = pathUrlArr[pathUrlArr.length - 2];

  // let token: any;
  // useEffect(() => {
  //   return () => {
  //     if (pathUrlArr[pathUrlArr.length - 2] !== undefined) {
  //       setToken(pathUrlArr[pathUrlArr.length - 2]);
  //     } else if (pathUrlArr[pathUrlArr.length - 1] !== undefined) {
  //       setToken(pathUrlArr[pathUrlArr.length - 1]);
  //     } else {
  //       setToken(pathUrlArr[pathUrlArr.length - 3]);
  //     }
  //   };
  // }, []);

  const loginUser: any = async (value: any) => {
    setLoading(true);
    let { password, confirmPassword } = value;
    let data = {
      password: password,
      password2: confirmPassword,
    };
    if (token !== '' || token !== undefined) {
      let res = await axios.put(
        `${URL}/v1/auth/reset-password/${token}/`,
        data
      );
      if (res) {
        setLoading(false);
        message
          .loading('Action in progress..', 1.5)
          .then(() =>
            message.success('password has been successfully changed', 2.5)
          )
          .then(() => history.push('/login'));
      } else {
        message.error({ content: 'something went wrong' });
        setLoading(false);
      }
    } else {
      setLoading(false);
      message.error({ content: 'something went wrong,please try again' });
    }
  };

  return (
    <div className="screen-container">
      <Row justify="end">
        <Col
          xl={12}
          xs={24}
        >
          <div className="forgotpassword">
            <Card>
              <h1>Change Password</h1>
              <p>
                Please enter your new password below and confirm in the field
                below
              </p>
              <div className="login-parent">
                <div className="form-container">
                  <Form
                    name="normal_login"
                    className="login-form"
                    onFinish={loginUser}
                  >
                    <div className="username-container">
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your new password',
                          },
                        ]}
                      >
                        <Input.Password placeholder="New Password" />
                      </Form.Item>
                      <Form.Item
                        name="confirmPassword"
                        rules={[
                          {
                            required: true,
                            message: 'Please confirm your password',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!'
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Confirm Password" />
                      </Form.Item>
                    </div>

                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        loading={loading}
                      >
                        {/* Reset Password */}
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ResetPassword;

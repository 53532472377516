import './styles.css';
import { Button, Upload } from 'antd';

const UploadFileView = (props: any) => {
  const {
    uploadButton,
    width,
    height,
    handleChange,
    beforeUpload,
    hasSingleFile = false,
    disabled,
    title,
    text,
  } = props;
  const { Dragger } = Upload;
  return (
    <div
      style={{ width, height }}
      className="uploadFile-container"
    >
      <span>{title}</span>
      {hasSingleFile ? (
        <Upload
          multiple={false}
          disabled={disabled}
          name="avatar"
          listType="picture-card"
          className={hasSingleFile ? 'singleFile' : 'avatar-uploader'}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={(value) => handleChange(value, hasSingleFile)}
        >
          <div className="single-file-upload">
            <Button>{text}</Button>
          </div>
        </Upload>
      ) : (
        <Dragger
          multiple={true}
          disabled={disabled}
          name="avatar"
          listType="picture-card"
          className={hasSingleFile ? 'singleFile' : 'avatar-uploader'}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={(value) => handleChange(value, hasSingleFile)}
        >
          {uploadButton}
        </Dragger>
      )}
    </div>
  );
};

export default UploadFileView;

import { useState } from 'react';
import { Form, Input, Col, Row, Select, Button, Checkbox } from 'antd';
import { AuthServices } from 'src/api/services';
import { useRedux } from 'src/hooks';
import { ConfirmModal } from 'src/components';
import { ModalType } from 'src/screens/other/AddProject/tabs/Rooms/models';
import { Alert } from 'src/ui-kits';
import { strings } from 'src/common';
const UserFormInfo = ({ getUsers, referrer }: any) => {
  const { Option } = Select;
  const [showModal, setShowModal] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [status, setStatus] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [reffer, setReffer] = useState([]);
  const [bio, setBio] = useState('');
  const [prefferedName, setPrefferedName] = useState('');
  const [isReffer, setIsReffer] = useState(false);
  const [loading, setloading] = useState(false);
  const [calenderlink, setcalenderlink] = useState('');
  const { Save } = useRedux();
  const [form] = Form.useForm();
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  let newUserItems = {
    first_name: firstName,
    last_name: lastName,
    status: status,
    email: email,
    password: password,
    role: role,
    referrer: reffer,
    bio: bio,
    preferred_name: prefferedName !== '' ? prefferedName : firstName,
    calendar_link: calenderlink,
    is_referrer: isReffer,
  };

  const addNewUser = async () => {
    setloading(true);
    await AuthServices.addNewUser(newUserItems).then((results) => {
      if (results) {
        setloading(false);
        setModal({ show: true, type: 'success' });
        form.resetFields();
        setTimeout(() => {
          setModal((prev) => ({ ...prev, show: false }));

          Save({ userStatusConten: 'notShow' }, 'app');
          getUsers();
        }, 2000);
      } else {
        setloading(false);
        setModal({ show: true, type: 'error' });
        setTimeout(() => {
          // setDisabled(false);
          setModal((prev) => ({ ...prev, show: false }));
        }, 1500);
      }
    });
  };
  let selectedReferrerId: any = [];

  const handleChangeReferrer = (value: any) => {
    selectedReferrerId.push(value);

    setReffer(selectedReferrerId);
  };

  return (
    <div className="user-form-container">
      <Form
        layout="vertical"
        form={form}
        onFinish={() => addNewUser()}
      >
        <Row>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              label={'First Name'}
              name="first_name"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                placeholder={'john'}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              label={'Last Name'}
              name="last_name"
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                placeholder={'Andrew'}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={5}
          >
            <Form.Item
              label={'Preffered Name'}
              name={'prefname'}
              // rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                placeholder={'Smith'}
                onChange={(e) => setPrefferedName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={7}
          >
            <Form.Item
              label={'Email'}
              name={'email'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                placeholder={'jasmith@gmail.com'}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item label={'status'}>
              <Select
                defaultValue={'true'}
                onChange={(e) => {
                  return e === 'true' ? setStatus(true) : setStatus(false);
                }}
              >
                <Option value={'true'}>Active</Option>
                <Option value={'false'}>Deactive</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={24}
            xl={5}
          >
            <Form.Item
              label={'Password'}
              name={'password'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input.Password
                placeholder={'Must have at least 6 characters '}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              name={'role'}
              label={'Role'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Select
                onChange={(e) => setRole(e)}
                value={role}
              >
                <Option value={'admin'}>Admin</Option>
                <Option value="designer">Designer </Option>
                <Option value={'client'}>Client</Option>
                <Option value={'concierge'}>Concierge</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={6}
          >
            <Form.Item label={'Calendar Link'}>
              <Input
                placeholder={''}
                onChange={(e) => setcalenderlink(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item label={'Referrer'}>
              <Select onChange={(e) => handleChangeReferrer(e)}>
                {referrer &&
                  referrer.length !== 0 &&
                  referrer.map((ref: any) => {
                    return (
                      <Option
                        key={ref.id}
                        value={ref.id}
                      >
                        {ref.preferred_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="checkbox-referrer"
            xs={24}
            xl={4}
          >
            <Checkbox onChange={(e) => setIsReffer(e.target.checked)} />
            <span>This user refers clients</span>
          </Col>
          <Col
            xl={24}
            xs={24}
          >
            <Form.Item label="Bio">
              <Input.TextArea
                rows={6}
                onChange={(e) => setBio(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <div className="form-btns">
            <Button
              className='cancel-btn'
              onClick={() => Save({ userStatusConten: 'notShow' }, 'app')}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setShowModal(true)}
              htmlType="submit"
              loading={loading}
              className='save-btn'
            >
              Add User
            </Button>
          </div>
        </Row>
      </Form>

      {modal.type === 'success' && modal.show && (
        <Alert
          description={'User has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </div>
  );
};

export default UserFormInfo;

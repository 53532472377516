import { Alert } from "antd";
import { PropsType } from "./models";
import "./styles.css";
const Messages = (props: PropsType) => {
  const { type, description = "something went wrong please try again later " } =
    props;

  return (
    <div className="alert-message">
      {type === "success" && (
        <Alert
          onClose={props.onClose ? props.onClose : () => {}}
          message="Success!"
          description={description}
          type="success"
          showIcon
          closable
        />
      )}
      {type === "error" && (
        <Alert
          onClose={props.onClose ? props.onClose : () => {}}
          message="Error"
          description={description}
          type="error"
          showIcon
          closable
        />
      )}
      {type === "info" && (
        <Alert
          onClose={props.onClose ? props.onClose : () => {}}
          message="Info"
          description={description}
          type="info"
          showIcon
          closable
        />
      )}
      {type === "warning" && (
        <Alert
          onClose={props.onClose ? props.onClose : () => {}}
          message="Warning"
          description={description}
          type="warning"
          showIcon
          closable
        />
      )}
    </div>
  );
};

export default Messages;

import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from 'antd';
import { strings } from 'common/index';
import { ProjectsService } from 'src/api/services';
import { useRedux } from 'hooks/index';
import { Alert } from 'ui-kits/index';
import { UpArrowSVG } from 'assets/index';
import './styles.css';
import CollapseItems from 'src/components/collapseItems';
const ProjectData = ({ data, getProject }: any) => {
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [name, setName] = useState(data.name);
  const [type, setType] = useState<'test_1' | 'test_2' | undefined>(data.type);
  const [price, setPrice] = useState(data.price);
  const [disabled, setDisabled] = useState(false);
  const [scope, setScope] = useState(data.scope_of_project);
  const [loading, setLoading] = useState(false);
  const { Save, Get } = useRedux();
  const { projectDataContentStatus, editable } = Get().app;

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  const { TextArea } = Input;
  const { Option } = Select;

  let projectData = {
    // address1: address,
    name,
    price: Number(price),
    type,
    scope_of_project: scope,
  };
  const editProject = async () => {
    setLoading(true);
    setDisabled(true);
    let result = await ProjectsService.editProject(idOfproject, projectData);

    if (result) {
      setModal({ show: true, type: 'success' });

      setLoading(false);
      localStorage.setItem(
        'informationCompleted',
        result.information_completed
      );
      setTimeout(() => {
        getProject(idOfproject);
        setDisabled(false);
        setModal((prev) => ({ ...prev, show: false }));
        Save(
          {
            enableAddProjectTabs: false,
            expandedItem: undefined,
          },
          'app'
        );
      }, 2000);
    } else {
      setLoading(false);
      setDisabled(false);
      setModal({ show: true, type: 'error' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 3000);
    }

    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };
  const [form] = Form.useForm();

  useEffect(() => {}, [modal]);

  const isFieldsTouched = form.isFieldsTouched([
    'projectName',
    'projectType',
    'price',
    'scope',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      {projectDataContentStatus === 'EditProjectDetail' && (
        <>
          <Card
            className="froms-container"
            title="Project Details:"
          >
            {!editable && (
              <CollapseItems
                onClick={() =>
                  Save(
                    {
                      expandedItem: undefined,
                      editable: true,
                      projectDataContentStatus: 'EditProjectDetail',
                    },
                    'app'
                  )
                }
              />
            )}

            {localStorage.getItem('userRole') === 'admin' ? (
              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  projectName: name,
                  projectType: type,
                  price: Number(price),
                  scope: scope,
                }}
              >
                <Row className="first-row">
                  <Col
                    xs={24}
                    xl={8}
                    key={'idx1'}
                  >
                    <Form.Item
                      label={strings.projectName}
                      name={'projectName'}
                      rules={[
                        {
                          required: true,
                          message: 'field is required',
                        },
                      ]}
                    >
                      <Input
                        readOnly={!editable}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        defaultValue={name}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className=""
                    xs={24}
                    xl={8}
                    key={'idx'}
                  >
                    <Form.Item
                      label={strings.projectType}
                      name={'projectType'}
                      rules={[
                        {
                          required: true,
                          message: 'field is required',
                        },
                      ]}
                    >
                      <Select
                        disabled={!editable}
                        defaultValue={type}
                        value={type}
                        onChange={(value) => setType(value)}
                        style={{ width: '100%' }}
                      >
                        <Option value="space-planning-/-furnishing">
                          Space planning/furnishing
                        </Option>
                        <Option value="renovations">Renovations</Option>
                        <Option value="combo">Combo </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="second-row">
                  <Col
                    xs={24}
                    xl={24}
                    key={'idx2'}
                  >
                    <Form.Item
                      label={strings.totalPrice}
                      name={'price'}
                      rules={[
                        {
                          required: true,
                          message: 'field is required',
                        },
                      ]}
                    >
                      <InputNumber
                        readOnly={!editable}
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value: any) =>
                          value!.replace(/\$\s?|(,*)/g, '')
                        }
                        style={{ width: '100%' }}
                        min={0}
                        defaultValue={price}
                        onChange={(e) => setPrice(e)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  style={{ paddingBottom: 0 }}
                  className="third-row"
                >
                  <Col
                    xs={24}
                    xl={24}
                    key={'idx4'}
                  >
                    <Form.Item
                      name={'scope'}
                      label={'Scope of work'}
                      rules={[
                        {
                          required: true,
                          message: 'field is required',
                        },
                      ]}
                    >
                      <TextArea
                        readOnly={!editable}
                        defaultValue={scope}
                        value={scope}
                        onChange={(e) => setScope(e.target.value)}
                        rows={7}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {editable && (
                  <Form.Item shouldUpdate>
                    <Button
                      htmlType="submit"
                      disabled={
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length > 0 ||
                        disabled
                      }
                      className="save-btn"
                      onClick={editProject}
                      loading={loading}
                    >
                      {strings.save}
                    </Button>
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        Save(
                          {
                            expandedItem: undefined,
                          },
                          'app'
                        );

                        localStorage.setItem(
                          'isFieldsTouched',
                          JSON.stringify(false)
                        );
                      }}
                    >
                      {strings.cancel}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            ) : (
              <>
                <CollapseItems
                  onClick={() => {
                    Save(
                      {
                        expandedItem: undefined,
                        projectDataContentStatus: 'EditProjectDetail',
                        editable: true,
                      },
                      'app'
                    );
                  }}
                />

                <Row className="first-row-client">
                  <Col
                    xs={24}
                    xl={8}
                    key={'idx1'}
                    className="client-view"
                  >
                    <div>
                      <span>Project Name </span>
                      <p className="client-view-p">{data.name}</p>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    xl={8}
                    key={'idx'}
                    className="client-view"
                  >
                    <div>
                      <span>{strings.projectType} </span>
                      <p className="client-view-p">{data.type}</p>
                    </div>
                  </Col>
                </Row>

                <Row
                  style={{ paddingBottom: 0 }}
                  className="third-row-client"
                >
                  <Col
                    xs={24}
                    xl={24}
                    key={'idx4'}
                    className="client-view"
                  >
                    <div>
                      <span>{strings.scopeOfProject} </span>
                      <p className="client-view-p">{data.scope_of_project}</p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </Card>
          {modal.show && (
            <Alert
              description={'Project has been edited successfully '}
              type="success"
            />
          )}
          {modal.type === 'error' && modal.show && <Alert type="error" />}
          {modal.type === 'warning' && modal.show && (
            <Alert
              description={strings.pleaseFillAllInputs}
              type="warning"
            />
          )}
        </>
      )}
    </>
  );
};

export default ProjectData;

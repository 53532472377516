import { useState, useEffect } from "react";
import { Checkbox } from "antd";
import { ProjectsService, NotificationsServices } from "src/api/services";
import { ConfirmModal, CirclePlusButton } from "components/index";
import { useRedux } from "src/hooks";
const AddInpiration = () => {
  const { Get, Save } = useRedux();
  const { checkboxStatus } = Get().app;
  const [checkStatusInspiration, setCheckStatus] = useState(
    checkboxStatus?.inspiration
  );

  const [checkStatusInspirationModal, setCheckStatusModal] = useState(false);
  // project id
  let path = window.location.pathname;
  let idPath = path.split("/");
  const idOfproject = idPath[2];
  //this function changes inspiration_completed field in project data api
  const editStateOfCheckbox = async (isDataComplete: boolean) => {
    if (isDataComplete) {
      let result = await ProjectsService.editProject(idOfproject, {
        inspirations_completed: true,
      });

      if (result) {
        Save(
          { checkboxStatus: { ...checkboxStatus, inspiration: true } },
          "app"
        );
        localStorage.setItem("inspiration_completed", "true");
      }
    } else {
      let result = await ProjectsService.editProject(idOfproject, {
        inspirations_completed: false,
      });

      if (result) {
        Save(
          { checkboxStatus: { ...checkboxStatus, inspiration: false } },
          "app"
        );
        localStorage.setItem("inspiration_completed", "false");
      }
    }
  };
  const sendCheckStatusInspiration = async () => {
    return await NotificationsServices.sendCheck(
      idOfproject,
      "inspiration"
    ).then((res) => {
      if (res) {
        setCheckStatusModal(false);
        setCheckStatus(true);
        setTimeout(() => {
          editStateOfCheckbox(true);
        }, 2000);
      }
    });
  };

  const role = localStorage.getItem("userRole");
  useEffect(() => {}, [checkboxStatus?.inspiration]);
  return (
    <>
      <div className="button-container">
        <CirclePlusButton
          disabled={checkboxStatus?.inspiration && role === "client"}
          onClick={() =>
            Save(
              {
                insprationContentStatus: "addInspration",
                expandedItem: 0,
              },
              "app"
            )
          }
          btnText={"Add New Inspiration"}
        />

        <span className="inspo-checkbox">
          <span className="checkbox-text">
            {"I've added all my inspirations."}
          </span>{" "}
          <Checkbox
            disabled={
              role !== "admin"
                ? checkboxStatus?.inspiration
                  ? true
                  : false
                : false
            }
            onChange={() => {
              if (!checkStatusInspiration) {
                setCheckStatusModal(true);
              } else {
                setCheckStatus(false);
                editStateOfCheckbox(false);
              }
            }}
            checked={checkboxStatus?.inspiration}
          />
        </span>
      </div>
      <ConfirmModal
        deleteModal={false}
        handleOk={async () => {
          await sendCheckStatusInspiration();
        }}
        handleCancel={() => setCheckStatusModal(false)}
        isModalVisible={checkStatusInspirationModal}
        okText={"Cancel"}
        cancelText={"YES,I have added all my inspirations"}
        questionTopText={
          "Are you sure that you have added all your inspirations? "
        }
        questionText={""}
      />
    </>
  );
};

export default AddInpiration;

import { useState } from 'react';
import CardItemView from './cardItem-view';
import { Props } from './models';
import { useRedux } from 'hooks/index';
import { ProjectsService } from 'api/services/index';
import { message } from 'antd';

export const CardItem = (props: Props) => {
  const { id, is_archived } = props;
  const { Save, Get } = useRedux();
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const [willArchive, setWillArchive] = useState<number>();
  const [willActivate, setWillActivate] = useState<number>();
  const [isArchived, setIsArchived] = useState<boolean | undefined>(
    is_archived
  );
  const { projectsLength } = Get().app;
  const showArchiveModal = (id: number) => {
    setWillArchive(id);
    setWillActivate(id);
    setArchiveModalVisible(true);
  };

  const activateProject = async () => {
    if (!willActivate || !projectsLength) {
      return;
    }
    const projectData = {
      is_archived: false,
    };
    let res = await ProjectsService.editProject(
      willActivate.toString(),
      projectData
    );
    if (res) {
      Save({ projectsLength: projectsLength - 1 }, 'app');
      setIsArchived(false);
      setArchiveModalVisible(false);
      message.success({
        content: 'The project has been activated',
      });
    }
  };
  const archiveProject = async () => {
    if (!willArchive || !projectsLength) {
      return;
    }
    const projectData = {
      is_archived: true,
    };
    let res = await ProjectsService.editProject(
      willArchive.toString(),
      projectData
    );
    if (res) {
      Save({ projectsLength: projectsLength - 1 }, 'app');
      setIsArchived(true);
      setArchiveModalVisible(false);
      message.success({
        content: 'The project has been archived',
      });
    }
  };

  const editProject = () => {
    Save(
      {
        projectFormsType: 'EditProject',
        projectId: Number(id),
        roomContentStatus: 'RoomsList',
        goodsContentStatus: 'GoodsList',
        terminalContentStatus: 'List',
        paymentShowContent: 'List',
        messageBoardContentStatus: 'List',
      },
      'app'
    );
  };

  return (
    <CardItemView
      activateProject={activateProject}
      archiveProject={archiveProject}
      editProject={editProject}
      showArchiveModal={showArchiveModal}
      setArchiveModalVisible={setArchiveModalVisible}
      archiveModalVisible={archiveModalVisible}
      isArchived={isArchived}
      {...props}
    />
  );
};

export default CardItem;

import { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
} from 'antd';
import { FileOutlined } from '@ant-design/icons';
import { useRedux } from 'src/hooks';
import { CameraSVG, VideoSVG } from 'assets/index';
import { ConversationServices, ProjectsService } from 'src/api/services';
import CarouselModal from 'components/Carousel';
import { strings } from 'src/common';
import { Alert } from 'src/ui-kits';
import FileItem from 'src/components/FileItem';
import './styles.css';
import { useForm } from 'antd/lib/form/Form';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { RotateImage } from 'src/components/RotateImage';
export const AddConversations = ({ getMessages }: any) => {
  const { Save } = useRedux();
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [member, setMember] = useState([]);
  const [assignedProject, setAssaignedProject] = useState<any>([]);
  const [description, setDescription] = useState('');
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [disabled, setDisabled] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [files, setFiles] = useState<any[]>([]);
  const [degree, setDegree] = useState(0);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [rotateImageData, setRotateImageData] = useState(null);

  const { Option } = Select;

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const removeFilePress = (fileId: number) => {
    if (!files) {
      return;
    }
    let newFile = [...files];
    let editedFile = newFile.filter(function (value: any) {
      return value.id !== fileId;
    });

    setFiles(editedFile);
  };
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  const createMessageBoard = () => {
    Save({ messageBoardContentStatus: 'List' }, 'app');
  };

  const isPNG = (file: any) =>
    file.toUpperCase() === 'PNG' ||
    file.toUpperCase() === 'TIFF' ||
    file.toUpperCase() === 'JPG' ||
    file.toUpperCase() === 'JPEG' ||
    file.toUpperCase() === 'GIF' ||
    file.toUpperCase() === 'EPS' ||
    file.toUpperCase() === 'HEIF' ||
    file.toUpperCase() === 'DNG' ||
    file.toUpperCase() === 'RAW';
  const isVideo = (file: any) =>
    file.toUpperCase() === 'AVI' ||
    file.toUpperCase() === 'FLV' ||
    file.toUpperCase() === 'WMV' ||
    file.toUpperCase() === 'MP4' ||
    file.toUpperCase() === 'MOV';

  //
  const createNewConversation = async () => {
    if (member === null || description === '' || title === '') {
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 1500);
      return;
    }
    setDisabled(true);
    const formData: any = new FormData();
    if (member && member.length !== 0) {
      for (let i = 0; i < member.length; i++) {
        formData.append('users', member[i]);
      }
    }
    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded' && isPNG(files[i].format)) {
          formData.append(`photos[${i}]photo`, files[i].file);
          formData.append(`photos[${i}]photo_title`, files[i].name);
          if (files[i].rotate_angle) {
            formData.append(`photos[${i}]rotate_angle`, files[i].rotate_angle);
          }
        } else if (files[i].type === 'unUploaded' && isVideo(files[i].format)) {
          formData.append(`videos[${i}]video`, files[i].file);
          formData.append(`videos[${i}]video_title`, files[i].name);
        } else {
          formData.append(`files[${i}]file`, files[i].file);
          formData.append(`files[${i}]file_title`, files[i].name);
        }
      }
    }
    formData.append('description', description);
    formData.append('title', title);
    const result = await ConversationServices.createConversation(
      idOfproject,
      formData
    );
    if (result) {
      setModal({ show: true, type: 'success' });
      setTimeout(() => {
        setDisabled(false);
        setModal((prev) => ({ ...prev, show: false }));
        createMessageBoard();
        projectUserList();
        getMessages();
        Save(
          {
            expandedItem: undefined,
          },
          'app'
        );
      }, 1500);
    } else {
      setDisabled(false);
      setModal({ show: true, type: 'error' });
      setTimeout(() => {
        setDisabled(false);
        setModal((prev) => ({ ...prev, show: false }));
      }, 1500);
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const projectUserList = async (offset = 1) => {
    await ProjectsService.getProjectUsers(idOfproject, {
      limit: 20,
      offset: (offset - 1) * 20,
    }).then((res) => {
      if (res && res.users) {
        setAssaignedProject(res.users);
      }
    });
  };
  useEffect(() => {
    projectUserList();
  }, []);

  const [form] = useForm();

  const isFieldsTouched = form.isFieldsTouched([
    'title',
    'members',
    'description',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      <Card className="add-card">
        <Form
          layout="vertical"
          form={form}
        >
          <Row className="first-row">
            <Col
              xl={8}
              xs={24}
            >
              <Form.Item
                label={strings.title}
                name="title"
              >
                <Input
                  className="conversation-title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col
              xl={8}
              xs={24}
            >
              <Form.Item
                label={strings.members}
                name="members"
              >
                <Select
                  showArrow
                  showSearch={false}
                  mode="multiple"
                  className="select-user"
                  style={{ width: '100%' }}
                  placeholder={strings.selectAUser}
                  optionFilterProp="children"
                  onChange={(value: any) => setMember(value)}
                >
                  {assignedProject &&
                    assignedProject.length !== 0 &&
                    assignedProject.map((user: any) => {
                      let userRole =
                        user?.role?.charAt(0).toUpperCase() +
                        user?.role?.slice(1);
                      return (
                        <Option
                          value={user.id}
                          key={user.id}
                        >
                          {userRole} :{user.preferred_name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row
            className="second-row"
            justify="center"
          >
            <Col
              xl={24}
              xs={24}
            >
              <Form.Item
                label={strings.description}
                name="description"
              >
                <Editor
                  spellCheck={true}
                  toolbarClassName="demo-toolbar-custom"
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor-custom"
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                  }}
                  onEditorStateChange={onEditorStateChange}
                  onChange={(e: any) => {
                    localStorage.setItem(
                      'isFieldsTouched',
                      JSON.stringify(true)
                    );

                    setDescription(
                      draftToHtml(convertToRaw(editorState.getCurrentContent()))
                    );
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <div className="attach-row-message-board">
            <Upload
              customRequest={({ file, onSuccess }: any) => {
                return false;
              }}
              showUploadList={false}
              beforeUpload={(file: any) => {
                const isPNG =
                  file.type.toUpperCase() === 'IMAGE/PNG' ||
                  file.type.toUpperCase() === 'IMAGE/TIFF' ||
                  file.type.toUpperCase() === 'IMAGE/JPG' ||
                  file.type.toUpperCase() === 'IMAGE/JPEG' ||
                  file.type.toUpperCase() === 'IMAGE/GIF' ||
                  file.type.toUpperCase() === 'IMAGE/EPS' ||
                  file.type.toUpperCase() === 'IMAGE/HEIF' ||
                  file.type.toUpperCase() === 'IMAGE/DNG' ||
                  file.type.toUpperCase() === 'IMAGE/RAW';
                if (!isPNG) {
                  message.error(
                    `${file.name} should be one of png, tiff, jpg, jpeg, gif, eps, HEIF, DNG, Raw types.`
                  );
                }
                return isPNG || Upload.LIST_IGNORE;
              }}
              onChange={(info: any) => {
                setFiles((prev) => [
                  ...prev,
                  {
                    id: Math.round(Math.random() * 1000),
                    rotate_angle: 0,
                    uid: info.file.uid,
                    lastModified: info.file.lastModified,
                    lastModifiedDate: info.file.lastModifiedDate,
                    name: info.file.name,
                    file: info.file.originFileObj,
                    size: {
                      type: info.file.size < 100000 ? 'KB' : 'MB',
                      size:
                        info.file.size < 100000
                          ? Math.round(info.file.size / 10) / 10
                          : Math.round(info.file.size / 100000) / 10,
                    },
                    type: 'unUploaded',
                    format:
                      info.file.name.split('.')[
                        info.file.name.split('.').length - 1
                      ],
                    webkitRelativePath: info.file.webkitRelativePath,
                  },
                ]);
              }}
            >
              <Button
                icon={
                  <img
                    src={CameraSVG}
                    alt=""
                  />
                }
              >
                {'Photo'}
              </Button>
            </Upload>
            <Upload
              customRequest={({ file, onSuccess }: any) => {
                return false;
              }}
              showUploadList={false}
              beforeUpload={(file) => {
                const isVideo =
                  file.type.toUpperCase() === 'VIDEO/AVI' ||
                  file.type.toUpperCase() === 'VIDEO/FLV' ||
                  file.type.toUpperCase() === 'VIDEO/WMV' ||
                  file.type.toUpperCase() === 'VIDEO/MP4' ||
                  file.type.toUpperCase() === 'VIDEO/MOV';

                if (!isVideo) {
                  message.error(
                    `${file.name} should be one of AVI, FLV, WMV, mp4, MOV types.`
                  );
                }
                return isVideo || Upload.LIST_IGNORE;
              }}
              onChange={(info: any) => {
                //@ts-ignore
                // setFiles((prev) => [...prev, info.file.originFileObj]);
                setFiles((prev) => [
                  ...prev,
                  {
                    id: Math.round(Math.random() * 1000),
                    uid: info.file.uid,
                    lastModified: info.file.lastModified,
                    lastModifiedDate: info.file.lastModifiedDate,
                    name: info.file.name,
                    file: info.file.originFileObj,
                    size: {
                      type: info.file.size < 100000 ? 'KB' : 'MB',
                      size:
                        info.file.size < 100000
                          ? Math.round(info.file.size / 10) / 10
                          : Math.round(info.file.size / 100000) / 10,
                    },
                    type: 'unUploaded',
                    format:
                      info.file.name.split('.')[
                        info.file.name.split('.').length - 1
                      ],
                    webkitRelativePath: info.file.webkitRelativePath,
                  },
                ]);
              }}
            >
              <Button
                icon={
                  <img
                    src={VideoSVG}
                    alt=""
                  />
                }
              >
                {'Video'}
              </Button>
            </Upload>
            <Upload
              customRequest={({ file, onSuccess }: any) => {
                return false;
              }}
              beforeUpload={(file) => {
                const isVideoOrImage =
                  file.type.toUpperCase() === 'VIDEO/AVI' ||
                  file.type.toUpperCase() === 'VIDEO/FLV' ||
                  file.type.toUpperCase() === 'VIDEO/WMV' ||
                  file.type.toUpperCase() === 'VIDEO/MP4' ||
                  file.type.toUpperCase() === 'VIDEO/MOV' ||
                  file.type.toUpperCase() === 'IMAGE/PNG' ||
                  file.type.toUpperCase() === 'IMAGE/TIFF' ||
                  file.type.toUpperCase() === 'IMAGE/JPG' ||
                  file.type.toUpperCase() === 'IMAGE/JPEG' ||
                  file.type.toUpperCase() === 'IMAGE/GIF' ||
                  file.type.toUpperCase() === 'IMAGE/EPS' ||
                  file.type.toUpperCase() === 'IMAGE/HEIF' ||
                  file.type.toUpperCase() === 'IMAGE/DNG' ||
                  file.type.toUpperCase() === 'IMAGE/RAW ';

                if (isVideoOrImage) {
                  message.error(
                    `${file.name} should not be an image or video type.`
                  );
                }
                return !isVideoOrImage || Upload.LIST_IGNORE;
              }}
              showUploadList={false}
              onChange={(info: any) => {
                setFiles((prev) => [
                  ...prev,
                  {
                    id: Math.round(Math.random() * 1000),
                    uid: info.file.uid,
                    lastModified: info.file.lastModified,
                    lastModifiedDate: info.file.lastModifiedDate,
                    name: info.file.name,
                    file: info.file.originFileObj,
                    size: {
                      type: info.file.size < 100000 ? 'KB' : 'MB',
                      size:
                        info.file.size < 100000
                          ? Math.round(info.file.size / 10) / 10
                          : Math.round(info.file.size / 100000) / 10,
                    },
                    type: 'unUploaded',
                    format:
                      info.file.name.split('.')[
                        info.file.name.split('.').length - 1
                      ],
                    webkitRelativePath: info.file.webkitRelativePath,
                  },
                ]);
              }}
            >
              <Button icon={<FileOutlined />}>{'File'}</Button>
            </Upload>
          </div>
          <Row>
            <Col
              xs={24}
              xl={24}
            >
              {files &&
                files.map((item: any, idx: number) => {
                  return (
                    <FileItem
                      isEditable={isPNG(item.format)}
                      setFiles={setFiles}
                      files={files}
                      setIsRotateImageActive={setIsRotateImageActive}
                      key={item.id}
                      disabled={false}
                      fileData={item.file}
                      name={item.name}
                      id={item.id}
                      format={item.format}
                      type={item.type}
                      file={item}
                      size={item.size}
                      width={'98%'}
                      removeFilePress={removeFilePress}
                      showImagePress={() => showCarouselImage(idx)}
                      onEdit={(rotateImageData: any) => {
                        setIsRotateImageActive(true);
                        setRotateImageData(rotateImageData);
                      }}
                    />
                  );
                })}
            </Col>
          </Row>
          <br />
          {showCarousel && (
            <CarouselModal
              onCancelPress={() => setShowCarousel(false)}
              isModalVisible={showCarousel}
              imageArray={files}
              defaultIndex={carouselIndex}
            />
          )}
        </Form>
        <Row justify="end">
          <Button
            className="cancel-btn"
            onClick={() => {
              Save(
                {
                  messageBoardContentStatus: 'NotShow',
                  expandedItem: undefined,
                },
                'app'
              );
              localStorage.setItem('isFieldsTouched', JSON.stringify(false));
            }}
          >
            {strings.cancel}
          </Button>
          <Button
            onClick={() => createNewConversation()}
            className="save-btn"
            disabled={disabled}
          >
            {strings.save}
          </Button>
        </Row>
      </Card>
      <RotateImage
        files={files}
        setFiles={setFiles}
        onFinish={() => {
          setIsRotateImageActive(false);
          setDegree(0);
        }}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      />
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The conversation has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </>
  );
};

export default AddConversations;

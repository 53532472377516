import { CardItem } from "./components/index";
import "./styles.css";
const CardListView = (props: any) => {
  const { project, projectListType } = props;

  return (
    <>
      {project &&
        project.map((item: any) => {
          const {
            created_on,
            name,
            status,
            id,
            scope_of_project = "",
            is_archived,
            users,
            messages_length,
          } = item;

          let editedTime =
            created_on.split(" ")[2] +
            " " +
            created_on.split(" ")[1] +
            " " +
            created_on.split(" ")[3];

          return (
            <CardItem
              projectItem={item}
              key={id}
              projectListType={projectListType}
              editedTime={editedTime}
              name={name}
              status={status}
              id={id}
              scope_of_project={scope_of_project}
              is_archived={is_archived}
              users={users}
              messages_length={messages_length}
            />
          );
        })}
    </>
  );
};

export default CardListView;

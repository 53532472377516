import ProjectTable from "../../Table";
import "./styles.css";
const DesignersView = ({
  users,
  removeUserFromProject,
  addUserToThisProject,
}: any) => {
  return (
    <div>
      <ProjectTable
        users={users}
        removeUserFromProject={removeUserFromProject}
        addUserToThisProject={addUserToThisProject}
      />
    </div>
  );
};

export default DesignersView;

import { ViewProps } from './models';
import './styles.css';
import { TrashSVG, PenSVG, EyeSVG, UpArrowSVG } from 'assets/index';
import { strings } from 'src/common';
import {
  Card,
  Button,
  Row,
  Col,
  Input,
  Typography,
  Form,
  Modal,
  Carousel,
  message,
} from 'antd';
import { useRedux } from 'src/hooks';
import { useState, useEffect } from 'react';
import { FileItem } from 'components/index';
import { ExportSVG, ImageInspo, LinkInspo } from 'assets/index';
import { ProjectsService } from 'src/api/services';
import { ConfirmModal } from 'components/index';
import { PlayCircleOutlined, SoundOutlined } from '@ant-design/icons';
import { URL } from 'src/api/api';
import { RotateImage } from '../RotateImage';

const InspirationItemView = (props: ViewProps) => {
  let role = localStorage.getItem('userRole');

  const { Paragraph } = Typography;
  const { item, typeInspration, insprationList } = props;
  const { Save, Get } = useRedux();
  const [disabled, setDisabled] = useState(false);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { attachment_title, attachment, id } = item;
  const { expandedItem, checkboxStatus, editable } = Get().app;
  const [srcAttachments, setSrcAttachments] = useState<any>([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [showCarouselUpload, setShowCarouselUpload] = useState(false);
  const [loading, setloading] = useState(false);
  const [rotateImageData, setRotateImageData] = useState();
  const [rotateImageDataFiles, setrotateImageDataFiles] = useState([item]);
  const [link, setlink] = useState<string>(item.link);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [degree, setDegree] = useState(0);
  const [fileDescription, setfileDescription] = useState(
    typeInspration === 'photo' && item.description
  );
  const [description, setdescription] = useState<string>(
    typeInspration === 'web' && item.description
  );
  const [pinterestDescription, setpinterestDescription] = useState(
    typeInspration === 'pinterest' && item.description
  );
  const [pinterestLink, setpinterestLink] = useState(
    typeInspration === 'pinterest' && item.board_link
  );
  const [houzzDescription, setHouzzDescription] = useState(
    typeInspration === 'houzz' && item.description
  );
  const [houzzLink, setHouzzLink] = useState(
    typeInspration === 'houzz' && item.link
  );

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const { TextArea } = Input;
  let allAttachments: Array<string> = [];
  const listOfAttachments = () => {
    if (attachment) {
      let src = `${URL}/${attachment}`;
      allAttachments.push(src);
      setSrcAttachments(allAttachments);
    }
  };

  const editWebsite = async () => {
    let data = {
      link: link,
      description: description,
    };
    setDisabled(true);
    setloading(true);
    if (link.toLocaleLowerCase().includes('https://')) {
      await ProjectsService.editeInsprationWebsite(idOfproject, data, id).then(
        (res) => {
          if (res) {
            setloading(false);
            message.success({
              content: 'inspiration has been edited successfully',
            });
            setDisabled(false);
            Save(
              {
                expandedItem: undefined,
                insprationContentStatus: 'notShowInspration',
              },
              'app'
            );
          } else {
            setloading(false);
            setDisabled(false);
            message.error({
              content: 'something went wrong,please try again later',
            });
          }
        }
      );
    } else {
      setloading(false);
      setDisabled(false);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
  };
  const deleteWebsiteInspration = async () => {
    setloading(true);
    await ProjectsService.deleteInsprationWebsite(idOfproject, id).then(
      (res) => {
        if (res) {
          setloading(false);
          Save(
            {
              expandedItem: undefined,
              insprationContentStatus: 'notShowInspration',
            },
            'app'
          );
          setShowConfirmModal(false);
          setTimeout(() => {
            insprationList();
            message.success({
              content: 'This inspiration has been successfully deleted',
            });
          }, 1000);
        } else {
          setloading(false);
          setShowConfirmModal(false);
          message.error({
            content: 'something went wrong,please try again later',
          });
        }
      }
    );
  };

  const editUploadFile = async () => {
    let formData: any = new FormData();
    formData.append('description', description);
    if (rotateImageDataFiles[0].rotate_angle !== 0) {
      formData.append(`rotate_angle`, rotateImageDataFiles[0].rotate_angle);
    }
    setDisabled(true);
    setloading(true);

    await ProjectsService.editInsprationUploadFile(
      idOfproject,
      formData,
      id
    ).then((res) => {
      if (res) {
        setloading(false);
        setDisabled(false);
        message.success({
          content: 'Inspiration has been edited successfully',
        });
        Save(
          {
            expandedItem: undefined,
            insprationContentStatus: 'notShowInspration',
          },
          'app'
        );
      } else {
        setDisabled(false);
        setloading(false);
        setShowConfirmModal(false);
        message.error({
          content: 'something went wrong,please try again later',
        });
      }
    });
  };
  const deleteUploadFile = async () => {
    setloading(true);
    await ProjectsService.deleteInsprationUploadFile(idOfproject, id).then(
      (res) => {
        if (res) {
          setloading(false);
          Save(
            {
              expandedItem: undefined,
              insprationContentStatus: 'notShowInspration',
            },
            'app'
          );

          setShowConfirmModal(false);
          setTimeout(() => {
            message.success({
              content: 'This inspiration has been successfully deleted',
            });
            insprationList();
          }, 1000);
        } else {
          setloading(false);
          setShowConfirmModal(false);
          message.error({
            content: 'something went wrong,please try again later',
          });
        }
      }
    );
  };

  const editPinterest = async () => {
    let data = {
      board_link: pinterestLink,
      description: pinterestDescription,
    };
    setloading(true);
    setDisabled(true);
    if (pinterestLink.toLocaleLowerCase().includes('https://')) {
      ProjectsService.editInsprationPinterest(idOfproject, data, id)
        .then((res) => {
          if (res) {
            setloading(false);
            setDisabled(false);
            message.success({
              content: 'Inspiration has been edited successfully',
            });
            Save(
              {
                expandedItem: undefined,
                insprationContentStatus: 'notShowInspration',
              },
              'app'
            );
          }
        })
        .catch(() => {
          setDisabled(false);
          setloading(false);
        });
    } else {
      setDisabled(false);
      setloading(true);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
  };
  const deletePinterest = async () => {
    await ProjectsService.deleteInsprationPinterest(idOfproject, id).then(
      (res) => {
        if (res) {
          setloading(false);

          setShowConfirmModal(false);
          setTimeout(() => {
            Save(
              {
                expandedItem: undefined,
                insprationContentStatus: 'notShowInspration',
              },
              'app'
            );
            message.success({
              content: 'This inspiration has been successfully deleted',
            });
            insprationList();
          }, 1000);
        } else {
          setloading(false);
          setShowConfirmModal(false);
          message.error({
            content: 'something went wrong,please try again later',
          });
        }
      }
    );
  };
  const editHouzz = async () => {
    setloading(true);
    setDisabled(true);
    if (houzzLink.toLocaleLowerCase().includes('https://')) {
      await ProjectsService.editInsprationHouzz(
        idOfproject,
        {
          link: houzzLink,
          description: houzzDescription,
        },
        id
      )
        .then((res) => {
          if (res) {
            setloading(false);
            setDisabled(false);
            message.success({
              content: 'Inspiration has been edited successfully',
            });
            Save(
              {
                expandedItem: undefined,
                insprationContentStatus: 'notShowInspration',
              },
              'app'
            );
          }
        })
        .catch(() => {
          setloading(false);
          setDisabled(false);
        });
    } else {
      setloading(false);
      setDisabled(false);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
  };
  const deleteHouzz = async () => {
    await ProjectsService.deleteInpirationHouzz(idOfproject, id).then((res) => {
      if (res) {
        setloading(false);

        setShowConfirmModal(false);
        setTimeout(() => {
          Save(
            {
              expandedItem: undefined,
              insprationContentStatus: 'notShowInspration',
            },
            'app'
          );
          message.success({
            content: 'This inspiration has been successfully deleted',
          });
          insprationList();
        }, 1000);
      } else {
        setloading(false);
        setShowConfirmModal(false);
        message.error({
          content: 'something went wrong,please try again later',
        });
      }
    });
  };
  useEffect(() => {
    listOfAttachments();
  }, [expandedItem]);

  const unExpendedFormWeb = () => {
    return (
      <>
        {expandedItem === id ? (
          <Card className="website-card">
            {!editable && (
              <div className="up-arrow-container">
                <div className="arrow-container">
                  <img
                    src={UpArrowSVG}
                    alt=""
                    onClick={() =>
                      Save(
                        {
                          expandedItem: undefined,
                          editable: true,
                          insprationContentStatus: 'notShowInspration',
                        },
                        'app'
                      )
                    }
                  />
                </div>
              </div>
            )}
            <div className="pinterest-container">
              <Form layout="vertical">
                <Row className="second-row">
                  <Col
                    xs={24}
                    xl={24}
                    key={'id1'}
                  >
                    <Form.Item label={'Link'}>
                      <div className="input-icon-container">
                        <Input
                          readOnly={!editable}
                          defaultValue={link}
                          value={link}
                          onChange={(e) => setlink(e.target.value)}
                        />
                        <a
                          href={link}
                          target={'_blank'}
                          rel="noopenesr noreferrer"
                        >
                          <img
                            src={ExportSVG}
                            alt=""
                          />
                        </a>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="third-row">
                  <Col
                    xs={24}
                    xl={24}
                    key={'id1'}
                  >
                    <Form layout="vertical">
                      <Form.Item label={strings.description}>
                        <TextArea
                          readOnly={!editable}
                          defaultValue={description}
                          rows={5}
                          value={description}
                          onChange={(e) => setdescription(e.target.value)}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                </Row>
              </Form>
            </div>
            {editable && (
              <Row justify="end">
                <div
                  onClick={() => setShowConfirmModal(true)}
                  className="trash-container-edit"
                >
                  <img
                    src={TrashSVG}
                    alt=""
                  />
                </div>
                <Button
                  className="cancel-btn "
                  onClick={() =>
                    Save(
                      {
                        expandedItem: undefined,
                        insprationContentStatus: 'notShowInspration',
                      },
                      'app'
                    )
                  }
                >
                  {strings.cancel}
                </Button>

                <Button
                  onClick={() => editWebsite()}
                  className="save-btn"
                  disabled={disabled}
                >
                  {strings.save}
                </Button>
              </Row>
            )}

            <ConfirmModal
              deleteModal={true}
              okText={'Cancel'}
              cancelText={'Yes,delete this inspiration'}
              questionText={
                'This action can not be undone. Are you sure you want to continue?'
              }
              isModalVisible={showConfirmModal}
              handleOk={() => deleteWebsiteInspration()}
              handleCancel={() => setShowConfirmModal(false)}
            />
          </Card>
        ) : (
          <div className="inspiration-item">
            <Row justify="space-between">
              <Col
                xl={20}
                xs={16}
              >
                <span
                  className="link-inspiration"
                  style={{ color: '#6BA2B9' }}
                >
                  <img
                    src={LinkInspo}
                    alt=""
                    style={{ width: '1rem', marginRight: '10px' }}
                  />
                  Website :{' '}
                  <a
                    href={link}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    style={{ marginLeft: '10px' }}
                  >
                    {link}
                  </a>{' '}
                </span>
                <Paragraph
                  onClick={() =>
                    Save(
                      {
                        expandedItem: id,
                        insprationContentStatus: 'editInspration',
                        editable: true,
                      },
                      'app'
                    )
                  }
                  className="inspiration-item-description"
                  ellipsis={{ rows: 3.5, expandable: true, symbol: 'more' }}
                >
                  {description}
                </Paragraph>
                {/* <p className="inspiration-item-description"></p> */}
              </Col>
              <Col
                xl={4}
                xs={8}
                className="col-icon-container"
              >
                {(!checkboxStatus?.inspiration ||
                  role === 'admin' ||
                  role === 'concierge') && (
                  <>
                    <div
                      onClick={() => {
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: false,
                          },
                          'app'
                        );
                      }}
                      className="eye-container"
                    >
                      <img
                        src={EyeSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() =>
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: true,
                          },
                          'app'
                        )
                      }
                      className="pen-container"
                    >
                      <img
                        src={PenSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => setShowConfirmModal(true)}
                      className="trash-container"
                    >
                      <img
                        src={TrashSVG}
                        alt=""
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <ConfirmModal
              deleteModal={true}
              okText={'Cancel'}
              cancelText={'Yes delete this inspiration'}
              questionText={
                'This action can not be undone. Are you sure you want to continue?'
              }
              isModalVisible={showConfirmModal}
              handleOk={() => deleteWebsiteInspration()}
              handleCancel={() => setShowConfirmModal(false)}
            />
          </div>
        )}
      </>
    );
  };
  const unExpendedFormFile = () => {
    const fileFormat = attachment.split('.');
    const isPNG = (file: any) =>
      file.toUpperCase() === 'PNG' ||
      file.toUpperCase() === 'TIFF' ||
      file.toUpperCase() === 'JPG' ||
      file.toUpperCase() === 'JPEG';
    return (
      <>
        {expandedItem === id ? (
          <>
            <Card className="website-card">
              {!editable && (
                <div className="up-arrow-container">
                  <div className="arrow-container">
                    <img
                      src={UpArrowSVG}
                      alt=""
                      onClick={() =>
                        Save(
                          {
                            expandedItem: undefined,
                            editable: true,
                            insprationContentStatus: 'notShowInspration',
                          },
                          'app'
                        )
                      }
                    />
                  </div>
                </div>
              )}
              <div className="upload-file">
                <Form layout="vertical">
                  <Row className="second-row">
                    <Col
                      xs={24}
                      xl={24}
                      key={'id1'}
                    >
                      {rotateImageDataFiles.map((file) => (
                        <div
                          className="file-items-container"
                          style={{ marginBottom: '10px' }}
                        >
                          <FileItem
                            isEditable={fileFormat && isPNG(fileFormat[1])}
                            key={file.id}
                            setIsRotateImageActive={setIsRotateImageActive}
                            disabled={
                              role === 'client'
                                ? checkboxStatus?.inspiration
                                : false
                            }
                            fileData={file.attachment}
                            name={file.attachment_title}
                            id={id}
                            files={file.attachment}
                            size={file.get_attachment_size}
                            file={file}
                            type={'uploaded'}
                            format={fileFormat && fileFormat[1]}
                            removeFilePress={() => setShowConfirmModal(true)}
                            showImagePress={() => setShowCarouselUpload(true)}
                            width={'99%'}
                            onEdit={(rotateImageData: any) => {
                              setIsRotateImageActive(true);
                              setRotateImageData(rotateImageData);
                            }}
                          />
                        </div>
                      ))}
                    </Col>
                  </Row>
                  <br />
                  <Row className="third-row">
                    <Col
                      xs={24}
                      xl={24}
                      key={'id1'}
                    >
                      <Form.Item label={strings.description}>
                        <TextArea
                          readOnly={!editable}
                          rows={5}
                          value={fileDescription}
                          onChange={(e) => setfileDescription(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
              {editable && (
                <>
                  <Button
                    onClick={() => editUploadFile()}
                    className="save-btn"
                    disabled={disabled}
                    loading={loading}
                  >
                    {strings.save}
                  </Button>
                  <Button
                    className="cancel-btn "
                    onClick={() =>
                      Save(
                        {
                          expandedItem: undefined,
                          insprationContentStatus: 'notShowInspration',
                        },
                        'app'
                      )
                    }
                  >
                    {strings.cancel}
                  </Button>
                </>
              )}

              <Modal
                visible={showCarouselUpload}
                onCancel={() => setShowCarouselUpload(false)}
                footer={null}
              >
                <Carousel arrows={true}>
                  {srcAttachments &&
                    srcAttachments.length !== 0 &&
                    srcAttachments.map((srcAttachment: any, index: any) => {
                      return (
                        <img
                          key={index}
                          src={srcAttachment}
                          alt=""
                        />
                      );
                    })}
                </Carousel>
              </Modal>
              <ConfirmModal
                deleteModal={true}
                okText={'Cancel'}
                cancelText={'Yes,delete this inspiration'}
                questionText={
                  'Are you sure that you want to delete this inspiration? deleting this will result in removing this image and its description'
                }
                isModalVisible={showConfirmModal}
                handleOk={() => deleteUploadFile()}
                handleCancel={() => setShowConfirmModal(false)}
              />
              {isRotateImageActive && (
                <RotateImage
                  files={rotateImageDataFiles}
                  setFiles={setrotateImageDataFiles}
                  onFinish={() => {
                    setIsRotateImageActive(false);
                    setDegree(0);
                  }}
                  isRotateImageActive={isRotateImageActive}
                  rotateImageData={rotateImageData}
                  onEdit={() => setDegree((prev: number) => prev + 90)}
                  degree={degree}
                />
              )}
            </Card>
          </>
        ) : (
          <div className="inspiration-item">
            <Row justify="space-between">
              <Col
                xl={20}
                xs={16}
              >
                <span
                  style={{ color: '#6BA2B9' }}
                  className="image-inspration"
                >
                  <img
                    src={ImageInspo}
                    alt=""
                    style={{ width: '1rem', marginRight: '10px' }}
                  />
                  Photo/Video:{' '}
                  <a
                    href={`${URL}/${attachment}`}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    {attachment && attachment.toLowerCase().includes('mp4') ? (
                      <PlayCircleOutlined className="image-inspiration-uploaded" />
                    ) : (attachment &&
                        attachment.toLowerCase().includes('png')) ||
                      attachment.toLowerCase().includes('svg') ||
                      attachment.toLowerCase().includes('jpg') ||
                      attachment.toLowerCase().includes('jpeg') ? (
                      <img
                        alt=""
                        src={`${URL}/${attachment}`}
                        className="image-inspiration-uploaded"
                        style={{ width: '2rem', height: '2rem' }}
                      />
                    ) : (
                      <SoundOutlined className="image-inspiration-uploaded" />
                    )}{' '}
                    <span className="image-inspration-attachment-title">
                      {attachment_title}
                    </span>
                  </a>
                </span>
                <Paragraph
                  onClick={() =>
                    Save(
                      {
                        expandedItem: id,
                        insprationContentStatus: 'editInspration',
                        editable: true,
                      },
                      'app'
                    )
                  }
                  className="inspiration-item-description"
                  ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}
                >
                  {fileDescription}
                </Paragraph>
              </Col>
              <Col
                xl={4}
                xs={8}
                className="col-icon-container"
              >
                {(!checkboxStatus?.inspiration ||
                  role === 'admin' ||
                  role === 'concierge') && (
                  <>
                    <div
                      onClick={() => {
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: false,
                          },
                          'app'
                        );
                      }}
                      className="eye-container"
                    >
                      <img
                        src={EyeSVG}
                        alt=""
                      />
                    </div>

                    <div
                      onClick={() =>
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: true,
                          },
                          'app'
                        )
                      }
                      className="pen-container"
                    >
                      <img
                        src={PenSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => setShowConfirmModal(true)}
                      className="trash-container"
                    >
                      <img
                        src={TrashSVG}
                        alt=""
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Modal
              visible={showCarousel}
              onCancel={() => setShowCarousel(false)}
              footer={null}
            >
              <Carousel arrows={true}>
                {rotateImageDataFiles &&
                  rotateImageDataFiles.length !== 0 &&
                  rotateImageDataFiles.map((file: any) => {
                    return (
                      <img
                        src={process.env.REACT_APP_BASE_URL + file.attachment}
                        alt=""
                        key={file.id}
                        style={{ transform: `rotate(${file.rotate_angle})deg` }}
                      />
                    );
                  })}
              </Carousel>
            </Modal>
            <ConfirmModal
              deleteModal={true}
              okText={'Cancel'}
              cancelText={'Yes delete this inspiration'}
              questionText={
                'This action can not be undone. Are you sure you want to continue?'
              }
              isModalVisible={showConfirmModal}
              handleOk={() => deleteUploadFile()}
              handleCancel={() => setShowConfirmModal(false)}
            />
          </div>
        )}
      </>
    );
  };
  const unExpendedFormPinterest = () => {
    return (
      <>
        {expandedItem === id ? (
          <Card className="website-card">
            {!editable && (
              <div className="up-arrow-container">
                <div className="arrow-container">
                  <img
                    src={UpArrowSVG}
                    alt=""
                    onClick={() =>
                      Save(
                        {
                          expandedItem: undefined,
                          editable: true,
                          insprationContentStatus: 'notShowInspration',
                        },
                        'app'
                      )
                    }
                  />
                </div>
              </div>
            )}
            <div className="pinterest-container">
              <Row className="second-row">
                <Col
                  xs={24}
                  xl={24}
                  key={'id1'}
                >
                  <Form layout="vertical">
                    <Form.Item label={strings.boardLink}>
                      <div className="input-icon-container">
                        <Input
                          readOnly={!editable}
                          value={pinterestLink}
                          onChange={(e) => setpinterestLink(e.target.value)}
                        />
                        <a
                          href={pinterestLink}
                          target={'_blank'}
                          rel="noopenesr noreferrer"
                        >
                          <img
                            src={ExportSVG}
                            alt=""
                          />
                        </a>
                      </div>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row className="third-row">
                <Col
                  xs={24}
                  xl={24}
                  key={'id1'}
                >
                  <Form layout="vertical">
                    <Form.Item label={strings.description}>
                      <TextArea
                        readOnly={!editable}
                        rows={5}
                        value={pinterestDescription}
                        onChange={(e) =>
                          setpinterestDescription(e.target.value)
                        }
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
            {editable && (
              <>
                <Button
                  disabled={disabled}
                  onClick={() => editPinterest()}
                  className="save-btn"
                >
                  {strings.save}
                </Button>
                <Button
                  className="cancel-btn "
                  onClick={() =>
                    Save(
                      {
                        expandedItem: undefined,
                        insprationContentStatus: 'notShowInspration',
                      },
                      'app'
                    )
                  }
                >
                  {strings.cancel}
                </Button>
              </>
            )}
          </Card>
        ) : (
          <div className="inspiration-item">
            <Row justify="space-between">
              <Col
                xl={20}
                xs={16}
              >
                <span
                  style={{ color: '#6BA2B9' }}
                  className="link-inspiration"
                >
                  <img
                    src={LinkInspo}
                    alt=""
                    style={{ width: '1rem', marginRight: '10px' }}
                  />
                  Pinterest :{' '}
                  <a
                    href={pinterestLink}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    style={{ marginLeft: '10px' }}
                  >
                    {pinterestLink}
                  </a>{' '}
                </span>
                <Paragraph
                  onClick={() =>
                    Save(
                      {
                        expandedItem: id,
                        insprationContentStatus: 'editInspration',
                        editable: true,
                      },
                      'app'
                    )
                  }
                  className="inspiration-item-description"
                  ellipsis={{ rows: 3.5, expandable: true, symbol: 'more' }}
                >
                  {pinterestDescription}
                </Paragraph>
              </Col>
              <Col
                xl={4}
                xs={8}
                className="col-icon-container"
              >
                {(!checkboxStatus?.inspiration ||
                  role === 'admin' ||
                  role === 'concierge') && (
                  <>
                    <div
                      onClick={() => {
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: false,
                          },
                          'app'
                        );
                      }}
                      className="eye-container"
                    >
                      <img
                        src={EyeSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() =>
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: true,
                          },
                          'app'
                        )
                      }
                      className="pen-container"
                    >
                      <img
                        src={PenSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => setShowConfirmModal(true)}
                      className="trash-container"
                    >
                      <img
                        src={TrashSVG}
                        alt=""
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <ConfirmModal
              deleteModal={true}
              okText={'Cancel'}
              cancelText={'Yes,delete this inspiration'}
              questionText={
                'This action can not be undone. Are you sure you want to continue?'
              }
              isModalVisible={showConfirmModal}
              handleOk={() => deletePinterest()}
              handleCancel={() => setShowConfirmModal(false)}
            />
          </div>
        )}
      </>
    );
  };
  const unExpendedFornHouzz = () => {
    return (
      <>
        {expandedItem === id ? (
          <Card className="website-card">
            {!editable && (
              <div className="up-arrow-container">
                <div className="arrow-container">
                  <img
                    src={UpArrowSVG}
                    alt=""
                    onClick={() =>
                      Save(
                        {
                          expandedItem: undefined,
                          editable: true,
                          insprationContentStatus: 'notShowInspration',
                        },
                        'app'
                      )
                    }
                  />
                </div>
              </div>
            )}
            <div className="pinterest-container">
              <Row className="second-row">
                <Col
                  xs={24}
                  xl={24}
                  key={'id1'}
                >
                  <Form layout="vertical">
                    <Form.Item label={'Houzz Idea Book'}>
                      <div className="input-icon-container">
                        <Input
                          readOnly={!editable}
                          value={houzzLink}
                          onChange={(e) => setHouzzLink(e.target.value)}
                        />
                        <a
                          href={houzzLink}
                          target={'_blank'}
                          rel="noopenesr noreferrer"
                        >
                          <img
                            src={ExportSVG}
                            alt=""
                          />
                        </a>
                      </div>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row className="third-row">
                <Col
                  xs={24}
                  xl={24}
                  key={'id1'}
                >
                  <Form layout="vertical">
                    <Form.Item label={strings.description}>
                      <TextArea
                        readOnly={!editable}
                        rows={5}
                        value={houzzDescription}
                        onChange={(e) => setHouzzDescription(e.target.value)}
                      />
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
            {editable && (
              <>
                <Button
                  onClick={() => editHouzz()}
                  className="save-btn"
                  disabled={disabled}
                >
                  {strings.save}
                </Button>
                <Button
                  className="cancel-btn "
                  onClick={() =>
                    Save(
                      {
                        expandedItem: undefined,
                        insprationContentStatus: 'notShowInspration',
                      },
                      'app'
                    )
                  }
                >
                  {strings.cancel}
                </Button>
              </>
            )}
          </Card>
        ) : (
          <div className="inspiration-item">
            <Row justify="space-between">
              <Col
                xl={20}
                xs={16}
              >
                <span
                  style={{ color: '#6BA2B9' }}
                  className="link-inspiration"
                >
                  <img
                    src={LinkInspo}
                    alt=""
                    style={{ width: '1rem', marginRight: '10px' }}
                  />
                  Houzz Idea Book :{' '}
                  <a
                    href={houzzLink}
                    target={'_blank'}
                    rel="noopener noreferrer"
                    style={{ marginLeft: '10px' }}
                  >
                    {houzzLink}
                  </a>{' '}
                </span>
                <Paragraph
                  onClick={() =>
                    Save(
                      {
                        expandedItem: id,
                        insprationContentStatus: 'editInspration',
                        editable: true,
                      },
                      'app'
                    )
                  }
                  className="inspiration-item-description"
                  ellipsis={{ rows: 3.5, expandable: true, symbol: 'more' }}
                >
                  {houzzDescription}
                </Paragraph>
              </Col>
              <Col
                xl={4}
                xs={8}
                className="col-icon-container"
              >
                {(!checkboxStatus?.inspiration ||
                  role === 'admin' ||
                  role === 'concierge') && (
                  <>
                    <div
                      onClick={() => {
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: false,
                          },
                          'app'
                        );
                      }}
                      className="eye-container"
                    >
                      <img
                        src={EyeSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() =>
                        Save(
                          {
                            expandedItem: id,
                            insprationContentStatus: 'editInspration',
                            editable: true,
                          },
                          'app'
                        )
                      }
                      className="pen-container"
                    >
                      <img
                        src={PenSVG}
                        alt=""
                      />
                    </div>
                    <div
                      onClick={() => setShowConfirmModal(true)}
                      className="trash-container"
                    >
                      <img
                        src={TrashSVG}
                        alt=""
                      />
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <ConfirmModal
              deleteModal={true}
              okText={'Cancel'}
              cancelText={'Yes,delete this inspiration'}
              questionText={
                'This action can not be undone. Are you sure you want to continue?'
              }
              isModalVisible={showConfirmModal}
              handleOk={() => deleteHouzz()}
              handleCancel={() => setShowConfirmModal(false)}
            />
          </div>
        )}
      </>
    );
  };
  const switchForms = () => {
    switch (typeInspration) {
      case 'web':
        return unExpendedFormWeb();
      case 'photo':
        return unExpendedFormFile();
      case 'pinterest':
        return unExpendedFormPinterest();
      case 'houzz':
        return unExpendedFornHouzz();
      default:
        break;
    }
  };

  return <>{switchForms()}</>;
};

export default InspirationItemView;

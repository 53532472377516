import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { CloudUploadSVG } from 'assets/index';
import { useEffect, useState } from 'react';
import { strings } from 'src/common';
import { Props } from './models';
import { message } from 'antd';
import UploadFileView from './uploadFile-view';
import { useRedux } from 'src/hooks';

export const UploadFile = (props: Props) => {
  const { setFiles, text, title, onlyImageOnlyPdfAlowed = false } = props;
  const windowWidth = window.innerWidth;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const { Get } = useRedux();
  const { degree } = Get().app;

  const uploadButton = (
    <div className="upload-button">
      <div
        className="browserFile-div"
        style={{ marginTop: 8 }}
      >
        <img
          src={CloudUploadSVG}
          alt=""
        />
        <span>{strings.browserFile}</span>
      </div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <span>{windowWidth < 769 ? 'Upload file ' : text}</span>
    </div>
  );

  const beforeUpload = (file: any) => {
    return false;
  };
  useEffect(() => {}, [degree]);
  function DataURIToBlob(dataURI: any) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  }

  const getBase64 = (blob: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function () {
      const file = DataURIToBlob(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const handleChange = async (info: any, hasSingleFile: boolean) => {
    let splitLength = info.file.name.split('.').length;
    let format = info.file.name.split('.')[splitLength - 1];
    const onlyImageOnlyPdf =
      format.toUpperCase() === 'PNG' ||
      format.toUpperCase() === 'TIFF' ||
      format.toUpperCase() === 'JPG' ||
      format.toUpperCase() === 'JPEG' ||
      format.toUpperCase() === 'GIF' ||
      format.toUpperCase() === 'EPS' ||
      format.toUpperCase() === 'HEIF' ||
      format.toUpperCase() === 'DNG' ||
      format.toUpperCase() === 'PDF' ||
      format.toUpperCase() === 'RAW ';

    if (onlyImageOnlyPdfAlowed && !onlyImageOnlyPdf) {
      message.error({ content: 'Image and PDF only' });
      return;
    }
    if (hasSingleFile) {
      setFiles((prev: any) => {
        return {
          id: Math.round(Math.random() * 1000),
          type: 'unUploaded',
          format: format,
          file: info.file,
          rotate_angle: 0,
          name: info.file.name,
          size: {
            type: info.file.size < 100000 ? 'KB' : 'MB',
            size:
              info.file.size < 100000
                ? Math.round(info.file.size / 10) / 10
                : Math.round(info.file.size / 100000) / 10,
          },
        };
      });
    } else {
      setFiles((prev: any) => [
        ...prev,
        {
          id: Math.round(Math.random() * 1000),
          rotate_angle: 0,
          type: 'unUploaded',
          format: format,
          file: info.file,
          name: info.file.name,

          size: {
            type: info.file.size < 100000 ? 'KB' : 'MB',
            size:
              info.file.size < 100000
                ? Math.round(info.file.size / 10) / 10
                : Math.round(info.file.size / 100000) / 10,
          },
        },
      ]);
    }
  };

  return (
    <UploadFileView
      uploadButton={uploadButton}
      {...props}
      handleChange={handleChange}
      beforeUpload={beforeUpload}
      getBase64={getBase64}
      loading={loading}
      imageUrl={imageUrl}
      title={title}
      text={text}
    />
  );
};

export default UploadFile;

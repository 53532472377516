import CardListView from './cardList-view';
import { message } from 'antd';
import { useState } from 'react';

export const CardList = (props: any) => {
  const { name, status, created_on, project, projectListType } = props;
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSucceedModalVisible, setIsSucceedModalVisible] = useState(false);
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);

  const showConfirmModal = () => {
    setIsConfirmModalVisible(true);
  };

  const handleOk = () => {
    setIsConfirmModalVisible(false);
    setIsSucceedModalVisible(true);

    message.success({
      content: 'The project has been archived',
    });
  };

  const handleCancel = () => {
    setIsConfirmModalVisible(false);
  };

  return (
    <CardListView
      {...props}
      projectListType={projectListType}
      setIsSucceedModalVisible={setIsSucceedModalVisible}
      onClick={showConfirmModal}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isSucceedModalVisible={isSucceedModalVisible}
      isConfirmModalVisible={isConfirmModalVisible}
      name={name}
      status={status}
      created_on={created_on}
      project={project}
      archiveModalVisible={archiveModalVisible}
      setArchiveModalVisible={setArchiveModalVisible}
    />
  );
};

export default CardList;

import { useState, memo, useEffect } from "react";
import { useRedux } from "src/hooks";
import { ButtonStatusType, Props } from "./models";
import ProjectDataView from "./projectData-view";

export const ProjectData = (props: Props) => {
  const { Save } = useRedux();
  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>("NotShow");

  let path = window.location.pathname;
  let idPath = path.split("/");
  const idOfproject = idPath[2];

  useEffect(() => {
    if (idOfproject) {
      Save(
        {
          projectDataContentStatus: "EditProjectDetail",
        },
        "app"
      );
    }
  }, [idOfproject]);

  return (
    <ProjectDataView
      setButtonStatus={setButtonStatus}
      buttonStatus={buttonStatus}
      {...props}
    />
  );
};

export default memo(ProjectData);

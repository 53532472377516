// @ts-ignore
import {
  APP_CHANGE_DATA,
  APP_INSERT_DATA,
  APP_RESET_DATA,
} from '../../actions/app/actionType';
import { AppStates } from './models';

const INITIAL_STATE: AppStates = {
  clientInformationStatus: 'notShown',
  terminalContentStatus: 'NotShow',
  projectDataContentStatus: 'AddProjectData',
  goodsContentStatus: 'NotShow',
  roomContentStatus: 'NotAdd',
  timelineContentStatus: 'NotAdd',
  expandedItem: undefined,
  showModal: false,
  showCarouselModal: false,
  enableAddProjectTabs: true,
  editingProjectName: 'projectname',
  roomImages: [],
  addTerminalErrorModal: false,
  addTerminalSucessModal: false,
  fillnputWarningModal: false,
  addProjectSucessModal: false,
  addProjectErrorModal: false,
  fillProjectWarningModal: false,
  messageBoardContentStatus: 'NotShow',
  addMessageBoardSucessModal: false,
  addMessageBoardErrorModal: false,
  fillMessageBoardWarningModal: false,
  sucesssPaymentModal: false,
  errorPaymentModal: false,
  fillPaymentWarning: false,
  sucessGoodsModal: false,
  errorGoodsModal: false,
  fillGoodsWarningModal: false,
  sucessRoomsModal: false,
  errorRoomsModal: false,
  fillRoomsWarningModal: false,
  errorEditProfileModal: false,
  successEditProfileModal: false,
  fillProfileWarning: false,
  users: [],
  goodsImage: [],
  goodsShowDetail: false,
  roomsShowDetail: false,
  meetingImages: [],
  paymentShowContent: 'NotShow',
  projectId: 0,
  archiveProjects: [],
  projectFormsType: '',
  editgoodImage: [],
  showEditCarouselModal: false,
  removeId: 0,
  projectsLength: 0,
  roomTypes: [],
  goodCategories: [],
  whereToKeepGoods: [],
  showDeleteModal: {
    isVisible: false,
    deleteItem: 0,
  },
  showAddModal: {
    isVisible: false,
    addItem: 0,
  },
  userStatusConten: 'notShow',
  userEditeMoodData: {
    bio: '',
    calendar_link: null,
    email: '',
    first_name: '',
    id: 0,
    last_name: '',
    preferred_name: '',
    referrer: [],
    role: '',
    status: false,
  },
  searchResultProject: '',
  checkStatusInspration: 'notSelected',
  showInsprationCard: false,
  insprationContentStatus: 'notShowInspration',
  userWillBeAssignedToProject: {
    isAssigned: false,
    user: {},
  },
  usersAssignedInProject: [],
  conversationStatus: 'notShow',
  checkboxStatus: {
    rooms: false,
    goods: false,
    inspiration: false,
    clientInfo: false,
    projectId: 0,
  },
  uploadMessageModal: false,
  activeTab: 'PROJECTDATA',
  lastCommentId: null,
  isRotateImageActive: false,
  rotateImageData: null,
  editable: true,
  degree: 0,
  nameOfRotatedFile: null,
  formatOfRotateFile: null,
  presentationStatus: 'list',
};

export default (
  state = INITIAL_STATE,
  action: { type: string; value?: AppStates }
) => {
  switch (action.type) {
    case APP_CHANGE_DATA:
      return { ...state, ...action.value };
    case APP_INSERT_DATA:
      return { ...state, ...action.value };
    case APP_RESET_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};

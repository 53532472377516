export const convertDate = (due_date: any) => {
  if (due_date) {
    let splitDate = due_date.split(' ');
    let year = splitDate[3];
    let day = splitDate[2];
    let month = splitDate[1];
    switch (month) {
      case 'Jan':
        return `${year}-01-${day}`;
      case 'Feb':
        return `${year}-02-${day}`;
      case 'Mar':
        return `${year}-03-${day}`;
      case 'Apr':
        return `${year}-04-${day}`;
      case 'May':
        return `${year}-05-${day}`;
      case 'Jun':
        return `${year}-06-${day}`;
      case 'Jul':
        return `${year}-07-${day}`;
      case 'Aug':
        return `${year}-08-${day}`;
      case 'Sep':
        return `${year}-09-${day}`;
      case 'Oct':
        return `${year}-10-${day}`;
      case 'Nov':
        return `${year}-11-${day}`;
      case 'Dec':
        return `${year}-12-${day}`;

      default:
        break;
    }
  }
};

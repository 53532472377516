export * from "./auth";
export * from "./goods/index";
export * from "./room/index";
export * from "./projects/index";
export * from "./payments&Meetings";
export * from "./timeline";
export * from "./conversation";
export * from "./categories";
export * from "./types";
export * from "./questions";
export * from "./deleteFiles";
export * from "./notifications";
export * from "./presentation";
export * from "./statics";
export interface ResponseType<T> {
  count: number;
  next: string | null;
  previous: string | null;
  results: Array<T>;
}

import {
  LeftOutlined,
  RightOutlined,
  VideoCameraFilled,
} from '@ant-design/icons';
import { Carousel, Modal } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import React, { useEffect } from 'react';
import { useRedux } from 'src/hooks';
import './styles.css';

const CarouselView = (props: any) => {
  const { Save, Get } = useRedux();
  let carouselRef: CarouselRef | null | any = React.useRef(null);
  const { showCarouselModal } = Get().app;
  let {
    isModalVisible = showCarouselModal,
    DataURIToBlob,
    onCancelPress,
    defaultIndex,
    finallbase64,
  } = props;
  useEffect(() => {
    carouselRef.goTo(defaultIndex);
  }, []);
  useEffect(() => {}, [finallbase64]);
  const isVideo = (format: any) =>
    format?.toUpperCase() === 'AVI' ||
    format?.toUpperCase() === 'FLV' ||
    format?.toUpperCase() === 'WMV' ||
    format?.toUpperCase() === 'MP4' ||
    format?.toUpperCase() === 'MOV';
  return (
    <Modal
      className="modal"
      visible={isModalVisible}
      footer={null}
      onCancel={() =>
        onCancelPress
          ? onCancelPress()
          : Save({ showCarouselModal: false }, 'app')
      }
    >
      <Carousel
        ref={(value) => {
          carouselRef = value;
        }}
        arrows
        dots={false}
        nextArrow={
          <div>
            <RightOutlined style={{ color: 'white' }} />
          </div>
        }
        prevArrow={
          <div>
            <LeftOutlined style={{ color: 'white' }} />
          </div>
        }
      >
        {finallbase64.length > 0 &&
          finallbase64.map((item: any, index: number) => {
            return (
              <div
                className="img-carousel"
                key={index}
              >
                {isVideo(item.format) ? (
                  <div className="carousel-video">
                    <a
                      href={item.file}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      style={{ marginLeft: '10px' }}
                    >
                      <VideoCameraFilled style={{ width: '100px' }} />
                    </a>
                  </div>
                ) : (
                  <img
                    src={
                      item.type === 'unUploaded'
                        ? URL.createObjectURL(DataURIToBlob(item.file))
                        : item.file
                    }
                    alt=""
                    style={{ transform: `rotate(${item.rotate_angle}deg)` }}
                  />
                )}
              </div>
            );
          })}
      </Carousel>
    </Modal>
  );
};

export default CarouselView;

import './styles.css';
import { Button, Form, Input, message } from 'antd';
import { AuthServices } from 'api/services';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Row, Card, Col } from 'antd';
const ForgetPassView = () => {
  let history = useHistory();
  const [loading, setloading] = useState(false);
  const handleForgetPassword = async (value: any) => {
    let { email } = value;
    let data = {
      email,
    };

    setloading(true);
    let forgotPassword = await AuthServices.forgotPassword(data);
    if (forgotPassword) {
      setloading(false);
      if (forgotPassword === null) {
        message.info(
          {
            content:
              'If your email exists in our database, you will receive an email with reset password link.Please make sure to check your "Spam/Junk" folder.',
          },
          3
        );
      } else {
        return message
          .loading('Action in progress..', 2.5)
          .then(() =>
            message.info(
              {
                content:
                  'If your email exists in our database, you will receive an email with reset password link.Please make sure to check your "Spam/Junk" folder.',
              },
              3
            )
          )
          .then(() => history.push('/login'));
      }
    } else {
      message.info(
        {
          content:
            'If your email exists in our database, you will receive an email with reset password link.Please make sure to check your "Spam/Junk" folder.',
        },
        3
      );
      setloading(false);
    }
  };

  return (
    <div className="screen-container">
      <Row justify="end">
        <Col
          xl={12}
          xs={24}
        >
          <div className="forgotpassword">
            <Card>
              <h1>Forgot Your Password?</h1>
              <p>
                Enter Your Email Address and We Will Send You a Link to Reset
                Your Password
              </p>
              <div className="login-parent">
                <div className="form-container">
                  <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={handleForgetPassword}
                  >
                    <div className="username-container">
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your email!',
                          },
                        ]}
                      >
                        <Input placeholder="Enter Email" />
                      </Form.Item>
                    </div>

                    <Form.Item>
                      <Button
                        loading={loading}
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                      >
                        Reset Password
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgetPassView;

import React, { useState, useEffect } from 'react';
import { PresentationService } from 'src/api/services';
import PresentationDetail from './PresentationDetail';

import CirclePlusButtonView from 'src/components/CirclePlusButton/circlePlusButton-view';
import { useRedux } from 'src/hooks';
import UploadPresentation from './UploadPresentation';
import { URL } from 'src/api/api';
const role = localStorage.getItem('userRole');
const Presentation = () => {
  const { Save, Get } = useRedux();
  const { presentationStatus } = Get().app;
  const [presentations, setPresentations] = useState([]);
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const getPresentationList = async () => {
    await PresentationService.presentationList(idOfproject).then((res) => {
      if (res) {
        setPresentations(res.results);
      }
    });
  };

  useEffect(() => {
    getPresentationList();
  }, []);
  return (
    <>
      {role !== 'client' && (
        <CirclePlusButtonView
          btnText={'Add presentation'}
          onClick={() => Save({ presentationStatus: 'upload' }, 'app')}
        />
      )}
      {presentationStatus === 'upload' && (
        <UploadPresentation getPresentationList={getPresentationList} />
      )}
      {presentations &&
        presentations.length !== 0 &&
        (presentationStatus === 'list' || !presentationStatus) &&
        presentations.map((item: any, idx: number) => {
          let uploadDate = item?.upload_date?.split('U');
          let fileType = item?.presentation_file?.split('.');
          return item.presentations.map((e: any) => {
            return (
              <PresentationDetail
                key={idx}
                fileName={e.presentation_file_title}
                file={`${URL}${e.presentation_file}`}
                idx={idx}
                fileType={fileType ? fileType[1] : 'pdf'}
                uploadDate={uploadDate[0]}
                fileSize={e.get_presentation_file_size}
                presentationId={item.id}
                idOfproject={idOfproject}
                getPresentationList={getPresentationList}
              />
            );
          });
        })}
    </>
  );
};

export default React.memo(Presentation);

//@ts-nocheck
import { useState, useEffect, memo } from 'react';
import { ViewProps } from './models';
import {
  TickSVG,
  PenSVG,
  TrashSVG,
  TickDone,
  DownArrowSVG,
  EyeSVG,
} from 'assets/index';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
} from 'antd';
import moment from 'moment';
import { strings } from 'common/languages/index';
import { useRedux } from 'hooks/index';
import { TimelinesServises } from 'api/services/index';
import './styles.css';
import { Alert } from 'src/ui-kits';
import CollapseItems from '../collapseItems';
import { convertDate } from './convertDate';
const TerminalItemView = (props: ViewProps) => {
  const {
    item,

    setRemoveItem,
    setRemoveModal,
    titles,
    getTimelines,
    id,
    usersAssignedProject,
  } = props;
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  const { Option } = Select;
  const { complete } = item;
  const { Save, Get } = useRedux();
  const { expandedItem, editable } = Get().app;
  const [disabled, setDisabled] = useState(false);
  const [keyPerson, setKeyPerson] = useState<any>(item.key_person.id);
  const [completeStatus, setComplete] = useState<boolean>(item.complete);
  const [isDone, setIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [titleName, setTitleName] = useState<any>(item.title_list);
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const [modal, setMessageModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  const onDeleteItem = () => {
    setRemoveModal && setRemoveModal(true);
    setRemoveItem && setRemoveItem(id);
  };

  const [form] = Form.useForm();
  const [title, setTitle] = useState<any>(0);
  const getNameTitle = () => {
    if (item && item.title_list !== null) {
      for (let i of titles) {
        if (i.id === item.title_list) {
          setTitleName(i.title);
          setTitle(item.title_list);
        }
      }
    }
  };
  const [dueDate, setDuedate] = useState<any>(convertDate(item.due_date));

  useEffect(() => {
    getNameTitle();
    convertDate(item.due_date);
  }, []);

  useEffect(() => {
    setIsDone(complete);
  }, [complete]);

  const submitValue = async () => {
    setLoading(true);
    if (title === 0 || keyPerson === 0 || dueDate === '') {
      setMessageModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setLoading(false);

        setMessageModal((prev) => ({ ...prev, show: false }));
      }, 1000);
      return;
    }
    let newDate = '';
    if (dueDate._d) {
      let year = dueDate._d.getFullYear();
      let month = (dueDate._d.getMonth() + 1).toString().padStart(2, '0');
      let day = dueDate._d.getDate().toString().padStart(2, '0');
      newDate = year + '-' + month + '-' + day;
    }
    const timelineItems = {
      title_list: title,
      key_person: Number(keyPerson),
      due_date: dueDate._d ? newDate : dueDate,
      complete: completeStatus,
    };
    setDisabled(true);
    const result = await TimelinesServises.editTimeline(
      idOfproject,
      id.toString(),
      timelineItems
    );
    if (result) {
      setLoading(false);
      setMessageModal({ show: true, type: 'success' });
      setTimeout(() => {
        setDisabled(false);
        Save({ expandedItem: undefined }, 'app');
        setMessageModal((prev) => ({ ...prev, show: false }));
        getTimelines();
      }, 2000);
    } else {
      setLoading(false);
      setMessageModal({ show: true, type: 'error' });
      setDisabled(false);
      setTimeout(() => {
        setMessageModal((prev) => ({ ...prev, show: false }));
      }, 1500);
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const isFieldsTouched = form.isFieldsTouched([
    'title',
    'duedate',
    'keyperson',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);
  return (
    <div className="status-list-container">
      {expandedItem !== id.toString() ? (
        <div className="content-container">
          <span
            style={{ cursor: 'pointer' }}
            onClick={() =>
              Save(
                {
                  expandedItem: id,
                  terminalContentStatus: 'edit',
                  editable: true,
                },
                'app'
              )
            }
          >
            {titleName ? titleName : ''}
          </span>
          <div className="right-container">
            <span>{item.due_date}</span>
            <div className={'tick-container'}>
              {isDone ? (
                <img
                  src={TickDone}
                  alt={'done'}
                />
              ) : (
                <img
                  src={TickSVG}
                  alt={'done'}
                />
              )}
            </div>
            {localStorage.getItem('userRole') === 'admin' ? (
              <>
                <div
                  onClick={() =>
                    Save(
                      {
                        expandedItem: id,
                        terminalContentStatus: 'edit',
                        editable: false,
                      },
                      'app'
                    )
                  }
                  className="eye-container"
                >
                  <img
                    src={EyeSVG}
                    alt={''}
                  />
                </div>
                <div
                  onClick={() =>
                    Save(
                      {
                        expandedItem: id,
                        terminalContentStatus: 'edit',
                        editable: true,
                      },
                      'app'
                    )
                  }
                  className="pen-container"
                >
                  <img
                    src={PenSVG}
                    alt={'pen'}
                  />
                </div>
                <div
                  onClick={() => onDeleteItem()}
                  className="trash-container"
                >
                  <img
                    src={TrashSVG}
                    alt={'trash'}
                  />
                </div>
              </>
            ) : (
              <div
                className="arrow-container"
                onClick={() =>
                  Save(
                    { expandedItem: id, terminalContentStatus: 'edit' },
                    'app'
                  )
                }
              >
                <img
                  src={DownArrowSVG}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <Card className="froms-container">
            {!editable && (
              <CollapseItems
                onClick={() =>
                  Save(
                    {
                      expandedItem: undefined,
                      terminalContentStatus: 'NotShow',
                      editable: true,
                    },
                    'app'
                  )
                }
              />
            )}
            {localStorage.getItem('userRole') === 'admin' ? (
              <Form
                layout="vertical"
                form={form}
                initialValues={{
                  title: titleName,
                  duedate: moment(dueDate, 'YYYY-MM-DD'),
                  keyperson: keyPerson,
                }}
              >
                <Row className="first-row">
                  <Col
                    xs={24}
                    xl={8}
                    key={'idx'}
                  >
                    <Form.Item
                      label={strings.title}
                      name={'title'}
                      rules={[{ required: true, message: 'field is required' }]}
                    >
                      <Select
                        disabled={!editable}
                        value={title}
                        style={{ width: '100%' }}
                        defaultValue={titleName}
                        onChange={(value) => setTitleName(value)}
                      >
                        {titles &&
                          titles.length !== 0 &&
                          titles.map((item: any) => (
                            <Option
                              key={item.id}
                              value={item.id}
                            >
                              {item.title}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx1'}
                  >
                    <Form.Item
                      label={strings.duedate}
                      name={'duedate'}
                      rules={[{ required: true, message: 'field is required' }]}
                    >
                      <DatePicker
                        disabled={!editable}
                        defaultValue={moment(dueDate, 'YYYY-MM-DD')}
                        value={moment(dueDate, 'YYYY-MM-DD')}
                        onChange={(dateString) => setDuedate(dateString)}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx2'}
                  >
                    <Form.Item
                      label={strings.keyPerson}
                      name={'keyperson'}
                      rules={[{ required: true, message: 'field is required' }]}
                    >
                      <Select
                        disabled={!editable}
                        style={{ width: '100%' }}
                        onChange={(e) => setKeyPerson(e)}
                        value={keyPerson}
                      >
                        {usersAssignedProject &&
                          usersAssignedProject.length !== 0 &&
                          usersAssignedProject.map((user: any, idx: number) => {
                            return (
                              <Option
                                value={user.id}
                                key={user.id + idx}
                              >
                                {user.preferred_name
                                  ? user.preferred_name
                                  : user.email}
                                -{user.role}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx3__'}
                  >
                    <div className="complete-container">
                      <span>{strings.complete}</span>
                      <Checkbox
                        disabled={!editable}
                        onChange={(e) => setComplete(e.target.checked)}
                        defaultChecked={complete}
                      />
                    </div>
                  </Col>
                </Row>
                {editable && (
                  <Form.Item shouldUpdate>
                    <Button
                      loading={loading}
                      onClick={() => submitValue()}
                      className="save-btn"
                      htmlType="submit"
                      disabled={
                        disabled ||
                        form
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length > 0
                      }
                    >
                      {strings.save}
                    </Button>
                    <Button
                      className="cancel-btn"
                      onClick={() => {
                        Save(
                          {
                            terminalContentStatus: 'List',
                            expandedItem: undefined,
                          },
                          'app'
                        );
                        localStorage.setItem(
                          'isFieldsTouched',
                          JSON.stringify(false)
                        );
                      }}
                    >
                      {strings.cancel}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            ) : (
              <>
                <CollapseItems
                  onClick={() =>
                    Save(
                      {
                        expandedItem: undefined,
                        terminalContentStatus: 'List',
                        editable: true,
                      },
                      'app'
                    )
                  }
                />
                <Row className="first-row-client">
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx1'}
                    className="client-view"
                  >
                    <div>
                      <span>Title</span>
                      <p className="client-view-p">{titleName}</p>
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx'}
                    className="client-view"
                  >
                    <div>
                      <span>{strings.duedate} </span>
                      <p className="client-view-p">{dueDate}</p>
                    </div>
                  </Col>

                  <Col
                    xs={24}
                    xl={4}
                    key={'idx2'}
                    className="client-view"
                  >
                    <div>
                      <span>{strings.keyPerson} </span>
                      <p className="client-view-p">
                        {item.key_person.preferred_name
                          ? item.key_person.preferred_name
                          : item.key_person.email}
                      </p>
                    </div>
                  </Col>
                  <div className="complete-container-client">
                    <span>{'Complete'}</span>
                    <Checkbox
                      defaultChecked={item.complete}
                      disabled={true}
                    />
                  </div>
                </Row>
              </>
            )}
          </Card>
          {modal.type === 'success' && modal.show && (
            <Alert
              description={'The timeline has been edited successfully'}
              type="success"
            />
          )}
          {modal.type === 'error' && modal.show && <Alert type="error" />}
          {modal.type === 'warning' && modal.show && (
            <Alert
              description={strings.pleaseFillAllInputs}
              type="warning"
            />
          )}
        </>
      )}
    </div>
  );
};

export default memo(TerminalItemView);

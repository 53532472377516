import { useState, useEffect } from 'react';
import CarouselView from './carousel-view';
import { Props } from './models';
import { PROTOCOL, BASE_URL } from 'api/api';

export const Carousel = (props: Props) => {
  const { imageArray } = props;
  const [selectedImage, setselectedImage] = useState(0);
  const [image, setImage] = useState('');
  const [imagesSrc, setImagesSrc] = useState<any>([]);
  const [base64s, setBase64s] = useState<any>([]);
  const [finallbase64, setFinallbase64] = useState([]);

  useEffect(() => {
    if (base64s.length == imageArray.length) {
      setFinallbase64(base64s);
    }
  }, [base64s]);

  function DataURIToBlob(dataURI: any) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  }

  const getBase64 = async (
    blob: any,
    id: number,
    format: any,
    perImage: any
  ) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function (value: any) {
      setBase64s((prev: any) => {
        return [
          ...prev,
          {
            format,
            type: 'unUploaded',
            file: reader.result,
            id: id,
            rotate_angle: perImage?.rotate_angle ? perImage?.rotate_angle : 0,
          },
        ];
      });
    };
    reader.onerror = function (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    setBase64s([]);
    if (imageArray && imageArray.length !== 0) {
      for (let perImage of imageArray) {
        if ('type' in perImage && perImage.type === 'unUploaded') {
          getBase64(perImage.file, perImage.id, perImage.format, perImage);
        } else {
          let name =
            'photo_or_video_title' in perImage
              ? perImage.photo_or_video_title
              : 'attachment_title' in perImage
              ? perImage.attachment_title
              : perImage.title
              ? 'floor_plan_title' in perImage
              : perImage.floor_plan_title;

          let splitLength = name.split('.').length;
          let attachment =
            'attachment' in perImage
              ? perImage.attachment
              : 'photo_or_video' in perImage
              ? perImage.photo_or_video
              : 'floor_plan' in perImage
              ? perImage.floor_plan
              : '';
          let format = name.split('.')[splitLength - 1];
          let permission = true;
          for (let perFile of base64s) {
            if (perImage.id === perFile.id) {
              permission = false;
            }
          }

          if (permission) {
            setBase64s((prev: any) => {
              return [
                ...prev,
                {
                  format,
                  type: 'uploaded',
                  file: `${PROTOCOL}${BASE_URL}${attachment}`,
                  id: perImage.id,
                  rotate_angle: perImage.rotate_angle,
                },
              ];
            });
          }
        }
      }
    }
  }, [imageArray]);

  return (
    <CarouselView
      finallbase64={finallbase64}
      base64s={base64s}
      DataURIToBlob={DataURIToBlob}
      imagesSrc={imagesSrc}
      getBase64={getBase64}
      selectedImage={selectedImage}
      setselectedImage={setselectedImage}
      image={image}
      {...props}
    />
  );
};

export default Carousel;

import { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  InputNumber,
  message,
} from 'antd';
import { useRedux } from 'src/hooks';
import { ProjectsService } from 'src/api/services';
import './styles.css';
import { UpArrowSVG } from 'src/assets';

const ClientInfo = ({ data, getProject, client }: any) => {
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const { Save, Get } = useRedux();
  const { editable } = Get().app;
  const role = localStorage.getItem('userRole');
  const clientPermission = role === 'client' && !data?.information_completed;
  const adminPermission = role === 'admin' && data?.information_completed;
  const [disabled, setDisabled] = useState(false);
  const onFinish = async (values: any) => {
    setDisabled(true);
    await ProjectsService.editProject(idOfproject, {
      address1: values.address1,
      address2: values.address2,
      country: values.country,
      city: values.city,
      province_state: values.state,
      phone_number: values.phone,
      postal_zip_code: values.postal,
      // information_completed: true,
    })
      .then(async (res) => {
        if (res) {
          message.success({
            content: "The user's information has been successfully added",
          });
          setTimeout(() => {
            Save(
              { expandedItem: undefined, clientInformationStatus: 'notShown' },
              'app'
            );
            setDisabled(false);
            getProject(idOfproject);
          }, 1000);
        } else {
          setDisabled(true);
        }
      })
      .catch((e) => console.log(e, 'error'));
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const setIsFieldsTouched = (changedValues: any) => {
    const isFieldsTouched = JSON.parse(
      localStorage.getItem('isFieldsTouched') as string
    );
    if (!isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  };

  return (
    <Card
      className="froms-container"
      title=" Your Address and Phone:"
    >
      <div className="right-container-client">
        <div className="arrow-container">
          <img
            src={UpArrowSVG}
            alt=""
            onClick={() =>
              Save(
                {
                  expandedItem: undefined,
                  editable: true,
                  clientInformationStatus: 'notShown',
                },
                'app'
              )
            }
          />
        </div>
      </div>
      <Form
        onValuesChange={setIsFieldsTouched}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          address1: data.address1,
          address2: data.address2,
          country: data.country,
          city: data.city,
          state: data.province_state,
          phone: data.phone_number,
          postal: data.postal_zip_code,
        }}
      >
        <Row className="first-row">
          <Col
            xs={24}
            xl={24}
            key={'idx1'}
          >
            <Form.Item
              label={'Address Line 1'}
              name={'address1'}
              rules={[
                {
                  required: true,
                  message: 'field is required',
                },
              ]}
            >
              <Input readOnly={!editable} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="second-row">
          <Col
            xs={24}
            xl={24}
            key={'idx'}
          >
            <Form.Item
              label={'Address Line 2 '}
              name={'address2'}
            >
              <Input readOnly={!editable} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="third-row">
          <Col
            xl={8}
            xs={24}
          >
            <Form.Item
              label={'City'}
              name={'city'}
              rules={[
                {
                  required: true,
                  message: 'field is required',
                },
              ]}
            >
              <Input readOnly={!editable} />
            </Form.Item>
          </Col>

          <Col
            xl={8}
            xs={24}
          >
            <Form.Item
              label={'State/Province'}
              name={'state'}
              rules={[
                {
                  required: true,
                  message: 'field is required',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col
            xl={8}
            xs={24}
          >
            <Form.Item
              label={'Country'}
              name={'country'}
              rules={[
                {
                  required: true,
                  message: 'field is required',
                },
              ]}
            >
              <Input readOnly={!editable} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col
            xl={8}
            xs={24}
          >
            <Form.Item
              label={'Postal/Zip code'}
              name={'postal'}
              rules={[
                {
                  required: true,
                  message: 'field is required',
                },
              ]}
            >
              <Input
                style={{ width: '100%' }}
                readOnly={!editable}
              />
            </Form.Item>
          </Col>
          <Col
            xl={8}
            xs={24}
          >
            <Form.Item
              label={'Phone Number'}
              name={'phone'}
              rules={[
                {
                  required: true,
                  message: 'field is required',
                },
              ]}
            >
              <Input readOnly={!editable} />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <br />
        <Row className="fourth-row">
          {client &&
            role !== 'client' &&
            client.map((client: any, idx: any) => {
              return (
                <Col
                  xl={24}
                  xs={24}
                  key={client.preferred_name + idx}
                >
                  <Form.Item
                    label={
                      client.preferred_name
                        ? `Bio of ${client.preferred_name}`
                        : 'Bio'
                    }
                    name={'clientBio'}
                  >
                    <Input.TextArea
                      readOnly={true}
                      rows={3}
                      value={client.bio === null ? '' : client.bio}
                    />
                    <span style={{ display: 'none' }}>{client.bio}</span>
                  </Form.Item>
                </Col>
              );
            })}
        </Row>
        {editable && (
          <Form.Item shouldUpdate>
            {(clientPermission || adminPermission) && (
              <Button
                htmlType="submit"
                className="save-btn"
                disabled={disabled}
              >
                Save
              </Button>
            )}

            <Button
              className="cancel-btn"
              onClick={() => {
                Save(
                  {
                    expandedItem: undefined,
                    clientInformationStatus: 'notShown',
                  },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        )}
      </Form>
    </Card>
  );
};

export default ClientInfo;

import { useState } from 'react';
import {
  downloadFile,
  OrangeEyeSVG,
  miniPdfIcon,
  TrashSVG,
} from 'assets/index';
import { Image } from 'antd';
import './styles.css';
import { PresentationService } from 'src/api/services';
import { ModalType } from '../ProjectData/models';
import { useRedux } from 'src/hooks';
import { Alert } from 'src/ui-kits';
const role = localStorage.getItem('userRole');
const PreviewFile = ({
  fileName,
  idx,
  file,
  fileType,
  uploadDate,
  fileSize,
  presentationId,
  idOfproject,
  getPresentationList,
}: any) => {
  const { Save } = useRedux();
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const deletePresentation = async () => {
    await PresentationService.deletePresentation(
      idOfproject,
      presentationId
    ).then((res) => {
      if (res) {
        setModal({ show: true, type: 'success' });
        setTimeout(() => {
          getPresentationList();
          Save({ presentationStatus: 'list' }, 'app');
        }, 2000);
      } else {
        setModal({ show: true, type: 'error' });
      }
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 2000);
    });
  };
  return (
    <div className="longItem-with-detail">
      <div className="longItem-container">
        <div className="pdf-download-container">
          <div className="pdf-download-title">
            <img
              src={miniPdfIcon}
              alt=""
            />
            <span className="file-index">File {`${idx + 1}`} :</span>
          </div>
          <div className="pdf-download-content">
            <p>{fileName && fileName}</p>
            <span className="size">
              {uploadDate}(
              {fileSize && fileSize < 100000
                ? Math.round(fileSize / 10) / 10
                : Math.round(fileSize / 100000) / 10}{' '}
              {fileSize < 100000 ? 'KB' : 'MB'})
            </span>
          </div>
        </div>
        <div className="right-container">
          {role !== 'client' && (
            <div
              onClick={() => deletePresentation()}
              className="trash-container"
            >
              <img
                src={TrashSVG}
                alt={'trash'}
              />
            </div>
          )}
          <div
            className="pen-container"
          >
            <a
              target={'_blank'}
              rel="noopenesr noreferrer"
              download
              href={file}
              title={fileName && fileName}
            >
              <img
                src={downloadFile}
                alt=""
              />
            </a>
          </div>

          <div
            className="eye-container"
            onClick={() => {
              setVisible(true);
            }}
          >
            {fileType && fileType=== 'pdf' ? (
              <a
                target={'_blank'}
                rel="noopenesr noreferrer"
                href={file}
              >
                <img
                  src={OrangeEyeSVG}
                  alt=""
                />
              </a>
            ) : (
              <>
                <img
                  src={OrangeEyeSVG}
                  alt=""
                  className="eye-preview"
                />
                <Image
                  width={200}
                  style={{ display: 'none' }}
                  preview={{
                    visible,
                    src: file,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'Presentation has been deleted successfully '}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
    </div>
  );
};

export default PreviewFile;

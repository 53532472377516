import { useRedux } from 'src/hooks';
import { LongItem } from 'components/index';
import ChatMessages from './ChatMessages';
import { ViewProps } from './models';
import { EditMessage } from './EditMessage';
import './styles.css';
const MessageBoardItemView = ({
  data,
  setContentStatus,
  setRemoveItem,
  setRemoveModal,
  getMessages,
  firstMessage,
}: ViewProps) => {
  const { Get } = useRedux();
  const { expandedItem, conversationStatus } = Get().app;
  let findCommentId = data?.comments?.find(
    (e: any) => e.id.toString() === expandedItem?.toString()
  );
  let newFiles: any = [];
  if (data.photos && data.photos.length !== 0) {
    for (let perFile of data.photos) {
      let file = {
        ...perFile,
        type: 'uploaded',
        rotate_angle: 0,
      };
      newFiles.push(file);
    }
  }

  return (
    <div className="messageboard-item">
      {expandedItem === data.id.toString() && conversationStatus === 'edit' && (
        <EditMessage
          data={data}
          photos={newFiles}
          getMessages={getMessages}
          firstMessage={firstMessage}
        />
      )}
      {data &&
        (expandedItem !== findCommentId?.id.toString() ||
          expandedItem !== data.id) &&
        conversationStatus === 'notShow' && (
          <LongItem
            firstMessage={firstMessage}
            setRemoveItem={setRemoveItem}
            setRemoveModal={setRemoveModal}
            setContentStatus={setContentStatus}
            type="MessageBoard"
            data={data}
          />
        )}
      {(expandedItem === findCommentId?.id.toString() ||
        expandedItem === data.id) &&
        conversationStatus === 'chat' && <ChatMessages data={data} />}
    </div>
  );
};

export default MessageBoardItemView;

import { useEffect } from "react";
import { Row, Col, Radio, Card, Button } from "antd";
import { strings } from "common/index";
import { useRedux } from "src/hooks";
import { Props } from "./models";
import UploadFileInspration from "./components/UploadFileInspration";
import WebsiteInspration from "./components/WebsiteInspration";
import PinterestInspearion from "./components/PinterestInspearion";
import HouzzInspration from "./components/HouzzIdeaBookInspiration";
import "./styles.css";

export const Inspiration = ({ data }: Props) => {
  const { Save, Get } = useRedux();
  const { checkStatusInspration, insprationContentStatus } = Get().app;

  useEffect(() => {}, [insprationContentStatus]);

  const AddInspirationContent = () => {
    switch (checkStatusInspration) {
      case "PinterestBoardLink":
        return <PinterestInspearion />;
      case "UploadPhotoOrVideo":
        return <UploadFileInspration />;
      case "Website":
        return <WebsiteInspration />;
      case "HouzzIdeaLink":
        return <HouzzInspration />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Card
        title={`${strings.inspiration}:`}
        className="form-container-inspiration"
      >
        <Row className="first-row">
          <Col xs={24} xl={3} key={"id1"}>
            <div className="checkbox-container">
              <span>{strings.website}</span>
              <Radio
                disabled={
                  checkStatusInspration !== "Website" &&
                  checkStatusInspration !== "notSelected"
                }
                className="radio-addproject"
                checked={checkStatusInspration === "Website"}
                onChange={() => {
                  Save({ checkStatusInspration: "Website" }, "app");
                }}
              />
            </div>
          </Col>
          <Col xs={24} xl={5} key={"id2"}>
            <div className="checkbox-container">
              <span>{strings.uploadPhotoOrVideo}</span>
              <Radio
                disabled={
                  checkStatusInspration !== "UploadPhotoOrVideo" &&
                  checkStatusInspration !== "notSelected"
                }
                className="radio-addproject"
                checked={checkStatusInspration === "UploadPhotoOrVideo"}
                onChange={() => {
                  Save({ checkStatusInspration: "UploadPhotoOrVideo" }, "app");
                }}
              />
            </div>
          </Col>
          <Col xs={24} xl={4} key={"id3"}>
            <div className="checkbox-container">
              <span>{strings.pinterestBoardLink}</span>
              <Radio
                disabled={
                  checkStatusInspration !== "PinterestBoardLink" &&
                  checkStatusInspration !== "notSelected"
                }
                className="radio-addproject"
                checked={checkStatusInspration === "PinterestBoardLink"}
                onChange={() => {
                  Save({ checkStatusInspration: "PinterestBoardLink" }, "app");
                }}
              />
            </div>
          </Col>
          <Col xs={24} xl={4} key={"id4"}>
            <div className="checkbox-container">
              <span>{"Houzz IdeaBook"}</span>
              <Radio
                disabled={
                  checkStatusInspration !== "HouzzIdeaLink" &&
                  checkStatusInspration !== "notSelected"
                }
                className="radio-addproject"
                checked={checkStatusInspration === "HouzzIdeaLink"}
                onChange={() => {
                  Save({ checkStatusInspration: "HouzzIdeaLink" }, "app");
                }}
              />
            </div>
          </Col>
        </Row>
        <Row justify="end">
          <Button
            className="cancel-btn"
            onClick={() => {

              Save(
                {
                  expandedItem: undefined,
                  insprationContentStatus: "notShowInspration",
                },
                "app"
              )
              localStorage.setItem('isFieldsTouched',JSON.stringify(false));
            }
            }
          >
            Cancel
          </Button>
        </Row>
      </Card>

      {insprationContentStatus === "addInspration" && AddInspirationContent()}
    </>
  );
};

export default Inspiration;

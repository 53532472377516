import { useState } from 'react';
import TerminalItemView from './terminalItem-view';
import { Props } from './models';
import { useRedux } from 'hooks/index';

export const TerminalItem = (props: Props) => {
  const { item, setRemoveItem, setRemoveModal } = props;
  const { id } = item;
  const [edited, setEdited] = useState(false);
  const { Save, Get } = useRedux();
  const { terminalContentStatus } = Get().app;

  const saveClick = () => {
    if (terminalContentStatus === 'Add') {
      Save({ terminalContentStatus: 'List' }, 'app');
      return;
    }
    Save({ expandedItem: undefined }, 'app');
  };

  const penOnClick = () => {
    Save({ expandedItem: id, terminalContentStatus: 'edit' }, 'app');
  };
  const CancelClick = () => {
    if (terminalContentStatus === 'NotShow') {
      Save({ terminalContentStatus: 'List' }, 'app');
      Save({ expandedItem: undefined }, 'app');
    }
  };

  return (
    <TerminalItemView
      CancelClick={CancelClick}
      setRemoveItem={setRemoveItem}
      setRemoveModal={setRemoveModal}
      saveClick={saveClick}
      penOnClick={penOnClick}
      edited={edited}
      setEdited={setEdited}
      id={id.toString()}
      {...props}
    />
  );
};
export default TerminalItem;

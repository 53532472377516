//@ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Modal,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  InputNumber,
  message,
} from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
  MeetingsServises,
  DeleteUploadedFilesServices,
} from 'src/api/services';
import { ExportSVG } from 'src/assets';
import { FileItem, UploadFile, CarouselModal } from 'components/index';
import { strings } from 'common/index';
import { useRedux } from 'hooks/index';
import './styles.css';
import { Alert } from 'src/ui-kits';
import CollapseItems from 'src/components/collapseItems';
import { convertDate } from 'src/components/TerminalItem/convertDate';

export type ModalType = {
  show: boolean;
  type: 'success' | 'warning' | 'error';
};
export type FileType = {
  type: string;
  file: string;
  name: string;
};

const EditDetailsView = ({
  data,
  attachments,
  getPayments,
  nameTitles,
  pdf,
}: any) => {
  const { Get, Save } = useRedux();
  let findNameOfTitle: any = '';
  const [nameTitle, setnameTitle] = useState('');
  const [title, setTitle] = useState<string>(data.title);
  const [meetingTitles, setMeetingTitles] = useState<any>([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  const getTitles = async () => {
    await MeetingsServises.getTitleMeeting().then((res) => {
      if (res) {
        setMeetingTitles(res.results);
      }
    });
  };
  const findTitle = () => {
    if (title && meetingTitles) {
      findNameOfTitle = meetingTitles.find((e: any) => e.id === data.title);
      if (findNameOfTitle !== undefined) setnameTitle(findNameOfTitle.title);
    }
  };
  useEffect(() => {
    findTitle();
  }, []);
  useEffect(() => {
    getTitles();
  }, []);

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const [showCarousel, setShowCarousel] = useState(false);
  const [link, setLink] = useState(data.online_meeting_link);
  const [files, setFiles] = useState<FileType[]>(attachments);
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(data.complete);
  const [instructions, setInstructions] = useState(data.instructions);
  const [time, setTime] = useState<any>(null);
  const [duration, setDuration] = useState(data.duration);
  const [disabled, setDisabled] = useState(false);
  const [paymentLink, setPaymentLink] = useState(
    data.payment_link ? data.payment_link : ''
  );
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const { editingProjectName, paymentShowContent, editable } = Get().app;
  let selectedTime: any;
  let newDate = '';
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  // const convertDate = () => {
  //   if (data.date) {
  //     let splitDate = data.date.split(' ');
  //     let year = splitDate[3];
  //     let day = splitDate[2];
  //     let month = splitDate[1];
  //     switch (month) {
  //       case 'Jan':
  //         return `${year}-01-${day}`;
  //       case 'Feb':
  //         return `${year}-02-${day}`;
  //       case 'Mar':
  //         return `${year}-03-${day}`;
  //       case 'Apr':
  //         return `${year}-04-${day}`;
  //       case 'May':
  //         return `${year}-05-${day}`;
  //       case 'Jun':
  //         return `${year}-06-${day}`;
  //       case 'Jul':
  //         return `${year}-07-${day}`;
  //       case 'Aug':
  //         return `${year}-08-${day}`;
  //       case 'Sep':
  //         return `${year}-09-${day}`;
  //       case 'Oct':
  //         return `${year}-10-${day}`;
  //       case 'Nov':
  //         return `${year}-11-${day}`;
  //       case 'Dec':
  //         return `${year}-12-${day}`;

  //       default:
  //         break;
  //     }
  //   }
  // };
  const convertTime = useCallback(() => {
    if (data.date) {
      let timeData: string[] = data.date.split(' ');
      if (timeData.length !== 0) {
        return timeData[4];
      }
    }
  }, []);

  const dueDate = convertDate(data.date);
  const startTime = convertTime();
  useEffect(() => {}, [files]);

  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }

    let newMeetingImages = [...files];
    let typeOfFile = newMeetingImages.find(function (value: any) {
      return value.id === fileId;
    });
    let editedFileMeeting = newMeetingImages.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'meeting_attachment',
        fileId
      );
      if (result) {
        setFiles(editedFileMeeting);
      }
    } else {
      setFiles(editedFileMeeting);
    }
  };

  useEffect(() => {}, [paymentShowContent]);

  const editPayment = async (id: number) => {
    setDisabled(true);
    setLoading(true);
    if (dates._d) {
      let year = dates._d.getFullYear();
      let month = (dates._d.getMonth() + 1).toString().padStart(2, '0');
      let day = dates._d.getDate().toString().padStart(2, '0');
      newDate = year + '-' + month + '-' + day;
    }

    const formData: any = new FormData();

    let prevDate = !dates._d && dueDate;
    let prevTime = time === null && startTime;
    let newTime = time !== null && time._d.toString();
    let timeArray: any = time !== null && time._d.toString().split(' ');
    if (timeArray.length !== 0) {
      selectedTime = timeArray[4];
    }

    if (newDate && newTime) {
      formData.append('date', `${newDate}T${selectedTime}`);
    } else if (prevDate && prevTime) {
      formData.append('date', `${dueDate}T${startTime}`);
      setLoading(false);
    } else if (prevDate && newTime) {
      formData.append('date', `${dueDate}T${selectedTime}`);
    } else if (newDate && prevTime) {
      formData.append('date', `${newDate}T${startTime}`);
    }

    formData.append('title', title);
    formData.append('online_meeting_link', link);

    formData.append('instructions', instructions);
    formData.append('project_name', editingProjectName);
    formData.append('complete', complete);
    formData.append('duration', duration);
    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded') {
          formData.append(`attachments[${i}]attachment`, files[i].file);
          formData.append(`attachments[${i}]attachment_title`, files[i].name);
        }
      }
    }
    if (paymentLink !== '') {
      if (paymentLink.toLowerCase().includes('https://')) {
        formData.append('payment_link', paymentLink);
      } else {
        message.error({
          content:
            'The link should start with https:// , something like https://goodspaceplan.com/',
        });
        setLoading(false);
        setDisabled(false);
        return;
      }
    } else if (paymentLink !== null) {
      formData.append('payment_link', paymentLink);
    }

    if (link.toLowerCase().includes('https://')) {
      let result = await MeetingsServises.editMeetings(id, data.id, formData);
      if (result) {
        setModal({ show: true, type: 'success' });
        setTimeout(() => {
          setLoading(false);
          setDisabled(false);
          setModal((prev) => ({ ...prev, show: false }));
          Save(
            { expandedItem: undefined, paymentShowContent: 'NotShow' },
            'app'
          );
          getPayments(id);
        }, 3000);
      } else {
        message.error({
          content: 'Something went wrong ,please try again later !',
        });
        setLoading(false);
        setDisabled(false);
      }
    } else {
      setDisabled(false);
      setLoading(false);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const [dates, setdates] = useState<any>('');
  useEffect(() => {}, [dates]);
  useEffect(() => {}, [nameTitle]);
  const role = localStorage.getItem('userRole');

  const isFieldsTouched = form.isFieldsTouched([
    'time',
    'date',
    'title',
    'duration',
    'link',
    'paymentLink',
    'notes',
    'attachment',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      <Card className="payment-card">
        {!editable && (
          <CollapseItems
            onClick={() => {
              return Save(
                {
                  expandedItem: undefined,
                  paymentShowContent: 'NotShow',
                  editable: true,
                },
                'app'
              );
            }}
          />
        )}
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            date: moment(dueDate, 'YYYY-MM-DD'),
            link: link,
            time: moment(convertTime(), 'HH:mm:ss'),
            duration: duration,
            paymentLink: paymentLink,
            notes: instructions,
          }}
        >
          <Row className="row1">
            <Col
              className="col1"
              xs={24}
              xl={3}
              key={'id1'}
            >
              <Form.Item
                label={strings.title}
                name="title"
                initialValue={title}
              >
                <Select
                  className="timeline-title"
                  disabled={role === 'client' || !editable}
                  style={{ width: '100%' }}
                  onChange={(value: any) => setTitle(value)}
                  value={title}
                  disable={!editable}
                >
                  {meetingTitles &&
                    meetingTitles.length !== 0 &&
                    meetingTitles.map((title: any) => {
                      return (
                        <Option
                          key={title.id}
                          value={title.id}
                        >
                          {title.title}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col
              className="col2"
              xs={24}
              xl={3}
              key={'id2'}
            >
              <Form.Item
                name={'date'}
                label={'Date'}
                rules={[{ required: true, message: 'field is required' }]}
              >
                <DatePicker
                  disabled={role === 'client' || !editable}
                  convertTimclient
                  style={{ width: '100%' }}
                  onChange={(dateString) => setdates(dateString)}
                />
              </Form.Item>
            </Col>
            <Col
              className="col2"
              xs={24}
              xl={3}
              key={'id3'}
            >
              <Form.Item
                name={'time'}
                label={'Start Time (PT)'}
                rules={[{ required: true, message: 'field is required' }]}
              >
                <TimePicker
                  disabled={role === 'client' || !editable}
                  defaultValue={moment(convertTime(), 'HH:mm:ss')}
                  value={time}
                  style={{ width: '100%' }}
                  onChange={(time: any) => setTime(time)}
                />
              </Form.Item>
            </Col>
            <Col
              className="col5"
              xs={24}
              xl={2}
              key={'id2-5'}
            >
              <Form.Item
                label={'Duration(mins)'}
                name={'duration'}
              >
                <InputNumber
                  readOnly={role === 'client' || !editable}
                  onChange={(e) => setDuration(e)}
                  value={duration}
                  defaultValue={duration}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col
              className="col3"
              xs={24}
              xl={3}
              key={'id4'}
            >
              <Form.Item
                label={strings.onlineMeetingLink}
                name={'link'}
                rules={[{ required: true, message: 'field is required' }]}
              >
                <Input
                  readOnly={role === 'client' || !editable}
                  defaultValue={link}
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  suffix={
                    <a
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: '15px', marginLeft: '11px' }}
                        src={ExportSVG}
                        alt=""
                      />
                    </a>
                  }
                />
              </Form.Item>
            </Col>
            <Col
              className="col3"
              xs={24}
              xl={3}
              key={'id323'}
            >
              <Form.Item
                label={'Payment Link'}
                name={'paymentLink'}
              >
                <Input
                  readOnly={role === 'client' || !editable}
                  onChange={(e) => setPaymentLink(e.target.value)}
                  suffix={
                    <a
                      href={paymentLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        style={{ width: '15px', marginLeft: '11px' }}
                        src={ExportSVG}
                        alt=""
                      />
                    </a>
                  }
                />
              </Form.Item>
            </Col>
            <Col
              className="colpdf"
              xs={24}
              xl={3}
            >
              {pdf && pdf !== null && (
                <a
                  href={`${URL}/${pdf}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button icon={<FilePdfOutlined />}>Meeting guide</Button>
                </a>
              )}
            </Col>
            <Col
              className="col4 checkbox-container"
              xs={24}
              xl={3}
              key={'id5'}
            >
              <span>{strings.completed}</span>
              <Checkbox
                disabled={role === 'client' || !editable}
                onChange={(e) => setComplete(e.target.checked)}
                value={complete}
                checked={complete}
              />
            </Col>
          </Row>
          <Row className="row2">
            <Col
              className="project-type-col"
              xs={24}
              xl={24}
              key={'idx3'}
            >
              <Form.Item
                label={'Notes'}
                name="notes"
                initialValue={instructions}
              >
                <TextArea
                  readOnly={role === 'client' || !editable}
                  rows={7}
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          {role === 'admin' && (
            <Row className="row3">
              <Col
                className="project-type-col"
                xs={24}
                xl={24}
                key={'idx3'}
              >
                <Form.Item
                  label={strings.attachment}
                  name="attachment"
                >
                  <UploadFile
                    disabled={!editable}
                    text="Drag and drop files here"
                    width={'100%'}
                    imageArray={files}
                    reduxProperty={'meetingImages'}
                    height={110}
                    setFiles={setFiles}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          <div className="meeting-fileItem">
            {files &&
              files.map((item: any, idx: number) => {
                const { id, name, size, type, attachment_title, title } = item;

                return (
                  <FileItem
                    isEditable={false}
                    key={id}
                    format={item.format}
                    disabled={role !== 'admin'}
                    fileData={item.attachment ? item.attachment : item.file}
                    name={
                      type === 'unUploaded'
                        ? name
                        : attachment_title
                        ? attachment_title
                        : title
                    }
                    id={id}
                    size={size}
                    file={item}
                    type={type}
                    width={'100%'}
                    removeFilePress={removeFilePress}
                    showImagePress={() => showCarouselImage(idx)}
                  />
                );
              })}
          </div>
          {editable && (
            <Form.Item
              shouldUpdate
              className="meeting-btns"
            >
              {role !== 'client' && (
                <Button
                  onClick={() => editPayment(Number(idOfproject))}
                  className="save-btn"
                  loading={loading}
                  htmlType="submit"
                  disabled={disabled}
                >
                  {strings.save}
                </Button>
              )}
              <Button
                className="cancel-btn"
                onClick={() => {
                  Save(
                    { expandedItem: undefined, paymentShowContent: 'NotShow' },
                    'app'
                  );
                  setLoading(false);
                  localStorage.setItem(
                    'isFieldsTouched',
                    JSON.stringify(false)
                  );
                }}
              >
                {strings.cancel}
              </Button>
            </Form.Item>
          )}
        </Form>
      </Card>

      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The meeting has been successfully edited'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </>
  );
};

export default EditDetailsView;

import { Checkbox, Collapse, Form, Input, message, Radio, Slider } from 'antd';
import { useState } from 'react';
import { saveQuestionnaireAnswers } from 'src/api/services';

import { QuestionnaireListItems, QuestionnaireListProps } from '../models';
const { Panel } = Collapse;

const QuestionnaireListItem = (props: QuestionnaireListProps) => {
  const [form] = Form.useForm();
  const [categoryKey, setCategoryKey] = useState<any>();

  return (
    <div className="categories_container">
      <Collapse
        accordion
        expandIconPosition="right"
        onChange={(key) => setCategoryKey(key)}
      >
        {props.questionnaireListData.map((category: QuestionnaireListItems) => {
          const questionLength = category?.questions.length;

          const onFinishHandler = (values: any) => {
            const boolean_questions = Object.entries(values)
              .filter(
                (item) =>
                  item[0].includes('booleanQ_') &&
                  item[0].includes(`_${categoryKey}_`)
              )
              .map((item) => ({
                id_number: item[0].split('_')[2],
                answer: item[1],
              }));
            const descriptive_questions = Object.entries(values)
              .filter(
                (item) =>
                  item[0].includes('descriptiveQ_') &&
                  item[0].includes(`_${categoryKey}_`)
              )
              .map((item) => ({
                id_number: item[0].split('_')[2],
                answer: item[1] || null,
              }));
            const multi_choice_questions = Object.entries(values)
              .filter(
                (item) =>
                  item[0].includes('multiQ_') &&
                  item[0].includes(`_${categoryKey}_`)
              )
              .map((item) => {
                return {
                  id_number: item[0].split('_')[2],
                  answer: item[1],
                };
              });
            const scale_questions = Object.entries(values)
              .filter(
                (item) =>
                  item[0].includes('scaleQ_') &&
                  item[0].includes(`_${categoryKey}_`)
              )
              .map((item) => ({
                id_number: item[0].split('_')[2],
                answer: item[1],
              }));

            const data = {
              boolean_questions,
              descriptive_questions,
              multi_choice_questions,
              scale_questions,
            };

            try {
              saveQuestionnaireAnswers(
                props.project_id,
                props.template_id,
                data
              )
                .then((response) => {
                  message.success(
                    'Your answers were saved successfully, if there is any left feel free to fill them out later'
                  );
                  props.getProjectQuestionnaire();
                })
                .catch(() => {
                  message.error(
                    'It looks like something went wrong, please check out the fields you answered for any problems'
                  );
                });
            } catch (error) {
              console.log(error);
              message.error(
                'It looks like something went wrong, please check out the fields you answered for any problems'
              );
            }
            localStorage.setItem('isFieldsTouched', JSON.stringify(false));
          };

          const onValuesChange = () => {
            if (
              !JSON.parse(localStorage.getItem('isFieldsTouched') as string)
            ) {
              localStorage.setItem('isFieldsTouched', JSON.stringify(true));
            }
          };

          return (
            <Panel
              header={`${category.name} (${questionLength} ${
                questionLength > 1 ? 'Questions' : 'Question'
              })`}
              key={category.id}
            >
              <Form
                form={form}
                onFinish={(values) => onFinishHandler(values)}
                onValuesChange={onValuesChange}
              >
                <ol className="caregory-questions_container">
                  {category.questions.map((question) => {
                    if (question.type === 'boolean_question_answer') {
                      return (
                        <Form.Item
                          name={`booleanQ_${category.id}_${question.id}`}
                          initialValue={question.answer}
                          label={question.question}
                          key={question.id}
                        >
                          <Radio.Group
                            disabled={question.answer !== null}
                            value={question.answer}
                          >
                            <Radio value="Yes">Yes</Radio>
                            <Radio value="No">No</Radio>
                          </Radio.Group>
                        </Form.Item>
                      );
                    } else if (
                      question.type === 'descriptive_question_answer'
                    ) {
                      return (
                        <Form.Item
                          name={`descriptiveQ_${category.id}_${question.id}`}
                          initialValue={question.answer}
                          label={question.question}
                          key={question.id}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={100}
                            value={question.answer ? question.answer : ''}
                            disabled={question.answer !== null}
                          />
                        </Form.Item>
                      );
                    } else if (
                      question.type === 'multi_choice_question_answer'
                    ) {
                      const options = question?.options;

                      return (
                        <Form.Item
                          name={`multiQ_${category.id}_${question.id}`}
                          initialValue={question.answer}
                          label={question.question}
                          key={question.id}
                        >
                          <Checkbox.Group disabled={question.answer !== null}>
                            {options &&
                              options.length > 0 &&
                              options.map((option: any, idx: any) => {
                                return (
                                  <Checkbox
                                    key={idx}
                                    value={option}
                                  >
                                    {option}
                                  </Checkbox>
                                );
                              })}
                          </Checkbox.Group>
                        </Form.Item>
                      );
                    } else if (question.type === 'scale_question_answer') {
                      return (
                        <Form.Item
                          name={`scaleQ_${category.id}_${question.id}`}
                          initialValue={question.answer}
                          label={question.question}
                          key={question.id}
                        >
                          <Slider
                            min={0}
                            max={10}
                            defaultValue={
                              question.answer !== null
                                ? (question.answer as any)
                                : (0 as any)
                            }
                            disabled={question.answer !== null}
                          />
                        </Form.Item>
                      );
                    }
                  })}
                </ol>
                <div className="button__container">
                  <Form.Item>
                    <button
                      type="submit"
                      className="caregory_questions-save-btn"
                    >
                      Save
                    </button>
                  </Form.Item>
                </div>
              </Form>
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
};

export default QuestionnaireListItem;

import { api } from 'api/api';
import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';
import { ResponseType } from '../index';

const END_POINTS = {
  goodsByProject: '/projects/{project_pk}/goods/',
  projectGoodById: '/projects/{project_pk}/goods/{good_id}/',
};

type GoodsSizeType = {
  title: string;
  size: number;
};
type GoodsAttachament = {
  id_number: string | null;
  id: number | null;
  attachment: string | null;
  attachment_title: string | null;
  get_attachment_size: string | null;
};
type GoodsType = {
  id: number;
  item: number | null;
  name: string | null;
  tendency: number | null;
  where_to_keep: number | null;
  sized: Array<GoodsSizeType>;
  description: string | null;
  attachment: Array<GoodsAttachament>;
};

export const GoodsServices = {
  createGoods: async (
    data: any,
    project_pk: string,
    customHeader?: object,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(
        END_POINTS.goodsByProject.replace(
          '{project_pk}',
          project_pk.toString()
        ),
        data,
        null,
        true,
        customHeader
      )
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        console.log(err, 'err');

        // if (err.toLowerCase().includes("timeout")) {
        //   return true;
        // }
        return null;
      });
  },
  getGoods: async (
    project_pk: string,
    params: any,
    badRequestCallback?: (err: any) => void
  ): Promise<ResponseType<GoodsType> | null> => {
    return await api
      .get(
        END_POINTS.goodsByProject.replace(
          '{project_pk}',
          project_pk.toString()
        ),
        params,
        true
      )
      .then((response) => {
        return response.data as ResponseType<GoodsType>;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('GoodsServices -> getGoods: ', err);
        return null;
      });
  },
  getGoodsById: async (
    project_pk: string,
    goodId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        END_POINTS.projectGoodById
          .replace('{project_pk}', project_pk)
          .replace('{good_id}', goodId),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('GoodsServices -> getGoodsById: ', err);
        return null;
      });
  },
  editGood: async (
    project_pk: string,
    goodId: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        END_POINTS.projectGoodById
          .replace('{project_pk}', project_pk)
          .replace('{good_id}', goodId),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        // if (err.toLowerCase().includes("timeout")) {
        //   return true;
        // }
        return null;
      });
  },

  deleteGoodsById: async (
    project_pk: string,
    goodId: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        END_POINTS.projectGoodById
          .replace('{project_pk}', project_pk)
          .replace('{good_id}', goodId),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('GoodsServices -> remove GoodsById: ', err);
        return null;
      });
  },
};

export const strings = {
  anUnknownErrorOccurredPleaseTryAgainLater:
    "An Unknown Error Occurred Please Try Again Later",
  notLoggedIn: "You Must First Log In",
  PageNotFound: "Page Not Found",
  deletePreliminaryInformationQuestionnaire:
    "Delete  Preliminary Information questionnaire",
  thisActionCanNotBeUndoneAreYouSure:
    "This action can NOT be undone. Are you sure you want to continue?",
  yesDeleteTemplate: "YES, DELETE TEMPLATE",
  questions: "Questions",
  or: "Or",
  pleaseMakeSureTheBoardIsPublicOtherWiseWeCantAccessIt:
    "Please make sure the board is public. Otherwise we can’t access it! ",
  yesTheBoardIsPublic: "Yes, the board is public.",
  answer: "Answer",
  photo: "Photo",
  video: "Video",
  file: "File",
  uppercaseCancel: "CANCEL",
  yesArchiveProject: "YES, ARCHIVE PROJECT",
  doYouWantToArchiveTheProject: "Do you want to archive the project?",
  archiveLoremIpsumDolorSitAmet: "Archive: Lorem ipsum dolor sit amet",
  writeYourAnswer: "Write Your Answer",
  postedBy: "Posted by",
  homeDecoratingAndDesignDiscussions: "Home Decorating & Design Discussions",
  selectAUser: "Select a user ...",
  members: "Members",
  searchConversations: "Search conversations",
  startANewConversation: "Start a New Conversation",
  website: "Website",
  uploadPhotoOrVideo: "Upload Photo or Video",
  pinterestBoardLink: "Pinterest Board",
  inspiration: "Inspiration",
  addNewInspiration: "Add new Inspiration",
  addNewQuestion: "Add New Questionnaire",
  addNewRoom: "Add New Room",
  projectData: "Project Data",
  rooms: "Rooms",
  goods: "Goods",
  users: "Users",
  timeLine: "Timeline",
  paymentAndMeeting: "Payment & Meeting",
  questionnaire: "Questionnaire",
  messaginBoard: "Message Board",
  projectName: "Project Name",
  projectType: "Project Type",
  totalPrice: "Total Price",
  address: "Address",
  scopeOfProject: "Scope of work",
  cancel: "Cancel",
  save: "Save",
  ihaveAddedAllMyRooms: "I 'v added all my rooms.",
  name: "Name",
  type: "Type",
  description: "Description",
  renameFile: "Rename File",
  browserFile: "Browse Files",
  clickToAddFloorPlan: "Click to Add Floor Plan ( PDF , Image )",
  addNewGoods: "Add New Goods",
  ihaveAddedAllMyGoods: " I’ve added all my goods.",
  addNewUser: "Add New User",
  AssignedProject: "Assigned to this project",
  All: "All",
  Administrators: "Administrators",
  Designers: "Designers",
  Concierges: "Concierges",
  Clients: "Clients",
  Referrer: "Referrer",
  Remove: "Remove",
  title: "Title",
  keyPerson: "Key Person",
  duedate: "Due Date",
  complete: "Complete",
  addNewTimeline: "Add New Timeline",
  projectDetails: "Project Details",
  link: "Link",
  attachment: "Attachment",
  boardLink: "Board Link (Make sure it is public)",
  AllMembers: "All Members",
  ReceivesNotifications: "Receives notifications",
  SendTheNewUseremailAboutTheirAccount:
    " Send the new user an email about their account.",
  active: "Active",
  deactive: "Deactive",
  dateAndTime: "Date and time",
  onlineMeetingLink: "Online Meeting Link",
  instructions: "Instructions",
  addNewButton: "Add New Meeting",
  completed: "Completed",
  Notifications: "Notifications",
  searchForUser: "Search for Users",
  searchForProjects: "Search for Projects",
  Status: "Status",
  addNewStatus: "Add New Status",
  successfullyAddTermina: " Your Terminal was saved successfully!",
  pleaseFillAllInputs: "All inputs must be filled out",
  successfullyUpdateProfile: "Your Profile was saved successfully!",
};
// pleaseFillAllInputs: "Please Save the form or Cancel changes!",

import { useState, useEffect } from 'react';
import {
  Tabs,
  Row,
  Input,
  Radio,
  Space,
  Card,
  Divider,
  Button,
  Dropdown,
  Col,
  Spin,
  Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { AuthServices } from 'src/api/services';
import { FilterSVG } from 'assets/index';
import { UserTable, RefferMemberTable } from 'src/components';
import { strings } from 'src/common';
import './styles.css';
import { PropsType } from './models';
const UserList = ({ users, loading, referrer, deleteUser }: PropsType) => {
  // antd
  const { TabPane } = Tabs;
  const { Search } = Input;
  // states
  const [value, setValue] = useState([]);

  const [listOptions, setListOptions] = useState(users);
  const [referrerOptions, setReferrerOptions] = useState(referrer);
  const [searchResult, setSearchResult] = useState(true);
  const [activeTab, setActiveTab] = useState('users');

  // functions

  const onSearch = async (value: string) => {
    if (value === '') {
      setListOptions(users);
      setSearchResult(true);
      setReferrerOptions(referrer);
    } else {
      await AuthServices.usersList({}).then((res) => {
        if (res?.results) {
          if (activeTab === 'users') {
            let filterUsersOnSearch = res.results.filter(
              (e) =>
                e.last_name === value ||
                e.first_name === value ||
                e.email === value ||
                e.preferred_name === value
            );

            setListOptions(filterUsersOnSearch);
            setTimeout(() => {
              if (filterUsersOnSearch.length === 0) {
                setSearchResult(false);
              }
            }, 150);
          } else if (activeTab === 'referrer') {
            let filterReferrerOnSearch = referrer.filter(
              (e) => e.name === value
            );
            setReferrerOptions(filterReferrerOnSearch);
            if (filterReferrerOnSearch.length === 0) {
              setSearchResult(false);
            }
          }
        }
      });
    }
  };

  const onFilterRole = (e: any) => {
    if (e.target.value !== 'all') {
      let newUsers = [...users];
      let filterListByRole = newUsers.filter((i) => i.role === e.target.value);
      setListOptions(filterListByRole);
    } else {
      setListOptions(users);
      setSearchResult(true);
    }
  };
  const onFilterStatus = (e: any) => {
    let filterListByStatus;
    if (e.target.value === 'active') {
      filterListByStatus = users.filter((i) => i.status === true);
    } else {
      filterListByStatus = users.filter((i) => i.status === false);
    }
    setListOptions(filterListByStatus);
  };

  const filterDropDown = (
    <Menu className="card-filter">
      <Card>
        <span className="filter-radio-container">ROLE</span>
        <br />
        <Radio.Group
          onChange={(e) => onFilterRole(e)}
          value={value}
          className="radio-filter"
        >
          <Space direction="vertical">
            <Radio
              value={'designer'}
              key="designer"
            >
              {strings.Designers}
            </Radio>
            <Radio
              value={'admin'}
              key="admin"
            >
              {strings.Administrators}
            </Radio>
            <Radio
              value={'client'}
              key="clients"
            >
              {strings.Clients}
            </Radio>
            <Radio
              value={'all'}
              key="All"
            >
              {'All'}
            </Radio>
          </Space>
        </Radio.Group>
        <Divider />
        <span className="status-radio">STATUS</span>
        <br />
        <Radio.Group onChange={(e) => onFilterStatus(e)}>
          <Space direction="vertical">
            <Radio
              value={'active'}
              key="active"
            >
              {strings.active}
            </Radio>

            <Radio
              value={'deactive'}
              key="deactive"
            >
              {strings.deactive}
            </Radio>
          </Space>
        </Radio.Group>
      </Card>
    </Menu>
  );
  return (
    <>
      <Row className="seach-filter-box">
        <Col
          xs={12}
          xl={4}
          className="filter-user-container"
        >
          <Dropdown
            trigger={['click']}
            overlay={filterDropDown}
            placement="bottomLeft"
          >
            <Button
              className="filter-btn"
              onClick={() => (e: any) => e.preventDefault()}
            >
              <img
                src={FilterSVG}
                alt="filter"
                width="30px"
                height="15px"
              />
              Filter
              <DownOutlined style={{ paddingLeft: '19px' }} />
            </Button>
          </Dropdown>
        </Col>
        <Col
          xs={12}
          xl={6}
        >
          <Search
            placeholder={strings.searchForUser}
            style={{ width: '100%', background: 'white', borderRadius: '7px' }}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      {loading ? (
        <div className="spin-container">
          <Spin size="small" />
        </div>
      ) : (
        <>
          {!searchResult ? (
            <p>No results were found </p>
          ) : (
            <Tabs
              defaultActiveKey="1"
              onChange={(tab) => setActiveTab(tab)}
              size="large"
            >
              <TabPane
                tab={strings.AllMembers}
                key="users"
                className="user-tabs-tab"
              >
                <UserTable
                  deleteUser={deleteUser}
                  users={listOptions.length === 0 ? users : listOptions}
                />
              </TabPane>
              <TabPane
                tab={strings.Referrer}
                key="referrer"
              >
                <RefferMemberTable
                  deleteUser={deleteUser}
                  referrer={
                    referrerOptions.length === 0 ? referrer : referrerOptions
                  }
                />
              </TabPane>
            </Tabs>
          )}
        </>
      )}
    </>
  );
};

export default UserList;

import Login from "./dashboard/login";
import Home from "./dashboard/Home";
import QuestionTemplates from "./dashboard/QuestionsScreen";
import ArchivedProjects from "./dashboard/ArchivedProjects";
import Profile from "./dashboard/Profile";
import AddProject from "./other/AddProject";
import AddUser from "./Members/AddUser";
import Member from "./Members";
import UserProject from "./Members/UserProject";
import ForgotPass from "./forgotPassword";
export {
  Login,
  Home,
  QuestionTemplates,
  ArchivedProjects,
  Profile,
  AddProject,
  AddUser,
  Member,
  UserProject,
  ForgotPass,
};

import { strings } from 'src/common';
import { Button, Table } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import './styles.css';
import { useRedux } from 'src/hooks';
import { ConfirmModal } from 'src/components';

const ProjectTableView = ({
  users,
  removeUserFromProject,
  addUserToThisProject,
}: any) => {
  const { Save, Get } = useRedux();
  const { showDeleteModal, usersAssignedInProject, showAddModal } = Get().app;
  const checkAssignedStatus = (recordId: any) => {
    if (usersAssignedInProject) {
      let newUserAssigned = [...usersAssignedInProject];
      let isAssigend = false;
      newUserAssigned.map((e) => {
        if (e.id === recordId) {
          isAssigend = true;
        }
      });
      return isAssigend;
    }
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'preferred_name',
      key: 'name',
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '2%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '5%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'Role',
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '5%',
      render: (text: any) => {
        return <>{text?.charAt(0).toUpperCase() + text?.slice(1)}</>;
      },
    },
    {
      title: '',
      key: 'tags',
      dataIndex: 'tags',
      // responsive: ["xs"],
      responsive: ['md', 'xs'] as Breakpoint[],
      render: (text: any, record: any) => {
        if (localStorage.getItem('userRole') === 'admin') {
          return (
            <>
              {!checkAssignedStatus(record.id) ? (
                <Button
                  size="middle"
                  className="add"
                  onClick={() => {
                    Save(
                      {
                        showAddModal: {
                          isVisible: true,
                          addItem: record,
                        },
                      },
                      'app'
                    );
                  }}
                >
                  {'Add '}
                </Button>
              ) : (
                <Button
                  size="middle"
                  className="remove"
                  onClick={() => {
                    Save(
                      {
                        showDeleteModal: {
                          isVisible: true,
                          deleteItem: record,
                        },
                      },
                      'app'
                    );
                  }}
                >
                  {strings.Remove}
                </Button>
              )}
            </>
          );
        } else {
          <></>;
        }
      },
      width: '2%',
    },
  ];
  return (
    <div className="table">
      <Table
        dataSource={users}
        columns={columns}
        pagination={{ defaultPageSize: 10 }}
      />
      <ConfirmModal
        deleteModal={true}
        handleOk={() => {
          showDeleteModal?.deleteItem &&
            removeUserFromProject(showDeleteModal.deleteItem);
        }}
        handleCancel={() =>
          Save({ showDeleteModal: { isVisible: false, deleteItem: 0 } }, 'app')
        }
        isModalVisible={
          showDeleteModal?.isVisible && showDeleteModal?.isVisible
        }
        okText={'Cancel'}
        cancelText={'Yes remove user from this project'}
        questionTopText={
          'Would you like to remove this user from this project?'
        }
        questionText={''}
      />
      <ConfirmModal
        handleOk={() => {
          showAddModal?.addItem && addUserToThisProject(showAddModal.addItem);
        }}
        handleCancel={() =>
          Save({ showAddModal: { isVisible: false, addItem: 0 } }, 'app')
        }
        isModalVisible={showAddModal?.isVisible}
        okText={'Cancel'}
        cancelText={'Yes, add the user to this project'}
        questionTopText={'Would you like to assign this user to this project?'}
        questionText={''}
      />
    </div>
  );
};

export default ProjectTableView;

import "./styles.css";
import { ViewProps } from "./models";
import { GspoLogo } from "src/assets";
import { Row, Card, Col } from "antd";
const LoginView = (props: ViewProps) => {
  const { LoginForm } = props;
  return (
    <div className="screen-container">
      <Row justify="end">
        <Col xl={12} xs={24}>
          <div className="right-part">
            <Card>
              <h1>Welcome to</h1>

              <img src={GspoLogo} alt="" />
              <h1 style={{ color: "#72a189" }}>Client Portal</h1>
              <div className="login-title-container"></div>
              <div className="login-parent">
                <LoginForm />
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginView;

import { useRedux } from "hooks/index";
import { AddConversations } from "./components/";
import "./styles.css";

const MessageBoardView = (props: any) => {
  const { MessageBoardList, getMessages } = props;
  const { Get } = useRedux();
  const { messageBoardContentStatus } = Get().app;

  return (
    <>
      <div className="message-container">
        {messageBoardContentStatus === "Add" && (
          <AddConversations getMessages={getMessages} />
        )}
        {(messageBoardContentStatus === "List" ||
          messageBoardContentStatus === "editMessageBoard" ||
          messageBoardContentStatus === "NotShow") && <MessageBoardList />}
      </div>
    </>
  );
};

export default MessageBoardView;

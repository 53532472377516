// @ts-nocheck

import React, { useEffect, useState } from 'react';
import {
  Card,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Row,
  Form,
  Upload,
  message,
  Image,
  Col,
} from 'antd';
import {
  UserOutlined,
  FileOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  TrashSVG,
  BlueTopButtonSVG,
  CameraSVG,
  VideoSVG,
  profileDefault,
} from 'assets/index';
import { Alert } from 'src/ui-kits';
import { useRedux } from 'src/hooks';
import { AuthServices, ConversationServices } from 'src/api/services';
import FileItem from '../FileItem';
import { CarouselModal } from '..';
import { RotateImage } from '../RotateImage';
import { URL } from 'src/api/api';

const ChatMessages = ({ data }: any) => {
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  const [editorState, setEditorState] = useState<any>(
    EditorState.createEmpty()
  );
  const handleText = (description) => {
    const html = description;
    const contentBlock: any = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  };
  const [comments, setComments] = useState<any>([]);
  const [disabled, setDisabled] = useState(false);
  const [showCarousel, setShowCarousel] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [comment, setComment] = useState<any>('');
  const [currentUser, setCurrentUser] = useState<any>();
  const [degree, setDegree] = useState(0);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [rotateImageData, setRotateImageData] = useState(null);
  const [editText, setEditText] = useState({
    isEdit: false,
    textId: null,
    newText: null,
  });

  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [loading, setLoading] = useState(false);
  const { Save, Get } = useRedux();
  const { editable } = Get().app;
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };

  const createMarkup = (text: any) => {
    return {
      __html: text,
    };
  };
  const getComments = async () => {
    await ConversationServices.getComments(idOfproject, data.id).then((res) => {
      if (res) {
        setComments(res.results);
      }
    });
  };
  const localData = JSON.parse(localStorage.getItem('userData')!);
  const role = localStorage.getItem('userRole');

  const getUsers = async () => {
    await AuthServices.usersList({}).then((res) => {
      if (res?.results) {
        const currentUser = res.results.filter((user: any) => {
          return user.id === localData?.id;
        })[0];
        setCurrentUser(currentUser);
      }
    });
  };

  useEffect(() => {
    getComments();
    getUsers();
    //eslint-disable-next-line
  }, []);

  const removeFilePress = (fileId: number) => {
    if (!files) {
      return;
    }
    let newFile = [...files];
    let editedFile = newFile.filter(function (value: any) {
      return value.id !== fileId;
    });

    setFiles(editedFile);
  };
  const isPNG = (file: any) =>
    file.toUpperCase() === 'PNG' ||
    file.toUpperCase() === 'TIFF' ||
    file.toUpperCase() === 'JPG' ||
    file.toUpperCase() === 'JPEG' ||
    file.toUpperCase() === 'GIF' ||
    file.toUpperCase() === 'EPS' ||
    file.toUpperCase() === 'HEIF' ||
    file.toUpperCase() === 'DNG' ||
    file.toUpperCase() === 'RAW';
  const isVideo = (file: any) =>
    file.toUpperCase() === 'AVI' ||
    file.toUpperCase() === 'FLV' ||
    file.toUpperCase() === 'WMV' ||
    file.toUpperCase() === 'MP4' ||
    file.toUpperCase() === 'MOV';
  const [form] = Form.useForm();
  const sendComment = async () => {
    if (!comment && files.length === 0) {
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 1500);
      return;
    }

    let formData: any = new FormData();
    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded' && isPNG(files[i].format)) {
          formData.append(`photos[${i}]photo`, files[i].file);
          formData.append(`photos[${i}]photo_title`, files[i].name);
          if (files[i].rotate_angle) {
            formData.append(`photos[${i}]rotate_angle`, files[i].rotate_angle);
          }
        } else if (files[i].type === 'unUploaded' && isVideo(files[i].format)) {
          formData.append(`videos[${i}]video`, files[i].file);
          formData.append(`videos[${i}]video_title`, files[i].name);
        } else if (files[i].type === 'unUploaded') {
          formData.append(`files[${i}]file`, files[i].file);
          formData.append(`files[${i}]file_title`, files[i].name);
        }
      }
    }

    formData.append('comment', comment);

    setLoading(true);
    setDisabled(true);
    await ConversationServices.postComments(
      formData,
      idOfproject,
      data.id
    ).then((res) => {
      if (res) {
        setLoading(false);
        form.resetFields();
        setModal({ show: true, type: 'success' });
        setTimeout(() => {
          getComments();
          setDisabled(false);
          setModal((prev) => ({ ...prev, show: false }));
          Save(
            {
              conversationStatus: 'notShow',
              expandedItem: undefined,
            },
            'app'
          );
        }, 3000);
      } else {
        setLoading(false);
        setTimeout(() => {
          message.error({
            content: 'something went wrong , please try again later',
          });

          setDisabled(false);
        }, 2500);
      }
    });
  };

  const editComment = async (
    projectId,
    conversationId,
    commentId,
    data,
    degree = 0
  ) => {
    let formData: any = new FormData();
    setLoading(true);
    setDisabled(true);
    if (data.isFile) {
      formData.append(`photos[${data.id}]rotate_angle`, degree);
      formData.append(`photos[${data.id}]id_number`, data.id);

      await ConversationServices.editCommentById(
        formData,
        projectId,
        conversationId,
        commentId
      ).then((res) => {
        message.success({
          content: `Photo has been edited successfully`,
        });
        setIsRotateImageActive(false);
        setDegree(0);
        getComments();
      });
    } else {
      await ConversationServices.editCommentById(
        { comment: data.comment },
        projectId,
        conversationId,
        commentId
      ).then((res) => {
        message.success({ content: `Comment has been edited successfully` });
        setEditText({ isEdit: false, textId: null });
        getComments();
      });
    }

    setLoading(false);
    setDisabled(false);
  };
  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState);
  };
  return (
    <>
      <Card className="chat-card">
        <div className="header">
          <span>{data.title}</span>
          <div className="right-part">
            <Avatar.Group>
              {data && //@ts-ignore
                data.users && //@ts-ignore
                data.users.map((user: any) => {
                  return (
                    <Tooltip
                      key={user.avatar + user.preferred_name}
                      title={user.preferred_name}
                      placement="top"
                    >
                      <Avatar
                        style={{ backgroundColor: '#87d068' }}
                        icon={!user.avatar && <UserOutlined />}
                        src={user.avatar && `${URL}/${user.avatar}`}
                      />
                    </Tooltip>
                  );
                })}
            </Avatar.Group>
            <div
              onClick={() =>
                Save(
                  {
                    expandedItem: undefined,
                    conversationStatus: 'notShow',
                    editable: true,
                  },
                  'app'
                )
              }
              className="top-icon"
            >
              <img
                src={BlueTopButtonSVG}
                alt=""
              />
            </div>
            {editable && role !== 'client' && (
              <div className="trash-container">
                <img
                  src={TrashSVG}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        <div className="descripion-header">
          <span>Created By : {data.created_by.preferred_name}</span>
          <span>{data.created_on}</span>
        </div>
        <Row>
          {data.description && (
            <span dangerouslySetInnerHTML={createMarkup(data.description)} />
          )}
        </Row>
        <Divider />

        {comments &&
          comments.length !== 0 &&
          comments.map((comment: any) => {
            return (
              <React.Fragment key={comment.id}>
                <div key={comment.id}>
                  <Row className="comment-container">
                    <div className="posted_by_container">
                      <Col
                        xl={18}
                        xs={22}
                      >
                        <img
                          src={
                            comment.created_by.avatar
                              ? `${URL}/${comment.created_by.avatar}`
                              : profileDefault
                          }
                          alt={comment.created_by.preferred_name}
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                          }}
                        />{' '}
                        <span>
                          Posted By: {comment.created_by.preferred_name}
                        </span>
                        <span>{comment.created_on}</span>
                      </Col>
                      {(role !== 'client' ||
                        (role !== 'admin' &&
                          currentUser?.id.toString() ===
                            comment.created_by?.id.toString())) && (
                        <Col
                          xs={2}
                          xl={6}
                        >
                          <span
                            onClick={() => {
                              setEditText({ isEdit: true, textId: comment.id });
                              handleText(comment.comment);
                            }}
                          >
                            <EditOutlined />
                            Edit comment
                          </span>
                        </Col>
                      )}
                    </div>
                  </Row>
                  {editText.isEdit && comment.id === editText.textId ? (
                    <>
                      <Row
                        justify="end"
                        className="edit-text-container"
                      >
                        <Editor
                          spellCheck={true}
                          wrapperClassName="editor-wrapper"
                          editorClassName="editor-custom"
                          editorState={editorState}
                          onEditorStateChange={onEditorStateChange}
                          onChange={(e: any) => {
                            setEditText({
                              ...editText,
                              newText: draftToHtml(
                                convertToRaw(editorState.getCurrentContent())
                              ),
                            });
                          }}
                        />
                      </Row>
                      <div className="comment-btn-container">
                        <Button
                          className="cancel-btn"
                          onClick={() => {
                            setEditText({
                              isEdit: false,
                              textId: null,
                              newText: null,
                            });
                            localStorage.setItem(
                              'isFieldsTouched',
                              JSON.stringify(false)
                            );
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          loading={loading}
                          disabled={disabled}
                          className="save-btn"
                          onClick={() => {
                            editComment(
                              idOfproject,
                              comment.conversation,
                              comment.id,
                              { comment: editText.newText, isFile: false }
                            );
                          }}
                        >
                          Save changes
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="comment-message">
                      <div
                        dangerouslySetInnerHTML={createMarkup(comment.comment)}
                      />
                    </div>
                  )}

                  {comment.photos.length > 0 ||
                  comment.videos.length > 0 ||
                  comment.files.length > 0 ? (
                    <div className="comment-media">
                      {comment.photos &&
                        comment.photos.map((photo: any) => {
                          return (
                            <div>
                              <Image
                                onClick={() => {
                                  setRotateImageData({
                                    url: `${URL}${photo.photo}`,
                                    type: 'uploaded',
                                    name: photo.photo_title,
                                    id: photo.id,
                                    size: photo.get_photo_size,
                                    format: 'jpg',
                                    rotate_angle: 0,
                                    commentId: comment.id,
                                    conversationId: comment.conversation,
                                    commentText: comment.comment,
                                    isFile: true,
                                  });
                                  setIsRotateImageActive(true);
                                }}
                                preview={false}
                                key={photo.photo}
                                width={100}
                                height={100}
                                style={
                                  rotateImageData?.id === photo.id
                                    ? {
                                        transform: `rotate(${
                                          rotateImageData?.rotate_angle + degree
                                        }deg) `,
                                      }
                                    : { transform: `rotate(0deg)` }
                                }
                                src={`${URL}/${photo.photo}`}
                              />
                            </div>
                          );
                        })}
                      {comment.videos &&
                        comment.videos.map((video: any) => {
                          return (
                            <a
                              className="video__container"
                              target="_blank"
                              rel="noreferrer"
                              href={`${URL}${video.video}`}
                              key={video.video}
                            >
                              <DownloadOutlined />
                              <p>download {video.video_title}</p>
                              <video height="100px">
                                <source
                                  src={`${URL}${video.video}`}
                                  type={`video/mp4`}
                                />
                                {video.video_title}
                              </video>
                            </a>
                          );
                        })}
                      {comment.files &&
                        comment.files.map((file: any) => {
                          return (
                            <a
                              className="file__container"
                              target="_blank"
                              rel="noreferrer"
                              href={`${URL}${file.file}`}
                              key={file.file}
                            >
                              <FileOutlined />
                              <p>{file.file_title}</p>
                            </a>
                          );
                        })}
                    </div>
                  ) : null}
                </div>

                <Divider />
              </React.Fragment>
            );
          })}

        {editable && (
          <>
            <div className="inputAndAvatar">
              <img
                src={
                  currentUser?.avatar
                    ? `${URL}/${currentUser?.avatar}`
                    : profileDefault
                }
                alt=""
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                }}
              />
            </div>
            <Row
              justify="center"
              className="editor-container"
            >
              <Editor
                spellCheck={true}
                toolbarClassName="demo-toolbar-custom"
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor-custom"
                onEditorStateChange={onEditorStateChange}
                onChange={() => {
                  localStorage.setItem('isFieldsTouched', JSON.stringify(true));
                  setComment(
                    draftToHtml(convertToRaw(editorState.getCurrentContent()))
                  );
                }}
              />
            </Row>

            <div className="attach-row">
              <Upload
                customRequest={({ file, onSuccess }: any) => {
                  return false;
                }}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isPNG =
                    file.type.toUpperCase() === 'IMAGE/PNG' ||
                    file.type.toUpperCase() === 'IMAGE/TIFF' ||
                    file.type.toUpperCase() === 'IMAGE/JPG' ||
                    file.type.toUpperCase() === 'IMAGE/JPEG' ||
                    file.type.toUpperCase() === 'IMAGE/GIF' ||
                    file.type.toUpperCase() === 'IMAGE/EPS' ||
                    file.type.toUpperCase() === 'IMAGE/HEIF' ||
                    file.type.toUpperCase() === 'IMAGE/DNG' ||
                    file.type.toUpperCase() === 'IMAGE/RAW ';
                  if (!isPNG) {
                    message.error(
                      `${file.name} should be one of png, tiff, jpg, jpeg, gif, eps, HEIF, DNG, Raw types.`
                    );
                  }
                  return isPNG || Upload.LIST_IGNORE;
                }}
                onChange={(info: any) => {
                  setFiles((prev) => [
                    ...prev,
                    {
                      id: Math.round(Math.random() * 1000),
                      uid: info.file.uid,
                      lastModified: info.file.lastModified,
                      lastModifiedDate: info.file.lastModifiedDate,
                      name: info.file.name,
                      file: info.file.originFileObj,
                      size: {
                        type: info.file.size < 100000 ? 'KB' : 'MB',
                        size:
                          info.file.size < 100000
                            ? Math.round(info.file.size / 10) / 10
                            : Math.round(info.file.size / 100000) / 10,
                      },
                      rotate_angle: 0,
                      type: 'unUploaded',
                      format:
                        info.file.name.split('.')[
                          info.file.name.split('.').length - 1
                        ],
                      webkitRelativePath: info.file.webkitRelativePath,
                    },
                  ]);
                }}
              >
                <Button
                  icon={
                    <img
                      src={CameraSVG}
                      alt=""
                    />
                  }
                >
                  {'Photo'}
                </Button>
              </Upload>
              <Upload
                customRequest={({ file, onSuccess }: any) => {
                  return false;
                }}
                showUploadList={false}
                beforeUpload={(file) => {
                  const isVideo =
                    file.type.toUpperCase() === 'VIDEO/AVI' ||
                    file.type.toUpperCase() === 'VIDEO/FLV' ||
                    file.type.toUpperCase() === 'VIDEO/WMV' ||
                    file.type.toUpperCase() === 'VIDEO/MP4' ||
                    file.type.toUpperCase() === 'VIDEO/MOV';

                  if (!isVideo) {
                    message.error(
                      `${file.name} should be one of AVI, FLV, WMV, mp4, MOV types.`
                    );
                  }
                  return isVideo || Upload.LIST_IGNORE;
                }}
                onChange={(info: any) => {
                  //@ts-ignore
                  setFiles((prev) => [
                    ...prev,
                    {
                      id: Math.round(Math.random() * 1000),
                      uid: info.file.uid,
                      lastModified: info.file.lastModified,
                      lastModifiedDate: info.file.lastModifiedDate,
                      name: info.file.name,
                      file: info.file.originFileObj,
                      size: {
                        type: info.file.size < 100000 ? 'KB' : 'MB',
                        size:
                          info.file.size < 100000
                            ? Math.round(info.file.size / 10) / 10
                            : Math.round(info.file.size / 100000) / 10,
                      },
                      type: 'unUploaded',
                      format:
                        info.file.name.split('.')[
                          info.file.name.split('.').length - 1
                        ],
                      webkitRelativePath: info.file.webkitRelativePath,
                    },
                  ]);
                }}
              >
                <Button
                  icon={
                    <img
                      src={VideoSVG}
                      alt=""
                    />
                  }
                >
                  {'Video'}
                </Button>
              </Upload>
              <Upload
                customRequest={({ file, onSuccess }: any) => {
                  return false;
                }}
                beforeUpload={(file) => {
                  const isVideoOrImage =
                    file.type.toUpperCase() === 'VIDEO/AVI' ||
                    file.type.toUpperCase() === 'VIDEO/FLV' ||
                    file.type.toUpperCase() === 'VIDEO/WMV' ||
                    file.type.toUpperCase() === 'VIDEO/MP4' ||
                    file.type.toUpperCase() === 'VIDEO/MOV' ||
                    file.type.toUpperCase() === 'IMAGE/PNG' ||
                    file.type.toUpperCase() === 'IMAGE/TIFF' ||
                    file.type.toUpperCase() === 'IMAGE/JPG' ||
                    file.type.toUpperCase() === 'IMAGE/JPEG' ||
                    file.type.toUpperCase() === 'IMAGE/GIF' ||
                    file.type.toUpperCase() === 'IMAGE/EPS' ||
                    file.type.toUpperCase() === 'IMAGE/HEIF' ||
                    file.type.toUpperCase() === 'IMAGE/DNG' ||
                    file.type.toUpperCase() === 'IMAGE/RAW ';

                  if (isVideoOrImage) {
                    message.error(
                      `${file.name} should not be an image or video type.`
                    );
                  }
                  return !isVideoOrImage || Upload.LIST_IGNORE;
                }}
                showUploadList={false}
                onChange={(info: any) => {
                  setFiles((prev) => [
                    ...prev,
                    {
                      id: Math.round(Math.random() * 1000),
                      uid: info.file.uid,
                      lastModified: info.file.lastModified,
                      lastModifiedDate: info.file.lastModifiedDate,
                      name: info.file.name,
                      file: info.file.originFileObj,
                      size: {
                        type: info.file.size < 100000 ? 'KB' : 'MB',
                        size:
                          info.file.size < 100000
                            ? Math.round(info.file.size / 10) / 10
                            : Math.round(info.file.size / 100000) / 10,
                      },
                      type: 'unUploaded',
                      format:
                        info.file.name.split('.')[
                          info.file.name.split('.').length - 1
                        ],
                      webkitRelativePath: info.file.webkitRelativePath,
                    },
                  ]);
                }}
              >
                <Button icon={<FileOutlined />}>{'File'}</Button>
              </Upload>
            </div>
            <div>
              {files &&
                files.map((item: any, idx: number) => {
                  return (
                    <FileItem
                      isEditable={isPNG(item.format)}
                      setFiles={setFiles}
                      files={files}
                      key={item.id}
                      disabled={false}
                      fileData={item.file}
                      name={item.name}
                      id={item.id}
                      format={item.format}
                      type={item.type}
                      file={item}
                      size={item.size}
                      width={'100%'}
                      removeFilePress={removeFilePress}
                      showImagePress={() => showCarouselImage(idx)}
                      onEdit={(rotateImageData: any) => {
                        setIsRotateImageActive(true);
                        setRotateImageData(rotateImageData);
                      }}
                      degree={degree}
                    />
                  );
                })}
            </div>
          </>
        )}

        <br />
        {showCarousel && (
          <CarouselModal
            onCancelPress={() => setShowCarousel(false)}
            isModalVisible={showCarousel}
            imageArray={files}
            defaultIndex={carouselIndex}
          />
        )}
        {editable && (
          <>
            <Button
              disabled={disabled}
              onClick={() => {
                sendComment();
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
              loading={loading}
              className="save-btn"
            >
              {'Answer'}
            </Button>
            <Button
              className="cancel-btn"
              onClick={() => {
                Save(
                  {
                    messageBoardContentStatus: 'List',
                    expandedItem: undefined,
                    conversationStatus: 'notShow',
                  },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
            >
              {'Cancel'}
            </Button>
          </>
        )}
      </Card>
      <RotateImage
        files={files}
        setFiles={setFiles}
        onFinish={() => {
          if (rotateImageData.type === 'uploaded') {
            editComment(
              idOfproject,
              rotateImageData.conversationId,
              rotateImageData.commentId,
              rotateImageData,
              degree
            );
          } else {
            setIsRotateImageActive(false);
            setDegree(0);
          }
        }}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => {
          setDegree((prev: number) => prev + 90);
        }}
        degree={degree}
      />
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The comment has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={'Please Save the form or Cancel changes!'}
          type="warning"
        />
      )}
    </>
  );
};

export default ChatMessages;

import { useEffect, useState } from 'react';
import { ViewProps } from './models';
import { AuthServices } from 'src/api/services';
import { Layout, Row, Col, message } from 'antd';
import { Header, UploadFile } from '../../../components';
import { useRedux } from 'src/hooks';
import { Form, Input,  Button, Card } from 'antd';
import { Alert } from 'ui-kits/index';
import { strings } from 'src/common';
import { ExportSVG } from 'assets/index';
import { UserOutlined } from '@ant-design/icons';
import './styles.css';
import { BASE_URL } from 'src/api/api';

const ProfileView = (props: ViewProps) => {
  const userId = localStorage.getItem('userId');
  const { Get, Save } = useRedux();
  const { successEditProfileModal, errorEditProfileModal, fillProfileWarning } =
    Get().app;
  const { Content } = Layout;

  const [profileImage, setProfileImage] = useState<any>({});
  const [profileSource, setProfileSource] = useState<any>();
  const [pdf, setPdf] = useState<any>();
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const { TextArea } = Input;

  
  useEffect(() => {
    if (profileImage && 'file' in profileImage) {
      getBase64(profileImage.file);
    }
  }, [profileImage]);

  function DataURIToBlob(dataURI: any) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);
    return new Blob([ia], { type: mimeString });
  }

  const getBase64 = (blob: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = function () {
      setProfileSource(reader.result);
    };
    reader.onerror = function (error) {};
  };

  const userDataJson: any = localStorage.getItem('userData');
  const userDataVar = JSON.parse(userDataJson);

  useEffect(() => {
    if (pdf && pdf.type === 'unUploaded') {
      message.success('The file has been successfully uploaded');
    }
  }, [pdf]);
  const saveProfileChange = async (values: any) => {
    const { email, prefName, firstName, lastName, bio, currentPass, newpass } =
      values;
    if (email === '' || prefName === '') {
      Save({ fillProfileWarning: true }, 'app');
      setTimeout(() => {
        Save({ fillProfileWarning: false }, 'app');
      }, 3000);
      return;
    }
    setDisable(true);
    setLoading(true);
    const formData: any = new FormData();
    formData.append('email', email);
    formData.append('preferred_name', prefName);
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('bio', bio);

    if (newpass !== '') {
      formData.append('password', newpass);
    }

    if (profileImage.type === 'unUploaded') {
      formData.append('avatar', profileImage.file);
    }
    if (pdf && pdf.type === 'unUploaded') {
      formData.append('pdf', pdf.file);
    }

    if (userId) {
      const result = await AuthServices.editProfile(
        userId.toString(),
        formData
      );

      if (result) {
        const userData = {
          email: result.data.email,
          role: result.data.role,
          bio: result.data.bio,
          preferredName: result.data.preferred_name,
          firstName: result.data.first_name,
          lastName: result.data.last_name,
          fullname: result.data.first_name + result.last_name,
          avatar: result.data.avatar,
          calender_link: result.data.calendar_link,
          status: result.data.status,
        };

        localStorage.setItem('userData', JSON.stringify(userData));
        setTimeout(() => {
          setDisable(false);
          setLoading(false);
          message.success({ content: 'profile has been edited successfully' });
        }, 2000);
      } else {
        Save({ errorEditProfileModal: true }, 'app');
        if (profileImage.type === 'unUploaded') {
          message.error({ content: 'the size of the file is too large !' });
        }
        setTimeout(() => {
          Save({ errorEditProfileModal: false }, 'app');
          // message.error({ content: "your password may be wrong " });
        }, 1500);
      }
    }
  };
  // useEffect(() => {}, [bio]);

  return (
    <Layout className="home">
      <Header search={false} />
      <Content>
        <p className="profile-header">Profile</p>

        <Row justify="center">
          <div className="profile-container">
            <Card
              className="froms-container"
              title="User Details:"
            >
              <br />
              <Form
                layout="vertical"
                style={{ display: 'flex' }}
                initialValues={{
                  firstName: userDataVar.firstName,
                  lastName: userDataVar.lastName,
                  prefName: userDataVar.preferredName,
                  email: userDataVar.email,
                  bio: userDataVar.bio,
                  currentPass: '',
                  newpass: '',
                  confirmpass: '',
                }}
                onFinish={saveProfileChange}
              >
                <Col
                  xl={18}
                  xs={24}
                >
                  <Row className="first-row">
                    <Col
                      xs={24}
                      xl={4}
                      key={'idx0'}
                    >
                      <Form.Item
                        label={'First Name'}
                        name="firstName"
                      >
                        <Input
                        // onChange={(e) => setfirstName(e.target.value)}
                        // value={firstName}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={4}
                      key={'idx1_'}
                    >
                      <Form.Item
                        label={'Last Name'}
                        name="lastName"
                      >
                        <Input
                        // onChange={(e) => setlastName(e.target.value)}
                        // value={lastName}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={4}
                      key={'idx1___'}
                    >
                      <Form.Item
                        label={'Preferred Name'}
                        name="prefName"
                      >
                        <Input
                        // onChange={(e) => setprefName(e.target.value)}
                        // value={prefName}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={6}
                      key={'idx1_1'}
                    >
                      <Form.Item
                        label={'Email'}
                        name="email"
                      >
                        <Input
                        // onChange={(e) => setEmail(e.target.value)}
                        // value={email}
                        />
                      </Form.Item>
                    </Col>
                    {userDataVar.role !== 'client' && (
                      <Button>
                        <a
                          href={userDataVar.calendar_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Calender
                          <img
                            src={ExportSVG}
                            style={{ width: '15px', marginLeft: '11px' }}
                            alt=""
                          />
                        </a>
                      </Button>
                    )}
                  </Row>

                  <Row className="sec-row">
                    <Col
                      xs={24}
                      xl={6}
                      key={'currentpass'}
                    >
                      <Form.Item
                        label={'Current Password'}
                        name={'currentPass'}
                      >
                        <Input.Password
                        // value={currentPass}
                        // onChange={(e) => setcurrentPass(e.target.value)}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={6}
                      key={'newpass'}
                    >
                      <Form.Item
                        label={'New Password'}
                        name={'newpass'}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>
                    <Col
                      xs={24}
                      xl={6}
                      key={'confirmpass'}
                    >
                      <Form.Item
                        label={'Confirm Password'}
                        name={'confirmpass'}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('newpass') === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error('The two passwords do not match!')
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                    </Col>

                    {/* <Form.Item
                      name="Receives email notifications"
                      valuePropName="checked"
                    >
                      <Checkbox>Receive email notifications</Checkbox>
                    </Form.Item> */}
                  </Row>

                  <Row className="third-row">
                    <Col
                      xl={24}
                      xs={24}
                    >
                      <Form.Item
                        name="bio"
                        label="Bio"
                      >
                        <TextArea
                          // value={bio === null ? "" : bio}
                          // onChange={(e: any) => setBio(e.target.value)//}
                          rows={8}
                        />
                        {/* <span style={{ display: "none" }}>{bio}</span> */}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={4}
                  xs={24}
                  className="upload-profile-col"
                >
                  <div className="avatar-profile-container">
                    {userDataVar.avatar && profileSource === undefined ? (
                      <img
                        src={`${BASE_URL}/${userDataVar.avatar}`}
                        alt=""
                      />
                    ) : profileSource ? (
                      <img
                        src={URL.createObjectURL(DataURIToBlob(profileSource))}
                        alt=""
                      />
                    ) : (
                      <div className="no-avatar-profile">
                        <UserOutlined />
                      </div>
                    )}
                    <br />
                    <UploadFile
                      text="Upload profile photo"
                      setFiles={setProfileImage}
                      hasSingleFile={true}
                      width={100}
                      height={50}
                    />
                    <span
                      style={{
                        fontSize: '12px',
                        marginTop: '10px',
                      }}
                    >
                      Recommended: 500x500
                    </span>
                  </div>
                  <div className="upload-pdf-profile-container">
                    <UploadFile
                      text="Upload bio (pdf)"
                      setFiles={setPdf}
                      hasSingleFile={true}
                      width={'80%'}
                      height={50}
                    />
                  </div>

                  <div className="form-btns-profile">
                    <Button
                      className="cancel-btn"
                      onClick={() => (window.location.pathname = '/home')}
                    >
                      Cancel
                    </Button>
                    <Button
                      htmlType="submit"
                      // onClick={() => saveProfileChange()}
                      loading={loading}
                      disabled={disable}
                    >
                      Save Changes
                    </Button>
                  </div>
                </Col>
              </Form>
            </Card>
          </div>
        </Row>
      </Content>
      {successEditProfileModal && (
        <Alert
          description={strings.successfullyUpdateProfile}
          type="success"
        />
      )}
      {errorEditProfileModal && (
        <Alert
          description={strings.anUnknownErrorOccurredPleaseTryAgainLater}
          type="error"
        />
      )}
      {fillProfileWarning && (
        <Alert
          description={
            'please enter your current password or make sure to fill out all fields'
          }
          type="warning"
        />
      )}
    </Layout>
  );
};

export default ProfileView;

import { memo, useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Slider,
  Checkbox,
  List,
  Spin,
  Pagination,
  Modal,
  InputNumber,
  message,
} from 'antd';
import { Alert } from 'src/ui-kits';
import {
  GoodsServices,
  CategoryServices,
  RoomServices,
  DeleteUploadedFilesServices,
  NotificationsServices,
  ProjectsService,
} from 'api/services/index';
import { useRedux } from 'hooks/index';
import { strings } from 'common/index';

import {
  CarouselModal,
  ConfirmModal,
  FileItem,
  UploadFile,
  CirclePlusButton,
} from 'components/index';
import { FileType, ModalType } from './models';
import { GoodItem } from './components/index';
import './styles.css';
import { RotateImage } from 'src/components/RotateImage';
const GoodsView = () => {
  const { Get, Save } = useRedux();
  const [form] = Form.useForm();
  const { goodsContentStatus, goodsShowDetail, checkboxStatus } = Get().app;
  const role = localStorage.getItem('userRole');
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [count, setCount] = useState(0);
  const [goods, setGoods] = useState<any>([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState<string>();
  const [showCarousel, setShowCarousel] = useState(false);
  const [category, setCategory] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<FileType[]>([]);
  const [whereToKeep, setWhereToKeep] = useState<number | null>(null);
  const [rooms, setRooms] = useState([]);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [name, setName] = useState('');
  const [goodCategories, setgoodCategories] = useState<Array<any>>([]);
  const [tendency, setTendency] = useState(5);
  const [description, setDescription] = useState('');
  const [items, setItems] = useState<any>('');
  const [goodItems, setGoodItems] = useState<any>([]);
  const [itemImage, setItemImage] = useState('');
  const [itemSizes, setItemSizes] = useState([]);
  const [sizes, setSizes] = useState<any>({});
  const [checkStatus, setCheckStatus] = useState(checkboxStatus?.goods);
  const [checkStatusModal, setCheckStatusModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [degree, setDegree] = useState(0);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [rotateImageData, setRotateImageData] = useState(null);
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };
  // By using the url address, we can get the project id
  let path = window.location.pathname;
  let idPath = path.split('/');
  let idOfproject: any = idPath[2];

  //antd
  const { Option } = Select;
  const convertData = (value: any) => {
    let newData = [];
    for (let perRoom of value) {
      let newFiles = [];
      if (perRoom.attachments) {
        for (let file of perRoom.attachments) {
          let newFile = {
            ...file,
            size: {
              type: file.get_attachment_size < 100000 ? 'KB' : 'MB',
              size:
                file.get_attachment_size < 100000
                  ? Math.round(file.get_attachment_size / 10) / 10
                  : Math.round(file.get_attachment_size / 100000) / 10,
            },
          };
          newFiles.push(newFile);
        }
      }

      let newRoom = {
        ...perRoom,
        attachments: newFiles,
      };
      newData.push(newRoom);
    }
    return newData;
  };
  //**** */
  let allCategory: any = [];
  //get rooms
  const getRooms = useCallback(async (id: string, offset = 1) => {
    setLoading(true);
    await RoomServices.roomsList(id, {
      limit: 6,
      offset: (offset - 1) * 6,
    }).then((result) => {
      setCount(result.count);
      setRooms(result.results);
      // setWhereToKeep(result.results);
      Save({ whereToKeepGoods: result.results }, 'app');
      setLoading(false);
    });
  }, []);
  // get goods for goods list
  const getGoods = async (offset = 1) => {
    setLoading(true);
    await GoodsServices.getGoods(idOfproject, {
      limit: 10,
      offset: (offset - 1) * 10,
    }).then((result) => {
      if (result) {
        setCount(result.count);
        setGoods(convertData(result.results));
        setLoading(false);
      }
    });
  };

  const getCategories = async () => {
    await CategoryServices.getCategory().then((res) => {
      if (res) {
        for (let i of res.results) {
          allCategory.push(i);
        }
        setgoodCategories(allCategory);
      }
    });
  };

  useEffect(() => {
    if (!items) {
      findSelectedCategory();
    }
  }, [items, category]);

  const findSelectedCategory = () => {
    if (category) {
      let findCategory: any = goodCategories.filter((e) => e.id === category);
      setGoodItems(findCategory);
    }
  };
  const findImageOfSelectedItem = () => {
    if (goodItems && goodItems.length !== 0) {
      for (let i of goodItems) {
        if (i.items) {
          let filterItem = i.items.find((e: any) => e.id === items);
          if (filterItem && filterItem.image) {
            setItemImage(`${URL}/${filterItem.image}`);
          }
        }
      }
    }
  };
  const findSizesOfSelectedItem = () => {
    if (goodItems && goodItems.length !== 0) {
      for (let i of goodItems) {
        if (i.items) {
          let filterItem = i.items.find((e: any) => e.id === items);
          if (filterItem && filterItem.sizes) {
            let sizes: any = [...filterItem.sizes];
            setItemSizes(sizes);
          }
        }
      }
    }
  };

  useEffect(() => {
    setItems('');
    setSizes({});
    setItemSizes([]);
  }, [category]);
  useEffect(() => {
    findImageOfSelectedItem();
    findSizesOfSelectedItem();
    setSizes({});
  }, [items]);

  useEffect(() => {
    if (idOfproject) {
      getGoods();
      getRooms(idOfproject);
    }
    getCategories();
  }, []);

  const showMore = () => {
    Save({ goodsShowDetail: true }, 'app');
  };

  const createGood = async () => {
    if (category === null || name === '') {
      setModal({ show: true, type: 'warning' });
      if (sizes) {
        let sizesArray: any = Object.keys(sizes);
        for (let i in sizesArray) {
          if (sizes[sizesArray[i]].size === null) {
            message.error({ content: 'Only digits ' });
            return;
          }
        }
      }
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 1500);
      return;
    }
    const formData: any = new FormData();
    setLoading(true);
    setDisabled(true);
    formData.append('name', name);
    formData.append('category', category);
    formData.append('item', items);
    formData.append('description', description);
    formData.append('tendency', tendency);
    formData.append('where_to_keep', [whereToKeep]);

    let result = await GoodsServices.createGoods(formData, idOfproject);
    if (result && (sizes || files?.length !== 0)) {
      const editFormData: any = new FormData();
      if (sizes) {
        let sizesArray: any = Object.keys(sizes);
        for (let i in sizesArray) {
          editFormData.append(`sizes[${i}]size`, sizes[sizesArray[i]]);
          editFormData.append(`sizes[${i}]title`, sizesArray[i]);
        }
      }
      if (files && files.length !== 0) {
        for (let i = 0; i < files.length; i++) {
          if (files[i].type === 'unUploaded') {
            editFormData.append(`attachment[${i}]attachment`, files[i].file);
            editFormData.append(
              `attachment[${i}]attachment_title`,
              files[i].name
            );
            if (files[i].rotate_angle) {
              editFormData.append(
                `attachment[${i}]rotate_angle`,
                files[i].rotate_angle
              );
            }
          }
        }
      }

      await GoodsServices.editGood(idOfproject, result.id, editFormData).then(
        (res) => {
          if (res) {
            getGoods();
            setLoading(false);
            form.resetFields();
            setModal({ show: true, type: 'success' });
            setTimeout(() => {
              setModal((prev) => ({ ...prev, show: false }));
              setDisabled(false);
              Save(
                {
                  goodsContentStatus: 'GoodsList',
                  expandedItem: undefined,
                },
                'app'
              );
            }, 1500);
          } else {
            getGoods();
            setDisabled(false);
            form.resetFields();
            setLoading(false);
            setModal({ show: true, type: 'error' });
            setTimeout(() => {
              setModal((prev) => ({ ...prev, show: false }));
            }, 1500);
          }
        }
      );
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };
  //this function changes rooms_completed field in project data api
  const editStateOfCheckbox = async (isDataComplete: boolean) => {
    setLoading(true);

    let result = await ProjectsService.editProject(idOfproject, {
      goods_completed: isDataComplete,
    });

    if (result) {
      Save(
        {
          checkboxStatus: {
            ...checkboxStatus,
            goods: isDataComplete,
          },
        },
        'app'
      );
      localStorage.setItem('goods_compeleted', `${isDataComplete}`);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const sendCheckStatusGoods = async () => {
    return await NotificationsServices.sendCheck(idOfproject, 'goods')
      .then((res) => {
        if (res) {
          setCheckStatusModal(false);
          setCheckStatus(true);
          setTimeout(() => {
            editStateOfCheckbox(true);
          }, 1000);
        }
      })
      .catch((e) => {
        return Modal.error({
          title: 'something went wrong, please try again later !',
        });
      });
  };

  const TopRow = () => {
    return (
      <div className="topRow-container">
        <>
          <CirclePlusButton
            disabled={
              goodsContentStatus === 'AddNew' ||
              (checkboxStatus?.goods && role === 'client')
            }
            onClick={() =>
              Save({ goodsContentStatus: 'AddNew', expandedItem: 0 }, 'app')
            }
            btnText={strings.addNewGoods}
          />
          <br />
          <span>
            <span className="checkbox-text">
              {"I've added all goods details"}
            </span>{' '}
            <Checkbox
              disabled={
                role !== 'admin'
                  ? goodsContentStatus === 'AddNew' || checkboxStatus?.goods
                    ? true
                    : false
                  : false
              }
              onChange={() => {
                if (!checkStatus) {
                  setCheckStatusModal(true);
                } else {
                  setCheckStatus(false);
                  editStateOfCheckbox(false);
                }
              }}
              checked={checkStatus}
            />
          </span>
        </>
      </div>
    );
  };

  const GoodsList = () => {
    return (
      <div className="goods-list">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {}}
          useWindow={false}
        >
          <List
            dataSource={goods}
            renderItem={(item: any) => {
              return (
                <>
                  <GoodItem
                    category={category}
                    title={item.name}
                    getGoods={getGoods}
                    setRemoveItem={setRemoveItem}
                    setRemoveModal={setRemoveModal}
                    data={item}
                  />
                </>
              );
            }}
          ></List>
        </InfiniteScroll>
        {count !== 0 && (
          <div className="pagination-container">
            <Pagination
              responsive={true}
              current={count}
              total={count}
              defaultCurrent={1}
              pageSize={10}
              onChange={(e) => getGoods(e)}
            />
          </div>
        )}
      </div>
    );
  };

  const removeGood = async () => {
    if (!removeItem) {
      return;
    }
    await GoodsServices.deleteGoodsById(
      idOfproject,
      removeItem.toString()
    ).then(() => {
      getGoods();
      setRemoveModal(false);
    });
  };
  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }
    let newgoodsImage = [...files];
    let typeOfFile = newgoodsImage.find(function (value: any) {
      return value.id === fileId;
    });
    let editedgoodsImage = newgoodsImage.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'good_attachment',
        fileId
      );
      if (result) {
        setFiles(editedgoodsImage);
      }
    } else {
      setFiles(editedgoodsImage);
    }
  };

  const isFieldsTouched = form.isFieldsTouched([
    'category',
    'items',
    'name',
    'keep',
    'tendency',
    'size',
    'description',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <div className="goods-container">
      {role !== 'designer' && role !== 'concierge' && <TopRow />}
      {goodsContentStatus === 'AddNew' && (
        <Card className="froms-container">
          <Form
            layout="vertical"
            form={form}
          >
            <Row className="row1-goods">
              <Col
                xs={24}
                xl={8}
                key={'idx1'}
              >
                <Form.Item
                  label={'Category'}
                  name="category"
                  rules={[{ required: true, message: 'filed is required' }]}
                >
                  <Select
                    style={{ width: '100%' }}
                    placeholder="BookCase Bed"
                    onChange={(value: string) => setCategory(value)}
                    value={category}
                  >
                    {goodCategories &&
                      goodCategories.length !== 0 &&
                      goodCategories.map((category) => {
                        return (
                          <Option
                            value={category.id}
                            key={category.id}
                          >
                            {category.category}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                xl={8}
                key={'idx2'}
              >
                <Form.Item
                  label={'Items'}
                  name="items"
                  rules={[{ required: true, message: 'filed is required' }]}
                >
                  <Select
                    value={items}
                    style={{ width: '100%', textTransform: 'capitalize' }}
                    onChange={(e: any) => setItems(e)}
                  >
                    {category &&
                      goodItems.length !== 0 &&
                      goodItems.map(
                        (item: any) =>
                          item.items &&
                          item.items.map((i: any) => {
                            return (
                              <Option
                                key={i.id}
                                value={i.id}
                                style={{ textTransform: 'capitalize' }}
                              >
                                {i.item}
                              </Option>
                            );
                          })
                      )}
                  </Select>
                </Form.Item>
              </Col>
              <Col
                xs={24}
                xl={8}
              >
                <Button onClick={showMore}>Show Item Details</Button>
              </Col>
            </Row>
            {goodsShowDetail && (
              <>
                <Row className="row-more-details">
                  <Col
                    xl={8}
                    xs={24}
                    className="col-more-details"
                  >
                    <Form.Item
                      label={'Name'}
                      name="name"
                      rules={[{ required: true, message: 'filed is required' }]}
                    >
                      <Input
                        style={{ width: '100%' }}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Bed, inherited from my grandma"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    xl={8}
                    key={'idx1'}
                  >
                    <Form.Item
                      label={'Where to keep'}
                      name="keep"
                    >
                      <Select
                        defaultValue={'Designer choice'}
                        onChange={(e: any) => {
                          setWhereToKeep(e);
                        }}
                        style={{ width: '100%' }}
                      >
                        {rooms &&
                          rooms.length !== 0 &&
                          rooms.map((item: any) => {
                            return (
                              <Option
                                key={item.id}
                                value={item.id}
                              >
                                {item.name}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xl={8}
                    xs={24}
                    className="sec-col-goods"
                  >
                    <div className="slider-form">
                      <Form.Item
                        label={'My tendency to keep this Item (0 to 10)'}
                        name="tendency"
                      >
                        <Slider
                          trackStyle={
                            tendency <= 5
                              ? {
                                  background:
                                    'linear-gradient(to right, #ffffff 0%, rgb(241 154 198) 100%)',
                                }
                              : tendency > 5 && tendency <= 8
                              ? {
                                  background:
                                    'linear-gradient(to right, #ffffff 0%, rgb(255 51 153 / 57%)  100%)',
                                }
                              : tendency >= 9
                              ? {
                                  background:
                                    'linear-gradient(to right, #ffffff 0%, #ff3399 100%)',
                                }
                              : { background: 'red' }
                          }
                          min={0}
                          max={10}
                          tooltipVisible
                          defaultValue={5}
                          onChange={(value) => setTendency(value)}
                          value={typeof tendency === 'number' ? tendency : 0}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>{' '}
                {itemImage && itemImage !== '' && (
                  <Row>
                    <Col
                      className="goods-image"
                      xl={10}
                      xs={24}
                    >
                      <img
                        src={itemImage}
                        alt=""
                      />
                    </Col>
                    <Col
                      xl={12}
                      xs={12}
                      className="measure-row"
                    >
                      {itemSizes &&
                        itemSizes.length !== 0 &&
                        itemSizes.map((size, idx: any) => (
                          <div
                            key={idx}
                            className="dimentions"
                          >
                            <Form
                              layout="vertical"
                              name="dimentions"
                            >
                              <Form.Item
                                label={size + '(in)'}
                                name="size"
                                rules={[
                                  {
                                    required: true,
                                    message: 'Only digits',
                                  },
                                  {
                                    type: 'number',
                                    message: 'Only digits',
                                  },
                                ]}
                              >
                                <InputNumber
                                  min={0}
                                  onChange={(e) => {
                                    setSizes((prevSize: any) => {
                                      let newSizes: any = { ...prevSize };
                                      newSizes[size] = Number(e);
                                      return newSizes;
                                    });
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          </div>
                        ))}
                    </Col>
                  </Row>
                )}
                <Row className="description-row">
                  <Col
                    xl={24}
                    xs={24}
                  >
                    <Form.Item
                      name="description"
                      label="Description"
                    >
                      <Input.TextArea
                        value={description}
                        rows={6}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={24}
                    xl={24}
                  >
                    <div className="upload-file-container">
                      <UploadFile
                        title={'Upload photos of your goods'}
                        text="Drag and drop files here"
                        setFiles={setFiles}
                        width={'100%'}
                        height={100}
                      />

                      <br />
                      <div className="file-items-container">
                        {files &&
                          files.map((item: any, idx: number) => {
                            const { id, name, size, type, format } = item;
                            const isPNG =
                              format.toUpperCase() === 'PNG' ||
                              format.toUpperCase() === 'TIFF' ||
                              format.toUpperCase() === 'JPG' ||
                              format.toUpperCase() === 'JPEG' ||
                              format.toUpperCase() === 'GIF' ||
                              format.toUpperCase() === 'EPS' ||
                              format.toUpperCase() === 'HEIF' ||
                              format.toUpperCase() === 'DNG' ||
                              format.toUpperCase() === 'RAW';
                            return (
                              <FileItem
                                isEditable={isPNG}
                                key={id}
                                format={format}
                                disabled={
                                  role === 'client'
                                    ? checkboxStatus?.goods
                                    : false
                                }
                                fileData={item.file.attachment}
                                name={type === 'unUploaded' && name}
                                id={id}
                                file={item}
                                size={size}
                                type={type}
                                width={'99%'}
                                removeFilePress={removeFilePress}
                                showImagePress={() => showCarouselImage(idx)}
                                onEdit={(rotateImageData: any) => {
                                  setIsRotateImageActive(true);
                                  setRotateImageData(rotateImageData);
                                }}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Form.Item>
              <div className="goods-btns">
                <Button
                  onClick={() => createGood()}
                  className="save-btn"
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  disabled={disabled}
                >
                  {strings.save}
                </Button>
                <Button
                  className="cancel-btn"
                  onClick={() => {
                    Save(
                      {
                        goodsContentStatus: 'GoodsList',
                        expandedItem: undefined,
                      },
                      'app'
                    );
                    localStorage.setItem(
                      'isFieldsTouched',
                      JSON.stringify(false)
                    );
                  }}
                >
                  {strings.cancel}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Card>
      )}
      {loading ? (
        <Spin size="small" />
      ) : (
        (goodsContentStatus === 'GoodsList' ||
          goodsContentStatus === 'editGoods' ||
          goodsContentStatus === 'NotShow') && <GoodsList />
      )}

      <ConfirmModal
        deleteModal={true}
        handleOk={removeGood}
        handleCancel={() => setRemoveModal(false)}
        isModalVisible={removeModal}
        okText={'Cancel'}
        cancelText={'Yes,delete this good'}
        questionTopText={
          'This action can not be undone. Are you sure you want to continue?'
        }
        questionText={''}
      />

      <ConfirmModal
        deleteModal={false}
        handleOk={async () => {
          await sendCheckStatusGoods();
        }}
        handleCancel={() => setCheckStatusModal(false)}
        isModalVisible={checkStatusModal}
        okText={strings.uppercaseCancel}
        cancelText={'YES,I have added all my goods'}
        questionTopText={'Are you sure that you have added all your goods? '}
        questionText={''}
      />
      <RotateImage
        files={files}
        setFiles={setFiles}
        onFinish={() => setIsRotateImageActive(false)}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      />
      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The good has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </div>
  );
};

export default memo(GoodsView);

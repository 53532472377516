import { useState } from 'react';
import { Form, Input, Col, Row, Select, Button, Modal, Checkbox } from 'antd';
import { AuthServices } from 'src/api/services';
import { useRedux } from 'src/hooks';
import { ModalType } from 'src/screens/other/AddProject/tabs/Rooms/models';
import { Alert } from 'src/ui-kits';
import { strings } from 'src/common';
const UserFormEditMood = ({ data, getUsers, referrer }: any) => {
  const { Save } = useRedux();
  const { Option } = Select;
  const [firstName, setFirstName] = useState(data.first_name);
  const [lastName, setLastName] = useState(data.last_name);
  const [status, setStatus] = useState(data.status);
  const [email, setEmail] = useState(data.email);
  const [password, setPassword] = useState('');
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [role, setRole] = useState(data.role);
  const [reffer, setReffer] = useState<any>(
    data.referrer.id ? data.referrer : []
  );
  const [bio, setBio] = useState(data.bio);
  const [prefferedName, setPrefferedName] = useState(data.preferred_name);
  const [calenderlink, setcalenderlink] = useState(data.calendar_link);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReffer, setIsReffer] = useState(data.is_referrer);
  let selectedReferrerId: any = [];
  const userId = data.id;
  let userInformation: any = {};
  const [form] = Form.useForm();

  let hasPassword = password && password !== '';
  const editUser = async (value: any) => {
    setLoading(true);
    if (hasPassword && reffer.length === 0) {
      userInformation = {
        ...value,
        password: password,
        status: status,
        is_referrer: isReffer,
        calendar_link: value.calendar_link ? value.calendar_link : '',
      };
      delete userInformation.referrer;
    } else if (reffer.length !== 0 && !hasPassword) {
      userInformation = {
        ...value,
        referrer: reffer.id ? [reffer.id] : reffer,
        status: status === 'active' ? true : false,
        is_referrer: isReffer,
        calendar_link: value.calendar_link ? value.calendar_link : '',
      };
      delete userInformation.password;
    } else if (
      (reffer.length > 0 && hasPassword) ||
      (value.referrer !== '' && hasPassword)
    ) {
      userInformation = {
        ...value,
        referrer: reffer.id ? [reffer.id] : reffer,
        status: status,
        is_referrer: isReffer,
        calendar_link: value.calendar_link ? value.calendar_link : '',
        password: password,
      };
    } else {
      userInformation = { ...value };
    }
    await AuthServices.editUserByID(userId.toString(), userInformation).then(
      (res) => {
        if (res) {
          setShowModal(false);
          setLoading(false);
          setModal({ show: true, type: 'success' });
          setTimeout(() => {
            setModal((prev) => ({ ...prev, show: false }));
            Save({ userStatusConten: 'notShow' }, 'app');
            getUsers();
          }, 2000);
        } else {
          setLoading(false);
          setModal({ show: true, type: 'error' });
          setTimeout(() => {
            // setDisabled(false);
            setModal((prev) => ({ ...prev, show: false }));
          }, 2500);
        }
      }
    );
  };

  const handleChangeReferrer = (value: any) => {
    selectedReferrerId.push(value);
    setReffer(selectedReferrerId);
  };

  return (
    <div className="user-form-container ">
      <Form
        layout="vertical"
        onFinish={editUser}
        form={form}
        initialValues={{
          first_name: data.first_name,
          preferred_name: prefferedName,
          role: role,
          email: email,
          status: data.status ? 'active' : 'deactive',
          referrer: data.referrer.preferred_name
            ? data.referrer.preferred_name
            : data.referrer.last_name
            ? data.referrer.first_name + data.referrer.last_name
            : '',
          last_name: data.last_name,
          bio: data.bio,
          calendar_link: data.calendar_link,
        }}
      >
        <Row>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              name={'first_name'}
              label={'First Name'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              name={'last_name'}
              label={'Last Name'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={5}
          >
            <Form.Item
              label={'Preffered Name'}
              name={'preferred_name'}
              // rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                defaultValue={prefferedName}
                value={prefferedName}
                onChange={(e) => setPrefferedName(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={7}
          >
            <Form.Item
              label={'Email'}
              name={'email'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                defaultValue={email}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              label={'status'}
              name="status"
            >
              <Select onChange={(e) => setStatus(e)}>
                <Option value={true}>Active</Option>
                <Option value={false}>Deactive</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col
            xs={24}
            xl={4}
            className="password-box"
          >
            <Form.Item label={'New Password'}>
              <Input.Password
                defaultValue={password}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              label={'Role'}
              name={'role'}
              rules={[{ required: true, message: 'Field is required' }]}
            >
              <Select
                onChange={(e) => setRole(e)}
                value={role}
                defaultValue={role}
              >
                <Option value={'admin'}>Admin</Option>
                <Option value="designer">Designer </Option>
                <Select.Option value={'client'}>Client</Select.Option>
                <Select.Option value={'concierge'}>Concierge</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={8}
          >
            <Form.Item
              label={'Calendar Link'}
              name="calendar_link"
            >
              <Input
                placeholder={''}
                value={calenderlink}
                onChange={(e) => setcalenderlink(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            xl={4}
          >
            <Form.Item
              label={'Refferer'}
              name="referrer"
            >
              <Select onChange={(e) => handleChangeReferrer(e)}>
                {referrer &&
                  referrer.length !== 0 &&
                  referrer.map((ref: any) => {
                    return (
                      <Option
                        key={ref.id}
                        value={ref.id}
                      >
                        {ref.preferred_name ?ref.preferred_name:ref.first_name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col
            className="checkbox-referrer"
            xs={24}
            xl={4}
          >
            <Checkbox
              defaultChecked={data.is_referrer}
              onChange={(e) => setIsReffer(e.target.checked)}
            />
            <span>This user refers clients</span>
          </Col>
          <Col
            xl={24}
            xs={24}
          >
            <Form.Item
              label="Bio"
              name={'bio'}
            >
              <Input.TextArea
                value={bio}
                rows={4}
                onChange={(e) => setBio(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <div className="form-btns">
            <Button
              className='cancel-btn'
              onClick={() => Save({ userStatusConten: 'notShow' }, 'app')}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              htmlType="submit"
              className='save-btn'
              onClick={() => setShowModal(true)}
              disabled={
                form.getFieldsError().filter(({ errors }) => errors.length)
                  .length > 0
              }
            >
              Edit User
            </Button>
          </div>
        </Row>
      </Form>
      {/* <ConfirmModal
        questionTopText={'You are about to edit this user. Are you sure? '}
        questionText={''}
        cancelText={'Yes edit user'}
        okText={'Cancel'}
        isModalVisible={showModal}
        handleOk={() => editUser()}
        handleCancel={() => setShowModal(false)}
      /> */}

      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The user has been edited successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </div>
  );
};

export default UserFormEditMood;

import { api } from "api/api";

import { apiErrorMessage } from "../../helpers/apiErrorHandling.js";
const ENDPOINTS = {
  timelines: "/projects/{project_pk}/time-lines/",
  timlinesById: "/projects/{project_pk}/time-lines/{id}/",
  timelineTitles: "/time-line-titles/",
};
export const TimelinesServises = {
  createTimelines: async (
    data: any,
    project_pk: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .post(ENDPOINTS.timelines.replace("{project_pk}", project_pk), data, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("TimelinesServices -> createTimelines: ", err);
        return null;
      });
  },
  getTimelines: async (
    project_pk: string,
    params: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(
        ENDPOINTS.timelines.replace("{project_pk}", project_pk),
        params,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("TimelinesServices -> getTimelines: ", err);
        return null;
      });
  },

  getTimelineById: async (
    project_pk?: number,
    timeline_id?: number,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(`/projects/${project_pk}/time-lines/${timeline_id}`, null, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("TimelinesServices -> getTimelines by id: ", err);
        return null;
      });
  },

  deleteTimelineById: async (
    project_pk: string,
    id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        ENDPOINTS.timlinesById
          .replace("{project_pk}", project_pk)
          .replace("{id}", id),
        null,
        true
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage(
              "TimelinesServices -> delete Timelines by id: ",
              err
            );
        return null;
      });
  },
  getTimelineTitles: async (badRequestCallback?: (err: any) => void) => {
    return await api
      .get(ENDPOINTS.timelineTitles)
      .then((response) => {
        return response.data.results;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("TimelinesServices -> getTimelines: ", err);
        return null;
      });
  },
  editTimeline: async (
    project_pk: string,
    timeline_id: string,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        ENDPOINTS.timlinesById
          .replace("{project_pk}", project_pk)
          .replace("{id}", timeline_id),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage("TimelinesServices -> getTimelines by id: ", err);
        return null;
      });
  },
};

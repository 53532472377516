import { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, Card, message } from 'antd';
import { ExportSVG } from 'assets/index';
import { strings } from 'common/index';
import { ProjectsService } from 'src/api/services';
import { ConfirmModal } from 'components/index';
import { useRedux } from 'src/hooks';
import { Alert } from 'src/ui-kits';
import { useForm } from 'antd/lib/form/Form';
const PinterestInspearion = () => {
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [disabled, setDisabled] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [pinterestLink, setpinterestLink] = useState('');
  const [pinterestDescription, setpinterestDescription] = useState('');
  const [loading, setLoading] = useState(false);

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const { Save } = useRedux();
  const { TextArea } = Input;

  let pinterestData = {
    board_link: pinterestLink,
    description: pinterestDescription,
  };

  const addPinterest = async () => {
    if (
      pinterestLink === undefined ||
      pinterestLink === '' ||
      pinterestDescription === '' ||
      pinterestDescription === undefined
    ) {
      setLoading(false);
      setSaveModal(false);
      setDisabled(true);
      setTimeout(() => {
        setModal({ show: false, type: 'warning' });
      }, 2000);
      return null;
    }
    setDisabled(true);
    setLoading(true);
    setSaveModal(false);
    if (pinterestLink.toLocaleLowerCase().includes('https://')) {
      await ProjectsService.addPinterestBoardLink(
        idOfproject,
        pinterestData
      ).then((res) => {
        if (res) {
          setLoading(false);
          setModal({ show: true, type: 'success' });
          setTimeout(() => {
            setDisabled(false);
            Save(
              {
                insprationContentStatus: 'insprationList',
                checkStatusInspration: 'notSelected',
                expandedItem: undefined,
              },
              'app'
            );
            setModal((prev) => ({ ...prev, show: false }));
          }, 1500);
        } else {
          setDisabled(false);
          setLoading(false);
          setSaveModal(false);

          setModal({ show: true, type: 'error' });
          setTimeout(() => {
            message.error({
              content:
                'The link should start with https:// , something like https://goodspaceplan.com/',
            });
            // setModal((prev) => ({ ...prev, show: false }));
          }, 3000);
        }
      });
    } else {
      setDisabled(false);
      message.error({
        content:
          'The link should start with https:// , something like https://goodspaceplan.com/',
      });
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const [form] = useForm();

  const isFieldsTouched = form.isFieldsTouched(['boardLink', 'description']);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <Card className="form-container-inspiration">
      <div className="pinterest-container">
        <Form
          layout="vertical"
          form={form}
        >
          <Row className="second-row">
            <Col
              xs={24}
              xl={24}
              key={'id1'}
            >
              <Form.Item
                label={strings.boardLink}
                name="boardLink"
              >
                <div className="input-icon-container">
                  <Input
                    value={pinterestLink}
                    onChange={(e) => setpinterestLink(e.target.value)}
                  />

                  <a
                    href={pinterestLink}
                    target={'_blank'}
                    rel="noopener noreferrer"
                  >
                    <img
                      src={ExportSVG}
                      alt=""
                    />
                  </a>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Row className="third-row">
            <Col
              xs={24}
              xl={24}
              key={'id1'}
            >
              <Form.Item
                label={strings.description}
                name="description"
              >
                <TextArea
                  rows={5}
                  value={pinterestDescription}
                  onChange={(e) => setpinterestDescription(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              onClick={() => {
                setSaveModal(true);
              }}
              className="save-btn"
              htmlType="submit"
              disabled={disabled}
              loading={loading}
            >
              {strings.save}
            </Button>
            <Button
              className="cancel-btn"
              onClick={() => {
                Save(
                  {
                    insprationContentStatus: 'notShowInspration',
                    checkStatusInspration: 'notSelected',
                    expandedItem: undefined,
                  },
                  'app'
                );
                localStorage.setItem('isFieldsTouched', JSON.stringify(false));
              }}
            >
              {strings.cancel}
            </Button>
          </Form.Item>
        </Form>
      </div>
      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The inspiration has been added successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={'All inputs must be filled out'}
          type="warning"
        />
      )}
      <ConfirmModal
        okText={'Cancel'}
        cancelText={' Yes board is public '}
        questionText={
          'Please make sure the board is public. Otherwise we can’t access it! '
        }
        isModalVisible={saveModal}
        handleOk={() => addPinterest()}
        handleCancel={() => setSaveModal(false)}
      />
    </Card>
  );
};

export default PinterestInspearion;

import { memo } from "react";
import { useRedux } from "src/hooks";
import { LongItem } from "components/index";
import ProjectData from "../ProjectData/index";
import AddInspiration from "../AddInspiration/Inspration";
import { InspirationList } from "..";
import AddInpirationComponent from "./AddInpirationComponent";
import ClientInfoItem from "../ClientInformations/ClientInfoItem";
const ProjectItem = (props: any) => {
  const { data, setRemoveModal, setRemoveItem, title, getProject } = props;
  const { Get } = useRedux();
  const {
    expandedItem,
    insprationContentStatus,
    projectDataContentStatus,
  } = Get().app;

  return (
    <>
      {expandedItem === data.id ? (
        <>
          <ProjectData data={data} getProject={getProject} />
        </>
      ) : (
        <LongItem
          setRemoveItem={setRemoveItem}
          setRemoveModal={setRemoveModal}
          type={"ProjectData"}
          data={data}
          title={title}
        />
      )}

      <ClientInfoItem
        data={data}
        title={"Client Information"}
        getProject={getProject}
      />
      <div className="inspiration-container">
        <AddInpirationComponent />
        {insprationContentStatus === "addInspration" && (
          <AddInspiration data={data} />
        )}
        {projectDataContentStatus === "EditProjectDetail" && (
          <InspirationList />
        )}
      </div>
    </>
  );
};

export default memo(ProjectItem);

//@ts-nocheck
import React, { useEffect, useState, useCallback } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  List,
  Row,
  Select,
  Spin,
  //Pagination,
} from 'antd';
import moment from 'moment';
import { CirclePlusButton, ConfirmModal, TerminalItem } from 'components/index';
import { ModalType } from './models';
import { strings } from 'common/index';
import { Alert } from 'ui-kits/index';

import InfiniteScroll from 'react-infinite-scroller';

import { useRedux } from 'src/hooks';
import { TimelinesServises, ProjectsService } from 'src/api/services';
import './styles.css';
import { useForm } from 'antd/lib/form/Form';

const TimeLineView = (props: any) => {
  const { Get, Save } = useRedux();
  const { terminalContentStatus } = Get().app;

  const [removeModal, setRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState<number>();
  const [count, setCount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [title, setTitle] = useState<any>(0);
  const [titles, setTitles] = useState<any>([]);
  const [dueDate, setDuedate] = useState<any>('');
  const [keyPerson, setKeyPerson] = useState<any>(0);
  const [complete, setComplete] = useState(false);
  const { Option } = Select;
  const [timelines, setTimelines] = useState([]);
  const [usersAssignedProject, setUsersAssaignedProject] = useState<any>([]);
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });

  let path = window.location.pathname;
  let idPath = path.split('/');
  let idOfproject: string = idPath[2];

  const [form] = useForm();
  // const [assignedProject, setAssaignedProject] = useState<any>([]);
  const projectUserList = async (offset = 1) => {
    await ProjectsService.getProjectUsers(
      idOfproject
      //   , {
      //   limit: 20,
      //   offset: (offset - 1) * 20,
      // }
    ).then((res) => {
      if (res && res.users) {
        setUsersAssaignedProject(res.users);
      }
    });
  };
  const getTimelinetitles = async () => {
    await TimelinesServises.getTimelineTitles().then((res) => {
      if (res) {
        setTitles(res);
      }
    });
  };

  const getTimelines = useCallback(async (offset = 1) => {
    setLoading(true);
    let res = await TimelinesServises.getTimelines(idOfproject, {});
    if (res) {
      setCount(res.count);
      setTimelines(res.results);
      setLoading(false);
      Save({ terminalContentStatus: 'List' }, 'app');
    }
  }, []);

  const createTimelines = async () => {
    setLoading(true);
    if (title === 0 || keyPerson === 0 || dueDate === '') {
      setModal({ show: true, type: 'warning' });
      setTimeout(() => {
        setModal((prev) => ({ ...prev, show: false }));
      }, 1500);
      return;
    }
    setDisabled(true);
    let year = dueDate._d.getFullYear();
    let month = (dueDate._d.getMonth() + 1).toString().padStart(2, '0');
    let day = dueDate._d.getDate().toString().padStart(2, '0');
    let newDate = year + '-' + month + '-' + day;

    const timelineItems = {
      title_list: title,
      key_person: keyPerson,
      due_date: newDate,
      complete: complete,
    };

    if (idOfproject) {
      await TimelinesServises.createTimelines(timelineItems, idOfproject)
        .then((res) => {
          if (res) {
            setLoading(false);
            setModal({ show: true, type: 'success' });
            form.resetFields();
            setTimeout(() => {
              setModal((prev) => ({ ...prev, show: false }));
              getTimelines(Number(idOfproject));
              setDisabled(false);
              Save(
                {
                  terminalContentStatus: 'List',
                  expandedItem: undefined,
                },
                'app'
              );
            }, 2000);
          }
        })
        .catch(() => {
          setModal({ show: true, type: 'error' });
          setTimeout(() => {
            setDisabled(false);
            setModal((prev) => ({ ...prev, show: false }));
          }, 1500);
        });
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };
  const StatusList = () => {
    return (
      <>
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {}}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          <List
            dataSource={timelines}
            renderItem={(item) => (
              <TerminalItem
                item={item}
                setRemoveItem={setRemoveItem}
                setRemoveModal={setRemoveModal}
                timelines={timelines}
                titles={titles}
                getTimelines={getTimelines}
                usersAssignedProject={usersAssignedProject}
              />
            )}
          >
            {loading && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
      </>
    );
  };

  const removeTimeline = async () => {
    if (!removeItem) {
      return;
    }
    let res = await TimelinesServises.deleteTimelineById(
      idOfproject,
      removeItem.toString()
    );
    if (res) {
      Save(
        {
          terminalContentStatus: 'List',
          expandedItem: undefined,
        },
        'app'
      );

      getTimelines(Number(idOfproject));
      setRemoveModal(false);
    }
  };
  useEffect(() => {
    getTimelinetitles();
    projectUserList();
    getTimelines();
  }, []);

  const isFieldsTouched = form.isFieldsTouched([
    'title',
    'duedate',
    'keyperson',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      {localStorage.getItem('userRole') === 'admin' && (
        <div>
          <CirclePlusButton
            disabled={terminalContentStatus === 'Add'}
            onClick={() =>
              Save({ terminalContentStatus: 'Add', expandedItem: 0 }, 'app')
            }
            btnText={'Add New Status'}
          />
        </div>
      )}
      <div className="timeline-container">
        {terminalContentStatus === 'Add' && (
          <>
            <Card className="froms-container">
              <Form
                layout="vertical"
                form={form}
              >
                <Row className="first-row">
                  <Col
                    xs={24}
                    xl={8}
                    key={'idx'}
                  >
                    <Form.Item
                      label={strings.title}
                      name={'title'}
                      rules={[{ required: true, message: 'field is required' }]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        value={title}
                        onChange={(value) => setTitle(value)}
                      >
                        {titles &&
                          titles.length !== 0 &&
                          titles.map((item: any) => (
                            <Option value={item.id}>{item.title}</Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx1'}
                  >
                    <Form.Item
                      label={strings.duedate}
                      name={'duedate'}
                      rules={[{ required: true, message: 'field is required' }]}
                    >
                      <DatePicker
                        value={moment(dueDate, 'YYYY-MM-DD')}
                        onChange={(dateString) => setDuedate(dateString)}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx2'}
                  >
                    <Form.Item
                      label={strings.keyPerson}
                      name={'keyperson'}
                      rules={[{ required: true, message: 'field is required' }]}
                    >
                      <Select
                        style={{ width: '100%' }}
                        onChange={(e) => setKeyPerson(e)}
                      >
                        {usersAssignedProject &&
                          usersAssignedProject.length !== 0 &&
                          usersAssignedProject.map((user: any, idx: number) => {
                            return (
                              <Option
                                value={user.id}
                                key={idx}
                              >
                                {user.preferred_name
                                  ? user.preferred_name
                                  : user.email}
                                -{user.role}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col
                    xs={24}
                    xl={4}
                    key={'idx3__'}
                  >
                    <div className="complete-container">
                      <span>{strings.complete}</span>
                      <Checkbox
                        onChange={(e) => setComplete(e.target.checked)}
                      />
                    </div>
                  </Col>
                </Row>
                <Form.Item shouldUpdate>
                  <Button
                    onClick={() => createTimelines()}
                    className="save-btn"
                    htmlType="submit"
                    disabled={
                      form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length > 0 ||
                      disabled
                    }
                  >
                    {strings.save}
                  </Button>
                  <Button
                    className="cancel-btn"
                    onClick={() => {
                      Save(
                        {
                          terminalContentStatus: 'List',
                          expandedItem: undefined,
                        },
                        'app'
                      );
                      localStorage.setItem(
                        'isFieldsTouched',
                        JSON.stringify(false)
                      );
                    }}
                  >
                    {strings.cancel}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </>
        )}

        {loading ? (
          <div className="spin-container">
            <Spin size="small" />
          </div>
        ) : (
          (terminalContentStatus === 'List' ||
            terminalContentStatus === 'edit' ||
            terminalContentStatus === 'NotShow') && <StatusList />
        )}
        <ConfirmModal
          deleteModal={true}
          handleOk={removeTimeline}
          handleCancel={() => setRemoveModal(false)}
          isModalVisible={removeModal}
          okText={strings.uppercaseCancel}
          cancelText={'YES, DELETE TIMELINE'}
          questionTopText={strings.thisActionCanNotBeUndoneAreYouSure}
          questionText={''}
        />
        {modal.type === 'success' && modal.show && (
          <Alert
            description={'Timeline has been added successfully'}
            type="success"
          />
        )}
        {modal.type === 'error' && modal.show && <Alert type="error" />}
        {modal.type === 'warning' && modal.show && (
          <Alert
            description={strings.pleaseFillAllInputs}
            type="warning"
          />
        )}
      </div>
    </>
  );
};

export default React.memo(TimeLineView);

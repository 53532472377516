import { memo, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Slider,
  InputNumber,
  message,
} from 'antd';
import {
  GoodsServices,
  RoomServices,
  DeleteUploadedFilesServices,
  CategoryServices,
} from 'api/services/index';
import { useRedux } from 'hooks/index';
import { strings } from 'common/index';
import { FileItem, UploadFile, CarouselModal } from 'components/index';
import { FileType } from './models';
import { Alert } from 'src/ui-kits';
import { RotateImage } from 'src/components/RotateImage';
import CollapseItems from 'src/components/collapseItems';
const EditDetails = ({ data, getGoods, category, attachments }: any) => {
  // redux
  const { Save, Get } = useRedux();
  const { TextArea } = Input;
  //type
  type SizesTypes = {
    title: string;
    size: number;
  };
  type ModalType = {
    show: boolean;
    type: 'success' | 'warning' | 'error';
  };
  //states
  const [degree, setDegree] = useState(0);
  const [isRotateImageActive, setIsRotateImageActive] = useState(false);
  const [rotateImageData, setRotateImageData] = useState(null);
  const { checkboxStatus, editable } = Get().app;
  const [modal, setModal] = useState<ModalType>({
    show: false,
    type: 'success',
  });
  const [form] = Form.useForm();
  const [categoryName, setcategoryName] = useState('');
  const [rooms, setRooms] = useState<any>([]);
  const [files, setFiles] = useState<FileType[]>(attachments);
  const [whereToKeep, setWhereToKeep] = useState<any>(data.where_to_keep.id);
  const [name, setName] = useState(data.name);
  const [tendency, setTendency] = useState(data.tendency);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [description, setDescription] = useState(data.description);
  const [loading, setLoading] = useState(false);
  const [sizes, setSizes] = useState<Array<SizesTypes>>(data.sizes);
  const [disabled, setDisabled] = useState(false);
  //role
  let role = localStorage.getItem('userRole');
  // By using the url address, we can get the project id
  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  const getRooms = useCallback(async (id: string, offset = 1) => {
    setLoading(true);
    await RoomServices.roomsList(id, {
      limit: 6,
      offset: (offset - 1) * 6,
    }).then((result) => {
      setRooms(result.results);
      setLoading(false);
    });
  }, []);
  // ***********
  useEffect(() => {
    getRooms(idOfproject);
  }, []);

  useEffect(() => {}, [files]);

  // select component antd
  const { Option } = Select;
  const showCarouselImage = (index: number) => {
    setShowCarousel(true);
    setCarouselIndex(index);
  };

  const editGood = async () => {
    setLoading(true);
    setDisabled(true);
    const formData: any = new FormData();
    if (category === null || name === '') {
      message.warning({ content: 'Please fill all inputs' });
      return;
    }
    if (files.length !== 0) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].type === 'unUploaded') {
          formData.append(`attachment[${i}]attachment`, files[i].file);
          formData.append(`attachment[${i}]attachment_title`, files[i].name);

          if (files[i].rotate_angle) {
            formData.append(
              `attachment[${i}]rotate_angle`,
              files[i].rotate_angle
            );
          }
        }
        if (files[i].type === 'uploaded') {
          formData.append(`attachment[${i}]id_number`, files[i].id);
          formData.append(
            `attachment[${i}]rotate_angle`,
            files[i].rotate_angle
          );
        }
      }
    }
    if (sizes) {
      let sizesArray: any = Object.keys(sizes);
      for (let i in sizesArray) {
        if (sizes[sizesArray[i]].size === null) {
          message.error({ content: 'Only digits ' });
          setDisabled(false);
          setLoading(false);
          return;
        } else {
          formData.append(`sizes[${i}]size`, sizes[sizesArray[i]].size);
          formData.append(`sizes[${i}]title`, sizes[sizesArray[i]].title);
        }
      }
    }
    formData.append('name', name);
    formData.append('description', description);
    formData.append('tendency', tendency);
    if (whereToKeep === undefined) {
      formData.append('where_to_keep', '');
    } else {
      formData.append('where_to_keep', whereToKeep);
    }

    let result = await GoodsServices.editGood(idOfproject, data.id, formData);

    if (result) {
      setLoading(false);
      setModal({ show: true, type: 'success' });
      form.resetFields();
      setTimeout(() => {
        getGoods();
        setDisabled(false);
        Save(
          {
            goodsContentStatus: 'GoodsList',
            expandedItem: undefined,
          },
          'app'
        );
      }, 1500);
    } else {
      Save({ errorGoodsModal: true }, 'app');

      setTimeout(() => {
        setDisabled(false);
        Save({ errorGoodsModal: false }, 'app');
      }, 1500);
    }
    localStorage.setItem('isFieldsTouched', JSON.stringify(false));
  };

  const getCategories = async () => {
    await CategoryServices.getCategory().then((res) => {
      if (res) {
        for (let i of res.results) {
          if (i.id === category) {
            setcategoryName(i.category);
          }
        }
      }
    });
  };

  useEffect(() => {
    getCategories();
  }, [categoryName]);

  const removeFilePress = async (fileId: number) => {
    if (!files) {
      return;
    }
    let newgoodsImage = [...files];
    let typeOfFile = newgoodsImage.find(function (value: any) {
      return value.id === fileId;
    });
    let editedgoodsImage = newgoodsImage.filter(function (value: any) {
      return value.id !== fileId;
    });
    if (typeOfFile?.type !== 'unUploaded') {
      let result = await DeleteUploadedFilesServices.deleteUploadedFiles(
        'good_attachment',
        fileId
      );
      if (result) {
        setFiles(editedgoodsImage);
      }
    } else {
      setFiles(editedgoodsImage);
    }
    // Save({ goodsImage: editedgoodsImage }, "app");
  };

  const isFieldsTouched = form.isFieldsTouched([
    'items',
    'name',
    'keep',
    'sizes',
    'tendency',
    'description',
  ]);

  useEffect(() => {
    if (isFieldsTouched) {
      localStorage.setItem('isFieldsTouched', JSON.stringify(true));
    }
  }, [isFieldsTouched]);

  return (
    <>
      <Card
        className="froms-container"
        title={name}
      >
        {!editable && (
          <CollapseItems
            onClick={() => {
              return Save(
                {
                  expandedItem: undefined,
                  goodsContentStatus: 'NotShow',
                  editable: true,
                },
                'app'
              );
            }}
          />
        )}
        <Form
          layout="vertical"
          initialValues={{
            description: description,
            name: name,
            whereToKeep: data?.where_to_keep?.name
              ? data.where_to_keep.name
              : 'Designer choice',
            tendency: data.tendency,
          }}
          form={form}
        >
          <Row>
            <Col
              xs={24}
              xl={8}
              key={'idx2'}
            >
              <Form.Item
                label={'Item'}
                name="items"
              >
                <Input
                  readOnly
                  defaultValue={data.item.item}
                  style={{ width: '100%', textTransform: 'capitalize' }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="row-more-details">
            <Col
              xl={8}
              xs={24}
            >
              <Form.Item
                label={'Name'}
                name="name"
                rules={[{ required: true, message: 'filed is required' }]}
              >
                <Input
                  readOnly={!editable}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                  style={{ width: '100%' }}
                  placeholder="Bed, inherited from my grandma"
                />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              xl={8}
              key={'idx1'}
            >
              <Form.Item
                label={'Where to keep'}
                name="whereToKeep"
              >
                <Select
                  onChange={(value) => setWhereToKeep(value)}
                  style={{ width: '100%' }}
                  value={whereToKeep}
                  disabled={!editable}
                >
                  {rooms &&
                    rooms.length !== 0 &&
                    rooms.map((room: any) => {
                      return (
                        <Option
                          value={room.id}
                          key={room.id}
                        >
                          {room.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xl={8}
              xs={24}
              className="sec-col-goods"
            >
              <Form.Item
                label={'My tendency to keep this Item (0 to 10)'}
                name="tendency"
              >
                <Slider
                  trackStyle={
                    tendency <= 5
                      ? {
                          background:
                            'linear-gradient(to right, #ffffff 0%, rgb(241 154 198) 100%)',
                        }
                      : tendency > 5 && tendency <= 8
                      ? {
                          background:
                            'linear-gradient(to right, #ffffff 0%, rgb(255 51 153 / 57%)  100%)',
                        }
                      : tendency >= 9
                      ? {
                          background:
                            'linear-gradient(to right, #ffffff 0%, #ff3399 100%)',
                        }
                      : { background: 'red' }
                  }
                  min={0}
                  max={10}
                  tooltipVisible
                  defaultValue={5}
                  onChange={(value) => setTendency(value)}
                  value={typeof tendency === 'number' ? tendency : 0}
                  disabled={!editable}
                />
              </Form.Item>
            </Col>
          </Row>

          {data && data.item && data.item.image && (
            <Row>
              <Col
                className="goods-image"
                xl={10}
                xs={24}
              >
                <img
                  src={`${URL}${data.item.image}`}
                  alt=""
                />
              </Col>
              <Col
                xl={12}
                xs={12}
                className="measure-row"
              >
                {data.sizes &&
                  data.sizes.length !== 0 &&
                  data.sizes.map((item: any, idx: number) => (
                    <div
                      key={idx}
                      className="dimentions"
                    >
                      <Form layout="vertical">
                        <Form.Item
                          label={item.title}
                          name="sizes"
                          rules={[
                            {
                              required: true,
                              message: 'Only digits',
                            },

                            {
                              type: 'number',
                              message: 'Only digits',
                            },
                          ]}
                        >
                          <InputNumber
                            readOnly={!editable}
                            type="number"
                            defaultValue={sizes[idx].size}
                            value={sizes[idx].size}
                            min={0}
                            onChange={(e) => {
                              setSizes((prevSize: any) => {
                                let newSizes: any = [...prevSize];
                                newSizes[idx].size = e;
                                return newSizes;
                              });
                            }}
                          />
                        </Form.Item>
                      </Form>
                    </div>
                  ))}
              </Col>
            </Row>
          )}

          <Row className="description-row">
            <Col
              xl={24}
              xs={24}
            >
              <Form.Item
                name="description"
                label="Description"
              >
                <TextArea
                  readOnly={!editable}
                  defaultValue={description}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={6}
                />
              </Form.Item>
            </Col>
          </Row>
          {((role === 'client' && !checkboxStatus?.goods) ||
            role === 'admin') && (
            <div className="upload-file-container">
              <div className="uploadFile-row">
                <UploadFile
                  title={'Upload photos of your goods'}
                  text="Drag and drop files here"
                  setFiles={setFiles}
                  width={'100%'}
                  height={80}
                  disabled={!editable}
                />
              </div>
              <br />
            </div>
          )}
          <div className="file-items-container">
            {files &&
              files.map((item: any, idx: number) => {
                const { name, id, size, type, attachment_title, format } = item;
                const isPNG =
                  name?.toUpperCase().includes('PNG') ||
                  name?.toUpperCase().includes('JPG') ||
                  name?.toUpperCase().includes('JPEG') ||
                  attachment_title?.toUpperCase().includes('PNG') ||
                  attachment_title?.toUpperCase().includes('JPG') ||
                  attachment_title?.toUpperCase().includes('JPEG');
                return (
                  <FileItem
                    isEditable={isPNG}
                    key={id}
                    format={format}
                    disabled={role === 'client' ? checkboxStatus?.goods : false}
                    fileData={item.attachment}
                    name={type === 'unUploaded' ? name : attachment_title}
                    id={id}
                    size={size}
                    file={item}
                    // type={!type ? 'uploaded' : 'unUploaded'}
                    type={item?.type}
                    removeFilePress={removeFilePress}
                    showImagePress={() => showCarouselImage(idx)}
                    width={'100%'}
                    onEdit={(rotateImageData: any) => {
                      setIsRotateImageActive(true);
                      setRotateImageData(rotateImageData);
                    }}
                    degree={degree}
                  />
                );
              })}
          </div>
          {editable && (
            <div className="goods-btns">
              {((role === 'client' && !checkboxStatus?.goods) ||
                role !== 'client') && (
                <Button
                  className="save-btn"
                  disabled={disabled}
                  loading={loading}
                  onClick={() => editGood()}
                >
                  {strings.save}
                </Button>
              )}
              <Button
                onClick={() => {
                  Save(
                    { expandedItem: undefined, goodsContentStatus: 'NotShow' },
                    'app'
                  );
                  localStorage.setItem(
                    'isFieldsTouched',
                    JSON.stringify(false)
                  );
                }}
                className="cancel-btn"
              >
                {strings.cancel}
              </Button>
            </div>
          )}
        </Form>
      </Card>
      {showCarousel && (
        <CarouselModal
          onCancelPress={() => setShowCarousel(false)}
          isModalVisible={showCarousel}
          imageArray={files}
          defaultIndex={carouselIndex}
        />
      )}

      <RotateImage
        files={files}
        setFiles={setFiles}
        onFinish={() => {
          setIsRotateImageActive(false);
          setDegree(0);
        }}
        isRotateImageActive={isRotateImageActive}
        rotateImageData={rotateImageData}
        onEdit={() => setDegree((prev: number) => prev + 90)}
        degree={degree}
      />

      {modal.type === 'success' && modal.show && (
        <Alert
          description={'The good has been edited successfully'}
          type="success"
        />
      )}
      {modal.type === 'error' && modal.show && <Alert type="error" />}
      {modal.type === 'warning' && modal.show && (
        <Alert
          description={strings.pleaseFillAllInputs}
          type="warning"
        />
      )}
    </>
  );
};

export default memo(EditDetails);

import { api } from 'api/api';
import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';
export const deleteUploadedFiles = (field_name: string) => {};
export const DeleteUploadedFilesServices = {
  deleteUploadedFiles: async (
    field_name: string,
    object_pk: number,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(`/delete-file/${field_name}/${object_pk}/`, null, true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('CategoryServices -> getCategory: ', err);
        return null;
      });
  },
};

import React from 'react';
import { Button, Table } from 'antd';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import './styles.css';

const ClientTable = ({ users, addClientToProject }: any) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'preferred_name',
      key: 'name',
      // responsive: ["xs"]
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '5%',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      // responsive: ["xs"]
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '5%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'Role',
      // responsive: ["xs"]
      responsive: ['md', 'xs'] as Breakpoint[],
      width: '5%',
    },
    {
      title: '',
      key: 'tags',
      dataIndex: 'tags',
      // responsive: ["xs"],
      responsive: ['md', 'xs'] as Breakpoint[],
      render: (text: any, record: any) => {
        if (localStorage.getItem('userRole') === 'admin') {
          return (
            <Button
              size='middle'
              className='add'
              onClick={() => addClientToProject()}>
              {'Add '}
            </Button>
          );
        } else {
          return <></>;
        }
      },
      width: '5%',
    },
  ];
  return (
    <div className='table'>
      <Table dataSource={users} columns={columns} pagination={false} />
    </div>
  );
};

export default ClientTable;

import CirclePlusButtonView from './circlePlusButton-view';
import { Props } from './models';

export const CirclePlusButton = (props: Props) => {
  let { btnText } = props;
  return (
    <CirclePlusButtonView
      {...props}
      btnText={btnText}
    />
  );
};

export default CirclePlusButton;

import { useState } from 'react';
import MessageBoardItemView from './messageBoardItem-view';
import { Props, ContentStatusType } from './models';

import { useRedux } from 'hooks/index';

export const MessageBoardItem = (props: Props) => {
  const { Save } = useRedux();

  const [contentStatus, setContentStatus] = useState<ContentStatusType>('Edit');
  const setExpandedItem = (value: number | undefined) => {
    Save({ expandedItem: value }, 'app');
  };
  return (
    <MessageBoardItemView
      setContentStatus={setContentStatus}
      contentStatus={contentStatus}
      setExpandedItem={setExpandedItem}
      {...props}
    />
  );
};

export default MessageBoardItem;

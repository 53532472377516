import { api } from 'api/api';
import { apiErrorMessage } from '../../helpers/apiErrorHandling.js';

const ENDPOINTS = {
  meetings: '/projects/{project_pk}/meetings/',
  meetingsById: '/projects/{project_pk}/meetings/{id}/',
  meetingTitle: '/meeting-titles/',
};

export const MeetingsServises = {
  createMeetings: async (
    data: any,
    project_pk: string,
    customHeader: object
  ) => {
    return await api
      .post(
        ENDPOINTS.meetings.replace('{project_pk}', project_pk),
        data,
        null,
        true,
        customHeader
      )
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        if (err.toLowerCase().includes('timeout')) {
          return true;
        }
        return null;
      });
  },
  getMeetings: async (
    project_pk: string,
    params: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(ENDPOINTS.meetings.replace('{project_pk}', project_pk), params, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('meetingsServices -> meetings: ', err);
        return null;
      });
  },
  getMeetingsById: async (
    data: any,
    project_pk?: number,
    meetings_id?: number,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .get(`/projects​/${project_pk}​/meetings​/${meetings_id}`, data, true)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('meetingsServices -> meetings: ', err);
        return null;
      });
  },

  deleteMeetingsById: async (
    project_pk: string,
    meetings_id: string,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .delete(
        ENDPOINTS.meetingsById
          .replace('{project_pk}', project_pk)
          .replace('{id}', meetings_id),
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('meetingsServices -> meetings: ', err);
        return null;
      });
  },
  editMeetings: async (
    project_pk: number,
    meetingId: number,
    data: any,
    badRequestCallback?: (err: any) => void
  ) => {
    return await api
      .patch(
        ENDPOINTS.meetingsById
          .replace('{project_pk}', project_pk.toString())
          .replace('{id}', meetingId.toString()),
        data,
        null,
        true
      )
      .then((response) => {
        return response.data;
      })
      .catch((err: any) => {
        if (err.toLowerCase().includes('timeout')) {
          return true;
        }
        return null;
      });
  },
  getTitleMeeting: async (badRequestCallback?: (err: any) => void) => {
    return await api
      .get(ENDPOINTS.meetingTitle)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        badRequestCallback
          ? badRequestCallback(err)
          : apiErrorMessage('meetingsServices -> meetings: ', err);
        return null;
      });
  },
};

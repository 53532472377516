import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ProjectsService } from 'src/api/services';
import { InspirationItem } from 'components/index';
import './styles.css';
import { message } from 'antd';

const InspirationListView = ({ insprationData }: any) => {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [inspoWeb, setinspoWeb] = useState([]);
  const [inspoPhoto, setInspoPhoto] = useState<any>([]);
  const [inspoPinterest, setInspoPinterest] = useState([]);
  const [expandedItem, setExpandedItem] = useState<number>();
  const [inspoHouzz, setinspoHouzz] = useState([]);

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];
  let insprationWebsites: any = [];
  let insprationPhotos: any = [];
  let insprationPinterest: any = [];
  let inspirarionHouzz: any = [];
  const insprationList = async () => {
    await ProjectsService.projectDetail(idOfproject).then((res) => {
      setLoading(true);
      if (res) {
        setLoading(false);
        insprationWebsites = res.websites;
        insprationPhotos = res.photo_and_videos;
        insprationPinterest = res.pinterest_board_links;
        inspirarionHouzz = res.houzz_Idea_book;
        setinspoWeb(insprationWebsites);
        setInspoPhoto(insprationPhotos);
        setInspoPinterest(insprationPinterest);
        setinspoHouzz(inspirarionHouzz);
      }
    });
  };

  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    if (mounted) {
      insprationList();
    }
    return () => setMounted(false);
  }, []);

  const deletePhotoOnPress = async (id: any, type: any) => {
    let newInspoPhotos = inspoPhoto.map((item: any) => {
      if (item.id == id) {
        return { ...item, attachment: '', attachment_title: '' };
      } else {
        return item;
      }
    });

    setInspoPhoto(newInspoPhotos);
  };

  return (
    <>
      <div className="infinite-projectData">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={() => {}}
          hasMore={!loading && hasMore}
          useWindow={false}
        >
          {inspoWeb &&
            inspoWeb.length !== 0 &&
            inspoWeb.map((web, index) => {
              return (
                <div key={index}>
                  <InspirationItem
                    expandedItem={expandedItem}
                    item={web}
                    setExpandedItem={setExpandedItem}
                    typeInspration="web"
                    insprationList={insprationList}
                  />
                </div>
              );
            })}
          {inspoPhoto &&
            inspoPhoto.length !== 0 &&
            inspoPhoto.map((photo: any, index: any) => {
              photo = { ...photo, rotate_angle: 0 };
              return (
                <div key={index}>
                  <InspirationItem
                    expandedItem={expandedItem}
                    item={photo}
                    deleteonPress={deletePhotoOnPress}
                    setExpandedItem={setExpandedItem}
                    typeInspration="photo"
                    insprationList={insprationList}
                  />
                </div>
              );
            })}
          {inspoPinterest &&
            inspoPinterest.length !== 0 &&
            inspoPinterest.map((pinterest, index) => {
              return (
                <div key={index}>
                  <InspirationItem
                    expandedItem={expandedItem}
                    item={pinterest}
                    setExpandedItem={setExpandedItem}
                    typeInspration="pinterest"
                    insprationList={insprationList}
                  />
                </div>
              );
            })}{' '}
          {inspoHouzz &&
            inspoHouzz.length !== 0 &&
            inspoHouzz.map((houzz, index) => {
              return (
                <div key={index}>
                  <InspirationItem
                    expandedItem={expandedItem}
                    item={houzz}
                    setExpandedItem={setExpandedItem}
                    typeInspration="houzz"
                    insprationList={insprationList}
                  />
                </div>
              );
            })}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InspirationListView;

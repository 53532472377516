// @ts-nocheck
import './styles.css';
import { useRedux } from 'hooks/index';
import { LongItem } from 'components/index';
import { EditDetails } from '../index';

const RoomItemView = (props: any) => {
  const { data, setRemoveModal, setRemoveItem, getRooms } = props;
  const { Get } = useRedux();
  const { expandedItem } = Get().app;
  let newFiles: any = [];
  let newFloorPlan: any = [];
  if (data.photo_or_video) {
    for (let perFile of data.photo_or_video) {
      let file = {
        ...perFile,
        type: 'uploaded',
        rotate_angle: 0,
        size: {
          type: perFile.get_photo_or_video_size < 100000 ? 'KB' : 'MB',
          size:
            perFile.get_photo_or_video_size < 100000
              ? Math.round(perFile.get_photo_or_video_size / 10) / 10
              : Math.round(perFile.get_photo_or_video_size / 100000) / 10,
        },
      };
      newFiles.push(file);
    }
  }
  if (data.floor_plan) {
    for (let perFile of data.floor_plan) {
      let file = {
        ...perFile,
        type: 'uploaded',
        rotate_angle: 0,
        size: {
          type: perFile.get_floor_plan_size < 100000 ? 'KB' : 'MB',
          size:
            perFile.get_floor_plan_size < 100000
              ? Math.round(perFile.get_floor_plan_size / 10) / 10
              : Math.round(perFile.get_floor_plan_size / 100000) / 10,
        },
      };
      newFloorPlan.push(file);
    }
  }

  return (
    <>
      {expandedItem === data.id ? (
        <EditDetails
          data={data}
          attachments={newFiles}
          getRooms={getRooms}
          floorPlanAttachments={newFloorPlan}
        />
      ) : (
        <LongItem
          setRemoveModal={setRemoveModal}
          setRemoveItem={setRemoveItem}
          type={'Rooms'}
          data={data}
        />
      )}
    </>
  );
};

export default RoomItemView;

import React from "react";
import "./styles.css";
import { ViewProps } from "./models";
import ProjectTable from "../../Table";
const UserView = (props: ViewProps) => {
  return (
    <div>
      <ProjectTable />
    </div>
  );
};

export default UserView;

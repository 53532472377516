import "./styles.css";
import { Card, Row } from "antd";
import { UserForm, UserFormEditMood } from "./components/index";
import { useRedux } from "src/hooks";
import { CirclePlusButton } from "components/index";
import { strings } from "common/languages/index";

const AddUserView = ({ editMood, addMood, getUsers, referrer }: any) => {
  const { Get, Save } = useRedux();
  const { userEditeMoodData, userStatusConten } = Get().app;

  const TopRow = () => {
    return (
      <div className="topRow-container">
        <CirclePlusButton
          onClick={() => {
            Save({ userStatusConten: "addUser" }, "app");
          }}
          btnText={strings.addNewUser}
        />
      </div>
    );
  };
  return (
    <div>
      <TopRow />
      {userStatusConten === "notShow" ? null : (
        <>
          <Card>
            <Row>
              {userStatusConten === "addUser" && addMood && (
                <UserForm getUsers={getUsers} referrer={referrer} />
              )}
              {userStatusConten === "editeUser" && editMood && (
                <UserFormEditMood
                  data={userEditeMoodData}
                  getUsers={getUsers}
                  referrer={referrer}
                />
              )}
            </Row>
          </Card>
          <Row style={{ margin: "23px 28px  2px" }}>
            {/* <Checkbox>{strings.ReceivesNotifications}</Checkbox>
            <Checkbox>{strings.SendTheNewUseremailAboutTheirAccount}</Checkbox> */}
          </Row>
        </>
      )}
    </div>
  );
};

export default AddUserView;

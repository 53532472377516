import { memo, useState, useEffect } from 'react';
import { Modal, Checkbox, Spin } from 'antd';
import { useRedux } from 'src/hooks';
import { NotificationsServices, ProjectsService } from 'api/services/index';
import { PenSVG, DownArrowSVG, EyeSVG } from 'assets/index';
import ClientInfo from './ClientInfo';
import { ConfirmModal } from 'components/index';
// import { ProjectsService, AuthServices } from "src/api/services";

const ProjectItem = (props: any) => {
  const { data, title, getProject } = props;
  const { Get, Save } = useRedux();
  const { expandedItem } = Get().app;

  let path = window.location.pathname;
  let idPath = path.split('/');
  const idOfproject = idPath[2];

  const role = localStorage.getItem('userRole');
  const { checkboxStatus } = Get().app;

  const [client, setClient] = useState<any>();
  const [checkStatus, setCheckStatus] = useState(checkboxStatus?.clientInfo);
  const [checkStatusModal, setCheckStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const editStateOfCheckbox = async (isDataComplete: boolean) => {
    setLoading(true);

    let result = await ProjectsService.editProject(idOfproject, {
      information_completed: isDataComplete,
    });

    if (result) {
      Save(
        { checkboxStatus: { ...checkboxStatus, clientInfo: isDataComplete } },
        'app'
      );
      localStorage.setItem('information_compeleted', `${isDataComplete}`);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const getClientId = () => {
    if (data && data.users) {
      let client = data.users.filter((e: any) => e.role === 'client');
      setClient(client);
    }
  };

  useEffect(() => {
    getClientId();
  }, []);
  const sendCheckStatusClientInfo = async () => {
    return await NotificationsServices.sendCheck(idOfproject, 'user_info')
      .then((res) => {
        if (res) {
          setCheckStatusModal(false);
          setCheckStatus(true);
          setTimeout(() => {
            editStateOfCheckbox(true);
          }, 1000);
        }
      })
      .catch((e) => {
        return Modal.error({
          title: 'something went wrong, please try again later !',
        });
      });
  };
  useEffect(() => {}, [checkboxStatus?.clientInfo]);
  return (
    <>
      <div className="client-info-checkbox-container">
        <span>
          <span className="checkbox-text">{"I've added my information"}</span>{' '}
          <Checkbox
            disabled={
              role !== 'admin'
                ? checkboxStatus?.clientInfo
                  ? true
                  : false
                : false
            }
            onChange={() => {
              if (!checkStatus) {
                setCheckStatusModal(true);
              } else {
                setCheckStatus(false);
                editStateOfCheckbox(false);
              }
            }}
            checked={checkboxStatus?.clientInfo}
          />
        </span>
      </div>
      {expandedItem === -1 ? (
        <ClientInfo
          data={data}
          getProject={getProject}
          client={client}
        />
      ) : (
        <>
          <div className="longItem-with-detail">
            <div className="longItem-container">
              <span
                className="title"
                onClick={() => {
                  Save(
                    { expandedItem: -1, clientInformationStatus: 'edit' },
                    'app'
                  );
                }}
              >
                {title ? title : data.name}
              </span>
              <div className="right-container">
                {role !== 'client' && (
                  <div
                    onClick={() => {
                      Save(
                        {
                          expandedItem: -1,
                          clientInformationStatus: 'edit',
                          editable: false,
                        },
                        'app'
                      );
                    }}
                    className="eye-container"
                  >
                    <img
                      src={EyeSVG}
                      alt=""
                    />
                  </div>
                )}

                <div
                  onClick={() => {
                    Save(
                      {
                        expandedItem: -1,
                        clientInformationStatus: 'edit',
                        editable: true,
                      },
                      'app'
                    );
                  }}
                  className="pen-container"
                >
                  {loading ? (
                    <Spin />
                  ) : !checkboxStatus?.clientInfo || role === 'admin' ? (
                    <img
                      src={PenSVG}
                      alt={'pen'}
                    />
                  ) : (
                    <img
                      src={DownArrowSVG}
                      alt=""
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ConfirmModal
        deleteModal={false}
        handleOk={async () => {
          await sendCheckStatusClientInfo();
        }}
        handleCancel={() => setCheckStatusModal(false)}
        isModalVisible={checkStatusModal}
        okText={'Cancel'}
        cancelText={'YES,I have added my information'}
        questionTopText={
          "Are you sure that you have added all your information? After this step, you won't be able to edit or add information. "
        }
        questionText={''}
      />
    </>
  );
};

export default memo(ProjectItem);

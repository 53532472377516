import axios from 'axios';
import { message } from 'antd';
export const PROTOCOL = 'https://';
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}/v1`;
export const ENDPOINT_LEVEL = 'v1/';
export const TOKEN_PREFIX = 'Token';
export const URL = process.env.REACT_APP_BASE_URL;

export const getAuthToken = async () => {
  if (localStorage.getItem('token')) {
    return `${TOKEN_PREFIX} ${localStorage.getItem('token')}`;
  } else {
    return '';
  }
};
export const fetchUrl = async (options, alert, onUploadProgress) => {
  return new Promise((resolve, reject) => {
    axios
      .request({
        ...options,
        baseURL: options.baseURL ? options.baseURL : `${URL}/v1`,
        headers: {
          ...options.headers,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },

        timeout: 1000000,
        onUploadProgress: (progress) => {
          if (onUploadProgress) {
            onUploadProgress(progress);
          }
        },
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (err.message.includes('403')) {
          return message.error({
            content: 'you do not have permission for this action',
          });
        }
        if (err.message.includes('Network Error')) {
          return message.error({
            content: 'Network Error',
          });
        }

        return reject(err);
      });
  });
};

export const api = {
  get: async (url, params = null, authorization = true, alert) => {
    let options = { method: 'GET', url: url, params, headers: {} };
    if (authorization) {
      options = {
        ...options,
        headers: {
          Authorization: await getAuthToken(),
        },
      };
    }
    return fetchUrl(options, alert);
  },
  post: async (
    url,
    data,
    params,
    authorization = true,
    customHeader,
    onUploadProgress
  ) => {
    let options = {
      method: 'POST',
      url: url,
      data,
      ...params,
      headers: { ...customHeader },
    };
    if (authorization) {
      options = {
        ...options,
        headers: {
          ...customHeader,
          Authorization: await getAuthToken(),
        },
      };
    }
    return fetchUrl(options, alert, onUploadProgress);
  },
  put: async (
    url,
    data,
    params,
    authorization = true,
    alert,
    onUploadProgress
  ) => {
    let options = { method: 'PUT', url: url, data, params, headers: {} };
    if (authorization) {
      options = {
        ...options,
        headers: {
          Authorization: await getAuthToken(),
        },
      };
    }
    return fetchUrl(options, alert, onUploadProgress);
  },
  patch: async (
    url,
    data,
    params,
    authorization = true,
    alert,
    onUploadProgress
  ) => {
    let options = { method: 'PATCH', url: url, data, params, headers: {} };
    if (authorization) {
      options = {
        ...options,
        headers: {
          Authorization: await getAuthToken(),
        },
      };
    }
    return fetchUrl(options, alert, onUploadProgress);
  },
  delete: async (url, params, authorization = true, alert) => {
    let options = { method: 'DELETE', url: url, params, headers: {} };
    if (authorization) {
      options = {
        ...options,
        headers: {
          Authorization: await getAuthToken(),
        },
      };
    }
    return fetchUrl(options, alert);
  },
};

import { useState } from "react";
import { useRedux } from "hooks/index";
import ConfirmModalView from "./confirmModals-view";
import { Props } from "./models";
import "./styles.css";

const ConfirmModal = (props: Props) => {
  const { Save } = useRedux();
  const { handleOk, handleCancel, deleteModal } = props;
  const [disabled, setDisabled] = useState(false);

  const finallHandleOk = () => {
    if (handleCancel) {
      setDisabled(true);
      handleCancel();
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    } else {
      setDisabled(false);
      Save({ showModal: false }, "app");
    }
  };

  const finallHandleCancel = () => {
    if (handleOk) {
      setDisabled(true);
      handleOk();
      setTimeout(() => {
        setDisabled(false);
      }, 2000);
    } else {
      setDisabled(false);
      Save({ showModal: false }, "app");
    }
  };

  return (
    <ConfirmModalView
      disable={disabled}
      deleteModal={deleteModal}
      finallHandleOk={finallHandleOk}
      finallHandleCancel={finallHandleCancel}
      {...props}
    />
  );
};
export default ConfirmModal;

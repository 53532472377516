import {
  USER_CHANGE_DATA,
  USER_INSERT_DATA,
  USER_RESET_DATA,
} from "../../actions/user/actionType";
import { UserStates } from "./models";

const INITIAL_STATE: UserStates = {
  userData: {
    bio: "",
    preferredName: "",
    lastName: "",
    firstName: "",
    role: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    rePassword: "",
    projectId: 0,
  },
};

export default (
  state = INITIAL_STATE,
  action: { type: string; value?: UserStates }
) => {
  switch (action.type) {
    case USER_CHANGE_DATA:
      return { ...state, ...action.value };

    case USER_INSERT_DATA:
      return { ...state, ...action.value };

    case USER_RESET_DATA:
      return INITIAL_STATE;
    default:
      return state;
  }
};
